import { Map } from 'immutable';
import { Alert } from 'react-bootstrap';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeNotification } from 'core/actions';
import { getNotification } from 'core/reducer';

export const Notification: React.FC = () => {
  const dispatch = useDispatch();
  const notification = useSelector(getNotification);

  const clearNotification = () => {
    dispatch(removeNotification());
  };

  useEffect(() => {
    return () => {
      clearNotification();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (notification.equals(Map({ message: null, level: null, code: null }))) {
    return null;
  }

  return (
    <Alert
      className="global-notification"
      bsStyle={notification.get('level')}
      onDismiss={clearNotification}
      data-testid="global-notification"
    >
      <p>{notification.get('message')}</p>
    </Alert>
  );
};
