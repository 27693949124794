import { useConfig, useSetConfig } from 'contexts/ConfigContext';

import { ControlLabel, FormControl } from 'react-bootstrap';

export const PrivacyPolicyUrlInput: React.FC = () => {
  const { privacyPolicyUrl } = useConfig();
  const setConfig = useSetConfig();

  const handleChange = (event: React.ChangeEvent<FormControl>) => {
    // This is a tad annoying having to do this, but react-bootstrap sucks
    const value: string = (event.target as unknown as HTMLInputElement).value;
    setConfig('privacyPolicyUrl', value);
  };

  return (
    <div>
      <ControlLabel className="ff-label">
        Site title (text in tab):
      </ControlLabel>
      <FormControl
        type="text"
        value={privacyPolicyUrl}
        placeholder="Enter text"
        onChange={handleChange}
        id="privacy-policy-url"
      />
    </div>
  );
};
