import { PromotionLine, BasketAddition } from 'types/models/Basket';
import { CheckBasketResponseProductLine } from 'types/models/Responses/CheckBasketResponse';

export const isBasketAddition = (
  line: CheckBasketResponseProductLine | PromotionLine | BasketAddition,
): line is BasketAddition => {
  return (line as BasketAddition).displayName !== undefined;
};

export const isPromotionLine = (
  line: CheckBasketResponseProductLine | PromotionLine | BasketAddition,
): line is PromotionLine => {
  return (line as PromotionLine).promotionId !== undefined;
  // What scenario could this happen - loyalty rewards are passed through differently
  //   && !(line as PromotionLine).loyaltyRewardId
};
