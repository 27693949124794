export const getCurrencySymbol = (currency: string): string | undefined => {
  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: currency || 'GBP',
  };

  return new Intl.NumberFormat(navigator.language, options)
    .formatToParts()
    .find((x) => x.type === 'currency')?.value;
};
