import { CombinedVenue } from 'types/models';
import { VenueImage } from 'venue/components/SingleVenue';
import { VenueInformation } from 'venue/components/SingleVenue/VenueInfo';

interface VenueInfoDesktopProps {
  venue: CombinedVenue;
}

export const VenueInfoDesktop: React.FC<VenueInfoDesktopProps> = ({
  venue,
}) => {
  const image = venue?.displayImages[0];
  return (
    <div className="venue-info-desktop panel panel-default">
      <VenueImage image={image} />
      <VenueInformation venue={venue} />
    </div>
  );
};
