import { Dialog } from 'components/Dialog';

interface ConfirmAllergenDialogProps {
  proceed: () => void;
  dismiss: () => void;
}

export const ConfirmAllergenDialog: React.FC<ConfirmAllergenDialogProps> = ({
  proceed,
  dismiss,
}) => {
  return (
    <Dialog
      isOpen={true}
      title="Allergies"
      datatestid="allergies-confirmation-modal"
      description="Do you, or a member of your party, have a dietary allergy or intolerance?"
      accept={{
        action: proceed,
        text: 'Yes',
        btnType: 'default',
        datatestid: 'allergies-button-confirm',
      }}
      reject={{
        action: dismiss,
        text: 'No',
        datatestid: 'allergies-button-cancel',
      }}
    />
  );
};
