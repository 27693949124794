import { useSelector } from 'react-redux';
import { selectUser } from 'user/reducer';
import { LoginMethod } from 'types/models/User';

export const EmailConfirmation: React.FC = () => {
  const user = useSelector(selectUser).toJS();
  const { loginMethod } = user;

  switch (loginMethod) {
    case LoginMethod.User:
    case LoginMethod.Sso:
      return (
        <p>{`A confirmation email has been sent to ${user.user.email}`}</p>
      );
    case LoginMethod.Anonymous:
      return (
        <p>{`A confirmation email has been sent to ${user.guestEmail}`}</p>
      );
    default:
      return null;
  }
};
