import { useConfig } from 'contexts/ConfigContext';
import { MenuGroupItemImage } from 'types/models';

interface ImageItemProps {
  imageItem: MenuGroupItemImage;
}

export const ImageItem: React.FC<ImageItemProps> = ({ imageItem }) => {
  const { galleryViewEnabled } = useConfig();
  return (
    <div className={galleryViewEnabled ? 'gallery-image' : ''}>
      <img
        src={imageItem.image.imageURL}
        //   width={imageItem.image.dimensions.width} /* ignoring dimensions in favour of the responsive class */
        //   height={imageItem.image.dimensions.height}
        className="menu-group-image"
        alt="" /* ally: decorative */
      />
    </div>
  );
};
