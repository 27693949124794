import { ModalFooter } from 'components/Modal';
import {
  ConfirmButton,
  FooterQuantityField,
  CancelButton,
} from 'menu/components/MenuModal/MenuModalFooter';
import { MinimumAgeDisclaimer } from 'menu/components/MinimumAge';
import { AztecProduct } from 'types/models';
import { CalorieAllowancePhrase } from 'menu/components/Calories';

interface MenuModalFooterProps {
  aztecProduct: AztecProduct;
  updateAztecProduct: (product: AztecProduct) => void;
  handleCancel: () => void;
  handleConfirm: () => void;
}

export const MenuModalFooter: React.FC<MenuModalFooterProps> = ({
  aztecProduct,
  updateAztecProduct,
  handleCancel,
  handleConfirm,
}) => {
  const minCustomerAge = aztecProduct.minimumCustomerAge;

  return (
    <ModalFooter>
      <FooterQuantityField
        aztecProduct={aztecProduct}
        updateAztecProduct={updateAztecProduct}
      />
      <CalorieAllowancePhrase classNameOveride="calorie-intake-footer" />
      {minCustomerAge ? <MinimumAgeDisclaimer minAge={minCustomerAge} /> : null}
      <ConfirmButton
        aztecProduct={aztecProduct}
        handleConfirm={handleConfirm}
      />
      <CancelButton handleCancel={handleCancel} />
    </ModalFooter>
  );
};
