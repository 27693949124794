import {
  ErrorResponse,
  GetHotelRoomFoliosResponse,
  GetHotelsResponse,
  MakeChargeToRoomResponse,
} from 'types/models';

export const isRoomFolios = (
  response: GetHotelRoomFoliosResponse | ErrorResponse | undefined,
): response is GetHotelRoomFoliosResponse => {
  return (
    response !== undefined &&
    (response as GetHotelRoomFoliosResponse).folios !== undefined
  );
};

export const isRoomFoliosError = (
  response: GetHotelRoomFoliosResponse | ErrorResponse | undefined,
): response is ErrorResponse => {
  return response !== undefined && (response as ErrorResponse).code !== 200;
};

export const isHotels = (
  response: GetHotelsResponse | ErrorResponse | undefined,
): response is GetHotelsResponse => {
  return (
    response !== undefined &&
    (response as GetHotelsResponse).hotels !== undefined
  );
};

export const isChargeToRoomError = (
  response: MakeChargeToRoomResponse | ErrorResponse | undefined,
): response is ErrorResponse => {
  return response !== undefined && (response as ErrorResponse).code !== 200;
};
