import { ModalHeader, ModalBody, ModalFooter } from 'components/Modal';
import { usePhrases } from 'contexts/ConfigContext';
import { getRoomPhrase, getRoomSubtitlePhrase } from 'utils';
import { useSalesAreas, useServices, useVenues } from 'contexts/VenueContext';
import { useForm, FieldValues, RegisterOptions } from 'react-hook-form';
import { useHotels } from 'contexts/HotelContext';
import dompurify from 'dompurify';
import { Loader } from 'components/Loader';
import { isRequired } from 'core/components/RHF/validation';
import { getServiceLabel } from 'venue/utils';

interface RoomSelectorProps {
  handleClose: () => void;
  errorMessage: string;
  handleFindRoom: (name: string, roomNumber: string) => void;
  foliosLoading: boolean;
}

export const RoomSelectorModal: React.FC<RoomSelectorProps> = ({
  handleClose,
  errorMessage,
  handleFindRoom,
  foliosLoading,
}) => {
  const { chargeToRoom, roomPhrase, hotelPhrase } = usePhrases();
  const { selectedVenue } = useVenues();
  const { services, selectedService } = useServices();
  const { selectedSalesArea } = useSalesAreas();
  const {
    hotels: { hotelName },
  } = useHotels();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const serviceLabel = getServiceLabel(services, selectedService);
  const sanitizer = dompurify.sanitize;

  const title = getRoomPhrase(
    chargeToRoom.roomSelector.title,
    roomPhrase.toLowerCase(),
  );
  const subtitle =
    selectedVenue && selectedSalesArea
      ? getRoomSubtitlePhrase(
          chargeToRoom.roomSelector.subtitle,
          roomPhrase.toLowerCase(),
          selectedVenue,
          selectedSalesArea,
          hotelPhrase,
          hotelName,
          serviceLabel,
        )
      : '';
  const roomNumber = getRoomPhrase(
    chargeToRoom.roomSelector.roomNumberPhrase,
    roomPhrase,
  );
  const buttonTitle = getRoomPhrase(
    chargeToRoom.roomSelector.button.title,
    roomPhrase.toLowerCase(),
  );

  const submitRoomSearch = (fieldValues: FieldValues) => {
    handleFindRoom(fieldValues.name, fieldValues.roomNumber);
  };

  const roomNumberOptions: RegisterOptions = {
    ...isRequired('Room number', true),
  };

  const nameOptions: RegisterOptions = {
    ...isRequired(chargeToRoom.roomSelector.guestNamePhrase, true),
  };

  return (
    <>
      <ModalHeader closeButton onRequestClose={handleClose}>
        <h2 className="title">{title}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizer(subtitle),
          }}
        />
      </ModalHeader>

      <ModalBody>
        {foliosLoading ? (
          <Loader text="Loading Folios" />
        ) : (
          <>
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            <div className={`form-group ${errors.name ? 'has-error' : ''}`}>
              <input
                {...register('name', nameOptions)}
                placeholder={chargeToRoom.roomSelector.guestNamePhrase}
                className="form-control"
                data-testid="text-guest-name"
                disabled={foliosLoading}
                aria-invalid={errors.name ? 'true' : 'false'}
                aria-required="true"
                aria-label={chargeToRoom.roomSelector.guestNamePhrase}
              />
              {errors && errors['name'] && (
                <span className="text-danger" role="alert">
                  {errors['name']?.message}
                </span>
              )}
            </div>
            <div
              className={`form-group ${errors.roomNumber ? 'has-error' : ''}`}
            >
              <input
                {...register('roomNumber', roomNumberOptions)}
                placeholder={roomNumber}
                className="form-control"
                data-testid="text-room-number"
                disabled={foliosLoading}
                aria-invalid={errors.roomNumber ? 'true' : 'false'}
                aria-required="true"
                aria-label={roomNumber}
              />
              {errors && errors['roomNumber'] && (
                <span className="text-danger" role="alert">
                  {errors['roomNumber']?.message}
                </span>
              )}
            </div>

            {chargeToRoom.roomSelector.supportingText && (
              <div>{chargeToRoom.roomSelector.supportingText}</div>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-primary"
          onClick={handleSubmit(submitRoomSearch)}
          data-testid="btn-submit-charge-to-room-modal"
          disabled={foliosLoading}
        >
          {buttonTitle}
        </button>
        <button
          className="btn btn-default hide-modal-button hide-on-responsive"
          onClick={handleClose}
          data-testid="btn-cancel-charge-to-room-modal"
          disabled={foliosLoading}
        >
          Cancel
        </button>
      </ModalFooter>
    </>
  );
};
