import { FormattedDateTime } from 'common/components/FormattedDateTime';
import { useConfig } from 'contexts/ConfigContext';
import { CustomFormattedNumber } from 'common/components/CustomFormattedNumber';
import { ServiceLabel } from 'core/components';
import { FormatDateOptions } from 'react-intl';
import { Order } from 'types/models/Orders';
import { simpleDateToIsoString } from 'utils';

interface OrderDetailsProps {
  order: Order;
  orderService: number;
  orderLocationName: string;
}
export const OrderDetails: React.FC<OrderDetailsProps> = ({
  order,
  orderService,
  orderLocationName,
}) => {
  const { currency, currencySymbolsDisabled, timeslots24hFormat } = useConfig();

  const formatDateOptions: FormatDateOptions = {
    month: 'long',
    weekday: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: !timeslots24hFormat,
  };

  const formattedDateTime = FormattedDateTime({
    date: new Date(simpleDateToIsoString(order.orderTimestamp)),
    options: formatDateOptions,
  });

  return (
    <>
      <td className="mobile-table-item">
        {orderService ? (
          <>
            <ServiceLabel id={orderService} />
            {` order at ${orderLocationName}, `}
          </>
        ) : null}
        <br />
        {formattedDateTime}
        <br />
        <CustomFormattedNumber
          style={currencySymbolsDisabled ? 'decimal' : 'currency'}
          value={Number(order.grandTotal.toFixed(2))}
          currency={currency}
          minimumFractionDigits={2}
          precedingText='Amount: '
        />
      </td>
    </>
  );
};
