import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { ModalFooter } from 'components/Modal';
import { ServiceModalContext } from 'contexts/ServiceModalContext';
import { isVenueAvailable } from 'venue/utils';
import { useConfig } from 'contexts/ConfigContext';
import { useVenues } from 'contexts/VenueContext';

interface VenueModalFooterProps {
  handleConfirm: () => void;
  disabled: boolean;
  confirmButtonText?: string;
  handleClose?: () => void;
}

export const VenueModalFooter: React.FC<VenueModalFooterProps> = ({
  handleConfirm,
  disabled,
  confirmButtonText = 'View Menu',
  handleClose,
}) => {
  const { onClose } = useContext(ServiceModalContext);
  const { supportsOrderDestinations } = useConfig();
  const { selectedVenue } = useVenues();
  const venueIsAvailable = isVenueAvailable(
    supportsOrderDestinations,
    selectedVenue,
  );

  const handleCloseClick = () => {
    onClose(true);
    if (handleClose) {
      handleClose();
    }
  };
  if (!venueIsAvailable) {
    return (
      <div className="col-xs-12 goto-button-container">
        <Button
          className="view-menu-button"
          bsStyle="primary"
          type="submit"
          disabled
          data-testid="venue-modal-unavailable-button"
        >
          Unavailable
        </Button>
      </div>
    );
  }

  return (
    <ModalFooter>
      <div className="col-xs-12 goto-button-container">
        <Button
          onClick={handleConfirm}
          disabled={disabled}
          className="view-menu-button"
          bsStyle="primary"
          data-testid="venue-modal-view-menu-button"
        >
          {confirmButtonText}
        </Button>
      </div>
      <div className="col-xs-12 goto-button-container">
        <Button
          className="hide-modal-button hide-on-responsive"
          onClick={handleCloseClick}
          data-testid="venue-modal-close-button"
        >
          Close
        </Button>
      </div>
    </ModalFooter>
  );
};
