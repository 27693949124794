import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { Alert } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

interface ParamsProps {
  venueId: string;
  serviceName: string;
  menuId: string;
}

export const LoadingError: React.FC = () => {
  const params = useParams<ParamsProps>();
  const { multipleMenusEnabled } = useConfig();
  const { venueTitle } = usePhrases();

  return (
    <div>
      <Alert bsStyle={'danger'} className="text-center">
        {multipleMenusEnabled
          ? 'There was a problem loading the menu. Please make a different selection.'
          : `There are no menus available at this ${venueTitle.toLocaleLowerCase()}.`}
        <p>
          {multipleMenusEnabled ? (
            <Link
              className="link"
              to={`/venue/${params.venueId}/${params.serviceName}/menus/`}
            >
              Return to Menus
            </Link>
          ) : (
            <Link className="link" to={`/venues`}>
              Return to {venueTitle}s
            </Link>
          )}
        </p>
      </Alert>
    </div>
  );
};
