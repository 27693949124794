import { AztecProduct } from 'types/models';
import { getSelectedUpsells } from 'menu/utils';

interface AndedUpsellLinesProps {
  andedUpsells: AztecProduct[];
}

export const AndedUpsellLines: React.FC<AndedUpsellLinesProps> = ({
  andedUpsells,
}) => {
  const selectedUpsells = getSelectedUpsells(andedUpsells);
  return (
    <>
      {selectedUpsells.map((upsell) => (
        <div key={upsell.id} className="text-muted">
          + {upsell.displayName}
        </div>
      ))}
    </>
  );
};
