import { FormattedDateTime } from 'common/components/FormattedDateTime';
import { useConfig } from 'contexts/ConfigContext';
import { FormatDateOptions } from 'react-intl';
import { useVenues } from 'contexts/VenueContext';
import { Timeslot } from 'types/models';

interface TimeslotOptionProps {
  timeslot: Timeslot;
  datatestid: string;
}

export const TimeslotOption: React.FC<TimeslotOptionProps> = ({
  timeslot,
  datatestid,
}) => {
  const { timeslots24hFormat } = useConfig();
  const { selectedVenue } = useVenues();

  if (!timeslot || !selectedVenue?.timezone) return null;

  const timeslotDate = new Date(timeslot.time);

  const isToday = timeslotDate.toDateString() === new Date().toDateString();

  const formatCommon: FormatDateOptions = {
    timeZone: selectedVenue?.timezone,
    timeZoneName: 'short',
  };

  const formatToday: FormatDateOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: timeslots24hFormat ? 'h23' : 'h12',
    ...formatCommon,
  };

  const formatNextDay: FormatDateOptions = {
    month: 'long',
    weekday: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: timeslots24hFormat ? 'h23' : 'h12',
    ...formatCommon,
  };

  const formattedTimeslot = FormattedDateTime({
    date: timeslotDate,
    options: isToday ? formatToday : formatNextDay,
  });

  return (
    <option
      value={timeslot.time}
      disabled={!timeslot.available}
      data-testid={datatestid}
    >
      {`${isToday ? 'Today, ' : ''}${formattedTimeslot}`}
    </option>
  );
};
