import { useConfig, useSetConfig } from 'contexts/ConfigContext';

import { ControlLabel, FormControl } from 'react-bootstrap';

export const PageTitleInput: React.FC = () => {
  const { pageTitle } = useConfig();
  const setConfig = useSetConfig();

  const handleChange = (event: React.ChangeEvent<FormControl>) => {
    // This is a tad annoying having to do this, but react-bootstrap sucks
    const value: string = (event.target as unknown as HTMLInputElement).value;
    setConfig('pageTitle', value);
  };

  return (
    <div>
      <ControlLabel className="ff-label">
        Site title (text in tab):
      </ControlLabel>
      <FormControl
        type="text"
        value={pageTitle}
        placeholder="Enter text"
        onChange={handleChange}
      />
    </div>
  );
};
