import {
  CardNumber,
  Email,
  Pin,
  Text,
  Password,
  PhoneNumber,
  VirtualSelect,
  Checkbox,
  HTMLField,
  DateOfBirth,
} from 'core/components/RHF/FieldTemplates';
import { Field } from 'types/models/Forms';

interface FieldGeneratorProps {
  field: Field;
}

export const FieldGenerator: React.FC<FieldGeneratorProps> = ({ field }) => {
  // Use userFieldType if available
  // Need to check if it is a html or checkbox as these can have dynamic names
  // Ideally we use the name property to provide extra validation (phone, loyalty, address)
  // Fallback to generic field type

  const formFieldType = (): string => {
    if (field.userFieldType !== undefined && field.userFieldType !== '') {
      return field.userFieldType;
    } else if (field.type === 'html' || field.type === 'checkbox') {
      return field.type;
    } else if (field.name !== undefined && field.name !== '') {
      return field.name;
    } else {
      return field.type;
    }
  };

  switch (formFieldType()) {
    case 'title':
    case 'select':
      return (
        <VirtualSelect formField={field} autocomplete="honorific-prefix" />
      );

    case 'firstName':
    case 'first_name':
      return <Text field={field} autocomplete="given-name" />;

    case 'lastName':
    case 'last_name':
      return <Text field={field} autocomplete="family-name" />;

    case 'address1':
      return <Text field={field} autocomplete="address-line1" />;

    case 'address2':
      return <Text field={field} autocomplete="address-line2" />;

    case 'address3':
      return <Text field={field} autocomplete="address-line3" />;

    case 'country':
      return <Text field={field} autocomplete="country-name" />;

    case 'mobile_number':
    case 'mobile_phone':
    case 'mobilePhone':
    case 'home_phone':
      return <PhoneNumber field={field} />;

    case 'email':
    case 'loyalty_email':
      return <Email field={field} />;

    case 'cardNumber':
    case 'loyalty_card':
      return <CardNumber field={field} />;

    //We're not passing in an autocomplete here because:
    // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#preventing_autofilling_with_autocompletenew-password
    case 'password':
      return <Password field={field} />;

    case 'existing_password':
      return <Password field={field} autocomplete="current-password" />;

    case 'pin':
    case 'loyalty_pin':
      return <Pin field={field} />;

    case 'postcode':
      return <Text field={field} autocomplete="postal-code" />;

    case 'county':
    case 'extra1':
    case 'extra2':
    case 'extra3':
    case 'extra4':
    case 'extra5':
    case 'extra6':
    case 'extra7':
    case 'extra8':
    case 'extra9':
    case 'extra10':
    case 'promo_code':
    case 'town':
    case 'field_1':
    case 'field_2':
      return <Text field={field} />;

    case 'dob':
    case 'date':
      return <DateOfBirth field={field} />;

    case 'text':
      return <Text field={field} />;

    case 'checkbox':
      return <Checkbox field={field} />;

    case 'html':
      return <HTMLField field={field} />;

    default:
      return null;
  }
};
