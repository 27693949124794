import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { usePayMyBill } from 'contexts/PayMyBillContext/PayMyBillContext';
import { QuantityField } from 'menu/components/MenuModal/ModalComponents';
import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { FormattedBill } from 'types/models/PayMyBill';

interface BillEvenSplitProps {
  bill: FormattedBill;
  handlePaymentAmount: (amount: number) => void;
}

export const BillEvenSplit: React.FC<BillEvenSplitProps> = ({
  bill,
  handlePaymentAmount,
}) => {
  const { supportsTip } = useConfig();
  const { payMyBillTotalText, payMyBillBalanceText } = usePhrases();
  const { braintreeLoading } = usePayMyBill();

  const [splitQuantity, setSplitQuantity] = useState<number>(2);
  const [splitAmount, setSplitAmount] = useState<number>(bill.accountTotal / 2);

  const handleQuantityChange = (quantity: number) => {
    setSplitQuantity(quantity);
    setSplitAmount(((bill.accountTotal / quantity) * 100) / 100);
  };

  return bill.accountTotal > 0 ? (
    <>
      <div className="panel panel-default">
        <h4 className="no-margin">
          {'How many ways would you like to split the '}
          <FormattedCurrency value={bill.accountTotal} />
          {` ${payMyBillTotalText} by?`}
        </h4>
        <hr className="primary" />
        <div className="increment-split">
          <QuantityField
            quantity={splitQuantity}
            onChange={handleQuantityChange}
            min={2}
            max={99}
          />
        </div>
        <hr className="primary" />
        {splitAmount < bill.outstandingBalanceToPay ? (
          <div className="payable-amount">
            Amount to pay {supportsTip ? '(excluding tip)' : null}
            <span>
              <FormattedCurrency value={splitAmount} />
            </span>
          </div>
        ) : (
          <div className="split-amount-warning">
            We have reduced the amount to pay to{' '}
            <FormattedCurrency value={bill.outstandingBalanceToPay} />, as the{' '}
            {payMyBillBalanceText} is lower than the split amount.
          </div>
        )}
      </div>
      {splitAmount < bill.outstandingBalanceToPay ? (
        <button
          className="btn btn-primary checkout-fw-button"
          onClick={() => handlePaymentAmount(splitAmount)}
          data-testid="splitwise-confirm-amount-button"
          disabled={braintreeLoading}
        >
          {`Confirm amount (`}
          <FormattedCurrency value={splitAmount} />
          {`)`}
        </button>
      ) : (
        <button
          className="btn btn-primary checkout-fw-button"
          onClick={() => handlePaymentAmount(bill.outstandingBalanceToPay)}
          disabled={braintreeLoading}
        >
          {`Pay ${payMyBillBalanceText} (`}
          <FormattedCurrency value={bill.outstandingBalanceToPay} />
          {`)`}
        </button>
      )}
    </>
  ) : (
    <Alert
      bsStyle="success"
      className="text-center"
      data-testid="alert-success-notification"
    >
      This account has been settled
    </Alert>
  );
};
