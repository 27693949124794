import dompurify from 'dompurify';
import { VenueAddress as VenueAddressType } from 'types/models';
import { addressToString } from 'venue/utils';

interface VenueAddressProps {
  address: VenueAddressType;
  separator?: string;
  className?: string;
  omitFields?: string[];
}

export const VenueAddress: React.FC<VenueAddressProps> = ({
  address,
  separator = ', ',
  className,
  omitFields = [],
}) => {
  if (!address) {
    return null;
  }

  const addressHtml = addressToString(address, separator, omitFields);
  const sanitizer = dompurify.sanitize;

  return (
    <address
      className={className}
      dangerouslySetInnerHTML={{
        __html: sanitizer(addressHtml),
      }}
    ></address>
  );
};
