import { FormattedCurrency } from 'common/components/FormattedCurrency';
import {
  OrderBasketChoiceLine,
  OrderBasketLine as OrderBasketLineType,
} from 'types/models/Orders';
import { OrderChoiceLine } from './OrderChoiceLine';
import { OrderAndedLine } from 'order-history/components/OrderBasket/OrderAndedLine';

interface OrderBasketLineProps {
  basketItem: OrderBasketLineType;
}

export const OrderBasketLine: React.FC<OrderBasketLineProps> = ({
  basketItem,
}) => {
  return (
    <>
      <div className="order-history-basket-line">
        <div data-testid="order-history-item-name">
          {`${basketItem.quantity} x ${basketItem.name}`}
        </div>
        {basketItem.tariffPrice >= 0 && (
          <div data-testid="order-history-item-price">
            <FormattedCurrency value={basketItem.tariffPrice} />
          </div>
        )}
      </div>
      {basketItem.choiceLines
        ? basketItem.choiceLines.map(
            (choiceLine: OrderBasketChoiceLine, index: number) => (
              <OrderChoiceLine
                key={`choice-line-${index}`}
                choiceLine={choiceLine}
                parentName={basketItem.name}
              />
            ),
          )
        : null}
      {basketItem.andedLines
        ? basketItem.andedLines.map((andedLine, i) => (
            <OrderAndedLine andedLine={andedLine} key={`anded-line-${i}`} />
          ))
        : null}
      <hr />
    </>
  );
};
