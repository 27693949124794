import {
  HyperlinkUiSpecificData,
  MenuGroupItem,
  MenuGroupItemHyperlink,
  MenuGroupItemImage,
  MenuGroupItemProduct,
  MenuGroupItemSubHeader,
  MenuGroupItemTextField,
} from 'types/models';
import {
  HyperlinkItem,
  SubHeaderItem,
  ImageItem,
  ProductItem,
  TextFieldItem,
} from 'menu/components/MenuDisplayItems';

interface MenuDisplayItemProps {
  item: MenuGroupItem;
  handleHyperlinkClick: (
    hyperlinkSpecificData: HyperlinkUiSpecificData,
  ) => void;
}

export const MenuDisplayItem: React.FC<MenuDisplayItemProps> = ({
  item,
  handleHyperlinkClick,
}) => {
  switch (item.itemType) {
    case 'subHeader': {
      return <SubHeaderItem subHeaderItem={item as MenuGroupItemSubHeader} />;
    }
    case 'image': {
      return <ImageItem imageItem={item as MenuGroupItemImage} />;
    }

    case 'textField': {
      return <TextFieldItem textFieldItem={item as MenuGroupItemTextField} />;
    }

    case 'hyperlink': {
      const hyperlinkItem = item as MenuGroupItemHyperlink;
      return (
        <HyperlinkItem
          hyperlink={hyperlinkItem.hyperlink}
          uiSpecificData={hyperlinkItem.uiSpecificData}
          onClick={handleHyperlinkClick}
        />
      );
    }

    case 'product':
    default: {
      return <ProductItem productItem={item as MenuGroupItemProduct} />;
    }
  }
};
