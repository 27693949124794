interface MinimumAgeBadgeProps {
  minimumAge: number;
}

export const MinimumAgeBadge: React.FC<MinimumAgeBadgeProps> = ({
  minimumAge,
}) => {
  return (
    <div className="no-strikethrough">
      <span
        className="minimum-age-badge"
        aria-label={`Age restricted (${minimumAge})`}
        title={`Age restricted (${minimumAge})`}
      >
        {minimumAge}
      </span>
    </div>
  );
};
