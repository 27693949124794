import { memo } from 'react';
import { MdAddCircle } from 'react-icons/md';
import { useConfig } from 'contexts/ConfigContext';
import { CustomFormattedNumber } from 'common/components/CustomFormattedNumber';
import { hasCalories } from 'utils';
import { AztecPortion } from 'types/models';
import { isPortionAvailable } from 'menu/utils';
import { MenuItemDescription } from 'menu/components/MenuItemDescription';
import { CalorieUnit } from 'menu/components/Calories';
import { useMenuGroups } from 'contexts/MenuContext';
import { ItemUnavailable } from 'menu/components/MenuDisplayItems';

interface PortionItemProps {
  portion: AztecPortion;
  onPortionClick: (id: number) => void;
}

//TODO Why can't we use this in the multiple portions modal?
export const PortionItem: React.FC<PortionItemProps> = memo(
  ({ portion, onPortionClick }) => {
    const { currency, currencySymbolsDisabled } = useConfig();
    const { choiceGroups, products } = useMenuGroups();

    // Validate portion
    const portionIsAvailable = isPortionAvailable(
      portion,
      choiceGroups,
      products,
    );

    const portionHasCalories = hasCalories(portion.calories);

    return (
      <div className="menu-item choice-row">
        <div className="item-details">
          <span className="item-name portion-name">{portion.name}</span>
          {portionHasCalories ? (
            <div
              className={`text-muted ${portion.description && 'item-calories'}`}
            >
              {portion.calories} <CalorieUnit />
            </div>
          ) : null}
          {portion.description ? (
            <MenuItemDescription description={portion.description} />
          ) : null}
        </div>
        <div className="item-price-selector choice-section">
          {portionIsAvailable ? (
            <>
              <CustomFormattedNumber
                style={currencySymbolsDisabled ? 'decimal' : 'currency'}
                value={portion.price}
                currency={currency}
                className="item-price portion-price"
                hideZeroValue
                minimumFractionDigits={2}
              />
              <MdAddCircle
                size={25}
                onClick={() => onPortionClick(portion.id)}
                className="react-icon portion-add"
                title="Customise"
                data-testid={`portion-item-add`}
              />
            </>
          ) : (
            <ItemUnavailable />
          )}
        </div>
      </div>
    );
  },
);
