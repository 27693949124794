import { usePhrases, useSetPhrases } from 'contexts/ConfigContext';

import { ControlLabel, FormControl } from 'react-bootstrap';

export const GiftCardPhraseTextInput: React.FC = () => {
  const { giftCard } = usePhrases();
  const setPhrase = useSetPhrases();

  const handleChange = (event: React.ChangeEvent<FormControl>) => {
    // This is a tad annoying having to do this, but react-bootstrap sucks
    const value: string = (event.target as unknown as HTMLInputElement).value;
    setPhrase('giftCard', { ...giftCard, giftCardPhrase: value });
  };

  return (
    <div>
      <ControlLabel className="ff-label">Gift Card Phrase:</ControlLabel>
      <FormControl
        data-testid={`ff-giftcard-giftCardPhrase`}
        id={`ff-giftcard-giftCardPhrase`}
        type="text"
        value={giftCard.giftCardPhrase}
        placeholder="Enter text"
        onChange={handleChange}
      />
    </div>
  );
};
