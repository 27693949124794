import { useConfig, useSetConfig } from 'contexts/ConfigContext';

import { ControlLabel, FormControl } from 'react-bootstrap';

export const PayPalButtonColourSelect: React.FC = () => {
  const { payPalButtonColour } = useConfig();
  const setConfig = useSetConfig();

  const handleChange = (event: React.ChangeEvent<FormControl>) => {
    // This is a tad annoying having to do this, but react-bootstrap sucks
    const value: string = (event.target as unknown as HTMLSelectElement).value;
    setConfig('payPalButtonColour', value);
  };

  return (
    <div>
      <ControlLabel className="ff-label">PayPal Button Colour:</ControlLabel>
      <FormControl
        componentClass="select"
        data-testid={`ff-payPalButtonColour`}
        id={`ff-payPalButtonColour`}
        type="select"
        value={payPalButtonColour}
        onChange={handleChange}
      >
        <option>black</option>
        <option>blue</option>
        <option>gold</option>
        <option>silver</option>
        <option>white</option>
      </FormControl>
    </div>
  );
};
