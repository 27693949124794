import { FormattedCurrency } from 'common/components/FormattedCurrency';

interface TipPresetButtonTextProps {
  preset: number;
  calculateTipAmount: (preset: number) => void;
}

export const TipPresetButtonText: React.FC<TipPresetButtonTextProps> = ({
  preset,
  calculateTipAmount,
}) => {
  if (preset === 0) {
    return (
      <>
        No
        <br />
        Tip
      </>
    );
  }

  return (
    <>
      <span className="tip-button-percentage-text">{`${preset}%`}</span>
      <br />
      <FormattedCurrency value={Number(calculateTipAmount(preset))} />
    </>
  );
};
