import { useConfig, useSetConfig } from 'contexts/ConfigContext';

import { ControlLabel } from 'react-bootstrap';
import { ToggleSwitch } from 'core/components/ToggleSwitch';

/* I've inverting boolean both ways so that it sppears as enabled/disabling....it's the naming of the config option that's odd! */

export const SpecialRequestToggle: React.FC = () => {
  const { specialRequestsDisabled } = useConfig();
  const setConfig = useSetConfig();

  const handleClick = (toggledValue: boolean) => {
    setConfig('specialRequestsDisabled', !toggledValue);
  };

  return (
    <div>
      <ControlLabel className="ff-label">Special Requests:</ControlLabel>
      <ToggleSwitch
        id="special-requests"
        onChange={handleClick}
        checked={!specialRequestsDisabled}
        disabled={false}
        name="special-requests"
        onLabel="on"
        offLabel="off"
        small={false}
      />
    </div>
  );
};
