import { useConfig, usePhrases } from 'contexts/ConfigContext';

interface CalorieAllowancePhraseProps {
  classNameOveride?: string;
}

export const CalorieAllowancePhrase: React.FC<CalorieAllowancePhraseProps> = ({
  classNameOveride,
}) => {
  const { shouldDisplayDailyCalorieAllowancePhraseOnMenu } = useConfig();
  const { dailyCalorieAllowancePhrase } = usePhrases();

  return shouldDisplayDailyCalorieAllowancePhraseOnMenu &&
    dailyCalorieAllowancePhrase ? (
    <div
      className={classNameOveride ?? 'calorie-intake-phrase'}
      data-testid="phrase-calorie-intake"
    >
      {dailyCalorieAllowancePhrase}
    </div>
  ) : null;
};
