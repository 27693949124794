import { useState } from 'react';

import { Button } from 'react-bootstrap';
import { BasketLines } from './BasketLines';
import { usePhrases } from 'contexts/ConfigContext';
import { useServices, useVenues } from 'contexts/VenueContext';
import { getUrlPart } from 'utils';
import { useGiftCard } from 'contexts/GiftCardContext';
import { ConfirmLeaveBasketModal } from 'checkout/components/Modals';
import { useHistory } from 'react-router';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { useBasket } from 'contexts/BasketContext';

export const CheckoutBasket: React.FC = () => {
  const history = useHistory();

  const [showBasketRedemptionWarning, setShowBasketRedemptionWarning] =
    useState<boolean>(false);

  const { basket: basketPhrase } = usePhrases();
  const { selectedVenue } = useVenues();
  const { selectedService } = useServices();
  const { removeAllGiftCards, redeemedGiftCards } = useGiftCard();
  const { redeemedLoyaltyRewards, removeAllRewards } = useLoyaltyRewards();
  const { setIsBasketCollapsed } = useBasket();

  const hasGiftCards = redeemedGiftCards.length > 0;
  const hasLoyaltyRewards = redeemedLoyaltyRewards.length > 0;

  const goToMenu = () => {
    if (hasGiftCards) {
      removeAllGiftCards();
    }

    if (hasLoyaltyRewards) {
      removeAllRewards();
    }

    if (selectedVenue?.id && selectedService !== -1) {
      const serviceUrlPart = getUrlPart(selectedService);
      history.push(`/venue/${selectedVenue.id}/${serviceUrlPart}/menus/`);
    }
    setIsBasketCollapsed(false);
    setShowBasketRedemptionWarning(false);
  };

  const cancelModal = () => {
    setShowBasketRedemptionWarning(false);
  };

  const edit = () => {
    if (hasGiftCards || hasLoyaltyRewards) {
      setShowBasketRedemptionWarning(true);
    } else {
      goToMenu();
    }
  };

  return (
    <>
      <div data-testid="cardBasket" className="card basket">
        <div className="header">
          <h3 className="title">{basketPhrase}</h3>
          <div data-testid="editLink" className="edit-link">
            <Button bsStyle="link" onClick={edit}>
              Edit
            </Button>
          </div>
        </div>
        <hr />
        <BasketLines />
      </div>
      <ConfirmLeaveBasketModal
        show={showBasketRedemptionWarning}
        cancelAction={cancelModal}
        acceptAction={goToMenu}
      />
    </>
  );
};
