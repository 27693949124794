import { useRef } from 'react';

interface MenuNavButtonProps {
  handleClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  buttonText: string;
  buttonId?: string | undefined;
  buttonClass?: string | undefined;
  testId?: string | undefined;
}

export const MenuNavButton: React.FC<MenuNavButtonProps> = ({
  handleClick,
  buttonId,
  buttonText,
  buttonClass,
  testId,
}) => {
  const menuButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    menuButtonRef.current?.blur();
    handleClick(e);
  };

  return (
    <button
      id={buttonId}
      className={buttonClass}
      onClick={handleButtonClick}
      ref={menuButtonRef}
      data-testid={testId}
    >
      {buttonText}
    </button>
  );
};
