import { usePhrases } from 'contexts/ConfigContext';
import { useVenues } from 'contexts/VenueContext';
import { MdRedeem } from 'react-icons/md';

export const NoRewardsMessage: React.FC = () => {
  const {
    loyalty: { rewardPhrase },
  } = usePhrases();
  const { selectedVenue } = useVenues();

  return (
    <div className="loyalty-no-rewards" data-testid="no-loyalty-rewards">
      <MdRedeem className="reward-icon" />
      <h4>No {rewardPhrase}s</h4>
      <p>
        There are no {rewardPhrase}s currently available for you to redeem at{' '}
        {selectedVenue?.name}. Please check back soon.
      </p>
    </div>
  );
};
