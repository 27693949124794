import { selectTipAmount } from 'checkout/selectors';
import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { useCheckoutDetails } from 'contexts/CheckoutContext';
import { useSelector } from 'react-redux';

export const GrandTotal: React.FC = () => {
  const { basketTotal } = useCheckoutDetails();
  const tipAmount = useSelector(selectTipAmount);

  const finalBasketTotal = Number(basketTotal) + Number(tipAmount);

  return (
    <div className="grandtotal h4">
      <div>Total</div>
      <div data-testid="Total">
        <FormattedCurrency value={finalBasketTotal} />
      </div>
    </div>
  );
};
