import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { OrderBasketChoiceLine } from 'types/models/Orders';

interface OrderChoiceLineProps {
  choiceLine: OrderBasketChoiceLine;
  key: string;
  parentName: string;
}

export const OrderChoiceLine: React.FC<OrderChoiceLineProps> = ({
  choiceLine,
  key,
  parentName,
}) => {
  const getChoiceName = (choice: OrderBasketChoiceLine) =>
    choice.name
      ? choice.name
      : choice.eposName
      ? choice.eposName
      : `${parentName} choice`;

  let totalLinePrice = choiceLine.tariffPrice || 0;
  const choiceListText: string[] = [];
  let hasDuplicateNames = false;

  choiceListText.push(getChoiceName(choiceLine));
  const shouldRender =
    choiceLine.quantity >= 0 &&
    (choiceLine.name ||
      choiceLine.eposName ||
      choiceLine.choiceLines ||
      totalLinePrice > 0);

  const handleNestedChoice = (choiceLines: OrderBasketChoiceLine[]) => {
    choiceLines.forEach((nestedChoiceLine: OrderBasketChoiceLine) => {
      // add to total price if there is a tariff available
      if (nestedChoiceLine.tariffPrice) {
        totalLinePrice += nestedChoiceLine.tariffPrice;
      }
      // add name to list
      if (
        choiceListText[0] === `${parentName} choice` &&
        choiceListText[0] === getChoiceName(nestedChoiceLine)
      ) {
        hasDuplicateNames = true;
      } else {
        choiceListText.push(getChoiceName(nestedChoiceLine));
      }

      // check for next level of nested choices and run again
      if (nestedChoiceLine.choiceLines) {
        handleNestedChoice(nestedChoiceLine.choiceLines);
      }
    });
  };

  if (choiceLine.choiceLines) {
    handleNestedChoice(choiceLine.choiceLines ?? []);
    if (hasDuplicateNames) {
      choiceListText[0] += 's';
    }
  }

  return shouldRender ? (
    <div className="text-muted order-history-choice-line" key={key}>
      <div>
        {`> ${choiceLine.quantity} x 
          ${choiceListText.join(', ')}`}
      </div>
      <div data-testid="order-history-item-price">
        {totalLinePrice && totalLinePrice > 0 ? (
          <FormattedCurrency value={totalLinePrice} />
        ) : (
          'FREE'
        )}
      </div>
    </div>
  ) : null;
};
