/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react';

import { Col } from 'react-bootstrap';
import { Field as ImmutableField } from 'redux-form/immutable';
import { FieldGroup } from 'core/components/FormComponents';
import { min, required, password } from 'core/validation';
import { Field } from 'types/models/Forms';

interface PasswordProps {
  field: Field;
}
/**
 * @deprecated
 */
export const Password: React.FC<PasswordProps> = ({ field }) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [validation, setValidation] = useState<
    ((arg: never) => string | undefined)[]
  >([]);

  useEffect(() => {
    const fieldIsPassword = password(field.isPassword, field.min);
    const fieldMin = min(field.min);
    const fieldRequired = required(field.required);

    const tmpValidation = [fieldIsPassword, fieldMin, fieldRequired];

    setValidation(tmpValidation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Col sm={field.width}>
      <div
        className={passwordShown ? 'password-hide' : 'password-show'}
        onClick={() => setPasswordShown(!passwordShown)}
      />
      <ImmutableField
        name={field.name}
        component={FieldGroup}
        type={passwordShown ? 'text' : 'password'}
        placeholder={field.placeholder}
        label={field.label}
        validate={validation}
      />
    </Col>
  );
};
