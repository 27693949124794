import { useConfig, useSetConfig } from 'contexts/ConfigContext';

import { ControlLabel } from 'react-bootstrap';
import { ToggleSwitch } from 'core/components/ToggleSwitch';

export const AllergenPromptToggle: React.FC = () => {
  const { showAllergenPrompt } = useConfig();
  const setConfig = useSetConfig();

  const handleClick = (toggledValue: boolean) => {
    setConfig('showAllergenPrompt', toggledValue);
  };

  return (
    <div>
      <ControlLabel className="ff-label">Show Allergen Prompt: </ControlLabel>
      <ToggleSwitch
        id="showAllergenPrompt"
        onChange={handleClick}
        checked={showAllergenPrompt}
        disabled={false}
        name="showAllergenPrompt"
        onLabel="on"
        offLabel="off"
        small={false}
      />
    </div>
  );
};
