import { useAPI } from 'api/useAPI';
import { requestBodyFormatter } from 'api/utils';
import axios, { AxiosRequestConfig } from 'axios';
import { usePhrases } from 'contexts/ConfigContext';
import { addNotification } from 'core/actions';
import { processTablesToReactSelectOptions } from 'utils/processTables';
import { createContext, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ErrorResponse, GetTablesResponse } from 'types/models';
import {
  ReactSelectGroup,
  ReactSelectOption,
} from 'types/react-select-virtualized';

interface TableContext {
  initialised: boolean;
  isFetchingTables: boolean;
  fetchTables: (venueId: number, salesAreaId: number) => void;
  tables: (ReactSelectGroup | ReactSelectOption)[];
  hasGroups: boolean;
  selectedTable: ReactSelectOption | undefined;
  setSelectedTable: (table: ReactSelectOption | undefined) => void;
  showTablesModal: boolean;
  setShowTablesModal: (status: boolean) => void;
}

// set up context
export const TableContext = createContext<TableContext>({
  initialised: false,
  isFetchingTables: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchTables: () => {},
  tables: [],
  hasGroups: false,
  selectedTable: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedTable: () => {},
  showTablesModal: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowTablesModal: () => {},
});

// set up hook
export const useTables = (): TableContext => {
  const consumer = useContext(TableContext);

  // Condition used to determine if Context Provider has been declared
  if (!consumer.initialised) {
    throw new Error('TableContextProvider not initialised');
  }

  return consumer;
};

interface TableContextProviderProps {
  children: React.ReactNode;
}

// set up provider
export const TableContextProvider: React.FC<TableContextProviderProps> = ({
  children,
}) => {
  const { url, getTables } = useAPI();
  const { table: tablePhrase } = usePhrases();
  const dispatch = useDispatch();

  const [isFetchingTables, setIsFetchingTables] = useState(false);
  const [tableOptions, setTableOptions] = useState<
    (ReactSelectGroup | ReactSelectOption)[]
  >([]);
  const [hasGroups, setHasGroups] = useState(false);
  const [selectedTable, setSelectedTable] = useState<
    ReactSelectOption | undefined
  >(undefined);
  const [showTablesModal, setShowTablesModal] = useState(false);

  const fetchTables = (venueId: number, salesAreaId: number) => {
    if (!isFetchingTables) {
      setIsFetchingTables(true);
      setTableOptions([]);

      const getTablesConfig = getTables();
      const fetchTableOptions: AxiosRequestConfig = {
        url,
        method: 'POST',
        headers: getTablesConfig.headers,
        data: requestBodyFormatter({
          method: getTablesConfig.method,
          siteId: venueId,
          salesAreaId,
          ...getTablesConfig.body,
        }),
      };

      axios(fetchTableOptions)
        .then((response) => {
          const data = response.data as GetTablesResponse;
          if (data.response === 'OK') {
            // DISCUSS - this is existing code and I think it makes sense to keep it
            // There's another method similar to this that processes it into a general table format but it doesn't look to actually be used anywhere
            setTableOptions(
              processTablesToReactSelectOptions(data, tablePhrase),
            );
            setHasGroups(data.groups.length > 0);
          } else {
            const err = response.data as ErrorResponse;
            dispatch(
              addNotification(
                `There was an error loading ${tablePhrase}s. ${err.detail} (${err.code})`,
                'danger',
              ),
            );
          }
        })
        .catch(() => {
          dispatch(
            addNotification(
              `There was an unexpected error fetching ${tablePhrase}s.`,
              'danger',
            ),
          );
        })
        .finally(() => {
          setIsFetchingTables(false);
        });
    }
  };
  return (
    <TableContext.Provider
      value={{
        initialised: true,
        isFetchingTables,
        fetchTables,
        tables: tableOptions,
        hasGroups,
        selectedTable,
        setSelectedTable,
        showTablesModal,
        setShowTablesModal,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};
