import {
  AztecChoice,
  AztecProduct,
  Choice,
  ChoiceGroup,
} from 'types/models/Menu';
import {
  CheckBasketAndedLine,
  CheckBasketRequestChoiceLine,
  CheckBasketRequestProductLine,
} from 'types/models/Responses/CheckBasketResponse';
import {
  getChildPortionId,
  getSelectedChoices,
  getSelectedPortion,
  getSelectedUpsells,
} from 'menu/utils';

// checkBasket convert basket data
export const basketLineToCheckoutLine = (
  product: AztecProduct,
): CheckBasketRequestProductLine => {
  const selectedPortion = getSelectedPortion(product);
  const checkBasketLine: CheckBasketRequestProductLine = {
    courseId: product.defaultCourseId,
    displayRecordId: product.displayRecordId,
    ingredientId: product.id,
    menuId: product.menuId,
    portionTypeId: selectedPortion.id,
    quantity: selectedPortion.quantity ?? 0,
  };

  if (product.specialRequest) {
    checkBasketLine.messages = [product.specialRequest];
  }

  if (selectedPortion.choices.length > 0) {
    const choiceLines: CheckBasketRequestChoiceLine[] = [];
    // First loop over the top level choices
    selectedPortion.choices.forEach((choice, index) => {
      const selectedChoices = getSelectedChoices(choice?.choiceGroup?.choices);
      // Add lines for removed defaults
      choiceLines.push(
        ...getDefaultChoiceOptions(choice, selectedChoices, index),
      );
      // handle selected choice lines
      selectedChoices.forEach((selectedChoice) => {
        const selectedPortionId = getChildPortionId(
          choice.choiceGroup.portionRatios,
          selectedChoice.choiceId ?? selectedChoice.productId,
          choice.portionId,
        );

        const isDefault = choice.choiceGroup.defaults.includes(
          selectedChoice.productId,
        );

        for (let i = 0; i < selectedChoice.quantity; i++) {
          choiceLines.push(
            ...handleChoiceLine(
              isDefault,
              index,
              selectedChoice,
              choice.choiceId,
              selectedPortionId,
              i,
            ),
          );
        }
      });
    });
    checkBasketLine.choiceLines = choiceLines;
  }

  if (product.andedUpsells) {
    const selectedUpsells = getSelectedUpsells(product.andedUpsells);
    const upsellGroupId = product.upsellGroup?.id;
    if (upsellGroupId) {
      const andedUpsellLines: CheckBasketAndedLine[] = selectedUpsells.map(
        (upsell) => {
          return {
            displayRecordId: upsell.displayRecordId,
            ingredientId: upsell.id,
            menuId: product.menuId,
            portionTypeId: upsell.defaultPortionId,
            quantity: selectedPortion.quantity ?? 0,
            upsellGroupId: upsellGroupId,
          };
        },
      );
      checkBasketLine.andedLines = andedUpsellLines;
    }
  }
  return checkBasketLine;
};

const handleChoiceLine = (
  isDefault: boolean,
  choiceIndex: number,
  selectedChoice: Choice,
  parentChoiceId: number,
  selectedPortionId: number,
  quantityIndex: number,
): CheckBasketRequestChoiceLine[] => {
  const choiceLine: CheckBasketRequestChoiceLine[] = [];
  // For defaults only include a line for quantities over 1
  if (isDefault && quantityIndex === 0) {
    return choiceLine;
  }
  if (selectedChoice.displayRecordToUse) {
    if (isProductChoice(selectedChoice)) {
      choiceLine.push(
        generateProductChoiceLine(
          selectedChoice,
          choiceIndex,
          parentChoiceId,
          selectedPortionId,
          selectedChoice.displayRecordToUse.id,
          quantityIndex > 0, // isExtra - for any additional quantities
        ),
      );
    } else if (isSubChoice(selectedChoice)) {
      choiceLine.push(
        generateSubChoiceLine(
          selectedChoice,
          choiceIndex,
          parentChoiceId,
          selectedPortionId,
          selectedChoice.displayRecordToUse.id,
          quantityIndex > 0, // isExtra - for any additional quantities
        ),
      );
    }
  }
  return choiceLine;
};

const generateProductChoiceLine = (
  choice: Choice,
  choiceIndex: number,
  choiceId: number,
  selectedPortionId: number,
  displayRecordId: number,
  isExtra: boolean,
): CheckBasketRequestChoiceLine => {
  const choiceLine: CheckBasketRequestChoiceLine = {
    ingredientId: choice.productId,
    portionTypeId: selectedPortionId,
    displayRecordId: displayRecordId,
    quantity: 1,
    choiceIndex: choiceIndex,
    choiceId: choiceId,
  };
  if (isExtra) {
    choiceLine.extra = 'YES';
  }

  if (choice.productToUse && getSelectedPortion(choice.productToUse)) {
    const selectedPortion = getSelectedPortion(choice.productToUse);
    const choiceLines: CheckBasketRequestChoiceLine[] = [];
    // First loop over the top level choices
    selectedPortion.choices.forEach((nestedChoice, index) => {
      const selectedChoices = getSelectedChoices(
        nestedChoice?.choiceGroup?.choices,
      );
      // Add lines for removed defaults
      choiceLines.push(
        ...getDefaultChoiceOptions(nestedChoice, selectedChoices, index),
      );
      // handle selected choice lines
      selectedChoices.forEach((selectedChoice) => {
        const selectedPortionId = getChildPortionId(
          nestedChoice.choiceGroup.portionRatios,
          selectedChoice.choiceId ?? selectedChoice.productId,
          nestedChoice.portionId,
        );

        const isDefault = nestedChoice.choiceGroup.defaults.includes(
          selectedChoice.productId,
        );

        for (let i = 0; i < selectedChoice.quantity; i++) {
          choiceLines.push(
            ...handleChoiceLine(
              isDefault,
              index,
              selectedChoice,
              nestedChoice.choiceId,
              selectedPortionId,
              i,
            ),
          );
        }
      });
    });
    choiceLine.choiceLines = choiceLines;
  }
  return choiceLine;
};

const generateSubChoiceLine = (
  choice: Choice,
  choiceIndex: number,
  choiceId: number,
  selectedPortionId: number,
  displayRecordId: number,
  isExtra: boolean,
): CheckBasketRequestChoiceLine => {
  const choiceLine: CheckBasketRequestChoiceLine = {
    choiceId: choiceId,
    choiceIndex: choiceIndex,
    displayRecordId: displayRecordId,
    ingredientId: choice.choiceId ?? choice.productId,
    portionTypeId: selectedPortionId,
    quantity: 1,
  };

  if (isExtra) {
    choiceLine.extra = 'YES';
  }

  if (choice.choiceGroup) {
    const choiceLines: CheckBasketRequestChoiceLine[] = [];
    const choiceGroup = choice.choiceGroup;
    const selectedChoices = getSelectedChoices(choiceGroup?.choices);

    selectedChoices.forEach((selectedChoice) => {
      const selectedChoiceIndex = getSelectedChoiceIndex(
        choiceGroup,
        selectedChoice,
      );

      const choicePortionId = getChildPortionId(
        choiceGroup.portionRatios,
        selectedChoice.choiceId ?? selectedChoice.productId,
        selectedPortionId,
      );

      const isDefault = choiceGroup.defaults.includes(selectedChoice.productId);

      for (let i = 0; i < selectedChoice.quantity; i++) {
        choiceLines.push(
          ...handleChoiceLine(
            isDefault,
            selectedChoiceIndex,
            selectedChoice,
            choice.choiceId ?? choiceGroup.id,
            choicePortionId,
            i,
          ),
        );
      }
    });

    choiceLine.choiceLines = choiceLines;
  }
  return choiceLine;
};

const getDefaultChoiceOptions = (
  choice: AztecChoice,
  selectedChoices: Choice[],
  index: number,
): CheckBasketRequestChoiceLine[] => {
  const choiceLines: CheckBasketRequestChoiceLine[] = [];

  choice?.choiceGroup?.defaults.forEach((defaultId) => {
    // Check that the default has not been selected
    const selectedDefault = selectedChoices.filter(
      (sc) => sc.productId === defaultId,
    );
    // If it has then get the default choice and set the quantity to -1
    if (selectedDefault.length === 0) {
      const defaultChoice = getDefaultChoice(choice, defaultId);

      if (defaultChoice?.displayRecordToUse) {
        const selectedPortionId = getChildPortionId(
          choice.choiceGroup.portionRatios,
          defaultChoice.productId,
          choice.portionId,
        );

        choiceLines.push({
          ingredientId: defaultChoice.productId,
          portionTypeId: selectedPortionId,
          displayRecordId: defaultChoice.displayRecordToUse?.id,
          quantity: -1,
          choiceIndex: index,
          choiceId: choice.choiceId,
        });
      }
    }
  });

  return choiceLines;
};

const getSelectedChoiceIndex = (
  choiceGroup: ChoiceGroup,
  selectedChoice: Choice,
) => {
  return choiceGroup?.choices.findIndex(
    (c) =>
      (c.choiceId && c.choiceId === selectedChoice.choiceId) ||
      (c.productId && c.productId === selectedChoice.productId),
  );
};
const getDefaultChoice = (choice: AztecChoice, defaultId: number) => {
  return choice?.choiceGroup?.choices.find((c) => c.productId === defaultId);
};
const isProductChoice = (selectedChoice: Choice) => {
  return selectedChoice.productId && selectedChoice.displayRecordToUse;
};
const isSubChoice = (selectedChoice: Choice) => {
  return selectedChoice.choiceId && selectedChoice.displayRecordToUse;
};
