import { useEffect, useState } from 'react';

import { Alert } from 'react-bootstrap';
import { TimeslotOption } from '../TimeslotOption';
import { useParams } from 'react-router';
import { TimeslotPhrase } from 'common/components/TimeslotPhrase';
import { ServiceType } from 'types/models';
import { getServiceLabel } from 'venue/utils';
import { useSalesAreas, useServices, useVenues } from 'contexts/VenueContext';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { isRequired } from 'core/components/RHF/validation';
import { useTimeslots } from 'contexts/TimeslotContext/TimeslotContext';
import cs from 'classnames';
import { Loader } from 'components/Loader';

interface ParamsProps {
  timeslot: string;
}

export const TimeslotSelector: React.FC = () => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { timeslot: timeslotFromUrl } = useParams<ParamsProps>();
  const { selectedVenue } = useVenues();
  const { services, selectedService } = useServices();
  const { selectedSalesArea } = useSalesAreas();
  const { timeslots, handleFetchTimeslots, isFetchingTimeslots } =
    useTimeslots();

  const [hasValidTimeslotUrl, setHasValidTimeslotUrl] = useState(false);

  const hasError = errors['timeslot'] !== undefined;
  const fieldError = errors['timeslot'];

  const formGroupClasses = cs('form-group', hasError && 'has-error');

  const checkValidTimeslotUrl = () => {
    if (timeslotFromUrl) {
      const timeslotAsDate = Date.parse(timeslotFromUrl);

      if (!isNaN(timeslotAsDate)) {
        const foundTimeslot = timeslots.find(
          (ts) =>
            Date.parse(ts.time) === timeslotAsDate && Boolean(ts.available),
        );

        if(foundTimeslot){
          setValue('timeslot', foundTimeslot.time);
        }

        setHasValidTimeslotUrl(true);
      }
    }
  };

  const timeslotPhrase = TimeslotPhrase({
    selectedService,
  });

  const timeslotOptions: RegisterOptions = {
    ...isRequired('timeslot', true),
  };

  // Make API call to get timeslots
  useEffect(() => {
    handleFetchTimeslots(selectedVenue, selectedService, selectedSalesArea);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVenue, selectedService, selectedSalesArea]);

  useEffect(() => {
    checkValidTimeslotUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeslots, timeslotFromUrl]);

  const orderPhrase = (
    <>
      {selectedService === ServiceType.ClickAndCollect ? (
        <h5>
          {`You're using ${getServiceLabel(
            services,
            ServiceType.ClickAndCollect,
          )} for collection time:`}
        </h5>
      ) : (
        <h5>
          {`You're using ${getServiceLabel(
            services,
            ServiceType.DeliveryToLocation,
          )} for delivery time:`}
        </h5>
      )}
    </>
  );

  if (isFetchingTimeslots) {
    return <Loader text="Loading timeslots" />;
  }

  if (timeslots.length === 0) {
    return <Alert bsStyle="danger">No timeslots available</Alert>;
  }

  return (
    <div className={formGroupClasses}>
      {hasValidTimeslotUrl ? orderPhrase : null}
      <select
        {...register('timeslot', timeslotOptions)}
        className="form-control"
        data-testid="timeslot-select"
      >
        <option
          value=""
          disabled
          selected
        >{`Select a ${timeslotPhrase}`}</option>
        {timeslots.map((ts) => (
          <TimeslotOption
            key={`timeslot-option-${ts.time}`}
            timeslot={ts}
            datatestid="timeslot-option"
          />
        ))}
      </select>
      {fieldError && (
        <span className="help-block" role="alert">
          Please select a valid timeslot
        </span>
      )}
    </div>
  );
};
