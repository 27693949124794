import { FaChevronCircleRight } from 'react-icons/fa';
import { ListGroupItem } from 'react-bootstrap';
import { Hyperlink, HyperlinkUiSpecificData } from 'types/models';
import { useConfig } from 'contexts/ConfigContext';
import cs from 'classnames';

interface HyperlinkItemProps {
  hyperlink: Hyperlink;
  uiSpecificData: HyperlinkUiSpecificData;
  onClick: (hyperlink: HyperlinkUiSpecificData) => void;
}

export const HyperlinkItem: React.FC<HyperlinkItemProps> = ({
  hyperlink,
  uiSpecificData,
  onClick,
}) => {
  const { galleryViewEnabled, multipleMenusEnabled } = useConfig();

  const imageUrl = hyperlink.image.imageURL;

  const hyperlinkClasses = cs(
    imageUrl && 'list-group-item-hyperlink--bg-image',
    'list-group-item-hyperlink',
  );

  if (!multipleMenusEnabled || galleryViewEnabled) {
    return null;
  }

  return (
    <ListGroupItem
      className={hyperlinkClasses}
      onClick={() => onClick(uiSpecificData)}
    >
      {imageUrl ? (
        <img src={imageUrl} alt={hyperlink.text} />
      ) : (
        <>{hyperlink.text}</>
      )}
      <FaChevronCircleRight
        className="list-group-item-hyperlink-proceed-icon"
        data-testid="hyperlink-button"
      />
    </ListGroupItem>
  );
};
