import {
  AztecChoice,
  Choice,
  AztecProduct,
  BreadcrumbTrack,
  ChoiceGroup,
  UpsellGroup,
  AztecPortion,
  ChoiceType,
} from 'types/models';
import {
  getChildPortionId,
  getNestedProduct,
  getSelectedPortion,
} from 'menu/utils';

export const isChoiceInvalid = (choice: AztecChoice | Choice): boolean => {
  const choiceGroup = choice.choiceGroup;
  // Not sure in what situation we won't have a choiceGroup
  if (choiceGroup) {
    const minChoices = choiceGroup.minimum;

    const totalChoiceSelection = countChoiceGroupSelectionQuantities(
      choiceGroup?.choices,
    );

    if (minChoices && minChoices > totalChoiceSelection) return true;
  }

  return false;
};

export const updateChoice = (
  product: AztecProduct,
  choiceIndex: number,
  choiceGroupIndex: number,
  selectedPortionId: number,
  quantity?: number,
): AztecProduct => {
  const updatedProduct = { ...product };
  const selectedPortion = updatedProduct.portions[selectedPortionId];

  // If a portion quantity is already set then maintain it
  // Else set it to 1
  selectedPortion.quantity = selectedPortion.quantity || 1;

  const selectedChoiceGroup = selectedPortion.choices[choiceIndex].choiceGroup;
  updateChoiceQuantity(selectedChoiceGroup, choiceGroupIndex, quantity);

  return updatedProduct;
};

export const updateNestedChoice = (
  product: AztecProduct,
  choiceIndex: number,
  choiceGroupIndex: number,
  selectedPortionId: number,
  breadcrumbs: BreadcrumbTrack[],
  quantity?: number,
): AztecProduct => {
  const updatedProduct = { ...product };
  const productToUse = getNestedProduct(updatedProduct, breadcrumbs);
  if (productToUse) {
    updateChoice(
      productToUse,
      choiceIndex,
      choiceGroupIndex,
      selectedPortionId,
      quantity,
    );
  } else {
    const choiceToUse = getNestedChoice(
      getSelectedPortion(product),
      breadcrumbs,
    );
    updateNestedChoiceGroup(choiceToUse, choiceGroupIndex, quantity);
  }

  return updatedProduct;
};

export const updateNestedChoiceGroup = (
  choice: Choice,
  choiceGroupIndex: number,
  quantity?: number,
): Choice => {
  const updatedChoice = { ...choice };
  const selectedChoiceGroup = choice.choiceGroup;

  updateChoiceQuantity(selectedChoiceGroup, choiceGroupIndex, quantity);

  return updatedChoice;
};

const updateChoiceQuantity = (
  choiceGroup: ChoiceGroup | undefined,
  choiceGroupIndex: number,
  quantity?: number,
): void => {
  if (!choiceGroup) return;

  const choiceGroupType = getChoiceGroupType(
    choiceGroup?.minimum,
    choiceGroup?.maximum,
  );
  switch (choiceGroupType) {
    case 'Multiple':
      choiceGroup?.choices.map((c, i) => {
        // Find choice
        if (choiceGroupIndex === i) {
          updateMultipleChoiceQuantity(quantity, c);
        }
      });
      break;
    case 'SingleOptional':
      choiceGroup?.choices.map((c, i) => {
        updateSingleOptionalChoice(c, choiceGroupIndex, i, quantity);
      });
      break;
    case 'SingleRequired':
      choiceGroup?.choices.map((c, i) => {
        updateSingleRequiredChoice(c, choiceGroupIndex, i, quantity);
      });
      break;
  }
};

const updateMultipleChoiceQuantity = (
  quantity: number | undefined,
  c: Choice,
) => {
  if (quantity) {
    c.quantity = quantity;
  }
  // If it is already selected then deselect or select and set quantity = 1
  else if (c.quantity > 0) {
    removeNestedChoices(c);
  } else {
    c.quantity = 1;
  }
};

const updateSingleOptionalChoice = (
  choice: Choice,
  choiceGroupIndex: number,
  choiceIndex: number,
  quantity?: number,
) => {
  if (choiceGroupIndex !== choiceIndex || choice.quantity > 0) {
    removeNestedChoices(choice);
  } else {
    choice.quantity = quantity ?? 1;
  }
};

const updateSingleRequiredChoice = (
  choice: Choice,
  choiceGroupIndex: number,
  choiceIndex: number,
  quantity?: number,
) => {
  if (choiceGroupIndex === choiceIndex) {
    choice.quantity = quantity ?? 1;
  } else {
    removeNestedChoices(choice);
  }
};

export const updateAndedUpsellQuantity = (
  product: AztecProduct,
  upsellGroup: UpsellGroup,
  upsellIndex: number,
): AztecProduct => {
  const updatedProduct = { ...product };
  if (upsellGroup.selectionType === 'singleOptional') {
    updatedProduct.andedUpsells?.map((upsell, i) => {
      const portionToUse = upsell.portions[upsell.defaultPortionId];
      if (
        upsellIndex !== i ||
        (portionToUse.quantity && portionToUse?.quantity > 0)
      ) {
        portionToUse.quantity = 0;
      } else {
        portionToUse.quantity = 1;
      }
    });
  } else if (upsellGroup.selectionType === 'unlimitedOptional') {
    updatedProduct.andedUpsells?.map((upsell, i) => {
      if (upsellIndex === i) {
        const portionToUse = upsell.portions[upsell.defaultPortionId];
        if (portionToUse.quantity && portionToUse?.quantity > 0) {
          portionToUse.quantity = 0;
        } else {
          portionToUse.quantity = 1;
        }
      }
    });
  }

  return updatedProduct;
};

export const removeSelectedChoices = (selectedPortion: AztecPortion): void => {
  selectedPortion.choices.forEach((choice) => {
    choice.choiceGroup?.choices.forEach((cgc) => {
      if (cgc.quantity) {
        if (cgc.productToUse) {
          const nestedPortion = getSelectedPortion(cgc.productToUse);
          if (nestedPortion) {
            removeSelectedChoices(nestedPortion);
          }
        } else if (cgc.choiceId && cgc.choiceGroup) {
          const selectedChoices = getSelectedChoices(cgc.choiceGroup?.choices);
          selectedChoices.forEach((sc) => removeNestedChoices(sc));
        }
      }
      cgc.quantity = 0;
    });
  });
  selectedPortion.quantity = 0;
};

export const removeNestedChoices = (choice: Choice): void => {
  if (choice.productToUse) {
    const nestedPortion = getSelectedPortion(choice.productToUse);
    // This catches if a nested choice has been shown but nothing selected
    if (nestedPortion) {
      removeSelectedChoices(nestedPortion);
    }
  } else if (choice.choiceId && choice.choiceGroup) {
    const selectedChoices = getSelectedChoices(choice.choiceGroup?.choices);
    selectedChoices.forEach((sc) => removeNestedChoices(sc));
  }
  choice.quantity = 0;
};

export const getChoiceGroupType = (
  minChoices: number | undefined,
  maxChoices: number | undefined,
): ChoiceType => {
  if (maxChoices === undefined || maxChoices > 1) {
    return 'Multiple';
  } else if (minChoices === 0) {
    return 'SingleOptional';
  } else {
    return 'SingleRequired';
  }
};

export const countChoiceGroupSelectionQuantities = (
  choices: Choice[],
): number => {
  return choices.reduce((acc, choice) => acc + (choice.quantity || 0), 0);
};

export const getSelectedChoices = (choices: Choice[]): Choice[] => {
  return choices ? choices.filter((c) => c.quantity > 0) : [];
};

export const getNestedChoice = (
  portion: AztecPortion,
  breadcrumbs: BreadcrumbTrack[],
): Choice => {
  let portionToUse = portion;
  let nestedChoice =
    portionToUse.choices[breadcrumbs[0].choiceIndex].choiceGroup?.choices[
      breadcrumbs[0].choiceGroupIndex
    ];

  breadcrumbs.forEach((bc, idx) => {
    if (idx === 0) return;
    if (nestedChoice.productToUse) {
      const prevBreadcrumb = breadcrumbs[idx - 1];
      const portionIdToUse = getChildPortionId(
        prevBreadcrumb.portionRatios,
        nestedChoice.productToUse.id,
        prevBreadcrumb.portionId,
      );
      portionToUse = nestedChoice?.productToUse.portions[portionIdToUse];
      nestedChoice =
        portionToUse.choices[breadcrumbs[idx].choiceIndex].choiceGroup?.choices[
          breadcrumbs[idx].choiceGroupIndex
        ];
    }
  });
  return nestedChoice;
};
