import { Dialog } from 'components/Dialog';
import { usePhrases } from 'contexts/ConfigContext';
import { GiftCardLine } from 'types/models/Responses/CheckBasketResponse';

interface ConfirmGiftCardRemovalModalProps {
  show: boolean;
  cardToRemove: GiftCardLine | undefined;
  cancelAction: () => void;
  acceptAction: (giftCard: GiftCardLine) => void;
}

export const ConfirmGiftCardRemovalModal: React.FC<
  ConfirmGiftCardRemovalModalProps
> = ({ show, cardToRemove, cancelAction, acceptAction }) => {
  const {
    giftCard: { giftCardPhrase },
  } = usePhrases();

  if (!cardToRemove) {
    return null;
  }

  return (
    <Dialog
      isOpen={show}
      title={`Remove ${giftCardPhrase}`}
      description={`Would you like to remove the ${giftCardPhrase}?`}
      datatestid="remove-giftcard-confirmation-modal"
      accept={{
        action: () => acceptAction(cardToRemove),
        text: `Remove ${giftCardPhrase}`,
        btnType: 'destructive',
        datatestid: 'btn-removal-gifcard-confirmation-modal',
      }}
      reject={{
        action: () => cancelAction(),
        text: 'Cancel',
      }}
    />
  );
};
