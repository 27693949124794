import { FormatDateOptions } from 'react-intl';

export const FormattedDateTime = ({
  date,
  options,
}: {
  date: number | Date;
  options: FormatDateOptions;
}): string => {
  return new Intl.DateTimeFormat(navigator.language, options).format(date);
};
