import { useConfig } from 'contexts/ConfigContext';
import { CustomFormattedNumber } from 'common/components/CustomFormattedNumber';
import { AztecProduct } from 'types/models';
import { hasCalories } from 'utils';
import { useEffect } from 'react';
import { AllergenLink } from 'menu/components/Allergens';
import { QuantityField } from 'menu/components/MenuModal/ModalComponents';
import {
  isPortionAvailable,
  sortPortionsByPriceThenId,
  updatePortionQuantity,
} from 'menu/utils';
import { MenuItemDescription } from 'menu/components/MenuItemDescription';
import { CalorieUnit } from 'menu/components/Calories';
import { useMenuGroups } from 'contexts/MenuContext';
import { ItemUnavailable } from 'menu/components/MenuDisplayItems';

interface QuantifyPortionsModalProps {
  aztecProduct: AztecProduct;
  updateAztecProduct: (product: AztecProduct) => void;
}

export const QuantifyPortionsModal: React.FC<QuantifyPortionsModalProps> = ({
  aztecProduct,
  updateAztecProduct,
}) => {
  const { currency, currencySymbolsDisabled } = useConfig();
  const { choiceGroups, products } = useMenuGroups();
  const portionArray = Object.values(aztecProduct.portions);
  const displayRecord =
    aztecProduct.displayRecords[aztecProduct.displayRecordId];

  const onPortionQuantityChange = (portionId: number, quantity: number) => {
    const updatedProduct = updatePortionQuantity(
      aztecProduct,
      portionId,
      quantity,
    );
    updateAztecProduct(updatedProduct);
  };

  useEffect(() => {
    if (portionArray.some((p) => p.quantity)) {
      return;
    } else if (
      aztecProduct.defaultPortionId &&
      aztecProduct?.portions[aztecProduct.defaultPortionId]
    ) {
      // Update default portion to 1 if no portion has been selected
      const updatedProduct = updatePortionQuantity(
        aztecProduct,
        aztecProduct.defaultPortionId,
        1,
      );
      updateAztecProduct(updatedProduct);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {displayRecord?.description ? (
        <div className="display-record-description">
          {displayRecord.description} <AllergenLink />
        </div>
      ) : (
        <div className="display-record-description">
          <AllergenLink />
        </div>
      )}
      {sortPortionsByPriceThenId(portionArray).map((portion) => {
        const portionHasCalories = hasCalories(portion.calories);
        const portionAvailable = isPortionAvailable(
          portion,
          choiceGroups,
          products,
        );

        return (
          <div
            className="menu-item choice-row"
            key={`portion:${portion.id}`}
            data-testid="portion-item"
          >
            <div className="item-details">
              <span className="item-name">{portion.name}</span>
              {portionHasCalories ? (
                <div
                  className={`text-muted ${
                    portion.description && 'calorie-description'
                  }`}
                >
                  {portion.calories} <CalorieUnit />
                </div>
              ) : null}
              {portion.description ? (
                <MenuItemDescription description={portion.description} />
              ) : null}
            </div>
            <div className="item-price-selector choice-section">
              {portionAvailable ? (
                <>
                  <CustomFormattedNumber
                    style={currencySymbolsDisabled ? 'decimal' : 'currency'}
                    value={portion.price}
                    currency={currency}
                    hideZeroValue
                    minimumFractionDigits={2}
                    className="item-price"
                  />
                  <span className="item-quantity">
                    <QuantityField
                      quantity={portion.quantity || 0}
                      callbackArgs={[portion.id]}
                      onChange={onPortionQuantityChange}
                      max={99}
                    />
                  </span>
                </>
              ) : (
                <ItemUnavailable />
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};
