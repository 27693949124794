import { AztecPortion, DisplayRecord } from 'types/models';
import { getCalories } from 'utils';
import cs from 'classnames';
import { CalorieUnit } from 'menu/components/Calories';

interface CalorieAmountDisplayProps {
  displayRecord: DisplayRecord;
  portions: AztecPortion[];
  isOutOfStock?: boolean;
}

export const CalorieAmountDisplay: React.FC<CalorieAmountDisplayProps> = ({
  displayRecord,
  portions,
  isOutOfStock,
}) => {
  const classes = cs(
    'text-muted',
    'item-calories',
    isOutOfStock ? 'out-of-stock' : undefined,
  );

  const caloriePhrase = getCalories(displayRecord, portions);
  return caloriePhrase ? (
    <div data-testid="calorie-amount" className={classes}>
      {caloriePhrase} <CalorieUnit />
    </div>
  ) : null;
};
