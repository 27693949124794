export const LOG_OUT = 'user/LOG_OUT';
export const RECEIVE_USER = 'user/RECEIVE_USER';
export const INIT_LOG_IN = 'user/INIT_LOG_IN';
export const REQUEST_LOG_IN = 'user/REQUEST_LOG_IN';
export const RECEIVE_LOG_IN = 'user/RECEIVE_LOG_IN';
export const REQUEST_EDIT = 'user/REQUEST_EDIT';
export const RECEIVE_EDIT = 'user/RECEIVE_EDIT';
export const REQUEST_REGISTER = 'user/REQUEST_REGISTER';
export const INIT_REGISTER = 'user/INIT_REGISTER';
export const RECEIVE_REGISTER = 'user/RECEIVE_REGISTER';
export const SHOW_LOG_IN_MODAL = 'user/SHOW_LOG_IN_MODAL';
export const REQUEST_CHANGE_PASSWORD = 'user/REQUEST_CHANGE_PASSWORD';
export const RECEIVE_CHANGE_PASSWORD = 'user/RECEIVE_CHANGE_PASSWORD';
export const REQUEST_RESET_PASSWORD = 'user/REQUEST_RESET_PASSWORD';
export const RECEIVE_RESET_PASSWORD = 'user/RECEIVE_RESET_PASSWORD';
export const UPDATE_USER_DATA = 'user/UPDATE_USER_DATA';
export const REGISTERING_FROM_CHECKOUT = 'user/REGISTERING_FROM_CHECKOUT';
export const CHANGE_DOB = 'user/CHANGE_DOB';
export const REQUEST_RESET_PASSWORD_BY_TOKEN =
  'user/REQUEST_RESET_PASSWORD_BY_TOKEN';
export const RECEIVE_RESET_PASSWORD_BY_TOKEN =
  'user/RECEIVE_RESET_PASSWORD_BY_TOKEN';
export const INIT_GUEST_CHECKOUT = 'user/INIT_GUEST_CHECKOUT';
export const RESET_GUEST_CHECKOUT = 'user/RESET_GUEST_CHECKOUT';
export const RECEIVE_GUEST_EMAIL = 'user/RECEIVE_GUEST_EMAIL';
export const RECEIVE_LOGIN_METHOD = 'user/RECEIVE_LOGIN_METHOD';
export const HIDE_OTP_MODAL = 'user/HIDE_OTP_MODAL';
export const SHOW_OTP_MODAL = 'user/SHOW_OTP_MODAL';
export const INIT_OTP = 'user/INIT_OTP';
export const INIT_LOG_IN_WITH_OTP = 'user/INIT_LOG_IN_WITH_OTP';
export const REQUEST_LOG_IN_WITH_OTP = 'user/REQUEST_LOG_IN_WITH_OTP';
export const RECEIVE_LOG_IN_WITH_OTP = 'user/RECEIVE_LOG_IN_WITH_OTP';
export const OTP_ENABLED = 'user/OTP_ENABLED';
