import { useEffect, useState } from 'react';
import { TablesModal } from 'core/components/Modals/TablesModal';
import { TimeslotsModal } from 'core/components/Modals/TimeslotsModal';
import { BasketAnimation } from 'basket/components/Basket';
import { Helmet } from 'react-helmet-async';
import { LineList } from 'basket/components';
import { useBreakpoint } from 'contexts/BreakpointContext';
import { useConfig } from 'contexts/ConfigContext';
import { useMenus } from 'contexts/MenuContext';
import {
  StickyBasketBottomRow,
  StickyBasketFirstRow,
} from 'basket/components/Basket/StickyBasketComponents';
import { useBasket } from 'contexts/BasketContext';
import { useVouchers } from 'contexts/VoucherContext';

export const StickyBasket: React.FC = () => {
  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.position = 'relative';
    };
  }, []);

  const { stickyBasketEnabled } = useConfig();
  const { courses } = useMenus();
  const { basket, isBasketCollapsed } = useBasket();
  const { redeemedVouchers } = useVouchers();
  const { breakpoints } = useBreakpoint();

  const [headerColour, setHeaderColour] = useState<string>('');

  const isMobile =
    breakpoints.xs === true ||
    breakpoints.tiny === true ||
    breakpoints.landscape === true;

  const getClass = () => {
    if (isMobile) {
      if (isBasketCollapsed) {
        document.body.style.overflow = 'auto';
        document.body.style.position = 'relative';
        return 'sticky-basket-list-collapsed';
      }

      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      return 'sticky-basket-list-opened';
    }
    return 'sticky-basket-list';
  };

  if (!stickyBasketEnabled) {
    return null;
  }

  const getPose = () => {
    if (isMobile) {
      return 'open';
    }

    let position = 'out';

    if (basket.length + redeemedVouchers.length > 0) {
      if (isBasketCollapsed) {
        position = 'in';
      } else {
        position = 'open';
      }
    }

    return position;
  };

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={headerColour} />
      </Helmet>
      <BasketAnimation
        key={1}
        className={getClass()}
        pose={getPose()}
        isMobile={isMobile}
        data-testid="sticky-basket"
      >
        <StickyBasketFirstRow
          getClass={getClass}
          setHeaderColour={setHeaderColour}
        />
        {!isBasketCollapsed && (
          <>
            <div className="row basket-middle-row">
              <LineList courses={courses} />
            </div>
            <StickyBasketBottomRow />
          </>
        )}
      </BasketAnimation>
      <TimeslotsModal />
      <TablesModal />
    </>
  );
};
