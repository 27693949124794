/**
 * FORM INPUT VALIDATION
 */

export const required =
  (requiredOption: boolean) =>
  (value: unknown): string | undefined =>
    requiredOption && !value ? 'Required' : undefined;

export const email = (value: string): string | undefined =>
  value &&
  !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
    value,
  )
    ? 'Invalid email address'
    : undefined;

export const mobile =
  (isMobile: boolean) =>
  (value: string): string | undefined =>
    isMobile &&
    value &&
    !/^([+][0-9]{1,3}([ .-])?)?([(]{1}[0-9]{3}[)])?([0-9A-Z .-]{1,32})((x|ext|extension)?[0-9]{1,4}?)$/g.test(
      value,
    )
      ? 'Mobile number is invalid'
      : undefined;

export const password =
  (isPassword: boolean | undefined, minLength: number) =>
  (value: string): string | undefined =>
    isPassword &&
    value &&
    !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z`¬!"£$%^&*()-_=+'@#~<>,.{}[\]]{8,}$/g.test(
      value,
    )
      ? `Password must contain at least ${minLength} characters, one uppercase character, one lowercase character, and one digit`
      : undefined;

export const loyaltyCard =
  (isLoyaltyCard: boolean) =>
  (value: string): string | undefined =>
    isLoyaltyCard && value && !/^\w+$/g.test(value)
      ? 'Loyalty card number can be alphanumeric only'
      : undefined;

// export const confirmPassword =
//   (matchName: string) =>
//   (
//     value: string,
//     allValues: { get: (arg0: string) => string },
//   ): string | undefined =>
//     value !== allValues.get(matchName) ? 'Passwords must match' : undefined;

export const dob =
  (isDob: boolean) =>
  (value: string): string | undefined =>
    isDob &&
    value &&
    !/([12])\d{3}-(01|02|03|04|05|06|07|08|09|10|11|12)-(01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|23|24|25|26|27|28|29|30|31)/i.test(
      value,
    )
      ? 'Date of birth is invalid'
      : undefined;

export const yearLow =
  (isDob: boolean) =>
  (value: string): string | undefined =>
    isDob &&
    value &&
    parseInt(new Date().getFullYear().toString(), 10) -
      parseInt(value.slice(0, 4), 10) <
      16
      ? 'Minimum age needs to be 16'
      : undefined;

export const yearMax =
  (isDob: boolean) =>
  (value: string): string | undefined =>
    isDob &&
    value &&
    parseInt(new Date().getFullYear().toString(), 10) -
      parseInt(value.slice(0, 4), 10) >
      118
      ? 'Year of birth needs to be 1901 or above'
      : undefined;

export const select =
  () =>
  (value: unknown): string | undefined =>
    value === 'placeholder' || !value ? 'Please select' : undefined;

export const names =
  (isName: boolean) =>
  (value: string): string | undefined =>
    isName && value && /[$£&+,:;=?@#|<>.^*()%!0-9"]+/g.test(value)
      ? "Name can only contain letters, hyphens (-) and apostrophes (') "
      : undefined;

export const max =
  (maxLength: number) =>
  (value: string): string | undefined =>
    maxLength && value && value.length > maxLength
      ? `Must be no more than ${maxLength} characters`
      : undefined;

export const min =
  (minLength: number) =>
  (value: string): string | undefined =>
    minLength && value && value.length < minLength
      ? `Must be at least ${minLength} characters`
      : undefined;
