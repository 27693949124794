import { useContext } from 'react';
import { ModalHeader, ModalBody } from 'components/Modal';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { VenueModalFooter } from 'venue/components/SharedModalComponents/VenueModalFooter';
import { ServiceModalHeader } from 'venue/components/SharedModalComponents';
import { hideServiceChoiceModal } from 'venue/actions';
import { Notification } from 'core/components/Notification';
import core from 'core';
import { ServiceModalContext } from 'contexts/ServiceModalContext';
import { getUrlPart } from 'utils';
import { TimeslotPhrase } from 'common/components/TimeslotPhrase';
import { useAdditionalInformation } from 'contexts/AdditionalInformationContext';
import { useServices, useVenues } from 'contexts/VenueContext';
import { useTables } from 'contexts/TableContext/TableContext';
import { TimeslotSelector } from 'features/timeslot/components/TimeslotSelector';
import { useTimeslots } from 'contexts/TimeslotContext/TimeslotContext';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

interface TimeslotSelectorFormProps {
  timeslot: string;
}

export const SelectedServiceClickAndCollect: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const methods = useForm<TimeslotSelectorFormProps>();
  const { onClose } = useContext(ServiceModalContext);

  const { selectedService } = useServices();
  const { selectedVenue } = useVenues();
  const { setSelectedTimeslot, isFetchingTimeslots, timeslots } =
    useTimeslots();
  const { setSelectedTable } = useTables();
  const { getAdditionalInfo } = useAdditionalInformation();

  const timeslotPhrase = TimeslotPhrase({ selectedService });

  const handleConfirm: SubmitHandler<TimeslotSelectorFormProps> = (
    formValues,
  ) => {
    const formTimeslot = formValues.timeslot;
    // TODO - Look to maybe add this in as form validation?
    if (formTimeslot && !isNaN(new Date(formTimeslot).getTime())) {
      setSelectedTimeslot({ time: formTimeslot, available: true });
      dispatch(hideServiceChoiceModal());
      setSelectedTable(undefined);
      getAdditionalInfo();
      const urlPart = getUrlPart(selectedService);

      history.push(`/venue/${selectedVenue?.id}/${urlPart}/menus`);
    } else {
      dispatch(
        core.actions.addNotification(
          `Please select a ${timeslotPhrase}.`,
          'danger',
        ),
      );
    }
  };

  return (
    <FormProvider {...methods}>
      <ModalHeader closeButton onRequestClose={() => onClose(true)}>
        <ServiceModalHeader useDistanceIfAvailable />
      </ModalHeader>
      <ModalBody>
        <Notification />
        <TimeslotSelector />
      </ModalBody>
      <VenueModalFooter
        handleConfirm={methods.handleSubmit(handleConfirm)}
        disabled={isFetchingTimeslots || timeslots.length === 0}
      />
    </FormProvider>
  );
};
