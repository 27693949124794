import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { usePhrases } from 'contexts/ConfigContext';
import { ConfirmTablesModal } from 'core/components/Modals';
import { useTables } from 'contexts/TableContext/TableContext';

export const TableChanger: React.FC = () => {
  const { table: tablePhrase } = usePhrases();
  const [showModal, setShowModal] = useState(false);

  const { selectedTable, setShowTablesModal } = useTables();

  const showTablesModal = () => {
    setShowModal(true);
  };

  return (
    <>
      {tablePhrase}: {selectedTable?.label}
      <Button
        bsStyle="link"
        className="btn-change"
        onClick={() => showTablesModal()}
        data-testid="change-table-button"
      >
        CHANGE?
      </Button>
      <ConfirmTablesModal
        show={showModal}
        proceed={() => {
          setShowTablesModal(true);
          setShowModal(false);
        }}
        dismiss={() => {
          setShowModal(false);
        }}
      />
    </>
  );
};
