import { Dialog } from 'components/Dialog';
import { usePhrases } from 'contexts/ConfigContext';
import { RedeemedRewardItem } from 'types/models/Loyalty';

interface ConfirmLoyaltyRewardRemovalModalProps {
  show: boolean;
  rewardToRemove: RedeemedRewardItem | undefined;
  cancelAction: () => void;
  acceptAction: (reward: RedeemedRewardItem) => void;
}

export const ConfirmLoyaltyRewardRemovalModal: React.FC<
  ConfirmLoyaltyRewardRemovalModalProps
> = ({ show, rewardToRemove, cancelAction, acceptAction }) => {
  const {
    loyalty: { rewardPhrase },
  } = usePhrases();
  if (!rewardToRemove) {
    return null;
  }

  return (
    <Dialog
      isOpen={show}
      title={`Remove ${rewardPhrase}`}
      description={`Are you sure you want to remove the ${rewardPhrase}?`}
      datatestid="remove-reward-confirmation-modal"
      accept={{
        action: () => acceptAction(rewardToRemove),
        text: `Remove ${rewardPhrase}`,
        btnType: 'destructive',
        datatestid: 'remove-reward-confirm-btn',
      }}
      reject={{
        action: () => cancelAction(),
        text: 'Cancel',
      }}
    />
  );
};
