import {
  GrandTotal,
  SavingsTotal,
  SubTotal,
  TaxTotals,
  TipTotal,
} from 'checkout/components/TotalSummary/Totals';
import { PaymentContextualLoader } from 'checkout/components/Payment/ContextualLoader';

export const TotalSummary: React.FC = () => {
  return (
    <div className="card totals">
      <PaymentContextualLoader loadingText="Loading" isOpaque={false} />
      <SubTotal />
      <TaxTotals />
      <SavingsTotal />
      <TipTotal />
      <hr />
      <GrandTotal />
    </div>
  );
};
