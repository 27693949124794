import { useEffect, useState } from 'react';

import cs from 'classnames';
import { Button } from 'react-bootstrap';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { Field } from 'types/models/Forms';
import {
  isMatch,
  isNotOnlyWhitespace,
  isPassword,
  isRequired,
  minLength,
} from 'core/components/RHF/validation';

interface PasswordProps {
  field: Field;
  autocomplete?: string;
  shouldValidatePasswordStrength?: boolean;
  matchFieldName?: string;
}

export const Password: React.FC<PasswordProps> = ({
  field,
  autocomplete,
  shouldValidatePasswordStrength = true,
  matchFieldName,
}) => {
  const {
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext();

  const { min, required, label, placeholder, name } = field;

  const labelToUse = label ? label : name;

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const hasError = errors[name] !== undefined;
  const fieldError = errors[name];

  const formGroupClasses = cs(
    'form-group',
    'password',
    hasError && 'has-error',
  );

  const validationRules: RegisterOptions = {
    ...isRequired(labelToUse, required),
    ...(shouldValidatePasswordStrength && minLength(labelToUse, min ?? 0)),
    validate: {
      ...(required && {
        whiteSpace: (value) => isNotOnlyWhitespace(value, labelToUse),
      }),
      ...(shouldValidatePasswordStrength && {
        password: (value) => isPassword(value, min),
      }),
      ...(matchFieldName && {
        matchPassword: (value) => {
          const fieldToMatch = watch(matchFieldName);
          return isMatch(value, fieldToMatch, 'Passwords');
        },
      }),
    },
  };

  const requiredLabel = required ? <span className="required">*</span> : null;

  useEffect(() => {
    if (field.content) {
      setValue(field.name, field.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={formGroupClasses}>
      <label className="control-label" htmlFor={name}>
        {label}
        {requiredLabel}
      </label>
      <div className='password-wrapper'>
        <input
          className="form-control"
          type={showPassword === true ? 'text' : 'password'}
          placeholder={placeholder || labelToUse}
          aria-required={required ? 'true' : 'false'}
          aria-invalid={hasError ? 'true' : 'false'}
          {...register(name, validationRules)}
          autoComplete={autocomplete}
        />
        <Button
          className="hide-show"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword === true ? 'Hide' : 'Show'}
        </Button>
      </div>
      {fieldError && (
        <span className="help-block" role="alert">
          {fieldError.message}
        </span>
      )}
    </div>
  );
};
