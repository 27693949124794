import { Link } from 'react-router-dom';
import { usePhrases } from 'contexts/ConfigContext';

export const ForgottenPassword: React.FC = () => {
  const {
    login: { forgottenPasswordText },
  } = usePhrases();

  return (
    <>
      <div className="form-group">
        <p className="pull-right inline-block">
          <Link
            to="/user/password-request"
            className="dark-link"
            data-testid="login-forgot-password-link"
          >
            {forgottenPasswordText}
          </Link>
        </p>
      </div>
    </>
  );
};
