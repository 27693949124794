import { useAdditionalInformation } from 'contexts/AdditionalInformationContext';
import { VirtualSelect } from 'core/components/RHF/FieldTemplates';
import { FieldGenerator } from 'core/components/RHF';
import { useLocation } from 'react-router';

export const DeliveryToLocationInformation: React.FC = () => {
  const { locationInformationFields, additionalInformationError } =
    useAdditionalInformation();

  const history = useLocation();
  const searchParams = new URLSearchParams(history.search);

  return (
    <>
      {!additionalInformationError ? (
        <div data-testid="delayed-ordering-additional-info">
          {locationInformationFields.map((formField) => {
            if (formField.type === 'select') {
              const queryValue = searchParams.get(formField.name);
              if (queryValue) {
                const foundValue = formField?.options?.find((opt) => {
                  return (
                    opt.value.toLocaleLowerCase().replace(/\s+/g, '') ===
                    queryValue.toLocaleLowerCase().replace(/\s+/g, '')
                  );
                });

                return (
                  <div
                    data-testid="additional-field-select"
                    key={formField.name}
                  >
                    <VirtualSelect
                      formField={formField}
                      selectedOption={foundValue}
                    />
                  </div>
                );
              }

              return (
                <div data-testid="additional-field-select" key={formField.name}>
                  <VirtualSelect formField={formField} />
                </div>
              );
            } else {
              const queryValue = searchParams.get(formField.name);
              if (queryValue) {
                formField.content = queryValue;
              }

              return (
                <div
                  key={formField.name}
                  data-testid={`additional-field-${
                    formField.userFieldType ?? formField.name
                  }`}
                >
                  <FieldGenerator field={formField} />
                </div>
              );
            }
          })}
        </div>
      ) : (
        <div className="alert alert-danger" role="alert">
          {additionalInformationError.detail}
        </div>
      )}
    </>
  );
};
