import * as actionTypes from './actionTypes';
import { initialState } from './model';

const reducer = (state = initialState, action) => {


  switch (action.type) {

    case actionTypes.SET_TIP_AMOUNT:
      return state.set('tipAmount', action.payload);

    case actionTypes.SET_ADDITIONAL_INFO_ERROR_DIALOG:
      return state.set('additionalInfoErrorDialog', action.message);

    case actionTypes.SET_SELECTED_TIP_PRESET:
      return state.merge({
        selectedTipPreset: action.preset,
      });
    default:
      return state;
  }
};

export default reducer;

// Selectors that are Immutable objects