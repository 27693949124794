import { Coordinates } from 'types';
import { FixMeLater } from 'types/FixMeLater';
import { ServiceType } from 'types/models/Service';

export interface VenueAddress {
  [key: string]: string;
  country: string;
  county: string;
  line1: string;
  line2: string;
  line3: string;
  postcode: string;
  town: string;
}

export interface VenueContactDetails {
  telephone: string;
  email?: string;
}

export interface VenueCurrency {
  code: string;
  countryCode: string;
  currencyCode: string;
  htmlName: string;
  htmlNumber: string;
  symbol: string;
}

export interface VenueOpeningTime {
  closed: boolean;
  closingTime: string;
  label: string;
  openingTime: string;
  name: string;
}

export interface VenueDisplayImage {
  altText: string;
  id: number;
  url: string;
}

export interface VenueSalesArea {
  canChargeToRoom: boolean;
  canOrder: boolean;
  canPlaceOrder: boolean;
  description: string;
  friendly: string;
  id: number;
  images: VenueDisplayImage[];
  location: { distance: number; longitude: number; latitude: number };
  name: string;
  services: number[];
  supportedServices: number[];
  telephoneNumber: string;
}

export interface VenueSocial {
  facebook: string;
  googleplus: string;
  instagram: string;
  snapchat: string;
  twitter: string;
}

export interface ExternalLink {
  url: string;
  name: string;
  type: ExternalLinkType;
}

export type ExternalLinkType = 'Allergens';

export interface VenueBanner {
  sortOrder: number;
  images: VenueHomeImage[];
  link: string;
  title: string;
  id: number;
  serviceId?: ServiceType;
}

export const acceptedVenueBannerUrls = [
  'iorder://menu/orderfood',
  'iorder://menu/clickandcollect',
  'iorder://menu/deliverytolocation',
  'iorder://menu/paymybill',
  'iorder://menu/allergens',
];

export const venueBannerCustomUrlPrefix = 'iorder://menu/special?url=';

export interface VenueHomeImage {
  device: DeviveType;
  url: string;
  altText?: string;
}

export type DeviveType = 'normal' | 'retina';

export interface VenueHeader {
  images: VenueHomeImage[];
  name: string;
}

export interface VenueHome {
  banners: VenueBanner[];
  header: VenueHeader;
  venueId: number;
}

export interface VenueSummary {
  address: VenueAddress;
  canOrder: boolean;
  canPlaceOrder: boolean;
  comingSoon: boolean;
  contactDetails: VenueContactDetails;
  coordinates: Coordinates;
  currency: VenueCurrency;
  distance: number;
  id: number;
  isTemporarilyClosed: boolean;
  locale: string;
  name: string;
  openingTimes: VenueOpeningTime[];
  salesArea: VenueSalesArea[];
  services: number[];
  timezone: string;
}

export interface Venue {
  about: string;
  address: VenueAddress;
  canPlaceOrder: boolean;
  comingSoon: boolean;
  contactDetails: VenueContactDetails;
  currency: VenueCurrency;
  displayImage: string;
  displayImages: VenueDisplayImage[];
  distance: number;
  externalLinks: ExternalLink[];
  feedbackURL: string;
  id: number;
  levels: string[];
  locale: string;
  manager: string;
  name: string;
  rearMenuId: number;
  salesArea: VenueSalesArea[];
  services: number[]; //TODO - this might be a different format now - it's different in Redux
  social: VenueSocial;
  specialPromos: FixMeLater;
  thumbNail: string;
  venueCanOrder: boolean;
  venueRef: string;
}

export type CombinedVenue = Venue & VenueSummary;
