import { Nav } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { NavItemLink } from '../NavItemLink';
import { useConfig, usePhrases } from 'contexts/ConfigContext';

interface LoggedOutHeaderProps {
  closeNav: () => void;
}
type LocationState = {
  from: {
    pathname: string;
  };
};

export const LoggedOutHeader: React.FC<LoggedOutHeaderProps> = ({
  closeNav,
}) => {
  const location = useLocation<LocationState>();
  const from = location?.state?.from?.pathname || location.pathname;
  const {
    login: { loginButtonText, createAccountText },
  } = usePhrases();

  const { accountDeletionOnly, disableUserSignIn } = useConfig();

  if (accountDeletionOnly || disableUserSignIn) {
    return null;
  }

  return (
    <Nav
      className="no-top-margin"
      pullRight
      data-testid="header-logged-out-nav"
    >
      <NavItemLink
        className="header-link header-link-right-padding"
        to={'/user/login'}
        from={from}
        datatestid="header-log-in-link"
        onClick={closeNav}
      >
        {loginButtonText}
      </NavItemLink>
      <NavItemLink
        className="header-link"
        to="/user/register"
        from={from}
        datatestid="header-register-link"
        onClick={closeNav}
      >
        {createAccountText}
      </NavItemLink>
    </Nav>
  );
};
