import { Service, CombinedVenue } from 'types/models';

export const replaceWithDynamicText = (
  phrase: string,
  venue: CombinedVenue,
  service: Service,
): string => {
  return phrase
    .replace('$siteName$', venue.name)
    .replace('$siteTown$', venue.address.town)
    .replace('$siteCounty$', venue.address.county)
    .replace('$orderMode$', service.label);
};
