import { useRef, useState } from 'react';
import {
  WindowScroller,
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache,
  ListRowRenderer,
} from 'react-virtualized';
import { VenueListItem } from 'venue/components/Item';
import { Loader } from 'components/Loader';
import { usePhrases } from 'contexts/ConfigContext';
import { showServiceChoiceModal } from 'venue/actions';
import { ConfirmVenueModal } from 'venue/components';
import { changeCurrency, changeLocale, removeNotification } from 'core/actions';
import { getUrlPart } from 'utils';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useGiftCard } from 'contexts/GiftCardContext';
import { ServiceType, CombinedVenue } from 'types/models';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { useSalesAreas, useServices, useVenues } from 'contexts/VenueContext';
import { useBasket } from 'contexts/BasketContext';
import { useAllergenPrompt } from 'contexts/AllergenContext';
import { useCheckout } from 'contexts/CheckoutContext';

interface VenueListProps {
  venues: CombinedVenue[];
}

export const VenueList: React.FC<VenueListProps> = ({ venues }) => {
  const { venueTitle } = usePhrases();
  const history = useHistory();
  const dispatch = useDispatch();

  const cache = new CellMeasurerCache({ defaultHeight: 270, fixedWidth: true });
  const tableRef = useRef<List>(null);

  const { isLoadingVenues, selectedVenue, setSelectedVenue } = useVenues();
  const { selectedService, setSelectedService } = useServices();
  const { setSelectedSalesArea } = useSalesAreas();
  const { removeAllGiftCards, redeemedGiftCards } = useGiftCard();
  const { removeAllRewards, redeemedLoyaltyRewards } = useLoyaltyRewards();
  const { setHasPromptBeenShown } = useAllergenPrompt();
  const { basket, clearBasket } = useBasket();
  const { clearCheckout } = useCheckout();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const basketIsEmpty = basket.length === 0;
  const hasGiftCards = redeemedGiftCards.length > 0;
  const hasLoyaltyRewards = redeemedLoyaltyRewards.length > 0;

  const mostRecentWidth = 0;

  const recalculateRowHeight = () => {
    cache.clearAll();
    tableRef?.current?.recomputeRowHeights();
  };

  const setVenue = (service: ServiceType, venue: CombinedVenue) => {
    setSelectedVenue(venue);
    setSelectedService(service);
    setHasPromptBeenShown(false);

    dispatch(changeCurrency(venue.currency.currencyCode));
    dispatch(changeLocale(venue.locale.replace(/_/g, '-')));
  };

  const goToSalesArea = (service: ServiceType, venue: CombinedVenue) => {
    const availableServiceAreas = venue.salesArea.filter(
      (sa) => sa.canOrder && sa.services.includes(service),
    );
    if (availableServiceAreas.length === 1) {
      setVenue(service, venue);
      setSelectedSalesArea(availableServiceAreas[0]);
      dispatch(showServiceChoiceModal());
    } else {
      setVenue(service, venue);
      dispatch(removeNotification());
      history.push(`/venue/${venue.id}/${getUrlPart(service)}/sales-areas`);
    }
  };

  const handleClick = (service: ServiceType, venue: CombinedVenue) => {
    if (
      !basketIsEmpty &&
      (venue.id !== selectedVenue?.id || hasGiftCards || hasLoyaltyRewards)
    ) {
      setShowConfirmModal(true);
      setSelectedVenue(venue);
      setSelectedService(service);
    } else {
      goToSalesArea(service, venue);
    }
  };

  const handleAccept = (venue?: CombinedVenue) => {
    const combinedVenue = venue as CombinedVenue;
    setVenue(selectedService, combinedVenue);

    if (hasGiftCards) {
      removeAllGiftCards();
    }

    if (hasLoyaltyRewards) {
      removeAllRewards();
    }

    clearBasket();
    clearCheckout();
    goToSalesArea(selectedService, combinedVenue);
    setShowConfirmModal(false);
  };

  const handleDismiss = () => {
    setSelectedVenue(undefined);
    setShowConfirmModal(false);
  };

  const Row: ListRowRenderer = ({ index, key, style, parent }) => {
    const venueData = venues[index];

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
        width={mostRecentWidth}
      >
        <div style={style}>
          <VenueListItem
            key={venueData.id}
            venue={venueData}
            onRowExpand={() => recalculateRowHeight()}
            handleButtonClick={handleClick}
          />
        </div>
      </CellMeasurer>
    );
  };

  if (venues?.length > 0 && !isLoadingVenues) {
    return (
      <>
        <WindowScroller>
          {({ height, scrollTop }) => (
            <AutoSizer disableHeight onResize={recalculateRowHeight}>
              {({ width }) => (
                <List
                  autoHeight
                  deferredMeasurementCache={cache}
                  height={height}
                  width={width}
                  scrollTop={scrollTop}
                  ref={tableRef}
                  rowHeight={cache.rowHeight}
                  rowRenderer={Row}
                  rowCount={venues.length}
                  overscanRowCount={10}
                >
                  {Row}
                </List>
              )}
            </AutoSizer>
          )}
        </WindowScroller>
        <ConfirmVenueModal
          show={showConfirmModal}
          proceed={handleAccept}
          venue={selectedVenue}
          dismiss={handleDismiss}
        />
      </>
    );
  }

  if (!isLoadingVenues) {
    return <p className="text-center loading-wrapper">No {venueTitle}s</p>;
  }

  return (
    <div>
      <Loader />
    </div>
  );
};
