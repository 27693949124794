export enum PaymentType {
  CreditCard = 'Credit Card',
  ApplePay = 'Apple Pay',
  GooglePay = 'Google Pay',
  PayPal = 'PayPal',
}

export type PaymentDeviceData = string | null;
export type PaymentNonce = string | null;

export type PayPalButtonColour = 'black' | 'blue' | 'gold' | 'silver' | 'white';

export type ApplePayButtonColour = 'black' | 'white' | 'outline';

export interface AdditionalInformationFormData {
  email?: string;
  field_1?: string;
  field_2?: string;
}

export interface AdditionalInformationRequest {
  name: string;
  value: string;
}

export type ApplePayPaymentContactFields = Record<string, string | string[]>;

export interface ApplePayShippingInformation {
  phoneNumber?: string;
  emailAddress?: string;
  addressLines?: string[];
  administrativeArea?: string;
  country?: string;
  countryCode?: string;
  familyName?: string;
  givenName?: string;
  locality?: string;
  postalCode?: string;
}
