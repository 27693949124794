import { Header } from 'core/components/Header';
import { Footer } from 'core/components';
import { Notification } from 'core/components/Notification';

interface AppProps {
  children: React.ReactNode;
}

export const App: React.FC<AppProps> = ({ children }) => {
  return (
    <div id="globalWrapper">
      <Notification />
      <div id="mainContainer">
        <Header />
        <div id="routerContainer">{children}</div>
        <Footer />
      </div>
    </div>
  );
};
