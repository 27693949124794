import posed from 'react-pose';

export const BasketAnimation = posed.div({
  in: {
    opacity: 1,
    y: ({ isMobile }: { isMobile: boolean }) =>
      isMobile ? window.innerHeight - 70 : 430,
    transition: {
      duration: 200,
    },
  },
  out: {
    opacity: 1,
    y: ({ isMobile }: { isMobile: boolean }) =>
      isMobile ? window.innerHeight : 430,
    transition: {
      duration: 200,
    },
  },
  open: {
    y: 0,
    transition: {
      duration: 200,
    },
  },
});
