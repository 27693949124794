import { useSalesAreas, useVenues } from 'contexts/VenueContext';
import { usePhrases } from 'contexts/ConfigContext';
import { getOrderLocationPhrase } from 'utils';
import { VenueAddress } from 'venue/components/SingleVenue/VenueInfo';

export const Address: React.FC = () => {
  const { venueSalesAreaFormat } = usePhrases();
  const { selectedVenue } = useVenues();
  const { selectedSalesArea } = useSalesAreas();

  const orderLocationPhrase = getOrderLocationPhrase(
    venueSalesAreaFormat,
    selectedVenue,
    selectedSalesArea,
  );

  return selectedVenue ? (
    <div className="card address">
      <h3 className="title">{orderLocationPhrase}</h3>
      <div className="body">
        <VenueAddress address={selectedVenue.address} className="text-muted" />
      </div>
    </div>
  ) : null;
};
