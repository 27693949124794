import { Button } from 'react-bootstrap';
import { FaChevronCircleRight } from 'react-icons/fa';
import {
  CombinedVenue,
  VenueBanner,
  venueBannerCustomUrlPrefix,
} from 'types/models';
import { getVenueImage, isSalesAreaSupportingService } from 'venue/utils';
import cs from 'classnames';

interface VenueBannerProps {
  venue: CombinedVenue;
  banner: VenueBanner;
  handleClick: (venue: CombinedVenue, serviceId: number) => void;
}

export const VenueBannerItem: React.FC<VenueBannerProps> = ({
  venue,
  banner,
  handleClick,
}) => {
  const image = getVenueImage(banner.images);
  const imageUrl = image?.url;

  const bannerClasses = cs({
    'venue-banner-btn-image': imageUrl,
    'btn-block btn-primary venue-banner-btn': !imageUrl,
  });

  const serviceId = banner.serviceId;

  const getBannerUrl = (): string | undefined => {
    if (banner.link === 'iorder://menu/allergens') {
      return venue?.externalLinks.find(
        (x) => x.type.toLowerCase() === 'allergens',
      )?.url;
    } else {
      return banner.link.substring(venueBannerCustomUrlPrefix.length);
    }
  };

  const handleBannerClick = () => {
    if (banner.serviceId) {
      handleClick(venue, banner.serviceId);
    }
  };

  const bannerExternalUrl = getBannerUrl();
  const additionalBtnProps = !serviceId
    ? {
        as: 'a',
        href: bannerExternalUrl,
        target: '_blank',
        rel: 'no-referrer',
      }
    : {
        disabled: !isSalesAreaSupportingService(venue, serviceId),
        'data-testid': `service-banner-${serviceId}-button`,
      };

  return !serviceId && !bannerExternalUrl ? null : (
    <Button
      data-testid={`service-banner-${serviceId}-button`}
      className={bannerClasses}
      onClick={handleBannerClick}
      {...additionalBtnProps}
    >
      {imageUrl ? (
        <img src={imageUrl} alt={image?.altText || banner.title} />
      ) : (
        <>
          {banner.title}
          <FaChevronCircleRight className="service-button-proceed-icon" />
        </>
      )}
    </Button>
  );
};
