import { Dialog } from 'components/Dialog/Dialog';
import { usePhrases } from 'contexts/ConfigContext';
import { CombinedVenue } from 'types/models';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { useGiftCard } from 'contexts/GiftCardContext';

interface ConfirmVenueModalProps {
  show: boolean;
  proceed: (venue?: CombinedVenue) => void;
  dismiss: () => void;
  venue?: CombinedVenue;
}

export const ConfirmVenueModal: React.FC<ConfirmVenueModalProps> = ({
  show,
  proceed,
  dismiss,
  venue,
}) => {
  const {
    venueTitle,
    giftCard: { giftCardPhrase },
    loyalty: { rewardPhrase },
  } = usePhrases();
  const { redeemedLoyaltyRewards } = useLoyaltyRewards();
  const { redeemedGiftCards } = useGiftCard();

  const hasGiftCards = redeemedGiftCards.length > 0;
  const hasLoyaltyRewards = redeemedLoyaltyRewards.length > 0;

  const phraseToUse = hasGiftCards ? giftCardPhrase : rewardPhrase;

  return (
    <Dialog
      isOpen={show}
      title="Clear Basket"
      description={`If you change the selected ${venueTitle}, your basket will need to be emptied. ${
        hasGiftCards || hasLoyaltyRewards
          ? `All ${phraseToUse}s will be removed from your basket and will need to be re-added.`
          : ''
      } Do you wish to continue?`}
      accept={{
        action: venue ? () => proceed(venue) : () => proceed(),
        text: 'Continue',
        btnType: 'destructive',
      }}
      reject={{ action: dismiss, text: 'Cancel' }}
    />
  );
};
