import { Dialog } from 'components/Dialog/Dialog';
import { usePhrases } from 'contexts/ConfigContext';

interface ConfirmTablesModalProps {
  show: boolean;
  proceed: () => void;
  dismiss: () => void;
}

export const ConfirmTablesModal: React.FC<ConfirmTablesModalProps> = ({
  show,
  proceed,
  dismiss,
}) => {
  const { table: tablePhrase } = usePhrases();

  return (
    <Dialog
      isOpen={show}
      title={`Change ${tablePhrase}`}
      description={`You are about to change your ${tablePhrase}. Do you wish to continue?`}
      accept={{
        action: proceed,
        text: 'Continue',
        datatestid: 'warning-continue-button',
        btnType: 'destructive',
      }}
      reject={{
        action: dismiss,
        text: 'Cancel',
        datatestid: 'warning-cancel-button',
      }}
    />
  );
};
