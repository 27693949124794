import { CombinedVenue } from 'types/models';
import { MapPopover } from './MapPopover';
import { Overlay } from 'react-bootstrap';
import { HiLocationMarker } from 'react-icons/hi';
import { useRef } from 'react';

interface MapMarkerProps {
  lat: number;
  lng: number;
  venue: CombinedVenue;
  openPopoverId: number;
}
export const MapMarker: React.FC<MapMarkerProps> = ({
  venue,
  openPopoverId,
}) => {
  const target = useRef(null);

  return (
    <>
      <div ref={target} className="map-marker">
        <HiLocationMarker className="map-marker" />
      </div>
      <Overlay
        animation={false}
        placement="top"
        target={target.current ?? undefined}
        show={venue.id === openPopoverId}
      >
        <MapPopover venue={venue} />
      </Overlay>
    </>
  );
};
