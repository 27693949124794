import { AztecProduct } from 'types/models';
import { useMenuModal } from 'contexts/MenuModalContext';
import {
  getSelectedPortion,
  portionsHaveChoices,
  productHasAndedUpsells,
  updatePortionQuantity,
} from 'menu/utils';
import { QuantityField } from 'menu/components/MenuModal/ModalComponents';

interface FooterQuantityFieldProps {
  aztecProduct: AztecProduct;
  updateAztecProduct: (product: AztecProduct) => void;
}

export const FooterQuantityField: React.FC<FooterQuantityFieldProps> = ({
  aztecProduct,
  updateAztecProduct,
}) => {
  const { breadcrumbs } = useMenuModal();
  const onPortionQuantityChange = (portionId: number, quantity: number) => {
    const updatedProduct = updatePortionQuantity(
      aztecProduct,
      portionId,
      quantity,
    );
    updateAztecProduct(updatedProduct);
  };

  const somePortionsHaveChoices = portionsHaveChoices(aztecProduct);
  const selectedPortion = getSelectedPortion(aztecProduct);

  const displayQuantitySelector =
    // if no breadcrumbs, but choices is set, then we're past the portion selection and
    // at first level of choice-selection
    breadcrumbs.length === 0 &&
    selectedPortion &&
    (somePortionsHaveChoices || productHasAndedUpsells(aztecProduct));

  if (!displayQuantitySelector) {
    return null;
  }

  return (
    <div className="row quantity-section">
      <div className="col-xs-6 text-left">
        <span>Quantity</span>
      </div>
      <div className="col-xs-6">
        <QuantityField
          quantity={selectedPortion.quantity}
          min={1}
          callbackArgs={[selectedPortion.id]}
          onChange={onPortionQuantityChange}
          max={99}
        />
      </div>
    </div>
  );
};
