import { useCheckout } from 'contexts/CheckoutContext';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { MdDelete } from 'react-icons/md';
import { RedeemedRewardItem } from 'types/models/Loyalty';

interface ItemProps {
  reward: RedeemedRewardItem;
  handleShowModal: (reward: RedeemedRewardItem) => void;
}

export const LoyaltyRewardPanelLine: React.FC<ItemProps> = ({
  reward,
  handleShowModal,
}) => {
  const { isCrediting } = useLoyaltyRewards();
  const { isFetchingBasket } = useCheckout();

  return (
    <div
      className={isCrediting ? 'panel-line loading' : 'panel-line'}
      data-testid={`redeemed-reward-${reward.id}`}
    >
      <div className="details">
        <h4>1 x {reward.displayName}</h4>
      </div>
      <div className="remove-button" data-testid="remove-reward-btn">
        {isCrediting || isFetchingBasket ? (
          <MdDelete
            className="remove"
            size={25}
            aria-disabled={isCrediting || isFetchingBasket}
            role="button"
          />
        ) : (
          <MdDelete
            className="remove"
            size={25}
            role="button"
            onClick={() => handleShowModal(reward)}
          />
        )}
      </div>
    </div>
  );
};
