import { usePhrases, useSetPhrases } from 'contexts/ConfigContext';

import { ControlLabel, FormControl } from 'react-bootstrap';

export const LandingHeaderTextInput: React.FC = () => {
  const { landingHeader } = usePhrases();
  const setPhrase = useSetPhrases();

  const handleChange = (event: React.ChangeEvent<FormControl>) => {
    // This is a tad annoying having to do this, but react-bootstrap sucks
    const value: string = (event.target as unknown as HTMLInputElement).value;
    setPhrase('landingHeader', value);
  };

  return (
    <div>
      <ControlLabel className="ff-label">
        Landing header text (text just above &quot;List&quot; and
        &quot;Map&quot; tabs):
      </ControlLabel>
      <FormControl
        data-testid={`ff-landingHeader`}
        id={`ff-landingHeader`}
        type="text"
        value={landingHeader}
        placeholder="Enter text"
        onChange={handleChange}
      />
    </div>
  );
};
