import { useConfig, useSetConfig } from 'contexts/ConfigContext';

import { ControlLabel } from 'react-bootstrap';
import { ToggleSwitch } from 'core/components/ToggleSwitch';

export const VenueSearchToggle: React.FC = () => {
  const { disableSearch } = useConfig();
  const setConfig = useSetConfig();

  const handleClick = (toggledValue: boolean) => {
    setConfig('disableSearch', toggledValue);
  };

  return (
    <div>
      <ControlLabel className="ff-label">Venue Search Disabled:</ControlLabel>
      <ToggleSwitch
        id="venue-search"
        onChange={handleClick}
        checked={disableSearch}
        disabled={false}
        name="venue-search"
        onLabel="Yes"
        offLabel="No"
        small={false}
      />
    </div>
  );
};
