import { Button, Col, FormGroup, Grid, Panel, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsFetchingResetPassword } from 'user/selectors';
import { resetPassword } from 'user/actions';
import { Email } from 'core/components/RHF/FieldTemplates';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { Field } from 'types/models/Forms';

interface FormValues {
  emailAddress: string;
}

export const PasswordRequest: React.FC = () => {
  const dispatch = useDispatch();

  const methods = useForm<FormValues>();

  const handleResetPassword: SubmitHandler<FormValues> = (formData) => {
    dispatch(resetPassword(formData));
  };

  const emailField: Field = {
    label: 'Email Address',
    max: 64,
    min: 5,
    name: 'email',
    placeholder: 'Email Address',
    required: true,
    type: 'email',
  };

  const isFetchingResetPassword = useSelector(selectIsFetchingResetPassword);

  const button = isFetchingResetPassword ? (
    <Button
      bsStyle="primary"
      type="submit"
      onClick={methods.handleSubmit(handleResetPassword)}
      disabled
    >
      Loading
    </Button>
  ) : (
    <Button
      bsStyle="primary"
      type="submit"
      onClick={methods.handleSubmit(handleResetPassword)}
      disabled={
        methods.formState.errors && methods.formState.errors['emailAddress']
          ? true
          : false
      }
    >
      Send
    </Button>
  );

  return (
    <Grid>
      <p className="text-right">
        <Link to="/user/login">Already have an account? Log in here</Link>
      </p>
      <Panel>
        <h2>Request a new password</h2>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleResetPassword)}>
            <Row>
              <Col sm={12}>
                <Email field={emailField} />
              </Col>
            </Row>
            <FormGroup>{button}</FormGroup>
          </form>
        </FormProvider>
      </Panel>
    </Grid>
  );
};
