import { BillTableRow } from 'PayMyBill/bill/components/Products/BillTableRow';
import { TableDetails } from 'PayMyBill/bill/components/Shared/TableDetails';
import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { usePayMyBill } from 'contexts/PayMyBillContext/PayMyBillContext';
import { FormattedBill } from 'types/models/PayMyBill';

interface BillTotalsProps {
  bill: FormattedBill;
  showTable?: boolean;
  isCheckout?: boolean;
}

export const BillTotals: React.FC<BillTotalsProps> = ({
  bill,
  showTable,
  isCheckout,
}) => {
  const { supportsTip } = useConfig();
  const { payMyBillTotalText, payMyBillBalanceText } = usePhrases();

  const { paymentAmount } = usePayMyBill();

  const taxTotal = bill.taxes.reduce((a, b) => a + b.amount, 0);
  const subtotal = bill.accountTotal - taxTotal;
  const totalTips = bill.paymentLines
    .filter((line) => line.tip > 0)
    .reduce((a, b) => a + b.tip, 0);

  return (
    <>
      {showTable ? <TableDetails /> : null}
      <div className="bill-totals">
        <BillTableRow
          name="Subtotal"
          amount={subtotal}
          additionalClasses="bill-totals-line bill-total"
        />
        {bill.taxes.map((tax) => (
          <BillTableRow
            name={tax.name}
            amount={tax.amount}
            key={tax.id}
            additionalClasses="bill-totals-line"
          />
        ))}
        <BillTableRow
          name={payMyBillTotalText}
          amount={bill.accountTotal}
          additionalClasses="bill-totals-line bill-total"
        />
        {bill.paymentLines.map((payment, i) => (
          <BillTableRow
            name={payment.name}
            amount={payment.amount}
            isReduction={true}
            additionalClasses="bill-totals-line"
            key={`${payment.name}-${i}`}
          />
        ))}
        <BillTableRow
          additionalClasses="bill-totals-line bill-total outstanding-balance"
          amount={bill.outstandingBalanceToPay}
          name={payMyBillBalanceText}
        />
        {isCheckout && paymentAmount < bill.outstandingBalanceToPay ? (
          <BillTableRow
            additionalClasses="bill-totals-line bill-total"
            amount={paymentAmount}
            name={`Amount to pay ${supportsTip ? '(excluding tip)' : ''}`}
          />
        ) : null}
      </div>
      {totalTips > 0 ? (
        <>
          <hr />
          <div className="text-muted">
            The payments displayed above exclude{' '}
            <FormattedCurrency value={totalTips} /> paid as tips.
          </div>
        </>
      ) : null}
    </>
  );
};
