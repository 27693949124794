import {
  CheckVoucherResponse,
  ErrorResponse,
  VoidVoucherResponse,
} from 'types/models';

export const isCheckVoucher = (
  response: CheckVoucherResponse | ErrorResponse | undefined,
): response is CheckVoucherResponse => {
  return (
    response !== undefined &&
    (response as CheckVoucherResponse).voucher !== undefined
  );
};

export const isVoidVoucher = (
  response: VoidVoucherResponse | ErrorResponse | undefined,
): response is VoidVoucherResponse => {
  return (
    response !== undefined &&
    (response as VoidVoucherResponse).MESSAGE !== undefined
  );
};

export const isVoucherError = (
  response:
    | CheckVoucherResponse
    | VoidVoucherResponse
    | ErrorResponse
    | undefined,
): response is ErrorResponse => {
  return response !== undefined && (response as ErrorResponse).code !== 200;
};
