import { useSelector } from 'react-redux';
import { Button, FormGroup, Grid, Panel } from 'react-bootstrap';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { selectLoggedInUser } from 'user/reducer';
import { FormGenerator, WLASchemaFormGenerator } from 'core/components/RHF';
import { useLoyalty } from 'hooks/useLoyalty';
import { useConfig } from 'contexts/ConfigContext';
import { GenericJSObject } from 'types/Core';
import { isWebOrderingConfig } from 'types/guards';
import { ConfigField, WLAConfigField } from 'types/models/Forms';

export const LinkLoyalty: React.FC = () => {
  const methods = useForm({
    mode: 'onBlur',
  });

  const { forms } = useConfig();

  const user = useSelector(selectLoggedInUser);
  const userName = `${user.get('firstName')} ${user.get('lastName')}`;

  const { verifyLoyalty, isVerifying } = useLoyalty();

  const onSubmit: SubmitHandler<GenericJSObject> = (values) => {
    verifyLoyalty(values);
  };

  return (
    <Grid>
      <Panel>
        <h2>Link loyalty account</h2>
        <p>{`Signed in as ${userName}`}</p>
        <p>
          Please enter the details to link the loyalty card to your account:
        </p>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {/* The lines below decide whether to use the custom form generator for
              the stupid schema that has been put in place for this.  It will switch 
              back to using the original FormGenerator once the correct schema is
              restored.
          */}
            {forms &&
            forms.linkLoyaltyAccount &&
            forms.linkLoyaltyAccount[0] &&
            isWebOrderingConfig(forms.linkLoyaltyAccount[0]) ? (
              <div className="col-sm-12">
                <FormGenerator
                  definition={forms.linkLoyaltyAccount as ConfigField[]}
                />
              </div>
            ) : (
              <div className="col-sm-12">
                <WLASchemaFormGenerator
                  definition={forms.linkLoyaltyAccount as WLAConfigField[]}
                />
              </div>
            )}
            <FormGroup>
              <Button
                bsStyle="primary"
                className="btn-pe-submit"
                type="submit"
                disabled={!methods.formState.isValid || isVerifying}
                onClick={methods.handleSubmit(onSubmit)}
              >
                Verify
              </Button>
            </FormGroup>
          </form>
        </FormProvider>
      </Panel>
    </Grid>
  );
};
