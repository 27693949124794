import { useHistory } from 'react-router';
import GoogleMapReact from 'google-map-react';
import { useDispatch } from 'react-redux';
import { addNotification } from 'core/actions';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { MapMarker, getCenterAndZoom } from 'venue/components/MapComponents';
import { CombinedVenue } from 'types/models';
import { useVenues } from 'contexts/VenueContext';
import { useState } from 'react';

interface VenueMapProps {
  venuesAround: CombinedVenue[];
  venuesForMap: CombinedVenue[];
}

export const VenueMap: React.FC<VenueMapProps> = ({
  venuesAround,
  venuesForMap,
}) => {
  const { googleMapsApiKey } = useConfig();

  const { isLoadingVenues } = useVenues();

  const { venueTitle } = usePhrases();

  const history = useHistory();
  const dispatch = useDispatch();

  const [openPopoverVenueId, setOpenPopoverVenueId] = useState(-1);

  if (!googleMapsApiKey) {
    dispatch(
      addNotification('There is no Google Maps API key configured.', 'danger'),
    );
    history.push('/venues/');
  }

  if (venuesAround.length > 0) {
    const { center, zoom } = getCenterAndZoom(venuesAround);

    return (
      <div className="map-container">
        <GoogleMapReact
          bootstrapURLKeys={{ key: googleMapsApiKey }}
          center={center}
          zoom={zoom}
          resetBoundsOnResize
          onChildClick={(key, childProps) =>
            setOpenPopoverVenueId(childProps.venue.id)
          }
          onChange={() => setOpenPopoverVenueId(-1)}
        >
          {venuesForMap.map((venue) => (
            <MapMarker
              key={venue.id}
              lat={venue.coordinates.latitude}
              lng={venue.coordinates.longitude}
              venue={venue}
              openPopoverId={openPopoverVenueId}
            />
          ))}
        </GoogleMapReact>
      </div>
    );
  }

  if (!isLoadingVenues) {
    return <p className="text-center loading-wrapper">No {venueTitle}s</p>;
  }

  return null;
};
