import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn, selectLoginMethod } from 'user/selectors';
import { LoginMethod } from 'types/models/User';
import { useConfig } from 'contexts/ConfigContext';

export const AuthenticatedOrGuestOrderingRoute: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const loginMethod = useSelector(selectLoginMethod);
  const { accountDeletionOnly, disableUserSignIn } = useConfig();

  const ComponentElement = Component as React.ElementType;

  return (
    <Route
      {...rest}
      render={(props): React.ReactNode => {
        if (
          !accountDeletionOnly &&
          (isLoggedIn || loginMethod === LoginMethod.Anonymous)
        ) {
          return <ComponentElement {...props} />;
        }

        if (accountDeletionOnly) {
          return (
            <Redirect
              to={{
                pathname: '/user/delete-account',
                state: { from: props.location },
              }}
            />
          );
        }

        if (disableUserSignIn) {
          return (
            <Redirect
              to={{
                pathname: '/venues',
              }}
            />
          );
        }

        return (
          <Redirect
            to={{
              pathname: '/user/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
