import { useSelector, useDispatch } from 'react-redux';
import { selectIsServiceChoiceModalOpen } from 'venue/selectors';
import { hideServiceChoiceModal, markAsShown } from 'venue/actions';
import { Modal } from 'components/Modal';
import { SelectedServiceChoiceSwitch } from './SelectedServiceChoiceSwitch';
import { ServiceModalContext } from 'contexts/ServiceModalContext';
import { useConfig } from 'contexts/ConfigContext';
import { useSalesAreas, useVenues } from 'contexts/VenueContext';

interface SelectedServiceModalProps {
  resetVenueOnClose?: boolean;
}

export const SelectedServiceChoiceModal: React.FC<
  SelectedServiceModalProps
> = ({ resetVenueOnClose = false }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsServiceChoiceModalOpen);

  const { homepage, disableSearch } = useConfig();

  const { setSelectedVenue } = useVenues();
  const { setSelectedSalesArea } = useSalesAreas();

  const handleClose = (redirectIfRequired?: boolean) => {
    dispatch(hideServiceChoiceModal());
    setTimeout(() => {
      setSelectedSalesArea(undefined);

      if (resetVenueOnClose) {
        setSelectedVenue(undefined);
      }

      dispatch(markAsShown());
      if (disableSearch && redirectIfRequired) {
        window.open(homepage, '_self');
      }
    }, 300);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => handleClose(true)}
      datatestid="service-choice-modal"
      className="service-choice-modal"
    >
      <ServiceModalContext.Provider value={{ onClose: handleClose }}>
        <SelectedServiceChoiceSwitch />
      </ServiceModalContext.Provider>
    </Modal>
  );
};
