import { usePhrases } from 'contexts/ConfigContext';
import { RewardItem } from 'types/models/Loyalty';
import { getLoyaltyAvailabiltyPhrase } from 'utils';
import { LoyaltyRewardItem } from './LoyaltyRewardItem';
import { useVenues } from 'contexts/VenueContext';

interface LoyaltyRewardListProps {
  handleClose: () => void;
  rewards: RewardItem[];
}

export const LoyaltyRewardList: React.FC<LoyaltyRewardListProps> = ({
  handleClose,
  rewards,
}) => {
  const {
    loyalty: { rewardPhrase, siteAvailabilityPhrase },
  } = usePhrases();
  const { selectedVenue } = useVenues();

  const subtitle = selectedVenue
    ? getLoyaltyAvailabiltyPhrase(
        siteAvailabilityPhrase,
        rewardPhrase,
        selectedVenue.name,
        selectedVenue.address.town,
      )
    : '';

  const isAvailable = (reward: RewardItem): boolean => {
    return (
      reward.cost === 0 &&
      (reward.percent === 0 || reward.percent === '') &&
      (reward.price === 0 || reward.price === '') &&
      (reward.balance > 0 || reward.isUnlimited)
    );
  };

  const avaialbleRewards = rewards.filter((r) => isAvailable(r));
  const unavaialbleRewards = rewards.filter((r) => !isAvailable(r));

  return (
    <>
      <h2>Available {rewardPhrase}s</h2>
      <div>{subtitle}</div>
      <div className="loyalty-list-container">
        {avaialbleRewards.map((reward) => (
          <LoyaltyRewardItem
            reward={reward}
            isAvailable={true}
            key={reward.id}
            handleClose={handleClose}
          />
        ))}
        {unavaialbleRewards.map((reward) => (
          <LoyaltyRewardItem
            reward={reward}
            isAvailable={false}
            key={reward.id}
            handleClose={handleClose}
          />
        ))}
      </div>
    </>
  );
};
