import { useLocation } from 'react-router';
import { useConfig } from 'contexts/ConfigContext';
import { ServiceChanger } from 'core/components';

export const ServiceHeader: React.FC = () => {
  const { pickupTimeUnderHeader } = useConfig();
  const location = useLocation();

  const url = location.pathname.includes('menu');

  if (!pickupTimeUnderHeader || !url) {
    return null;
  }

  return <ServiceChanger />;
};
