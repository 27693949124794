import { Map } from 'immutable';

export const initialState = Map({
  isLoggedIn: false,
  isFetchingChangePassword: false,
  isFetchingEdit: false,
  isFetchingLogIn: false,
  isFetchingRegister: false,
  isFetchingResetPassword: false,
  isRegisteringFromCheckout: false,
  modalFlag: false,
  user: Map(),
  token: null,
  defaultBirthday: Map({ day: '1', month: '1', year: '1990' }),

  // OTP
  otpModalFlag: false,
  otpMethod: null,
  otpEmail: null,
  otpAccount: null,
  otpExpires: null,
  otpChallengeId: null,
});

export const getter = (): string => 'function';
