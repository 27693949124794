import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Loader } from 'components/Loader';
import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { AdditionalFieldsForm } from 'checkout/components/Payment/AdditionalFields';
import { useAdditionalInformation } from 'contexts/AdditionalInformationContext';
import { normaliseFormData } from 'checkout/utils';
import { RestartOrderDialog } from 'checkout/components/DeliveryToLocation';
import {
  selectTipAmount,
  selectAdditionalInfoErrorDialog,
} from 'checkout/selectors';
import { useCheckout, useCheckoutDetails } from 'contexts/CheckoutContext';

interface FormProps {
  field_1?: string;
  field_2?: string;
}

export const PaymentDisabledPaymentPanel: React.FC = () => {
  const methods = useForm<FormProps>({ mode: 'all' });

  const { placeOrder, isSubmittingPayment } = useCheckout();
  const { basketTotal } = useCheckoutDetails();

  const tipAmount = useSelector(selectTipAmount);
  const additionalInfoError999 = useSelector(selectAdditionalInfoErrorDialog);

  const {
    savedLocationInformation,
    locationInformationFields,
    userInformationFields,
  } = useAdditionalInformation();

  const totalAmount = Number(basketTotal) + Number(tipAmount);

  const handleComplete: SubmitHandler<FormProps> = (formData) => {
    const normalisedFormData = normaliseFormData(
      locationInformationFields,
      userInformationFields,
      { ...savedLocationInformation, ...formData },
    );

    placeOrder(normalisedFormData);
  };

  return (
    <>
      {isSubmittingPayment ? (
        <Loader text="Placing Order" />
      ) : (
        <>
          <h3 className="title">Place Order</h3>
          <FormProvider {...methods}>
            <div className="body">
              <form>
                <AdditionalFieldsForm />
                <Button
                  className="btn btn-primary center-block checkout-fw-button"
                  data-testid="button-place-order-zero-basket"
                  bsStyle="primary"
                  onClick={methods.handleSubmit(handleComplete)}
                >
                  Confirm and Place Order (
                  <FormattedCurrency value={totalAmount} />)
                </Button>
              </form>
            </div>
          </FormProvider>
        </>
      )}
      <RestartOrderDialog
        isOpen={!!additionalInfoError999}
        title="Payment Error"
        description={additionalInfoError999}
      />
    </>
  );
};
