import { useSelector } from 'react-redux';
import { LoginMethod } from 'types/models/User';
import { selectLoginMethod } from 'user/selectors';
import { Email } from 'core/components/RHF/FieldTemplates';
import { usePhrases } from 'contexts/ConfigContext';

export const GuestCheckoutForm: React.FC = () => {
  const loginMethod = useSelector(selectLoginMethod);

  const {
    guestCheckout: { guestCheckoutPhrase },
  } = usePhrases();

  const emailField = {
    label: 'Email Address',
    max: 64,
    min: 5,
    name: 'email',
    placeholder: 'Email Address',
    required: true,
    type: 'email',
  };

  return (
    <>
      {loginMethod === LoginMethod.Anonymous ? (
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <Email field={emailField} />
            <p className="additional-info-supporting-text text-muted">
              {guestCheckoutPhrase}
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
};
