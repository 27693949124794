import * as actionTypes from './actionTypes';
import core from 'core';

export const requestOrders = (userEmailAddress) => ({
  type: actionTypes.REQUEST_ORDERS,
  method: 'getMyOrders',
  userEmailAddress,
});

export const receiveOrders = (orders) => ({
  type: actionTypes.RECEIVE_ORDERS,
  orders,
});

export const setIsFetchingOrders = (isFetching) => ({
  type: actionTypes.IS_FETCHING_ORDERS,
  isFetching,
});

export const fetchOrders = () => (dispatch, getState) => {
  const email = getState().user.getIn(['user', 'email']);

  dispatch(setIsFetchingOrders(true));

  dispatch(requestOrders(email))
    .then((response) => {
      if (response.data.code && response.data.code !== 200) {
        dispatch(core.actions.addNotification(response.data.detail, 'danger'));
      } else {
        dispatch(receiveOrders(response.data.myOrders));
        dispatch(setIsFetchingOrders(false));
      }
    })
    .catch(() => {
      dispatch(setIsFetchingOrders(false));
    });
};
