import { Nav } from 'react-bootstrap';
import { NavItemLink } from 'core/components';
import { useConfig } from 'contexts/ConfigContext';
import { useSelector } from 'react-redux';
import { selectOtpEnabled } from 'user/selectors';

export const Sidebar: React.FC = () => {
  const { accountDeletionOnly, myDetailsLink, changePasswordLink } =
    useConfig();

  const otpEnabled = useSelector(selectOtpEnabled);

  if (accountDeletionOnly) {
    return null;
  }

  return (
    <div className="col-sm-3">
      <div className="panel panel-default">
        <Nav bsStyle="pills" stacked activeKey={1} className="sidebar">
          <Nav>
            <NavItemLink to="/user/order" datatestid="order-history-link">
              Order history
            </NavItemLink>
            {!myDetailsLink ? (
              <NavItemLink to="/user/edit" datatestid="my-details-link">
                My details
              </NavItemLink>
            ) : (
              <li role="presentation">
                <a
                  href={myDetailsLink}
                  target="blank"
                  data-testid="my-details-link"
                >
                  My details
                </a>
              </li>
            )}
            {!changePasswordLink ? (
              <NavItemLink
                to="/user/password-change"
                datatestid="change-password-link"
              >
                Change password
              </NavItemLink>
            ) : (
              <li role="presentation">
                <a
                  href={changePasswordLink}
                  target="blank"
                  data-testid="change-password-link"
                >
                  Change password
                </a>
              </li>
            )}
            {otpEnabled ? (
              <NavItemLink
                to="/user/delete-account"
                datatestid="delete-account-link"
              >
                Delete My Account
              </NavItemLink>
            ) : null}
          </Nav>
        </Nav>
      </div>
    </div>
  );
};
