import { Grid } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import { Checkout } from 'checkout/components/Checkout';
import { Complete } from 'complete/Complete';
import { NotFound } from 'routers';
import { AuthenticatedOrGuestOrderingRoute } from 'routers/PrivateRoute';
import { CheckoutPmb } from 'PayMyBill/checkout/CheckoutPmb';
import { CompletePmb } from 'PayMyBill/complete/CompletePmb';

export const CheckoutRoutes: React.FC = () => {
  return (
    <Grid>
      <Switch>
        <AuthenticatedOrGuestOrderingRoute
          exact
          path="/checkout/pay-my-bill"
          component={CheckoutPmb}
        />
        <AuthenticatedOrGuestOrderingRoute
          path="/checkout"
          component={Checkout}
        />
        <AuthenticatedOrGuestOrderingRoute
          exact
          path="/complete/pay-my-bill/:accountId/:outstandingBalance/:accountNumber"
          component={CompletePmb}
        />
        <AuthenticatedOrGuestOrderingRoute
          path="/complete"
          component={Complete}
        />
        <Route component={NotFound} />
      </Switch>
    </Grid>
  );
};
