import * as actionTypes from './actionTypes';

export const showServiceChoiceModal = () => ({
  type: actionTypes.SHOW_SERVICE_CHOICE_MODAL,
});

export const hideServiceChoiceModal = () => ({
  type: actionTypes.HIDE_SERVICE_CHOICE_MODAL,
});

export const markAsShown = () => ({
  type: actionTypes.MARK_AS_SHOWN,
});
