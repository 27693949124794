import { Button } from 'react-bootstrap';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { ModalFooter, ModalBody, ModalHeader } from 'components/Modal';
import { ContextualLoader } from 'components/ContextualLoader';
import { Notification } from 'core/components/Notification';
import { useGiftCard } from 'contexts/GiftCardContext';
import { getGiftCardPhrase } from 'utils';
import { Field } from 'types/models/Forms';
import { CardNumber, Pin } from 'core/components/RHF/FieldTemplates';

interface VerifyGiftCardFormProps {
  closeModal: () => void;
}

interface FormProps {
  giftCardNumber: string;
  giftCardPin: string;
}

export const VerifyGiftCardModalBody: React.FC<VerifyGiftCardFormProps> = ({
  closeModal,
}) => {
  const {
    giftCard: { giftCardPhrase, callToActionTitle, redeemSupportingText },
  } = usePhrases();

  const pinField: Field = {
    label: 'PIN',
    max: 64,
    min: 1,
    name: 'giftCardPin',
    placeholder: `PIN`,
    required: true,
    type: 'number',
  };

  const cardNumberField: Field = {
    label: 'Card Number',
    max: 64,
    min: 1,
    name: 'giftCardNumber',
    placeholder: `${giftCardPhrase} Number`,
    required: true,
    type: 'number',
  };

  const { giftCardImage } = useConfig();
  const methods = useForm<FormProps>({
    mode: 'all',
  });

  const { verifyGiftCard, isVerifying } = useGiftCard();

  const handleVerify: SubmitHandler<FormProps> = async ({
    giftCardNumber,
    giftCardPin,
  }) => {
    verifyGiftCard(giftCardNumber, giftCardPin);
  };

  return (
    <>
      <ModalHeader closeButton onRequestClose={closeModal}>
        <h2 className="title">
          {getGiftCardPhrase(callToActionTitle, giftCardPhrase)}
        </h2>
      </ModalHeader>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(handleVerify)}
          className="gc-verify-form"
          data-testid="gift-card-verify-form"
        >
          <ModalBody>
            {giftCardImage ? (
              <div className="gc-image-container">
                <img
                  src={giftCardImage}
                  className="gc-image"
                  alt={giftCardPhrase}
                  role="presentation"
                />
              </div>
            ) : null}
            <div>
              <CardNumber field={cardNumberField} isNumeric={true} />
              <Pin field={pinField} />
            </div>
          </ModalBody>
          <ModalFooter>
            {redeemSupportingText ? (
              <p className="gc-support-text">{redeemSupportingText}</p>
            ) : null}
            <Button
              className="btn btn-primary"
              bsStyle="primary"
              data-testid="button-add-gift-card"
              type="submit"
              onClick={methods.handleSubmit(handleVerify)}
            >
              {getGiftCardPhrase(callToActionTitle, giftCardPhrase)}
            </Button>
            <Button
              className="hide-modal-button hide-on-responsive btn btn-default"
              onClick={closeModal}
            >
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
      <ContextualLoader isVisible={isVerifying} />
      <Notification />
    </>
  );
};
