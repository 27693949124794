import { useBasket } from 'contexts/BasketContext';
import { Button } from 'react-bootstrap/lib';
import { MdDelete } from 'react-icons/md';

interface BasketLineDeleteButtonProps {
  idx: number;
}

export const BasketLineDeleteButton: React.FC<BasketLineDeleteButtonProps> = ({
  idx,
}) => {
  const { removeBasketItem } = useBasket();
  const onRemoveLineClick = (idx: number) => {
    removeBasketItem(idx);
  };

  return (
    <Button
      title="Remove"
      className="button-remove-line btn-with-icon"
      bsSize="xsmall"
      bsStyle="danger"
      onClick={() => onRemoveLineClick(idx)}
      data-testid="remove-item-button"
    >
      <MdDelete />
    </Button>
  );
};
