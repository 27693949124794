import { useConfig, useSetConfig } from 'contexts/ConfigContext';

import { ControlLabel } from 'react-bootstrap';
import { ToggleSwitch } from 'core/components/ToggleSwitch';

export const TimeslotTimesToggle: React.FC = () => {
  const { timeslots24hFormat } = useConfig();
  const setConfig = useSetConfig();

  const handleClick = (toggledValue: boolean) => {
    setConfig('timeslots24hFormat', toggledValue);
  };

  return (
    <div>
      <ControlLabel className="ff-label">Timeslot times:</ControlLabel>
      <ToggleSwitch
        id="timeslot-times-format"
        onChange={handleClick}
        checked={timeslots24hFormat}
        disabled={false}
        name="timeslot-times-format"
        onLabel="24h"
        offLabel="12h"
        small={false}
      />
    </div>
  );
};
