import cs from 'classnames';
import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { Alert } from 'react-bootstrap';
import {
  isNotOnlyWhitespace,
  higherThanValue,
  minLength,
  minValue,
  zeroValue,
  isParsableFloatingPoint,
} from 'core/components/RHF/validation';
import CurrencyInput from 'react-currency-input-field';
import { Field } from 'types/models/Forms';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { FormattedBill } from 'types/models/PayMyBill';
import { IntlShape, useIntl } from 'react-intl';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { formatCurrencyInput, getCurrencySymbol } from 'utils';
import _ from 'lodash';
import { useEffect } from 'react';
import { usePayMyBill } from 'contexts/PayMyBillContext/PayMyBillContext';

interface BillCutomAmountProps {
  bill: FormattedBill;
}

export const BillCustomAmount: React.FC<BillCutomAmountProps> = ({ bill }) => {
  const { currency, currencySymbolsDisabled, supportsTip } = useConfig();
  const { payMyBillBalanceText } = usePhrases();
  const { braintreeLoading } = usePayMyBill();

  const {
    formState: { errors },
    register,
    watch,
    unregister,
  } = useFormContext();

  const customAmountField: Field = {
    label: 'Amount to pay',
    min: 0.01,
    name: 'customAmount',
    placeholder: 'Amount to pay',
    required: true,
    type: 'currency',
  };

  const { min, required, label, placeholder, name } = customAmountField;
  const labelToUse = label;
  const hasError = errors[name] !== undefined;
  const fieldError = errors[name];
  const formGroupClasses = cs('form-group', hasError && 'has-error');
  const intl: IntlShape = useIntl();
  const defaultCurrencyInput: number = 0.0;

  const registerOptions: RegisterOptions = {
    ...minLength(labelToUse, min),
    ...higherThanValue(
      labelToUse,
      bill.outstandingBalanceToPay,
      payMyBillBalanceText.toLocaleLowerCase(),
      !currencySymbolsDisabled ? getCurrencySymbol(currency) : '',
    ),
    ...minValue(
      labelToUse,
      min,
      !currencySymbolsDisabled ? getCurrencySymbol(currency) : undefined,
    ),
    ...zeroValue(labelToUse, min),
    setValueAs: (value) => value.replace(/[^\d.]/g, ''),
    validate: {
      number: (value) => isParsableFloatingPoint(value, labelToUse),
      ...(required && {
        whiteSpace: (value) => isNotOnlyWhitespace(value, labelToUse),
      }),
    },
  };

  const getCustomValueDisplay = (): number => {
    return _.isEmpty(watch('customAmount'))
      ? defaultCurrencyInput
      : formatCurrencyInput(watch('customAmount'), 2, defaultCurrencyInput);
  };

  useEffect(() => {
    return () => {
      unregister('customAmount');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return bill.outstandingBalanceToPay > 0 ? (
    <>
      <div className="panel panel-default">
        <h4 className="no-margin">How much would you like to pay?</h4>
        {supportsTip ? (
          <div className="exclude-tip text-muted">(Excluding tip)</div>
        ) : null}
        <hr className="primary" />
        <div className={formGroupClasses}>
          <CurrencyInput
            className="form-control"
            type="text"
            placeholder={placeholder || labelToUse}
            aria-required={required ? 'true' : 'false'}
            aria-invalid={hasError ? 'true' : 'false'}
            {...register(name, registerOptions)}
            allowNegativeValue={false}
            intlConfig={
              currencySymbolsDisabled
                ? undefined
                : { locale: intl.locale, currency: currency }
            }
            decimalsLimit={2}
            decimalScale={2}
            inputMode="decimal"
            data-testid="bill-custom-input"
          />
          {fieldError && (
            <span className="help-block" role="alert">
              {fieldError.message}
            </span>
          )}
          <hr className="primary" />
        </div>
      </div>
      <button
        className="btn btn-primary checkout-fw-button"
        data-testid="custom-confirm-amount-button"
        disabled={braintreeLoading}
      >
        {`Confirm amount (`}
        <FormattedCurrency value={getCustomValueDisplay()} />
        {`)`}
      </button>
    </>
  ) : (
    <Alert
      bsStyle="success"
      className="text-center"
      data-testid="alert-success-notification"
    >
      This account has been settled
    </Alert>
  );
};
