import { FormattedNumber } from 'react-intl';
import { Keywords } from 'menu/components/Keywords';
import { MenuGroupProduct } from 'types/models';
import { useConfig } from 'contexts/ConfigContext';
import { useMenuGroups, useMenus } from 'contexts/MenuContext';
import { handleReturnKeyPress, hexToRgb } from 'utils';
import { useSalesAreas, useServices, useVenues } from 'contexts/VenueContext';
import { MinimumAgeBadge } from 'menu/components/MinimumAge';
import cs from 'classnames';
import { useMenuModal } from 'contexts/MenuModalContext';
import { useBasket } from 'contexts/BasketContext';
import { ProductQuantityIndicator } from 'menu/components/MenuDisplayItems/ProductQuantityIndicator';
import {
  doesPortionHaveValidPrice,
  getAztecProduct,
  getProductDisplayRecord,
  getProductPortions,
  isControlledPortion,
  isProductAvailable,
  isProductOutOfStock,
  sortPortionsByPriceThenId,
} from 'menu/utils';
import { ItemUnavailable } from 'menu/components/MenuDisplayItems/ItemUnavailable';
import { CalorieAmountDisplay } from 'menu/components/Calories';
import { isTimeslotService } from 'venue/utils';
import { useCheckout } from 'contexts/CheckoutContext';

interface ProductGalleryItemProps {
  product: MenuGroupProduct;
  upsellGroupId: number | undefined;
}

export const ProductGalleryItem: React.FC<ProductGalleryItemProps> = ({
  product,
  upsellGroupId,
}) => {
  const { gradientTintColour, shouldShowGradient } = useConfig();
  const { selectedVenue } = useVenues();
  const { selectedService } = useServices();
  const { selectedSalesArea } = useSalesAreas();
  const { currency, currencySymbolsDisabled } = useConfig();
  const { menus } = useMenus();
  const { menuId, products, choiceGroups, upsellGroups } = useMenuGroups();
  const { handleSelectedMenuItem } = useMenuModal();
  const { getBasketQuantity } = useBasket();
  const selectedMenu = menus.find((m) => m.id === menuId);

  const venueCanPlaceOrder = selectedVenue?.canPlaceOrder;
  const salesAreaCanPlaceOrder = selectedSalesArea?.canPlaceOrder;

  // can we order from menu?
  const menuCanOrder: boolean = isTimeslotService(selectedService)
    ? true
    : selectedMenu?.canOrder ?? false;

  const aztecProduct = getAztecProduct(product.productId, products);
  // Check if product is available
  const isAvailable = isProductAvailable(aztecProduct, choiceGroups, products);
  // Check if product is in stock
  const isOutOfStock = isProductOutOfStock(aztecProduct);
  // Get product display record.
  const displayRecord = getProductDisplayRecord(
    aztecProduct,
    product.displayRecordId,
  );
  const minimumAge = aztecProduct.minimumCustomerAge;

  // Get product portions.
  const portions = getProductPortions(aztecProduct);

  const availablePortions = portions.filter(
    (portion) =>
      doesPortionHaveValidPrice(portion) &&
      isControlledPortion(product, portion),
  );

  // Sort by price (Lowest first).
  const sortedPortionsArray = isAvailable
    ? sortPortionsByPriceThenId(availablePortions)
    : [];

  const totalSelected = getBasketQuantity(
    product.productId,
    product.displayRecordId,
  );

  const { isFetchingBasket } = useCheckout();

  const canOrder = menuCanOrder && venueCanPlaceOrder && salesAreaCanPlaceOrder;

  const handleAdd = () => {
    if (!isFetchingBasket) {
      handleSelectedMenuItem(
        product,
        products,
        choiceGroups,
        upsellGroupId,
        upsellGroups,
        menuId,
      );
    }
  };

  const outOfStockClass = isOutOfStock ? 'out-of-stock' : '';

  const classes = cs('gallery-item', totalSelected > 0 ? 'selected' : '');

  const canSelectProduct = isAvailable && sortedPortionsArray.length > 0;
  const canBeAddedToBasket = canSelectProduct && !isOutOfStock && canOrder;

  return (
    <div
      id={`product-${product.productId}-${product.displayRecordId}`}
      className={classes}
      data-testid={`menu-group-item${menuCanOrder ? '-orderable' : ''}`}
      onClick={() => canBeAddedToBasket && handleAdd()}
      onKeyDown={(e) =>
        handleReturnKeyPress(e, () => canBeAddedToBasket && handleAdd())
      }
      title="Add"
      aria-label="Add"
      role="button"
      tabIndex={0}
    >
      <div
        className={`gallery-header-image ${
          isOutOfStock ? 'gallery-header-image-out-of-stock' : ''
        }`}
        data-testid="gallery-item"
        style={
          displayRecord.image
            ? {
                backgroundImage: `url(${displayRecord.image})`,
              }
            : {}
        }
        data-test-id="gallery-item"
        role="img"
        aria-label={displayRecord.name}
      >
        {shouldShowGradient ? (
          <div
            className="gallery-header-gradient"
            style={{
              background: `linear-gradient(0, rgba(${hexToRgb(
                gradientTintColour,
              )}, 0.6),
        rgba(0,0,0,0))`,
            }}
          ></div>
        ) : null}
        <div className="name-container">
          <div
            className={`item-name ${isOutOfStock ? 'strikethrough' : ''}`}
            data-testid="menu-group-item-name"
          >
            {displayRecord.name}
            {minimumAge ? <MinimumAgeBadge minimumAge={minimumAge} /> : null}
            {displayRecord?.keywords?.length > 0 ? (
              <Keywords list={displayRecord.keywords} />
            ) : null}
          </div>
        </div>
      </div>
      <div
        className={
          canSelectProduct
            ? 'gallery-sub-content'
            : 'gallery-sub-content unavailable'
        }
      >
        {canSelectProduct ? (
          <div>
            <div
              className={`item-price ${outOfStockClass}`}
              data-testid="menu-group-item-price"
            >
              {sortedPortionsArray.length > 1 && 'From '}
              <FormattedNumber
                style={currencySymbolsDisabled ? 'decimal' : 'currency'}
                value={sortedPortionsArray[0].price}
                currency={currency}
                minimumFractionDigits={2}
              />
            </div>
            <CalorieAmountDisplay
              displayRecord={displayRecord}
              portions={portions}
              isOutOfStock={isOutOfStock}
            />
          </div>
        ) : (
          <ItemUnavailable />
        )}
        <ProductQuantityIndicator product={product} />
      </div>
    </div>
  );
};
