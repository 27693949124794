import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SelectedServiceChoiceModal } from 'venue/components/SelectedServiceChoiceModal';
import { useConfig } from 'contexts/ConfigContext';
import { VenueRouter } from 'routers/Venue';
import {
  AutocompleteSearch,
  LocationSearchButton,
} from 'venue/components/Search';
import { LandingHeader } from 'venue/components/LandingHeader';
import { NavigationLinks } from 'venue/components/NavigationLinks';
import { useVenues } from 'contexts/VenueContext';
import { useVenueSearch } from 'hooks/useVenueSearch';

export const VenuePage: React.FC = () => {
  const { disableSearch, searchPredictionEnabled, searchText } = useConfig();

  const { fetchVenues, fetchVenueSummary } = useVenues();

  const {
    searchTerm,
    filteredVenueList,
    filteredVenueMap,
    searchVenues,
    predictions,
  } = useVenueSearch();

  const { pathname } = useLocation();

  const handleSearchVenues = (term: string, prediction = false) => {
    searchVenues(term, prediction);
  };

  useEffect(() => {
    fetchVenues();
    fetchVenueSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMapsPage = pathname.includes('/map');

  return (
    <>
      {!disableSearch ? (
        <div className="container">
          <div
            className="panel panel-default header-panel"
            data-testid="panel-header"
          >
            <LandingHeader />
            <NavigationLinks />
            {!isMapsPage ? (
              <>
                <div className="row">
                  <div className="col-sm-7">
                    <span className="h2">{searchText}</span>
                  </div>
                  <div className="col-sm-5">
                    <LocationSearchButton />
                  </div>
                </div>
                {!searchPredictionEnabled ? (
                  <input
                    id="search"
                    type="search"
                    placeholder="Search"
                    className="form-control"
                    value={searchTerm}
                    autoComplete="off"
                    onChange={(e) => handleSearchVenues(e.target.value)}
                  />
                ) : null}
                {searchPredictionEnabled ? (
                  <AutocompleteSearch
                    searchVenue={handleSearchVenues}
                    predictions={predictions}
                  />
                ) : null}
              </>
            ) : null}
          </div>
          <VenueRouter
            venues={filteredVenueList}
            venuesForMap={filteredVenueMap}
          />
          <SelectedServiceChoiceModal resetVenueOnClose={true} />
        </div>
      ) : (
        <SelectedServiceChoiceModal resetVenueOnClose={true} />
      )}
    </>
  );
};
