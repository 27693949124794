import { useSelector } from 'react-redux';
import {
  selectIsFetchingLogIn,
  selectIsFetchingGuestCheckout,
} from 'user/selectors';
import { ContextualLoader } from 'components/ContextualLoader';
import { useCheckout } from 'contexts/CheckoutContext';

export const LoginContextualLoader: React.FC = () => {
  const isFetchingLogIn = useSelector(selectIsFetchingLogIn);
  const { isFetchingBasket } = useCheckout();
  const isFetchingGuestCheckout = useSelector(selectIsFetchingGuestCheckout);

  return (
    <ContextualLoader
      isVisible={isFetchingLogIn || isFetchingBasket || isFetchingGuestCheckout}
      loaderText={isFetchingBasket ? 'Loading Checkout' : ''}
    />
  );
};
