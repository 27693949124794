import { useConfig, useSetConfig } from 'contexts/ConfigContext';

import { ControlLabel } from 'react-bootstrap';
import { ToggleSwitch } from 'core/components/ToggleSwitch';

export const MultiMenuToggle: React.FC = () => {
  const { multipleMenusEnabled } = useConfig();
  const setConfig = useSetConfig();

  const handleClick = (toggledValue: boolean) => {
    setConfig('multipleMenusEnabled', toggledValue);
  };

  return (
    <div>
      <ControlLabel className="ff-label">Multiple menus:</ControlLabel>
      <ToggleSwitch
        id="multiple-menus"
        onChange={handleClick}
        checked={multipleMenusEnabled}
        disabled={false}
        name="multiple-menus"
        onLabel="on"
        offLabel="off"
        small={false}
      />
    </div>
  );
};
