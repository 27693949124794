import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { ReactElement, useState } from 'react';
import { RedeemedRewardItem } from 'types/models/Loyalty';
import { LoyaltyRewardPanelLine } from './LoyaltyRewardPanelLine';
import { ConfirmLoyaltyRewardRemovalModal } from 'checkout/components/Modals/ConfirmLoyaltyRewardRemovalModal';
import { useCheckout } from 'contexts/CheckoutContext';

export const LoyaltyRewardPanelLines: React.FC = () => {
  const { checkBasket } = useCheckout();
  const { redeemedLoyaltyRewards, creditLoyaltyReward } = useLoyaltyRewards();

  const hasLoyaltyRewards = redeemedLoyaltyRewards.length > 0;

  const [rewardForPrompt, setRewardForPrompt] = useState<RedeemedRewardItem>();
  const [showModal, setShowModal] = useState(false);

  const cancelAction = () => {
    setShowModal(false);
  };

  const handleRemoveReward = (reward: RedeemedRewardItem) => {
    setShowModal(false);
    creditLoyaltyReward(
      reward.transactionId,
      reward.rewardId,
      reward.rewardType,
      undefined,
      checkBasket,
    );
  };

  const handleShowModal = (reward: RedeemedRewardItem) => {
    setRewardForPrompt(reward);
    setShowModal(true);
  };

  const getRewardLines = () => {
    const rewardLines: ReactElement[] = [];
    redeemedLoyaltyRewards.forEach((reward) => {
      if (reward.quantity > 1) {
        for (let i = 0; i < reward.quantity; i++) {
          rewardLines.push(
            <LoyaltyRewardPanelLine
              reward={reward}
              key={`${reward.rewardId}-${i}`}
              handleShowModal={handleShowModal}
            />,
          );
        }
      } else {
        rewardLines.push(
          <LoyaltyRewardPanelLine
            reward={reward}
            key={reward.rewardId}
            handleShowModal={handleShowModal}
          />,
        );
      }
    });

    return rewardLines;
  };

  return (
    <>
      <div className="panel-line-container">
        {hasLoyaltyRewards && getRewardLines()}
      </div>
      <ConfirmLoyaltyRewardRemovalModal
        show={showModal}
        rewardToRemove={rewardForPrompt}
        cancelAction={cancelAction}
        acceptAction={handleRemoveReward}
      />
    </>
  );
};
