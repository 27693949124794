import {
  AccountDiscount,
  AccountItem,
  AccountItemChoice,
  AccountItemInstruction,
  AccountPayment,
  AccountPromotion,
  AccountSummary,
  Bill,
  FormattedBill,
  UnfilteredAccountItem,
} from 'types/models/PayMyBill';
// TODO - this file will move into the main project
export const filterAccounts = (
  accounts: AccountSummary[],
  tableNumber: number,
): AccountSummary[] => {
  // Match the number the user searched exactly with an optional single letter at the end to catch mutliple accounts per table
  const regex = new RegExp(`^${tableNumber}[a-zA-Z]?$`);

  return accounts.filter((v) => regex.test(v.TableNumber.toString()));
};

// TODO  move into gaurd file
export const isAccountPayment = (
  line: UnfilteredAccountItem,
): line is AccountPayment => {
  return (line as AccountPayment).paymentMethodId !== undefined;
};

export const isAccountPromotion = (
  line: UnfilteredAccountItem,
): line is AccountPromotion => {
  return (line as AccountPromotion).promotionId !== undefined;
};

export const isAccountDiscount = (
  line: UnfilteredAccountItem,
): line is AccountDiscount => {
  return (line as AccountDiscount).discountId !== undefined;
};

export const isAccountItemChoice = (
  line: AccountItemChoice | AccountItemInstruction,
): line is AccountItemChoice => {
  return (line as AccountItemChoice).tariffPrice !== undefined;
};

const formatAccountItemLines = (
  lines: UnfilteredAccountItem[],
): {
  accountLines: AccountItem[];
  discountLines: AccountDiscount[];
  paymentLines: AccountPayment[];
  promotionLines: AccountPromotion[];
} => {
  const accountLines: AccountItem[] = [];
  const discountLines: AccountDiscount[] = [];
  const paymentLines: AccountPayment[] = [];
  const promotionLines: AccountPromotion[] = [];

  //   TODO - check how promotions/discounts work
  lines.forEach((line) => {
    if (isAccountPayment(line)) {
      paymentLines.push(line);
    } else if (isAccountPromotion(line)) {
      promotionLines.push(line);
    } else if (isAccountDiscount(line)) {
      discountLines.push(line);
    } else {
      accountLines.push(line);
    }
  });

  return { accountLines, discountLines, paymentLines, promotionLines };
};

export const formatBill = (account: Bill): FormattedBill => {
  const { accountLines, discountLines, paymentLines, promotionLines } =
    formatAccountItemLines(account.account);

  const formattedAccount: FormattedBill = {
    accountId: account.accountId,
    accountLines: accountLines,
    accountNumber: account.accountNumber,
    accountTotal: account.accountTotal,
    courses: account.courses.filter(
      (course, index, self) =>
        index === self.findIndex((c) => c.id === course.id),
    ),
    discountLines: discountLines,
    keywords: account.keywords,
    paymentLines: paymentLines,
    promotionLines: promotionLines,
    outstandingBalanceToPay: account.outstandingBalanceToPay,
    tableNumber: account.tableNumber,
    taxes: account?.taxes ?? [],
  };

  return formattedAccount;
};

export const getTotalBillPayments = (
  paymentLines: AccountPayment[],
): number => {
  return paymentLines.reduce((total, line) => total + line.amount, 0);
};

export const calculateItemCost = (item: AccountItem): number => {
  let value = item.tariffPrice ?? 0;

  if ('choiceLines' in item) {
    value += handleAccountChoiceLines(item);
  }

  return value;
};

const handleAccountChoiceLines = (
  item: AccountItem | AccountItemChoice,
): number => {
  if (!item.choiceLines) {
    return 0;
  }

  return item.choiceLines.reduce((acc, choice) => {
    if (isAccountItemChoice(choice)) {
      let total = choice.tariffPrice ?? 0;
      if (choice.choiceLines) {
        total += handleAccountChoiceLines(choice);
      }
      return acc + total;
    }
    return acc;
  }, 0);
};
