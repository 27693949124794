/* eslint-disable sonarjs/no-empty-collection */
import { VoucherBasketLine } from 'types/models/Vouchers';
import { VoucherPanelLine } from 'checkout/components/Voucher/components';
import { useCheckout } from 'contexts/CheckoutContext';
import { useVouchers } from 'contexts/VoucherContext';

interface VoucherPanelLinesProps {
  handleRemovalClick: (voucherLine: VoucherBasketLine) => void;
}

export const VoucherPanelLines: React.FC<VoucherPanelLinesProps> = ({
  handleRemovalClick: handleRemovalClick,
}) => {
  const { voidVoucherLoading, voidAllVouchersLoading, redeemedVouchers } = useVouchers();
  const { isFetchingBasket } = useCheckout();

  return (
    <div className="panel-line-container" data-testid="voucher-container">
      {redeemedVouchers.map((voucherLine: VoucherBasketLine) => {
        return (
          <div
            key={`voucherPanelLine::${voucherLine.voucher.voucherCode}::${voucherLine.voucher.transactionId}`}
          >
            <VoucherPanelLine
              voucherLine={voucherLine}
              handleRemovalClick={handleRemovalClick}
              isVoiding={
                voidVoucherLoading || voidAllVouchersLoading || isFetchingBasket
              }
            />
          </div>
        );
      })}
    </div>
  );
};
