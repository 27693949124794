import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faStar as fasFaStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as farFaStar } from '@fortawesome/free-regular-svg-icons';

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Routes } from './routes';

import { store } from './store';
import {
  MenuContextProvider,
  MenuGroupContextProvider,
} from 'contexts/MenuContext';
import { VenueContextProvider } from 'contexts/VenueContext';
import { BreakpointContextProvider } from 'contexts/BreakpointContext';
import { AdditionalInformationProvider } from 'contexts/AdditionalInformationContext';
import { GiftCardContextProvider } from 'contexts/GiftCardContext';
import { CookieConsentBanner } from 'legal/CookieConsentBanner';
import { DeviceIdentifierContextProvider } from 'contexts/DeviceIdentifierContext';
import { CookiesProvider } from 'react-cookie';
import { DisabledCookiesDialog } from 'legal/DisabledCookiesDialog';
import { LoyaltyRewardContextProvider } from 'contexts/LoyaltyRewardContext';
import { BasketContextProvider } from 'contexts/BasketContext';
import { MenuModalContextProvider } from 'contexts/MenuModalContext/MenuModalContext';
import { HashRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { UserContextProvider } from 'contexts/UserContext';
import { TableContextProvider } from 'contexts/TableContext/TableContext';
import { AllergenContextProvider } from 'contexts/AllergenContext';
import { TimeslotContextProvider } from 'contexts/TimeslotContext/TimeslotContext';
import { PayMyBillContextProvider } from 'contexts/PayMyBillContext/PayMyBillContext';
import { CheckoutContextProvider } from 'contexts/CheckoutContext';
import { VoucherContextProvider } from 'contexts/VoucherContext';

Sentry.init({
  dsn: 'https://a19172dc156f429296b9191c944905c4@sentry.io/190707',
  environment: window.env.REACT_APP_ENV,
});

// if we're running in dev mode, include local styles
if (process.env.NODE_ENV !== 'production') {
  require('./style.scss');
}

// preload star FA icon
library.add(fasFaStar, farFaStar);

// if we're running Cypress, elevate store to a window var
if (window.Cypress) {
  window.store = store;
}

export const StartUp: React.FC = () => {
  const persistor = persistStore(store);

  // don't use `useConfig` here for now....it doesn't work for some reason!

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter>
          <VenueContextProvider>
            <BreakpointContextProvider>
              <TimeslotContextProvider>
                <MenuContextProvider>
                  <MenuGroupContextProvider>
                    <BasketContextProvider>
                      <MenuModalContextProvider>
                        <AdditionalInformationProvider>
                          <CookiesProvider>
                            <DeviceIdentifierContextProvider>
                              <UserContextProvider>
                                <TableContextProvider>
                                  <LoyaltyRewardContextProvider>
                                    <GiftCardContextProvider>
                                      <VoucherContextProvider>
                                        <CheckoutContextProvider>
                                          <AllergenContextProvider>
                                            <PayMyBillContextProvider>
                                              <IntlProvider
                                                locale={'en'}
                                                messages={{}}
                                              >
                                                <Routes />
                                                <CookieConsentBanner />
                                                <DisabledCookiesDialog />
                                              </IntlProvider>
                                            </PayMyBillContextProvider>
                                          </AllergenContextProvider>
                                        </CheckoutContextProvider>
                                      </VoucherContextProvider>
                                    </GiftCardContextProvider>
                                  </LoyaltyRewardContextProvider>
                                </TableContextProvider>
                              </UserContextProvider>
                            </DeviceIdentifierContextProvider>
                          </CookiesProvider>
                        </AdditionalInformationProvider>
                      </MenuModalContextProvider>
                    </BasketContextProvider>
                  </MenuGroupContextProvider>
                </MenuContextProvider>
              </TimeslotContextProvider>
            </BreakpointContextProvider>
          </VenueContextProvider>
        </HashRouter>
      </PersistGate>
    </Provider>
  );
};
