import {
  AztecChoice,
  AztecPortion,
  AztecProduct,
  Choice,
  ChoiceGroup,
} from 'types/models/Menu';
import {
  getChildPortionId,
  getSelectedChoices,
  getSelectedPortion,
  portionsHaveChoices,
  productHasAndedUpsells,
  productPortionsCount,
} from 'menu/utils';

export const calculateLinePrice = (product: AztecProduct): number => {
  const numberOfPortions = productPortionsCount(product);
  const selectedPortion = getSelectedPortion(product);
  const hasChoices = portionsHaveChoices(product);
  const hasAndedUpsells = productHasAndedUpsells(product);
  let price = 0;

  // Don't show the price if no portion OR on the multiple portion selector modal
  if (
    !selectedPortion ||
    (numberOfPortions > 1 && !hasChoices && !hasAndedUpsells)
  ) {
    return price;
  }

  // set price to selected portion
  price = selectedPortion.price;

  if (portionsHaveChoices(product) || productHasAndedUpsells(product)) {
    const choicesPrice = calculateChoicesPrice(selectedPortion.choices);
    const andedUpsellPrice = calculateAndedUpsellPrice(product.andedUpsells);
    price += choicesPrice + andedUpsellPrice;
  }
  return price;
};

export const totalBasketPrice = (lines: AztecProduct[]): number =>
  lines.reduce((acc, line) => {
    const selectedPortion = getSelectedPortion(line);
    const totalLinePrice =
      calculateLinePrice(line) * (selectedPortion.quantity ?? 0);
    return acc + totalLinePrice;
  }, 0);

export const getPriceForSupplement = (
  choice: Choice,
  choiceGroup: ChoiceGroup,
  portionToUse: AztecPortion,
  totalChoiceGroupSelectionQuantity: number,
): number => {
  if (choice.productId) {
    const isDefault = choiceGroup.defaults.includes(choice.productId);
    const maxChoiceLimitReached =
      totalChoiceGroupSelectionQuantity == choiceGroup.maximum;

    // if it's a default choice and not been selected then it's free
    if (
      isDefaultChoiceInclusive(
        isDefault,
        choiceGroup,
        choice,
        maxChoiceLimitReached,
      )
    ) {
      return 0;
    }

    // if it's within the inclusive range, it's at supplement price
    // (or free if supplement price is not set)
    if (
      isWithinInclusiveRange(choiceGroup, totalChoiceGroupSelectionQuantity)
    ) {
      return portionToUse?.supplementPrice ?? 0;
    }

    // else it's full price
    return portionToUse?.price ?? 0;
  }
  return 0;
};

export const calculateAndedUpsellPrice = (
  andedUpsells: AztecProduct[] | undefined,
): number => {
  if (!andedUpsells) return 0;

  const selectedUpsells = andedUpsells.filter((upsell) =>
    getSelectedPortion(upsell),
  );
  return selectedUpsells.reduce((accUpsellPrice, upsell) => {
    const upsellPrice = getSelectedPortion(upsell).price;
    return accUpsellPrice + upsellPrice;
  }, 0);
};

export const calculateChoicesPrice = (
  choices: AztecChoice[],
  portionId?: number,
): number => {
  return choices.reduce((accChoicesPrice, choice) => {
    const choiceGroup = choice.choiceGroup;
    let inclusivesRemaining = choiceGroup?.inclusive ?? 0;
    let choiceGroupPrice = 0;
    let selectionPrice = 0;
    let selectedDefaults = 0;
    if (choiceGroup) {
      // Sort to deal with defaults first
      const selectedChoices = getSelectedChoices(choiceGroup?.choices).sort(
        (choice) => (choiceGroup.defaults.includes(choice.productId) ? 1 : -1),
      );

      selectedDefaults = getSelectedDefaultsCount(choiceGroup, selectedChoices);

      choiceGroupPrice = selectedChoices.reduce(
        (accChoiceGroupPrice, sc, index) => {
          // fix so don't need the && productToUse
          if (isProduct(sc) && sc.productToUse) {
            const selectedChoice = { ...sc };
            // First quantity of default choices is always free
            if (choiceGroup.defaults.includes(sc.productId)) {
              selectedChoice.quantity -= 1;
              inclusivesRemaining -= 1;
            }
            // This doesn't work because inclusivesRemaining can't be updated
            // applyFirstDefaultChoice(
            //   choiceGroup,
            //   selectedChoice,
            //   inclusivesRemaining,
            // );

            const productToUse = sc.productToUse;
            const portionIdToUse: number = getChildPortionId(
              choiceGroup.portionRatios,
              selectedChoice.productId,
              portionId ?? choice.portionId,
            );

            const portion = productToUse.portions[portionIdToUse];

            if (!portion) return accChoiceGroupPrice;

            // number of quantity to handle as inclusive
            const selectionInclusiveQuantity = getSelectionInclusiveQuantity(
              selectedChoice,
              inclusivesRemaining,
              selectedDefaults,
              index,
            );

            // number that remains, if any
            const selectionStandardQuantity =
              selectedChoice.quantity - selectionInclusiveQuantity;

            // items within inclusive range are free unless supplement price is given
            const pricePerInclusiveQuantity = portion.supplementPrice ?? 0;
            const pricePerStandardQuantity = portion.price ?? 0;

            // update number of remaining inclusive slots
            inclusivesRemaining -= selectionInclusiveQuantity;

            selectionPrice =
              pricePerInclusiveQuantity * selectionInclusiveQuantity +
              pricePerStandardQuantity * selectionStandardQuantity;

            const nestedPortion = getSelectedPortion(sc.productToUse);
            if (nestedPortion) {
              selectionPrice += calculateChoicesPrice(nestedPortion.choices);
            }
          } else if (isChoice(sc)) {
            const scTest = sc as unknown as AztecChoice;
            selectionPrice += calculateChoicesPrice([scTest], choice.portionId);
          }

          // else choice is a choiceGroup - but then nothing should be done
          // return updated accumulated price value
          return accChoiceGroupPrice + selectionPrice;
        },
        0,
      );
    }
    return accChoicesPrice + choiceGroupPrice;
  }, 0);
};

const isDefaultChoiceInclusive = (
  isDefault: boolean,
  choiceGroup: ChoiceGroup,
  choice: Choice,
  maxChoiceLimitReached: boolean,
): boolean => {
  if (!choiceGroup?.inclusive) {
    return false;
  }

  return (
    isDefault &&
    choiceGroup?.inclusive > 0 &&
    (choice.quantity < 1 || (choice.quantity === 1 && maxChoiceLimitReached))
  );
};

const isWithinInclusiveRange = (
  choiceGroup: ChoiceGroup,
  totalChoiceGroupSelectionQuantity: number,
): boolean => {
  if (!choiceGroup?.inclusive) {
    return false;
  }

  return (
    choiceGroup.inclusive > 0 &&
    (choiceGroup.inclusive == choiceGroup.maximum ||
      totalChoiceGroupSelectionQuantity < choiceGroup.inclusive)
  );
};

const getSelectedDefaultsCount = (
  choiceGroup: ChoiceGroup,
  selectedChoices: Choice[],
): number => {
  if (choiceGroup.defaults.length > 0) {
    return selectedChoices.reduce(
      (acc, sc): number =>
        choiceGroup.defaults.includes(sc.productId) ? acc + 1 : acc,
      0,
    );
  }
  return 0;
};

const isProduct = (selectedChoice: Choice): boolean => {
  return Boolean(selectedChoice.productId && selectedChoice.productToUse);
};

const isChoice = (selectedChoice: Choice): boolean => {
  return Boolean(selectedChoice.choiceId && selectedChoice.choiceGroup);
};

// const applyFirstDefaultChoice = (
//   choiceGroup: ChoiceGroup,
//   selectedChoice: Choice,
//   inclusivesRemaining: number,
// ) => {
//   if (choiceGroup.defaults.includes(selectedChoice.productId)) {
//     selectedChoice.quantity -= 1;
//     inclusivesRemaining -= 1;
//   }
// };

const getSelectionInclusiveQuantity = (
  selectedChoice: Choice,
  inclusivesRemaining: number,
  selectedDefaults: number,
  index: number,
) => {
  // number of quantity to handle as inclusive
  let selectionInclusiveQuantity = Math.min(
    selectedChoice.quantity,
    inclusivesRemaining,
  );

  // Reduce the inclusive quantity to account for default selections that haven't been counted yet
  if (
    selectedDefaults > index + 1 &&
    selectionInclusiveQuantity >= inclusivesRemaining
  ) {
    selectionInclusiveQuantity -= selectedDefaults - (index + 1);
  }
  return selectionInclusiveQuantity;
};
