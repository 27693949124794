/* eslint-disable jsx-a11y/click-events-have-key-events */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { useConfig } from 'contexts/ConfigContext';
import { Button } from 'react-bootstrap';
import { handleReturnKeyPress } from 'utils/onKeyEventHelper';

interface MenuBackButtonProps {
  onClick: () => void;
}

export const MenuBackButton: React.FC<MenuBackButtonProps> = ({ onClick }) => {
  const { multipleMenusEnabled } = useConfig();

  if (!multipleMenusEnabled) {
    return null;
  }

  return (
    <Button
      bsStyle="link"
      className="back-button"
      tabIndex={0}
      onClick={() => onClick()}
      onKeyDown={(e) =>
        handleReturnKeyPress(e, () => onClick)
      }
    >
      <FontAwesomeIcon icon={faLongArrowAltLeft} /> Back to Menus
    </Button>
  );
};
