import { AztecProduct } from 'types/models';
import { Field } from 'types/models/Forms';
import { Text } from 'core/components/RHF/FieldTemplates';
import { isAlphanumericWithSpaces } from 'core/components/RHF/validation';

interface SpecialRequestFieldProps {
  aztecProduct: AztecProduct;
}

export const SpecialRequestField: React.FC<SpecialRequestFieldProps> = ({
  aztecProduct,
}) => {
  const productName =
    aztecProduct?.displayRecords[aztecProduct.displayRecordId].name ||
    aztecProduct?.displayName;

  const specialRequestInput: Field = {
    label: `Tell us how you would like your ${productName}`,
    max: 50,
    min: 0,
    name: 'specialRequest',
    placeholder: `Any other requirements?`,
    required: false,
    type: 'text',
    content: aztecProduct.specialRequest ?? '',
  };

  return (
    <div
      className="special-requirements"
      data-testid="field-special-requirements"
    >
      <h2 className="special-requirements-title">Special Requests</h2>
      <Text field={specialRequestInput} additionalRules={{ isAlphanumericWithSpaces }} />
    </div>
  );
};
