import { useServices } from 'contexts/VenueContext';
import { Button } from 'react-bootstrap';
import { FaChevronCircleRight } from 'react-icons/fa';
import { CombinedVenue } from 'types/models';
import { getServiceLabel, isSalesAreaSupportingService } from 'venue/utils';

interface ServiceButtonProps {
  venue: CombinedVenue;
  serviceId: number;
  handleClick: (venue: CombinedVenue, serviceId: number) => void;
}

export const ServiceButton: React.FC<ServiceButtonProps> = ({
  venue,
  serviceId,
  handleClick,
}) => {
  const { services } = useServices();
  const isServiceSupported = isSalesAreaSupportingService(venue, serviceId);
  const serviceLabel = getServiceLabel(services, serviceId);

  return isServiceSupported ? (
    <Button
      bsStyle="primary"
      className="btn-block service-button"
      data-testid={`venue-panel-${serviceId}-button`}
      onClick={() => handleClick(venue, serviceId)}
      disabled={!isServiceSupported}
    >
      {serviceLabel}
      <FaChevronCircleRight
        className="service-button-proceed-icon"
        data-testid="hyperlink-button"
      />
    </Button>
  ) : null;
};
