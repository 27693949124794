import { Bounds, fitBounds } from 'google-map-react';
import { maxBy, minBy } from 'lodash';
import { CombinedVenue } from 'types/models';

export const getCenterAndZoom = (
  venues: CombinedVenue[],
): {
  center: { lat: number; lng: number };
  zoom: number;
  newBounds: Bounds;
} => {
  let bounds = {
    ne: {
      lat:
        maxBy(venues, (venue) => venue.coordinates.longitude)?.coordinates
          .latitude ?? 0,
      lng:
        maxBy(venues, (venue) => venue.coordinates.longitude)?.coordinates
          .longitude ?? 0,
    },
    sw: {
      lat:
        minBy(venues, (venue) => venue.coordinates.latitude)?.coordinates
          .latitude ?? 0,
      lng:
        minBy(venues, (venue) => venue.coordinates.longitude)?.coordinates
          .longitude ?? 0,
    },
  };

  if (venues.length === 1) {
    bounds = {
      ne: {
        lat: venues[0].coordinates.latitude + 0.003,
        lng: venues[0].coordinates.longitude + 0.003,
      },
      sw: {
        lat: venues[0].coordinates.latitude - 0.003,
        lng: venues[0].coordinates.longitude - 0.003,
      },
    };
  }

  let width;
  let height;

  // hardcoded window widths!
  // (╯°□°）╯︵ ┻━┻
  if (window.innerWidth >= 1200) {
    width = 1000;
    height = 500;
  } else if (window.innerWidth >= 992) {
    width = 800;
    height = 400;
  } else if (window.innerWidth >= 768) {
    width = 600;
    height = 300;
  } else {
    width = 400;
    height = 250;
  }

  return fitBounds(bounds, { width, height });
};
