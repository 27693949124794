import { VoucherLine } from 'basket/components/Line';
import { VoucherBasketLine } from 'types/models/Vouchers';
import { useVouchers } from 'contexts/VoucherContext';

export const AdditionalLines: React.FC = () => {
  const { redeemedVouchers } = useVouchers();

  const hasVoucherLines = redeemedVouchers.length > 0;

  if (!hasVoucherLines) {
    return null;
  }

  return (
    <>
      {hasVoucherLines
        ? redeemedVouchers.map((line: VoucherBasketLine) => (
            <div className="list-group-item" key={`voucher-line-${line.id}`}>
              <VoucherLine line={line} />
            </div>
          ))
        : null}
    </>
  );
};
