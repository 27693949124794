import { RootStateOrAny } from 'react-redux';

export const selectTipAmount = (state: RootStateOrAny): number =>
  Number(state.checkout.get('tipAmount'));

export const selectAdditionalInfoErrorDialog = (
  state: RootStateOrAny,
): string => state.checkout.get('additionalInfoErrorDialog');

export const selectSelectedTipPreset = (state: RootStateOrAny): number =>
  state.checkout.get('selectedTipPreset');
