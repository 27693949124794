import { useConfig } from 'contexts/ConfigContext';
import { useVenues } from 'contexts/VenueContext';
import { CombinedVenue } from 'types/models';
import { BannerList } from 'venue/components/SingleVenue/BannerList';
import { getVenueImage } from 'venue/utils';

interface BannerContainerProps {
  venue: CombinedVenue;
  handleClick: (venue: CombinedVenue, serviceId: number) => void;
}

export const BannerContainer: React.FC<BannerContainerProps> = ({
  venue,
  handleClick,
}) => {
  const { showVenueHeaderBanner } = useConfig();
  const { venueHomeData } = useVenues();
  const headerImage = getVenueImage(venueHomeData?.header?.images);
  return (
    <div className="venue-banner-list">
      {showVenueHeaderBanner && headerImage ? (
        <img
          src={headerImage.url}
          alt={headerImage.altText}
          className="venue-header-banner"
          data-testid="banner-venue-header"
        />
      ) : null}
      <BannerList venue={venue} handleClick={handleClick} />
    </div>
  );
};
