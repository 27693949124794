import { ServiceType } from 'types/models';
import {
  DisplaySelectedTable,
  DisplaySelectedTimeslot,
} from '../ServiceInformation';
import { useServices } from 'contexts/VenueContext';

export const SelectedServiceInformation: React.FC = () => {
  const { selectedService } = useServices();
  switch (selectedService) {
    case ServiceType.ClickAndCollect:
    case ServiceType.DeliveryToLocation:
      return <DisplaySelectedTimeslot />;

    case ServiceType.OrderAndPay:
      return <DisplaySelectedTable />;

    default:
      return null;
  }
};
