import { MdAddCircle, MdRemoveCircle } from 'react-icons/md';
import { useConfig } from 'contexts/ConfigContext';
import cs from 'classnames';
import { useCheckout } from 'contexts/CheckoutContext';

interface QuantityFieldProps {
  callbackArgs?: number[];
  min?: number;
  max?: number;
  onChange: (...args: number[]) => void;
  quantity: number;
}

export const QuantityField: React.FC<QuantityFieldProps> = ({
  callbackArgs,
  min,
  max,
  onChange,
  quantity,
}) => {
  const { customiserButtonDiameter } = useConfig();
  const { isFetchingBasket } = useCheckout();

  // Treat undefined min/max as infinite
  const canDecrement = () => {
    if (min === undefined) {
      return true;
    }

    return quantity > min;
  };

  const canIncrement = () => {
    if (max === undefined) {
      return true;
    }
    return quantity < max;
  };

  const setQuantity = (quantity: number) => {
    if (!isFetchingBasket) {
      const args = callbackArgs ?? [];

      if (quantity >= (min ?? 0) && (max === undefined || quantity <= max)) {
        onChange?.apply(undefined, [...args, quantity]);
      }
    }
  };

  const decrementClasses = cs(
    'inline-block',
    'decrement',
    !canDecrement() ? 'disabled' : undefined,
  );

  const incrementClasses = cs(
    'inline-block',
    'increment',
    !canIncrement() ? 'disabled' : undefined,
  );

  return (
    <div className="quantity-selector">
      <div
        onClick={() => {
          if (canDecrement()) {
            setQuantity(quantity - 1);
          }
        }}
        role="presentation"
        className={decrementClasses}
        data-testid="decrease-item-quantity-button"
      >
        <MdRemoveCircle size={customiserButtonDiameter} />
      </div>
      <div className="quantity">
        <span>{quantity}</span>
      </div>
      <div
        onClick={() => {
          if (canIncrement()) {
            setQuantity(quantity + 1);
          }
        }}
        role="presentation"
        className={incrementClasses}
        data-testid="increase-item-quantity-button"
      >
        <MdAddCircle size={customiserButtonDiameter} />
      </div>
    </div>
  );
};
