import { useState } from 'react';
import { Panel } from 'react-bootstrap';
import { ItemButtons, ItemOpeningTimes } from './components';
import { useConfig } from 'contexts/ConfigContext';
import { useEffectSkipFirst } from 'hooks/useEffectSkipFirst';
import { FormattedDistance } from 'common/components/FormattedDistance';
import { ServiceType, CombinedVenue } from 'types/models';
import cs from 'classnames';
import { VenueAddress } from 'venue/components/SingleVenue/VenueInfo';

interface VenueListItemProps {
  onRowExpand: () => void;
  venue: CombinedVenue;
  handleButtonClick: (service: ServiceType, venue: CombinedVenue) => void;
}

export const VenueListItem: React.FC<VenueListItemProps> = ({
  onRowExpand,
  venue,
  handleButtonClick,
}) => {
  const { venueDetailsExpandedByDefault } = useConfig();

  const [venueDetailsHidden, setVenueDetailsHidden] = useState(
    !venueDetailsExpandedByDefault || false,
  );

  const toggleDetails = () => {
    setVenueDetailsHidden(!venueDetailsHidden);
  };

  useEffectSkipFirst(() => {
    onRowExpand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venueDetailsHidden]);

  const venueRowClasses = cs(
    'col-xs-12',
    'col-sm-8',
    venueDetailsHidden ? 'hidden-xs' : undefined,
  );

  return (
    <Panel
      className={`venue-panel${venueDetailsHidden ? ' compact' : ''}`}
      data-testid={`venue-panel-${venue.id}`}
    >
      <div className="row">
        <div
          className="col-xs-10 col-sm-12"
          onClick={() => toggleDetails()}
          onKeyDown={() => toggleDetails()}
          role="button"
          tabIndex={0}
        >
          <div className="inline-headers">
            <h2 className="no-margin-top" data-testid="name-venue-header">
              {venue.name}
              <FormattedDistance
                distance={venue.distance}
                showIcon={false}
                additionalClassName={'text-muted h3'}
                showBrackets={true}
              />
            </h2>
          </div>
        </div>
      </div>
      <div className="row venue-row">
        <div className={venueRowClasses}>
          <div className="row">
            <div className="col-sm-5 col-xs-12">
              <VenueAddress
                address={venue.address}
                separator="<br/>"
                className="text-muted"
              />
              {venue.contactDetails.telephone ? (
                <a href={`tel:${venue.contactDetails.telephone}`}>
                  {venue.contactDetails.telephone}
                </a>
              ) : null}
            </div>
            <div className="col-xs-12 col-sm-7 left">
              <div data-testid="venue-panel-timings">
                <ItemOpeningTimes venue={venue} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 col-lg-3 actions">
          <ItemButtons venue={venue} handleClick={handleButtonClick} />
        </div>
      </div>
    </Panel>
  );
};
