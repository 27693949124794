import { ModalBody } from 'components/Modal';
import { useConfig } from 'contexts/ConfigContext';
import {
  AztecPortion,
  AztecProduct,
  BreadcrumbTrack,
  Choice,
} from 'types/models';
import {
  ChoiceGroup,
  SpecialRequestField,
  NestedChoiceGroup,
} from 'menu/components/MenuModal/ModalComponents';
import { useEffect, useRef, useState } from 'react';
import { useMenuModal } from 'contexts/MenuModalContext';
import { AndedUpsellGroup } from 'menu/components/MenuModal/ModalBody';
import {
  getChildPortionId,
  getNestedChoice,
  getNestedProduct,
  getSelectedPortion,
  updateChoice,
  updateNestedChoice,
} from 'menu/utils';
import { AllergenLink } from 'menu/components/Allergens';

interface ChoiceGroupsModalProps {
  aztecProduct: AztecProduct;
  updateAztecProduct: (product: AztecProduct) => void;
  updateBreadcrumbs: (breadcrumbs: BreadcrumbTrack[]) => void;
}

export const ChoiceGroupsModal: React.FC<ChoiceGroupsModalProps> = ({
  aztecProduct,
  updateAztecProduct,
  updateBreadcrumbs,
}) => {
  const { specialRequestsDisabled } = useConfig();
  const { breadcrumbs } = useMenuModal();
  const displayRecord =
    aztecProduct.displayRecords[aztecProduct.displayRecordId];

  const selectedPortion = getSelectedPortion(aztecProduct);

  const [nestedChoicePortion, setNestedChoicePortion] = useState<
    AztecPortion | undefined
  >();

  // These 2 states concern a nested choice - ie no productId is available
  const [nestedChoice, setNestedChoice] = useState<Choice | undefined>();
  const [nestedChoicePortionId, setNestedChoicePortionId] = useState<
    number | undefined
  >();
  const andedUpsells = aztecProduct.andedUpsells;
  const showAndedUpsells =
    andedUpsells && andedUpsells.length > 0 && breadcrumbs.length === 0;
  const portionToMap = nestedChoicePortion ?? selectedPortion;

  const modalTopRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (breadcrumbs.length === 0) {
      setNestedChoicePortion(undefined);
      setNestedChoice(undefined);
      setNestedChoicePortionId(undefined);
    } else {
      const productToUse = getNestedProduct(aztecProduct, breadcrumbs);
      const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
      if (productToUse) {
        const portionIdToUse: number = getChildPortionId(
          lastBreadcrumb.portionRatios,
          productToUse.id,
          lastBreadcrumb.portionId,
        );
        const portionToUse = productToUse.portions[portionIdToUse];
        setNestedChoicePortion(portionToUse);
        setNestedChoicePortionId(portionIdToUse);
        setNestedChoice(undefined);
      } else {
        const choiceToUse = getNestedChoice(
          getSelectedPortion(aztecProduct),
          breadcrumbs,
        );
        setNestedChoice(choiceToUse);
        setNestedChoicePortionId(lastBreadcrumb.portionId);
        setNestedChoicePortion(undefined);
      }
    }

    // Scroll to the top of the modal on choice change
    scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs]);

  const handleUpdateChoice = (
    choiceIndex: number,
    choiceGroupIndex: number,
    quantity?: number,
    hasNestedChoice?: boolean,
  ) => {
    if (breadcrumbs.length === 0) {
      const updatedProduct = updateChoice(
        aztecProduct,
        choiceIndex,
        choiceGroupIndex,
        selectedPortion.id,
        quantity,
      );
      updateAztecProduct(updatedProduct);
    }

    if (breadcrumbs.length > 0 && nestedChoicePortionId) {
      const updatedProduct = updateNestedChoice(
        aztecProduct,
        choiceIndex,
        choiceGroupIndex,
        nestedChoicePortionId,
        breadcrumbs,
        quantity,
      );
      updateAztecProduct(updatedProduct);
    }

    if (hasNestedChoice && (breadcrumbs.length === 0 || nestedChoicePortion)) {
      const selectedChoice = nestedChoicePortion
        ? nestedChoicePortion.choices[choiceIndex]
        : selectedPortion.choices[choiceIndex];

      const breadcrumbTrack = {
        choiceIndex: choiceIndex,
        choiceGroupIndex: choiceGroupIndex,
        portionId: selectedChoice.portionId,
        portionRatios: selectedChoice.choiceGroup.portionRatios,
      };
      updateBreadcrumbs([...breadcrumbs, breadcrumbTrack]);
    }
  };

  const scrollToTop = () => {
    const { current } = modalTopRef;
    if (current !== null) {
      current.scrollIntoView();
    }
  };

  return (
    <ModalBody>
      <div className="display-record-description" ref={modalTopRef}>
        {displayRecord?.description ?? null}
        <AllergenLink />
      </div>
      {nestedChoice && nestedChoicePortionId ? (
        <NestedChoiceGroup
          updateChoice={handleUpdateChoice}
          nestedChoice={nestedChoice}
          nestedPortionId={nestedChoicePortionId}
        />
      ) : (
        portionToMap.choices.map((choice, idx) => {
          return (
            <ChoiceGroup
              key={`choiceGroup:${choice.choiceId}`}
              choice={choice}
              updateChoice={handleUpdateChoice}
              choiceIndex={idx}
            />
          );
        })
      )}
      {aztecProduct.upsellGroup && showAndedUpsells ? (
        <AndedUpsellGroup
          andedUpsells={andedUpsells}
          upsellGroup={aztecProduct.upsellGroup}
          aztecProduct={aztecProduct}
          updateAztecProduct={updateAztecProduct}
        />
      ) : null}
      {!specialRequestsDisabled ? (
        <SpecialRequestField aztecProduct={aztecProduct} />
      ) : null}
    </ModalBody>
  );
};
