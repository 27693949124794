import { Dialog } from 'components/Dialog';

interface EmptyBasketConfirmationModalProps {
  show: boolean;
  proceed: () => void;
  dismiss: () => void;
}

export const EmptyBasketConfirmationModal: React.FC<
  EmptyBasketConfirmationModalProps
> = ({ show, proceed, dismiss }) => {
  return (
    <Dialog
      isOpen={show}
      title="Clear Basket"
      description="Are you sure you wish to clear your basket?"
      accept={{
        action: proceed,
        text: 'Confirm',
        btnType: 'destructive',
        datatestid: 'empty-basket-confirm-button',
      }}
      reject={{
        action: dismiss,
        text: 'Cancel',
      }}
      datatestid="empty-basket-confirmation-modal"
    />
  );
};
