import { MdDirections } from 'react-icons/md';
import { CombinedVenue } from 'types/models';
import { ItemOpeningTimes } from 'venue/components/Item/components';
import {
  VenueAddress,
  VenueEmail,
  VenuePhone,
} from 'venue/components/SingleVenue/VenueInfo';
import { addressToString } from 'venue/utils';
import cs from 'classnames';

interface VenueInfoProps {
  venue: CombinedVenue;
  classNames?: string;
  isModal?: boolean;
}

export const VenueInformation: React.FC<VenueInfoProps> = ({
  venue,
  classNames,
  isModal = false,
}) => {
  const formattedAddress = addressToString(venue.address, ', ');
  const containerClasses = cs('venue-info', classNames);
  const hasContactDetails =
    venue.contactDetails.telephone ?? venue.contactDetails.email;

  const image = venue?.displayImages[0];
  const imageUrl = image?.url;
  const headerClasses = cs({
    'modal-header modal-header--icon-displayed': !imageUrl,
  });
  const venueTitleClasses = cs('venue-name', {
    title: isModal && !imageUrl,
    'no-margin-top': !isModal || imageUrl,
  });

  return (
    <div className={containerClasses}>
      {isModal ? (
        <div className={headerClasses}>
          <h2 className={venueTitleClasses}>{venue.name}</h2>
        </div>
      ) : (
        <h2 className={venueTitleClasses}>{venue.name}</h2>
      )}
      <div className="venue-address-row">
        <VenueAddress address={venue.address} separator="<br/>" />
        <a
          href={`http://maps.apple.com/?q=${formattedAddress}`}
          target="_blank"
          rel="noreferrer"
          className="btn btn-primary btn-with-icon"
        >
          Directions <MdDirections size={20} />
        </a>
      </div>
      <div>
        <h4>Opening Hours</h4>
        <ItemOpeningTimes venue={venue} />
      </div>
      {hasContactDetails ? (
        <div>
          <h4>Contact details</h4>
          <VenuePhone contactNumber={venue?.contactDetails?.telephone} />
          <VenueEmail email={venue?.contactDetails?.email} />
        </div>
      ) : null}
    </div>
  );
};
