import { MdEmail } from 'react-icons/md';

interface VenueEmailProps {
  email: string | undefined;
}

export const VenueEmail: React.FC<VenueEmailProps> = ({ email }) => {
  return email ? (
    <div className="venue-info-row">
      <MdEmail size={24} aria-hidden="true" aria-label="E-mail" />
      <a className="venue-link" href={`mailto:${email}`}>
        {email}
      </a>
    </div>
  ) : null;
};
