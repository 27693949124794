import { useConfig } from 'contexts/ConfigContext';
import { MenuGroupItemSubHeader } from 'types/models';

interface SubHeaderItemProps {
  subHeaderItem: MenuGroupItemSubHeader;
}

export const SubHeaderItem: React.FC<SubHeaderItemProps> = ({
  subHeaderItem,
}) => {
  const { galleryViewEnabled } = useConfig();

  return (
    <div className={galleryViewEnabled ? 'gallery-text' : ''}>
      <h4>{subHeaderItem.subHeader.text}</h4>
    </div>
  );
};
