import { MdDelete } from 'react-icons/md';
import { usePhrases } from 'contexts/ConfigContext';
import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { useGiftCard } from 'contexts/GiftCardContext';
import { getMaskedCardNumber } from 'utils';
import { GiftCardLine } from 'types/models/Responses/CheckBasketResponse';

interface ItemProps {
  giftCard: GiftCardLine;
  handleShowModal: (card: GiftCardLine) => void;
}

export const GiftCardBasketLine: React.FC<ItemProps> = ({
  giftCard,
  handleShowModal,
}) => {
  const {
    giftCard: { giftCardPhrase },
  } = usePhrases();

  const { isCrediting } = useGiftCard();

  if (!giftCard.amount || !giftCard.giftCardNumber) {
    return null;
  }

  return (
    <div
      className={isCrediting ? 'panel-line loading' : 'panel-line'}
      data-testid="panel-giftcard"
    >
      <div className="remove-button">
        {!isCrediting ? (
          <MdDelete
            className="remove"
            size={25}
            role="button"
            onClick={() => handleShowModal(giftCard)}
            data-testid="btn-remove-giftcard"
          />
        ) : (
          <MdDelete
            className="remove"
            size={25}
            aria-disabled={isCrediting}
            role="button"
            data-testid="btn-remove-giftcard"
          />
        )}
      </div>
      <div className="details">
        <h5>{giftCardPhrase}</h5>
        <div>{getMaskedCardNumber(giftCard.giftCardNumber)}</div>
      </div>
      <div className="discount">
        -<FormattedCurrency value={giftCard.amount} />
      </div>
    </div>
  );
};
