import { MdPhone } from 'react-icons/md';

interface VenuePhoneProps {
  contactNumber: string | undefined;
}

export const VenuePhone: React.FC<VenuePhoneProps> = ({ contactNumber }) => {
  return contactNumber ? (
    <div className="venue-info-row">
      <MdPhone size={24} aria-hidden="true" aria-label="Telephone Number" />
      <a className="venue-link" href={`tel:${contactNumber}`}>
        {contactNumber}
      </a>
    </div>
  ) : null;
};
