import { VenueSalesArea, CombinedVenue } from 'types/models';
import { filterSalesAreasCanPlaceOrder } from 'venue/utils';

export const getOrderLocationPhrase = (
  phrase: string,
  venue: CombinedVenue | undefined,
  salesArea: VenueSalesArea | undefined,
): string => {
  if (
    venue &&
    salesArea &&
    filterSalesAreasCanPlaceOrder(venue.salesArea).length > 1
  ) {
    return phrase
      .replace('$siteName$', venue.name)
      .replace('$salesAreaName$', salesArea.friendly || salesArea.name)
      .replace('$siteTown$', venue.address.town)
      .replace('$siteCounty$', venue.address.county);
  } else {
    return venue?.name ?? '';
  }
};
