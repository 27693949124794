import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { usePhrases } from 'contexts/ConfigContext';
import { GiftCardLine } from 'types/models/Orders';
import { getMaskedCardNumber } from 'utils';

interface OrderGiftCardLineProps {
  giftCardLine: GiftCardLine;
}

export const OrderGiftCardLine: React.FC<OrderGiftCardLineProps> = ({
  giftCardLine: { amount, giftCardNumber },
}) => {
  const {
    giftCard: { giftCardPhrase },
  } = usePhrases();

  return (
    <div className='order-history-gift-card'>
      <div>
        {`${giftCardPhrase} ${getMaskedCardNumber(giftCardNumber)}`}
      </div>
      <div
        data-testid="order-history-basket-total"
      >
        -<FormattedCurrency value={amount} />
      </div>
    </div>
  );
};
