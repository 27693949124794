import { ListGroup } from 'react-bootstrap';
import { Line, StickyBasketLine, VoucherLine } from 'basket/components/Line';
import { AdditionalLines } from 'basket/components';
import { useConfig } from 'contexts/ConfigContext';
import { AztecProduct, Course } from 'types/models';
import { CourseHeader } from 'checkout/components/CheckoutBasket/BasketLines';
import { Fragment } from 'react';
import { useBasket } from 'contexts/BasketContext';
import { useVouchers } from 'contexts/VoucherContext';

interface LineListProps {
  courses: Course[];
}

export const LineList: React.FC<LineListProps> = ({ courses }) => {
  const { stickyBasketEnabled } = useConfig();

  const { basket } = useBasket();
  const { redeemedVouchers } = useVouchers();

  const courseIds = basket.map((x) => x.defaultCourseId);

  const existingCourses = courses
    .filter((course) => courseIds.includes(course.id))
    .sort((foundCourse) => foundCourse.sortOrder);

  const courseCount = (currentCourse: Course) => {
    return courseIds.filter((course) => course == currentCourse.id).length;
  };

  return (
    <>
      {stickyBasketEnabled ? (
        <ListGroup className="basket-list-group-list">
          {existingCourses.map((existingCourse) => {
            return (
              <div
                className="sticky-basket-course-group"
                key={existingCourse.id}
              >
                <CourseHeader
                  course={existingCourse.displayName}
                  count={courseCount(existingCourse)}
                  className="sticky-basket-course-header"
                />
                {basket
                  .filter((cId) => cId.defaultCourseId == existingCourse.id)
                  .map((line: AztecProduct) => (
                    <StickyBasketLine
                      line={line}
                      key={`basketLine::${line.basketId}`}
                      idx={basket.indexOf(line)}
                    />
                  ))}
              </div>
            );
          })}
          <AdditionalLines />
          <div className="sticky-basket-placeholder" />
        </ListGroup>
      ) : (
        <>
          {existingCourses.map((existingCourse) => {
            return (
              <Fragment key={existingCourse.id}>
                <CourseHeader
                  course={existingCourse.displayName}
                  count={courseCount(existingCourse)}
                />
                <div className="basket-items-list">
                  {basket
                    .filter((cId) => cId.defaultCourseId == existingCourse.id)
                    .map((line: AztecProduct) => (
                      <Line
                        line={line}
                        key={`basketLine::${line.basketId}`}
                        idx={basket.indexOf(line)}
                      />
                    ))}
                </div>
              </Fragment>
            );
          })}
          {redeemedVouchers && (
            <div className="basket-items-list">
              {redeemedVouchers.map((line) => (
                <VoucherLine line={line} key={`voucherLine::${line.idx}`} />
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};
