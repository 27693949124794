import { Modal } from 'components/Modal';
import {
  RedeemGiftCardModalBody,
  VerifyGiftCardModalBody,
} from 'checkout/components/GiftCard/components/Modal';
import { useGiftCard } from 'contexts/GiftCardContext';

interface GiftModalProps {
  isShown: boolean;
  setIsShown: (x: boolean) => void;
}

export const GiftCardModal: React.FC<GiftModalProps> = ({
  isShown,
  setIsShown,
}) => {
  const handleClose = () => {
    setIsShown(false);
  };

  const goBackToVerification = () => {
    resetVerifiedCard();
  };

  const { verifiedGiftCard, resetVerifiedCard } = useGiftCard();

  return (
    <>
      {/* This extra check stops a race condition where the modal flashes up on close */}
      {isShown ? (
        <Modal
          isOpen={isShown}
          onRequestClose={handleClose}
          data-testid="giftCard-modal"
        >
          {!verifiedGiftCard ? (
            <VerifyGiftCardModalBody closeModal={handleClose} />
          ) : (
            <RedeemGiftCardModalBody
              goBackToVerification={goBackToVerification}
              closeModal={handleClose}
            />
          )}
        </Modal>
      ) : null}
    </>
  );
};
