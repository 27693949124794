import { usePhrases } from 'contexts/ConfigContext';
import { BillPaymentButtons } from 'PayMyBill/bill/components/Payment';
import { BasicBill } from 'PayMyBill/bill/components/Products';
import { TableDetails } from 'PayMyBill/bill/components/Shared/TableDetails';
import { BillTotals } from 'PayMyBill/bill/components/Totals';
import { BillMode, FormattedBill } from 'types/models/PayMyBill';

interface ReviewBillBodyProps {
  bill: FormattedBill;
  handleChangeDisplay: (mode: BillMode) => void;
  handlePaymentAmount: (amount: number) => void;
  isDeeplink?: boolean;
}

export const ReviewBillBody: React.FC<ReviewBillBodyProps> = ({
  bill,
  handleChangeDisplay,
  handlePaymentAmount,
}) => {
  const { payMyBillRemoveServiceChargeText } = usePhrases();

  const showTaxes =
    bill.taxes.length > 0 &&
    payMyBillRemoveServiceChargeText &&
    bill.outstandingBalanceToPay > 0;

  return (
    <>
      <div className="panel panel-default">
        <TableDetails />
        <BasicBill bill={bill} />
      </div>
      <div className="panel panel-default">
        <BillTotals bill={bill} />
      </div>
      {showTaxes ? (
        <div className="panel panel-default text-center">
          {payMyBillRemoveServiceChargeText}
        </div>
      ) : null}
      <BillPaymentButtons
        handleChangeDisplay={handleChangeDisplay}
        handlePaymentAmount={handlePaymentAmount}
        bill={bill}
      />
    </>
  );
};
