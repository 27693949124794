import { useEffect } from 'react';

import {
  PaymentDisabledPaymentPanel,
  PaymentForm,
  ZeroPriceBasketPaymentPanel,
} from 'checkout/components/Payment';
import { useConfig } from 'contexts/ConfigContext';
import { useCheckoutDetails, usePayment } from 'contexts/CheckoutContext';

export const PaymentPanel: React.FC = () => {
  const { acceptsPayment } = useConfig();
  const { basketTotal } = useCheckoutDetails();
  const { setPaymentInitError } = usePayment();

  useEffect(() => {
    setPaymentInitError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {basketTotal > 0 ? (
        <div className="card payment">
          {!acceptsPayment ? <PaymentDisabledPaymentPanel /> : <PaymentForm />}
        </div>
      ) : (
        <div className="card no-payment">
          <ZeroPriceBasketPaymentPanel />
        </div>
      )}
    </>
  );
};
