import { ModalFooter, ModalBody, ModalHeader } from 'components/Modal';
import { usePhrases } from 'contexts/ConfigContext';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { getLoyaltyPhrase } from 'utils';
import { NoRewardsMessage } from './NoRewardsMessage';
import { Button } from 'react-bootstrap';
import { LoyaltyRewardList } from './LoyaltyRewardList';
import { ContextualLoader } from 'components/ContextualLoader';

interface LoyaltyRewardsListProps {
  closeModal: () => void;
}

export const LoyaltyRewardsModalBody: React.FC<LoyaltyRewardsListProps> = ({
  closeModal,
}) => {
  const {
    loyalty: { rewardPhrase, rewardTitleText },
  } = usePhrases();
  const { loyaltyRewards, isDebiting } = useLoyaltyRewards();
  const loyaltyTitle = getLoyaltyPhrase(rewardTitleText, rewardPhrase);
  const hasLoyaltyRewards = loyaltyRewards.length > 0;

  return (
    <>
      <ModalHeader closeButton onRequestClose={closeModal}>
        <h2 className="title">{loyaltyTitle}</h2>
      </ModalHeader>
      <ModalBody>
        {hasLoyaltyRewards ? (
          <LoyaltyRewardList
            handleClose={closeModal}
            rewards={loyaltyRewards}
          />
        ) : (
          <NoRewardsMessage />
        )}
      </ModalBody>
      <ModalFooter className="hide-on-responsive">
        <Button className="btn btn-default" onClick={closeModal}>
          Close
        </Button>
      </ModalFooter>
      <ContextualLoader
        isVisible={isDebiting}
        loaderText={`Redeeming ${rewardPhrase}`}
      />
    </>
  );
};
