import { Button } from 'react-bootstrap';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { CombinedVenue } from 'types/models/Venue';
import {
  getSupportedServices,
  isSalesAreaSupportingServices,
  isVenueAvailable,
  isVenueTemporarilyUnavailable,
} from 'venue/utils';
import { ServiceType } from 'types/models';
import { Link } from 'react-router-dom';
import { ServiceButton } from 'venue/components/SingleVenue';

interface ItemButtonsProps {
  venue: CombinedVenue;
  handleClick: (service: ServiceType, venue: CombinedVenue) => void;
}

export const ItemButtons: React.FC<ItemButtonsProps> = ({
  venue,
  handleClick,
}) => {
  const {
    enableClickAndCollect,
    enableDeliveryLocation,
    enableOrderAndPay,
    supportsOrderDestinations,
    showVenueHomeBanners,
  } = useConfig();

  const { venueTitle } = usePhrases();

  const servicesForButtons = getSupportedServices(
    venue.services,
    enableOrderAndPay,
    enableClickAndCollect,
    enableDeliveryLocation,
  );

  const salesAreaSupportsServices = isSalesAreaSupportingServices(
    venue,
    servicesForButtons,
  );

  if (showVenueHomeBanners) {
    return (
      <Link
        to={`/venue/${venue.id}`}
        className="btn btn-primary btn-block"
        data-testid={`venue-panel-site-${venue.id}-button`}
      >
        View {venueTitle}
      </Link>
    );
  }

  if (
    !isVenueAvailable(supportsOrderDestinations, venue) ||
    servicesForButtons.length === 0 ||
    !salesAreaSupportsServices
  ) {
    return (
      <Button
        bsStyle="default"
        className="btn-block btn-with-icon"
        disabled
        data-testid="venue-panel-temp-unavailable-button"
      >
        Unavailable
      </Button>
    );
  }

  if (isVenueTemporarilyUnavailable(venue)) {
    return (
      <Button
        bsStyle="secondary"
        className="btn-block btn-with-icon"
        disabled
        data-testid="venue-panel-temp-unavailable-button"
      >
        Temporarily Unavailable
      </Button>
    );
  }

  return (
    <div className="venue-order-list">
      {[...servicesForButtons]
        .sort((a, b) => {
          return a - b;
        })
        .map((service) => {
          if (isVenueAvailable(supportsOrderDestinations, venue)) {
            return (
              <ServiceButton
                key={service}
                venue={venue}
                serviceId={service}
                handleClick={() => {
                  handleClick(service, venue);
                }}
              />
            );
          }
        })}
    </div>
  );
};
