import { useConfig, useSetConfig } from 'contexts/ConfigContext';

import { ControlLabel } from 'react-bootstrap';
import { ToggleSwitch } from 'core/components/ToggleSwitch';

export const VouchersEnabledToggle: React.FC = () => {
  const { vouchersEnabled } = useConfig();
  const setConfig = useSetConfig();

  const handleClick = (toggledValue: boolean) => {
    setConfig('vouchersEnabled', toggledValue);
  };

  return (
    <div>
      <ControlLabel className="ff-label">Vouchers Enabled:</ControlLabel>
      <ToggleSwitch
        id="vouchers-enabled"
        onChange={handleClick}
        checked={vouchersEnabled}
        disabled={false}
        name="vouchers-enabled"
        onLabel="on"
        offLabel="off"
        small={false}
      />
    </div>
  );
};
