import { FormattedDateTime } from 'common/components/FormattedDateTime';
import { usePhrases, useConfig } from 'contexts/ConfigContext';

import { FormatDateOptions } from 'react-intl';
import { ServiceType } from 'types/models';
import { Order } from 'types/models/Orders';

interface OrderServiceInformationProps {
  order: Order;
  orderService: ServiceType;
}

export const OrderServiceInformation: React.FC<
  OrderServiceInformationProps
> = ({ order, orderService }) => {
  const { table: tablePhrase } = usePhrases();
  const { timeslots24hFormat } = useConfig();

  const formatDateOptions: FormatDateOptions = {
    month: 'long',
    weekday: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: !timeslots24hFormat,
  };

  const isClickAndCollect = orderService === ServiceType.ClickAndCollect;
  const isDeliveryToLocation = orderService === ServiceType.DeliveryToLocation;

  return (
    <>
      {(isClickAndCollect || isDeliveryToLocation) && order.timeSlot ? (
        <span>
          <div className="info-item">
            <div>{isDeliveryToLocation ? 'Delivery' : 'Collection'} Time</div>
            <div className="text-muted info-field">
              {FormattedDateTime({
                date: new Date(order.timeSlot),
                options: formatDateOptions,
              })}
            </div>
          </div>
          {order.additionalInformation[0] &&
          order.additionalInformation[0].label ? (
            <>
              <div className="info-item">
                <div>{order.additionalInformation[0].label}</div>
                <div className="text-muted info-field">
                  {order.additionalInformation[0].value &&
                  order.additionalInformation[0].value !== ''
                    ? order.additionalInformation[0].value
                    : '-'}
                </div>
              </div>
            </>
          ) : null}
          {order.additionalInformation[1] &&
          order.additionalInformation[1].label ? (
            <>
              <div className="info-item">
                <div>{order.additionalInformation[1].label}</div>
                <div className="text-muted info-field">
                  {order.additionalInformation[1].value &&
                  order.additionalInformation[1].value !== ''
                    ? order.additionalInformation[1].value
                    : '-'}
                </div>
              </div>
            </>
          ) : null}
        </span>
      ) : null}
      {orderService === ServiceType.OrderAndPay ? (
        <span>
          <div className="info-item">
            <div>{tablePhrase}</div>
            <div className="text-muted info-field">{order.tableName}</div>
          </div>
        </span>
      ) : null}
    </>
  );
};
