import { useConfig, useSetConfig } from 'contexts/ConfigContext';

import { ControlLabel } from 'react-bootstrap';
import { ToggleSwitch } from 'core/components/ToggleSwitch';

export const VenueHomeHeaderBanner: React.FC = () => {
  const { showVenueHeaderBanner } = useConfig();
  const setConfig = useSetConfig();

  const handleClick = (toggledValue: boolean) => {
    setConfig('showVenueHeaderBanner', toggledValue);
  };

  return (
    <div>
      <ControlLabel className="ff-label">
        Show Venue Header Banner:
      </ControlLabel>
      <ToggleSwitch
        id="venue-header-banner"
        onChange={handleClick}
        checked={showVenueHeaderBanner}
        disabled={false}
        name="venue-home-banners"
        onLabel="Yes"
        offLabel="No"
        small={false}
      />
    </div>
  );
};
