import { Address } from 'checkout/components/Address';
import { PaymentForm } from 'checkout/components/Payment';
import { Tip } from 'checkout/components/Tip';
import { Loader } from 'components/Loader';
import { useConfig } from 'contexts/ConfigContext';
import { HotelContextProvider } from 'contexts/HotelContext';
import { usePayMyBill } from 'contexts/PayMyBillContext/PayMyBillContext';
import { useVenues } from 'contexts/VenueContext';
import { BillTotals } from 'PayMyBill/bill/components/Totals';
import { BackToBillBtn } from 'PayMyBill/checkout/components';
import { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router';

export const CheckoutPmb: React.FC = () => {
  const history = useHistory();
  const { supportsTip } = useConfig();

  const { selectedVenue } = useVenues();
  const { bill, braintreeToken, resetBraintreeToken } = usePayMyBill();

  const handleBackToBill = () => {
    resetBraintreeToken();
    history.push(`/venue/${selectedVenue?.id}/pay-my-bill/account`);
  };

  useEffect(() => {
    return () => {
      resetBraintreeToken();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!bill || !braintreeToken) {
    return <Redirect to="/venues" />;
  }

  if (!braintreeToken) {
    return <Loader text="BRAINTREE LOADING" />;
  }

  return (
    <HotelContextProvider>
      <div className="checkout-wrapper checkout-pmb">
        <div className="details">
          <Address />
          <div className="card totals">
            <BillTotals bill={bill} showTable isCheckout />
          </div>
          <BackToBillBtn handleClick={handleBackToBill} />
        </div>
        <div className="actions">
          {supportsTip && <Tip isPmb />}
          <div className="card payment">
            <PaymentForm isPmb />
          </div>
        </div>
      </div>
    </HotelContextProvider>
  );
};
