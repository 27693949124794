import { useAPI } from 'api/useAPI';
import { requestBodyFormatter } from 'api/utils';
import axios, { AxiosRequestConfig } from 'axios';
import { CheckBasketParams } from 'contexts/CheckoutContext';
import { useVenues, useSalesAreas } from 'contexts/VenueContext';
import { addNotification } from 'core/actions';
import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isVoidVoucher } from 'types/guards';
import {
  CheckVoucherResponse,
  ErrorResponse,
  VoidVoucherResponse,
} from 'types/models';
import { VoucherBasketLine } from 'types/models/Vouchers';

interface VoucherContext {
  initialised: boolean;
  checkVoucher: (voucherCode: string) => void;
  checkVoucherLoading: boolean;
  checkVoucherResponse: CheckVoucherResponse | ErrorResponse | undefined;
  redeemedVouchers: VoucherBasketLine[];
  resetCheckVoucherResponse: () => void;
  setRedeemedVouchers: (vouchers: VoucherBasketLine[]) => void;
  voidAllVouchers: () => void;
  voidAllVouchersLoading: boolean;
  voidVoucher: (voucherCode: string, checkBasket?: CheckBasketParams) => void;
  voidVoucherLoading: boolean;
}

export const VoucherContext = createContext<VoucherContext>({
  initialised: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  checkVoucher: () => {},
  checkVoucherLoading: false,
  checkVoucherResponse: undefined,
  redeemedVouchers: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetCheckVoucherResponse: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setRedeemedVouchers: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  voidAllVouchers: () => {},
  voidAllVouchersLoading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  voidVoucher: () => {},
  voidVoucherLoading: false,
});

export const useVouchers = (): VoucherContext => {
  const consumer = useContext(VoucherContext);
  if (!consumer.initialised) {
    throw new Error('Voucher Context Provider not initialised');
  }
  return consumer;
};

interface VoucherContextProviderProps {
  children: React.ReactNode;
}

export const VoucherContextProvider: React.FC<VoucherContextProviderProps> = ({
  children,
}) => {
  const dispatch = useDispatch();

  const { selectedVenue } = useVenues();
  const { selectedSalesArea } = useSalesAreas();

  // Check Voucher states
  // **************************************************************************//
  const [checkVoucherLoading, setCheckVoucherLoading] =
    useState<boolean>(false);

  const [checkVoucherResponse, setCheckVoucherResponse] = useState<
    CheckVoucherResponse | ErrorResponse | undefined
  >();

  // **************************************************************************//

  //Void voucher states
  // **************************************************************************//
  const [voidVoucherLoading, setVoidVoucherLoading] = useState<boolean>(false);

  // **************************************************************************//

  //Bulk voucher void states
  // **************************************************************************//
  const [isVoidingAllVouchers, setIsVoidingAllVouchers] =
    useState<boolean>(false);

  const [voidAllVouchersLoading, setVoidAllVouchersLoading] =
    useState<boolean>(false);

  // **************************************************************************//

  //Redeemed voucher state
  // **************************************************************************//
  const [redeemedVouchers, setRedeemedVouchers] = useState<VoucherBasketLine[]>(
    [],
  );
  // **************************************************************************//

  const {
    url,
    checkVoucher: checkVoucherHeaders,
    voidVoucher: voidVoucherHeaders,
  } = useAPI();

  const voidVoucher = (
    voucherCode: string,
    checkBasket?: CheckBasketParams,
  ) => {
    setVoidVoucherLoading(true);

    const voidVoucherConfig = voidVoucherHeaders();

    const voidVoidOptions: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      headers: voidVoucherConfig.headers,
      data: requestBodyFormatter({
        userDeviceIdentifier: 'web',
        version: '20',
        platform: 'web',
        method: voidVoucherConfig.method,
        siteId: selectedVenue?.id,
        venueId: selectedVenue?.id,
        salesAreaId: selectedSalesArea?.id,
        voucherCode,
      }),
    };

    axios(voidVoidOptions)
      .then((response) => {
        const data = response.data as VoidVoucherResponse;
        if (isVoidVoucher(data)) {
          const vouchersForCheckBasket = redeemedVouchers.filter(
            (v) => voucherCode !== v.VoucherCode,
          );
          removeVoucher(voucherCode);
          checkBasket &&
            checkBasket(false, undefined, undefined, {
              vouchers: vouchersForCheckBasket,
            });
        } else {
          dispatch(
            addNotification(
              'There was an error removing the voucher from your basket. Please try again.',
              'danger',
            ),
          );
        }
      })
      .catch(() => {
        setVoidVoucherLoading(false);
        dispatch(
          addNotification(
            'There was an unexpected error removing the voucher. Please try again.',
            'danger',
          ),
        );
      })
      .finally(() => {
        setVoidVoucherLoading(false);
      });
  };

  const voidAllVouchers = () => {
    setIsVoidingAllVouchers(true);
  };

  useEffect(() => {
    if (isVoidingAllVouchers) {
      voidAllVouchersCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVoidingAllVouchers]);

  const checkVoucher = (voucherCode: string): void => {
    setCheckVoucherResponse(undefined);
    setCheckVoucherLoading(true);

    const checkVoucherConfig = checkVoucherHeaders();

    const checkVoucherOptions: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      headers: checkVoucherConfig.headers,
      data: requestBodyFormatter({
        userDeviceIdentifier: 'web',
        version: '20',
        platform: 'web',
        method: checkVoucherConfig.method,
        siteId: selectedVenue?.id,
        venueId: selectedVenue?.id,
        salesAreaId: selectedSalesArea?.id,
        voucherCode: voucherCode,
      }),
    };

    axios(checkVoucherOptions)
      .then((response) => {
        const data = response.data as CheckVoucherResponse;
        if (data.status === 'OK') {
          setCheckVoucherResponse(data);
        } else {
          const err = response.data as ErrorResponse;
          setCheckVoucherResponse(err);
        }
      })
      .catch(() => {
        //API errors
        dispatch(
          addNotification(
            'There was an unexpected error applying the voucher. Please try again.',
            'danger',
          ),
        );
      })
      .finally(() => {
        setCheckVoucherLoading(false);
      });
  };

  const voidAllVouchersCall = (): void => {
    // You can redeem multiple vouchers, but no-one thought to write a bulk void endpoint
    // So we void one by one! ¯\(°_o)/¯
    // Successful voids go to a list, as do unsuccessful voids so the caller can handle it
    const failedVouchers: string[] = [];
    setVoidAllVouchersLoading(true);
    const voidVoucherConfig = voidVoucherHeaders();

    redeemedVouchers.forEach((voucher: VoucherBasketLine) => {
      const voidVoidOptions: AxiosRequestConfig = {
        url: url,
        method: 'POST',
        headers: voidVoucherConfig.headers,
        data: requestBodyFormatter({
          userDeviceIdentifier: 'web',
          version: '20',
          platform: 'web',
          method: voidVoucherConfig.method,
          siteId: selectedVenue?.id,
          venueId: selectedVenue?.id,
          salesAreaId: selectedSalesArea?.id,
          voucherCode: voucher.VoucherCode,
        }),
      };

      axios(voidVoidOptions)
        .then((response) => {
          const data = response.data as VoidVoucherResponse;

          if (data.STATUS !== 'OK') {
            failedVouchers.push(voucher.VoucherCode);
          }
        })
        .catch(() => {
          failedVouchers.push(voucher.VoucherCode);
        });
    });

    if (failedVouchers.length > 0) {
      dispatch(
        addNotification(
          `Could not remove vouchers ${failedVouchers.join(
            ', ',
          )}. Please try clearing your basket again.`,
          'danger',
        ),
      );
    }

    setRedeemedVouchers((prevVouchers) =>
      prevVouchers.filter((v) => failedVouchers.includes(v.VoucherCode)),
    );

    setVoidAllVouchersLoading(false);
    setIsVoidingAllVouchers(false);
  };

  const removeVoucher = (voucherCode: string) => {
    setRedeemedVouchers((prevVouchers) =>
      prevVouchers.filter((v) => voucherCode !== v.VoucherCode),
    );
  };

  const resetCheckVoucherResponse = () => {
    setCheckVoucherResponse(undefined);
  };

  return (
    <VoucherContext.Provider
      value={{
        initialised: true,
        checkVoucher,
        checkVoucherLoading,
        checkVoucherResponse,
        redeemedVouchers,
        resetCheckVoucherResponse,
        setRedeemedVouchers,
        voidAllVouchers,
        voidAllVouchersLoading,
        voidVoucher,
        voidVoucherLoading,
      }}
    >
      {children}
    </VoucherContext.Provider>
  );
};
