import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { useVenues } from 'contexts/VenueContext';
import { CombinedVenue, ServiceType } from 'types/models';
import { VenueBannerItem } from 'venue/components/SingleVenue/VenueBannerItem';
import { VenueErrorPanel } from 'venue/components/SingleVenue/VenueErrorPanel';
import {
  allBannersAreOrderingModes,
  formatVenueBanners,
  getSupportedServices,
  isSalesAreaSupportingServices,
  isVenueAvailable,
  isVenueTemporarilyUnavailable,
} from 'venue/utils';

interface BannerListProps {
  venue: CombinedVenue;
  handleClick: (venue: CombinedVenue, serviceId: number) => void;
}

export const BannerList: React.FC<BannerListProps> = ({
  venue,
  handleClick,
}) => {
  const {
    enableClickAndCollect,
    enableDeliveryLocation,
    enableOrderAndPay,
    supportsOrderDestinations,
  } = useConfig();

  const { venueTitle } = usePhrases();
  const { venueHomeData } = useVenues();

  if (!venueHomeData?.banners) {
    return (
      <VenueErrorPanel
        title={`Ordering at ${venueTitle} is currently unavailable`}
      />
    );
  }

  const filteredBanners = formatVenueBanners(venueHomeData.banners);
  const hasOnlyOrderingBanners = allBannersAreOrderingModes(filteredBanners);

  const supportedServices = getSupportedServices(
    venue.services,
    enableOrderAndPay,
    enableClickAndCollect,
    enableDeliveryLocation,
  );

  if (venue.services.includes(ServiceType.PayMyBill)) {
    supportedServices.push(ServiceType.PayMyBill);
  }

  const salesAreaSupportsServices = isSalesAreaSupportingServices(
    venue,
    supportedServices,
  );

  if (
    (!isVenueAvailable(supportsOrderDestinations, venue) ||
      supportedServices.length === 0 ||
      !salesAreaSupportsServices ||
      isVenueTemporarilyUnavailable(venue) ||
      filteredBanners.length === 0) &&
    hasOnlyOrderingBanners
  ) {
    return (
      <VenueErrorPanel
        title={`Ordering at this ${venueTitle} is currently unavailable`}
      />
    );
  }

  return (
    <>
      {filteredBanners.map((banner) => (
        <VenueBannerItem
          venue={venue}
          banner={banner}
          key={banner.id}
          handleClick={handleClick}
        />
      ))}
    </>
  );
};
