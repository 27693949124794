import { Badge } from 'common/components/Badge';
import { useConfig } from 'contexts/ConfigContext';

interface QuantityIndicatorProps {
  quantity: number;
}

export const QuantityIndicator: React.FC<QuantityIndicatorProps> = ({
  quantity,
}) => {
  const { productSelectionIndicatorsEnabled } = useConfig();

  return (
    <>
      {productSelectionIndicatorsEnabled && quantity > 0 && (
        <Badge className="basket-quantity-badge">{quantity}</Badge>
      )}
    </>
  );
};
