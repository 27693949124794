import { useMenuGroups } from 'contexts/MenuContext';

interface KeywordProps {
  keywordId: number;
}

export const Keyword: React.FC<KeywordProps> = ({ keywordId }) => {
  const { keywords } = useMenuGroups();
  const keyword = keywords.find((k) => k.id === keywordId);

  return keyword?.iconUrl ? (
    <img
      alt={keyword.name}
      title={keyword.name}
      className={`keyword-image keyword-${keywordId}`}
      src={keyword.iconUrl}
    />
  ) : null;
};
