import { usePhrases } from 'contexts/ConfigContext';
import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { MdErrorOutline } from 'react-icons/md';
import { useCheckoutDetails } from 'contexts/CheckoutContext';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { useGiftCard } from 'contexts/GiftCardContext';
import { useVouchers } from 'contexts/VoucherContext';

export const GiftCardPanelPhrase: React.FC = () => {
  const {
    giftCard: { giftCardPhrase },
    loyalty: { rewardPhrase },
  } = usePhrases();

  const { basketTotal } = useCheckoutDetails();
  const { redeemedLoyaltyRewards } = useLoyaltyRewards();
  const { redeemedGiftCards } = useGiftCard();
  const { redeemedVouchers } = useVouchers();

  const hasVouchersApplied = redeemedVouchers.length > 0;
  const hasLoyaltyReward = redeemedLoyaltyRewards.length > 0;
  const hasGiftCards = redeemedGiftCards.length > 0;

  return (
    <>
      {basketTotal === 0 && !hasGiftCards && !hasLoyaltyReward ? (
        <div className="gc-prompt text-muted reward-conflict">
          <MdErrorOutline size={25} />
          {giftCardPhrase} cannot be applied because the total is{' '}
          <FormattedCurrency value={0} />.
        </div>
      ) : null}
      {hasLoyaltyReward || hasVouchersApplied ? (
        <div className="reward-conflict text-muted">
          <MdErrorOutline size={25} />
          {`${giftCardPhrase} cannot be applied because the basket contains a ${
            hasLoyaltyReward ? rewardPhrase : 'voucher'
          }.`}
        </div>
      ) : null}
    </>
  );
};
