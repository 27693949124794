import { useConfig } from 'contexts/ConfigContext';
import { getDeviceIdKey } from 'utils';

export const CookieNoticePage: React.FC = () => {
  const { client } = useConfig();

  return (
    <div className="container">
      <div className="card panel panel-default cookie-notice">
        <h2>Cookie Notice</h2>
        <p>
          Our website uses cookies to distinguish you from other users of our
          website. This helps us to provide you with a good experience when you
          browse our website and also allows us to improve our site.
        </p>

        <p>
          A cookie is a small file of letters and numbers that we store on your
          browser or the hard drive of your computer if you agree. Cookies
          contain information that is transferred to your computer&apos;s hard
          drive.
        </p>
        <p>
          We use the following cookies:
          <ul>
            <li>
              Strictly necessary cookies. These are cookies that are required
              for the operation of our website. They include, for example,
              cookies that enable you to log into secure areas of our website,
              use a shopping cart or make use of e-billing services.
            </li>
            <li>
              Functionality cookies. These are used to recognise you when you
              return to our website. This enables us to personalise our content
              for you, greet you by name and remember your preferences (for
              example, your choice of language or region).
            </li>
          </ul>
        </p>
        <p>
          You can find more information about the individual cookies we use and
          the purposes for which we use them in the table below:
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Cookie Title</th>
                <th scope="col">Cookie Provider</th>
                <th scope="col">Cookie Type</th>
                <th scope="col">Purpose</th>
              </tr>
            </thead>
            <tr>
              <td data-label="Cookie Title">{`${client}::wo`}</td>
              <td data-label="Cookie Provider">Zonal</td>
              <td data-label="Cookie Type">HTTP Cookie</td>
              <td data-label="Purpose">
                <div className="purpose">
                  <p>This cookie is essential for our site: </p>
                  <p>
                    As it is used to determine if the visitor has accepted the
                    use of essential cookies on our site. This cookie is
                    necessary for GDPR compliance of the website.
                  </p>
                  <p>Expiry: 90 days</p>
                </div>
              </td>
            </tr>
            <tr>
              <td data-label="Cookie Title">{getDeviceIdKey(client)}</td>
              <td data-label="Cookie Provider">Zonal</td>
              <td data-label="Cookie Type">HTML Local Storage</td>
              <td data-label="Purpose">
                <div className="purpose">
                  <p>This cookie is essential for our site: </p>
                  <p>
                    As it is used in context with the Gift Card payment function
                    on the website. It is necessary for making a safe
                    transaction using a Gift Card.
                  </p>
                  <p>Expiry: Persistent</p>
                </div>
              </td>
            </tr>
            <tr>
              <td data-label="Cookie Title">[SessionID]</td>
              <td data-label="Cookie Provider">PayPal</td>
              <td data-label="Cookie Type">HTTP Cookie</td>
              <td data-label="Purpose">
                <div className="purpose">
                  <p>This cookie is essential for our site: </p>
                  <p>
                    As it is used in context with the PayPal payment function on
                    the website. It is necessary for making a safe transaction
                    through PayPal.
                  </p>
                  <p>Expiry: 1 day</p>
                </div>
              </td>
            </tr>
            <tr>
              <td data-label="Cookie Title">paypal_storage__</td>
              <td data-label="Cookie Provider">PayPal</td>
              <td data-label="Cookie Type">HTML Local Storage</td>
              <td data-label="Purpose">
                <div className="purpose">
                  <p>This cookie is essential for our site: </p>
                  <p>
                    As it is used in context with the PayPal payment function on
                    the website. It is necessary for making a safe transaction
                    through PayPal.
                  </p>
                  <p>Expiry: Persistent</p>
                </div>
              </td>
            </tr>
            <tr>
              <td data-label="Cookie Title">CONSENT</td>
              <td data-label="Cookie Provider">Google</td>
              <td data-label="Cookie Type">HTTP Cookie</td>
              <td data-label="Purpose">
                <div className="purpose">
                  <p>This cookie is essential for our site: </p>
                  <p>
                    As it is used to detect if the visitor has accepted the
                    marketing category in the cookie banner. This cookie is
                    necessary for GDPR compliance of the website.
                  </p>
                  <p>Expiry: 2 years</p>
                </div>
              </td>
            </tr>
            <tr>
              <td data-label="Cookie Title">enforce_policy</td>
              <td data-label="Cookie Provider">PayPal</td>
              <td data-label="Cookie Type">HTTP Cookie</td>
              <td data-label="Purpose">
                <div className="purpose">
                  <p>This cookie is essential for our site: </p>
                  <p>
                    As this cookie is provided by Paypal and is used in context
                    with transactions on the website. The cookie is necessary
                    for secure transactions.
                  </p>
                  <p>Expiry: 1 year</p>
                </div>
              </td>
            </tr>
            <tr>
              <td data-label="Cookie Title">l7_az</td>
              <td data-label="Cookie Provider">PayPal</td>
              <td data-label="Cookie Type">HTTP Cookie</td>
              <td data-label="Purpose">
                <div className="purpose">
                  <p>This cookie is essential for our site: </p>
                  <p>
                    As it is necessary for the PayPal login function on the
                    website.
                  </p>
                  <p>Expiry: 1 day</p>
                </div>
              </td>
            </tr>
            <tr>
              <td data-label="Cookie Title">ts</td>
              <td data-label="Cookie Provider">PayPal</td>
              <td data-label="Cookie Type">Pixel Tracker</td>
              <td data-label="Purpose">
                <div className="purpose">
                  <p>This cookie is essential for our site: </p>
                  <p>
                    As it is used in context with the PayPal payment function on
                    the website. The cookie is necessary for making a safe
                    transaction through PayPal.
                  </p>
                  <p>Expiry: Session</p>
                </div>
              </td>
            </tr>
            <tr>
              <td data-label="Cookie Title">ts_c</td>
              <td data-label="Cookie Provider">PayPal</td>
              <td data-label="Cookie Type">HTTP Cookie</td>
              <td data-label="Purpose">
                <div className="purpose">
                  <p>This cookie is essential for our site: </p>
                  <p>
                    As it is used in context with the PayPal payment function on
                    the website. The cookie is necessary for making a safe
                    transaction through PayPal.
                  </p>
                  <p>Expiry: 399 days</p>
                </div>
              </td>
            </tr>
            <tr>
              <td data-label="Cookie Title">x-pp-s</td>
              <td data-label="Cookie Provider">PayPal</td>
              <td data-label="Cookie Type">HTTP Cookie</td>
              <td data-label="Purpose">
                <div className="purpose">
                  <p>This cookie is essential for our site: </p>
                  <p>
                    As it is used in context with the PayPal payment function on
                    the website. The cookie is necessary for making a safe
                    transaction through PayPal.
                  </p>
                  <p>Expiry: 399 days</p>
                </div>
              </td>
            </tr>
            <tr>
              <td data-label="Cookie Title">LANG</td>
              <td data-label="Cookie Provider">PayPal</td>
              <td data-label="Cookie Type">HTTP Cookie</td>
              <td data-label="Purpose">
                <div className="purpose">
                  <p>This cookie is essential for our site: </p>
                  <p>
                    As it is used in context with the PayPal payment function on
                    the website. The cookie is necessary for making a safe
                    transaction through PayPal.
                  </p>
                  <p>Expiry: 399 days</p>
                </div>
              </td>
            </tr>
            <tr>
              <td data-label="Cookie Title">tsrce</td>
              <td data-label="Cookie Provider">PayPal</td>
              <td data-label="Cookie Type">HTTP Cookie</td>
              <td data-label="Purpose">
                <div className="purpose">
                  <p>This cookie is essential for our site: </p>
                  <p>
                    As it is used in context with the PayPal payment function on
                    the website. The cookie is necessary for making a safe
                    transaction through PayPal.
                  </p>
                  <p>Expiry: 399 days</p>
                </div>
              </td>
            </tr>
            <tr>
              <td data-label="Cookie Title">cookie_check</td>
              <td data-label="Cookie Provider">PayPal</td>
              <td data-label="Cookie Type">HTTP Cookie</td>
              <td data-label="Purpose">
                <div className="purpose">
                  <p>This cookie is essential for our site: </p>
                  <p>
                    As it is used in context with the PayPal payment function on
                    the website. The cookie is necessary for making a safe
                    transaction through PayPal.
                  </p>
                  <p>Expiry: 399 days</p>
                </div>
              </td>
            </tr>
          </table>
        </p>
        <p>
          Please note that the usage of the essential cookies set out above
          includes the following third parties cookies, over which we have no
          control. These named third parties may include, for example,
          advertising networks and providers of external services like web
          traffic analysis services.
        </p>
        <p>
          These third party cookies are likely to be analytical cookies or
          performance cookies or targeting cookies:
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Provider</th>
                <th scope="col">Information</th>
              </tr>
            </thead>
            <tr>
              <td data-label="Provider">Google</td>
              <td data-label="Information">
                <div className="information">
                  <p>
                    <a
                      href={`https://payments.google.com/payments/apis-secure/get_legal_document?ldo=0&ldt=privacynotice&ldl=en-GB`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://payments.google.com/payments/apis-secure/get_legal_document?ldo=0&ldt=privacynotice&ldl=en-GB
                    </a>
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td data-label="Provider">Cloudflare</td>
              <td data-label="Information">
                <div className="information">
                  <p>
                    <a
                      href={`https://www.cloudflare.com/en-gb/privacypolicy/`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.cloudflare.com/en-gb/privacypolicy/
                    </a>
                  </p>
                </div>
              </td>
            </tr>
          </table>
        </p>
        <p>
          These third party cookies may cookies may collect analytical and other
          data about you including but not limited to (i) the number of times
          you visit the site (ii) dates for the first and most recent visit(s)
          (iii) tracking your usage across devices and marketing channels and
          (iv) building a profile of your interests in order to show relevant &
          personalised advertising.
        </p>
        <p>
          To deactivate the use of third party advertising cookies, you may
          visit the consumer page to manage the use of these types of cookies
          but you should be aware that this will affect the functionality of our
          website.
        </p>
        <p>
          However, if you use your browser settings to block all cookies
          (including essential cookies) you may not be able to access all or
          parts of our website.
        </p>
        <p>
          Except for essential cookies, all cookies will expire after 2 years.
        </p>
      </div>
    </div>
  );
};
