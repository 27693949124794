import { FormatDateOptions, useIntl } from 'react-intl';
import { useConfig } from 'contexts/ConfigContext';
import { TimeslotPhrase } from 'common/components/TimeslotPhrase';
import { useServices, useVenues } from 'contexts/VenueContext';
import { useTimeslots } from 'contexts/TimeslotContext/TimeslotContext';

export const DisplaySelectedTimeslot: React.FC = () => {
  const { selectedTimeslot } = useTimeslots();
  const { selectedVenue } = useVenues();
  const { selectedService } = useServices();

  const { timeslots24hFormat } = useConfig();

  const intl = useIntl();

  const formatOptions: FormatDateOptions = {
    hourCycle: timeslots24hFormat === true ? 'h23' : 'h12',
    timeZone: selectedVenue?.timezone,
    timeZoneName: 'short',
  };

  return (
    <div
      className="card collection text-center"
      data-testid="card-collection-text-center"
    >
      {`${TimeslotPhrase({ selectedService })}: ${intl.formatTime(
        selectedTimeslot?.time,
        formatOptions,
      )}`}
    </div>
  );
};
