import * as actionTypes from './actionTypes';
import { initialState } from './model';

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.IS_FETCHING_ORDERS:
      return state.set('isFetchingOrders', action.isFetching);

    case actionTypes.RECEIVE_ORDERS:
      return state.merge({ orders: action.orders });

    default:
      return state;
  }
}

export default reducer;
