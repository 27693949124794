import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { MinimumAgeBadge } from 'menu/components/MinimumAge';
import { AztecProduct } from 'types/models';
import { calculateLinePrice, getSelectedPortion } from 'menu/utils';
import {
  AndedUpsellLines,
  BasketChoices,
} from 'checkout/components/CheckoutBasket/BasketLines';

interface BasketLineProps {
  line: AztecProduct;
}

export const BasketLine: React.FC<BasketLineProps> = ({ line }) => {
  const minimumAge = line?.minimumCustomerAge;
  const selectedPortion = getSelectedPortion(line);

  return (
    <div className="basket-line" data-testid="cardBasket-item">
      <div
        className="qty text-right"
        data-testid="cardBasket-item-quantity"
      >{`${selectedPortion.quantity}x`}</div>
      <div className="description" data-testid="cardBasket-item-description">
        <div>
          {line?.displayRecords[line.displayRecordId].name || line?.displayName}
        </div>
        <div className="item-description">
          {selectedPortion.name || selectedPortion.portion_name}
        </div>
        <BasketChoices choices={selectedPortion.choices} />
        {line.andedUpsells && (
          <AndedUpsellLines andedUpsells={line.andedUpsells} />
        )}
      </div>
      {minimumAge ? (
        <div className="basket-minimum-age-container">
          <MinimumAgeBadge minimumAge={minimumAge} />
        </div>
      ) : null}
      <div className="price" data-testid="cardBasket-item-price">
        <FormattedCurrency
          value={calculateLinePrice(line) * selectedPortion.quantity}
        />
      </div>
    </div>
  );
};
