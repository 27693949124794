import { Button } from 'react-bootstrap';
import { Currency } from 'core/components/RHF/FieldTemplates';
import { Field } from 'types/models/Forms';
import { selectTipAmount, selectSelectedTipPreset } from 'checkout/selectors';
import { setTipAmount, setSelectedTipPreset } from 'checkout/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

interface TipFieldProps {
  currencyInput: string;
}

export const CustomTipForm: React.FC = () => {
  const dispatch = useDispatch();
  const tipAmount = useSelector(selectTipAmount);
  const methods = useForm<TipFieldProps>({ mode: 'all' });
  const tipPercentage = useSelector(selectSelectedTipPreset);

  const currencyField: Field = {
    content: tipPercentage < 0 ? String(tipAmount) : '',
    label: 'Custom Tip',
    max: 999.99,
    min: 0.01,
    name: 'currencyInput',
    placeholder: 'Custom Tip',
    required: true,
    type: 'currency',
  };

  const handleConfirm: SubmitHandler<TipFieldProps> = ({ currencyInput }) => {
    dispatch(setTipAmount(Number(currencyInput).toFixed(2)));
    dispatch(setSelectedTipPreset(-1));
  };

  return (
    <div className="tip-custom-form" data-testid="tip-custom-form">
      <FormProvider {...methods}>
        <Currency field={currencyField} />
        <Button
          className="btn btn-primary checkout-fw-button"
          bsStyle="primary"
          onClick={methods.handleSubmit(handleConfirm)}
          data-testid="tip-confirm-button"
        >
          Add Tip
        </Button>
      </FormProvider>
    </div>
  );
};
