import {
  ErrorResponse,
  VenueSummaryResponse,
  VenuesResponse,
} from 'types/models';

export const isGetVenueSummaryResponse = (
  response: VenueSummaryResponse | ErrorResponse | undefined,
): response is VenueSummaryResponse => {
  return (
    response !== undefined &&
    (response as VenueSummaryResponse).venues !== undefined
  );
};

export const isVenueResponse = (
  response: VenuesResponse | ErrorResponse | undefined,
): response is VenuesResponse => {
  return (
    response !== undefined && (response as VenuesResponse).venues !== undefined
  );
};
