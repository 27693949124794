import { useEffect, useState } from 'react';

import { Button } from 'react-bootstrap';
import { usePhrases } from 'contexts/ConfigContext';
import {
  GiftCardPanelPhrase,
  GiftCardBasketLines,
} from 'checkout/components/GiftCard/components';
import { getGiftCardPhrase } from 'utils';
import { GiftCardModal } from 'checkout/components/GiftCard/components/Modal';
import { useGiftCard } from 'contexts/GiftCardContext';
import { Loader } from 'components/Loader';
import { useCheckout, useCheckoutDetails } from 'contexts/CheckoutContext';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { useVouchers } from 'contexts/VoucherContext';

export const GiftCard: React.FC = () => {
  const {
    giftCard: { giftCardPhrase, callToActionTitle },
  } = usePhrases();

  const { basketTotal } = useCheckoutDetails();
  const { isFetchingBasket } = useCheckout();
  const { resetVerifiedCard } = useGiftCard();
  const { redeemedLoyaltyRewards } = useLoyaltyRewards();
  const { redeemedVouchers } = useVouchers();

  const hasLoyaltyReward = redeemedLoyaltyRewards.length > 0;
  const hasVouchersApplied = redeemedVouchers.length > 0;

  const [isModalShown, setIsModalShown] = useState(false);

  useEffect(() => {
    if (!isModalShown) {
      resetVerifiedCard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalShown]);

  const ctaTitle = getGiftCardPhrase(callToActionTitle, giftCardPhrase);
  return (
    <>
      <div className="card gift-card">
        <h3 className="title">{ctaTitle}</h3>
        {isFetchingBasket ? (
          <Loader />
        ) : (
          <div className="body">
            <Button
              className="center-block checkout-fw-button"
              bsStyle="primary"
              data-testid="button-set-gift-card"
              disabled={
                basketTotal === 0 ||
                hasLoyaltyReward ||
                hasVouchersApplied ||
                isFetchingBasket
              }
              onClick={() => setIsModalShown(true)}
            >
              {ctaTitle}
            </Button>
            <GiftCardBasketLines />
            <GiftCardPanelPhrase />
          </div>
        )}
      </div>
      <GiftCardModal isShown={isModalShown} setIsShown={setIsModalShown} />
    </>
  );
};
