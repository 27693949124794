import { useEffect, useState } from 'react';

import { ChargeToRoomModal } from 'checkout/components/Modals';
import { FaBed } from 'react-icons/fa';
import { usePhrases } from 'contexts/ConfigContext';
import { getRoomPhrase } from 'utils';
import { useSelector } from 'react-redux';
import { selectAdditionalInfoErrorDialog } from 'checkout/selectors';

export const ChargeToRoom: React.FC = () => {
  const [modalShown, setModalShown] = useState<boolean>(false);
  const {
    roomPhrase,
    chargeToRoom: { buttonTitle },
  } = usePhrases();
  const title = getRoomPhrase(buttonTitle, roomPhrase.toLowerCase());

  const additionalInfoError999 = useSelector(selectAdditionalInfoErrorDialog);

  useEffect(() => {
    if (additionalInfoError999) {
      setModalShown(false);
    }
  }, [additionalInfoError999]);

  return (
    <div className="ctr-button">
      <button
        className="btn btn-outline-primary center-block checkout-fw-button"
        data-testid="btn-charge-to-room"
        onClick={() => setModalShown(true)}
        type="submit"
      >
        <FaBed className="charge-to-room-icon" /> {title}
      </button>
      <ChargeToRoomModal shown={modalShown} setShown={setModalShown} />
    </div>
  );
};
