import { useState } from 'react';
import { CombinedVenue } from 'types/models';
import {
  VenueInfoDesktop,
  VenueInfoHeader,
  VenueInfoModal,
} from 'venue/components/SingleVenue/VenueInfo';
import cs from 'classnames';

interface VenueInfoLayoutProps {
  children: React.ReactNode;
  selectedVenue: CombinedVenue;
  classNamePrefix?: string;
}
export const VenueInfoLayout: React.FC<VenueInfoLayoutProps> = ({
  children,
  selectedVenue,
  classNamePrefix,
}) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const containerClasses = cs(
    'venue-info-layout',
    classNamePrefix && `${classNamePrefix}-container`,
  );

  const bodyClasses = cs(
    'venue-info-body',
    classNamePrefix && `${classNamePrefix}-body`,
  );
  return (
    <div className={containerClasses}>
      <VenueInfoHeader
        venue={selectedVenue}
        handleOpen={() => setIsInfoModalOpen(true)}
      />
      <div className="container">
        <div className={bodyClasses}>
          <VenueInfoDesktop venue={selectedVenue} />
          {children}
        </div>
      </div>
      <VenueInfoModal
        venue={selectedVenue}
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
    </div>
  );
};
