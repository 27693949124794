interface CancelButtonProps {
  handleCancel: () => void;
}

export const CancelButton: React.FC<CancelButtonProps> = ({ handleCancel }) => {
  return (
    <button
      onClick={handleCancel}
      className="btn btn-default cancel-button hide-on-responsive hide-modal-button"
      data-testid="menu-modal-footer-cancel-button"
    >
      Cancel
    </button>
  );
};
