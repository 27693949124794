import { useConfig } from 'contexts/ConfigContext';
import { ServiceType } from 'types/models';
import { TableChanger, TimeslotChanger } from 'core/components';
import cs from 'classnames';
import { useServices } from 'contexts/VenueContext';

export const ServiceChanger: React.FC = () => {
  const { pickupTimeUnderHeader, pickupTimeOnTop, stickyBasketEnabled } =
    useConfig();
  const { selectedService } = useServices();

  const classes = cs(
    pickupTimeUnderHeader ? 'service-changer' : 'pickup-panel',
    pickupTimeOnTop && !pickupTimeUnderHeader && !stickyBasketEnabled
      ? 'panel panel-default'
      : null,
  );

  return (
    <div
      className={classes}
      data-testid={
        pickupTimeUnderHeader &&
        (ServiceType.ClickAndCollect || ServiceType.DeliveryToLocation)
          ? 'click-collect-service-changer'
          : 'order-pay-service-changer'
      }
    >
      {selectedService === ServiceType.ClickAndCollect ||
      selectedService === ServiceType.DeliveryToLocation ? (
        <TimeslotChanger />
      ) : null}
      {selectedService === ServiceType.OrderAndPay ? <TableChanger /> : null}
    </div>
  );
};
