import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { CombinedVenue } from 'types/models';
import { VenueList, VenueMap } from 'venue/components';

interface VenueRouterProps {
  venuesForMap: CombinedVenue[];
  venues: CombinedVenue[];
}

export const VenueRouter: React.FC<VenueRouterProps> = ({
  venuesForMap,
  venues,
}) => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${match.path}/map`}
        render={() => (
          <VenueMap venuesForMap={venuesForMap} venuesAround={venues} />
        )}
      />
      <Route
        path={`${match.path}/list`}
        render={() => <VenueList venues={venues} />}
      />
      <Route render={() => <Redirect to={`${match.url}/list`} />} />
    </Switch>
  );
};
