import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createRootReducer from './reducer';
import { apiMiddleware } from 'xhr';
import { tokenCheck } from 'utils';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  // eslint-disable-next-line global-require
  createRootReducer(),
  {},
  composeEnhancers(applyMiddleware(thunkMiddleware, tokenCheck, apiMiddleware)),
);

export type AppStore = typeof store;
