import { Modal } from 'components/Modal';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { VerifyLoyaltyModalBody } from './VerifyLoyaltyModalBody';
import { LoyaltyRewardsModalBody } from './LoyaltyRewardsModalBody';
import { Notification } from 'core/components/Notification';
interface LoyaltyModalProps {
  isShown: boolean;
  setIsShown: (x: boolean) => void;
}

export const LoyaltyRewardsModal: React.FC<LoyaltyModalProps> = ({
  isShown,
  setIsShown,
}) => {
  const { loyaltyRewardsError } = useLoyaltyRewards();
  const handleClose = () => {
    setIsShown(false);
  };

  return (
    <>
      {/* This extra check stops a race condition where the modal flashes up on close */}
      {isShown ? (
        <Modal
          isOpen={isShown}
          onRequestClose={handleClose}
          datatestid="loyalty-rewards-modal"
        >
          {loyaltyRewardsError ? (
            <VerifyLoyaltyModalBody closeModal={handleClose} />
          ) : (
            <LoyaltyRewardsModalBody closeModal={handleClose} />
          )}
          <Notification />
        </Modal>
      ) : null}
    </>
  );
};
