import { createContext, useContext, useEffect, useState } from 'react';
import { AztecPortions, AztecProduct } from 'types/models';
import {
  getProductPortions,
  getSelectedPortion,
  portionsHaveChoices,
} from 'menu/utils';
import { v4 as uuidv4 } from 'uuid';

interface BasketContext {
  initialised: boolean;
  addProductToBasket: (product: AztecProduct, specialRequest?: string) => void;
  basket: AztecProduct[];
  basketHasChanged: boolean;
  clearBasket: () => void;
  getBasketQuantity: (productId: number, displayRecordId: number) => number;
  isBasketCollapsed: boolean;
  setBasketHasChanged: (status: boolean) => void;
  setIsBasketCollapsed: (status: boolean) => void;
  removeBasketItem: (idx: number) => void;
  updateBasketQuantity: (
    idx: number,
    quantity: number,
    portionId: number,
  ) => void;
}

export const BasketContext = createContext<BasketContext>({
  initialised: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addProductToBasket: () => {},
  basket: [],
  basketHasChanged: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearBasket: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getBasketQuantity: () => 0,
  isBasketCollapsed: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsBasketCollapsed: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setBasketHasChanged: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeBasketItem: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateBasketQuantity: () => {},
});

export const useBasket = (): BasketContext => {
  const consumer = useContext(BasketContext);
  if (!consumer.initialised) {
    throw new Error('Basket Context Provider not initialised');
  }
  return consumer;
};

interface BasketContextProviderProps {
  children: React.ReactNode;
}

export const BasketContextProvider: React.FC<BasketContextProviderProps> = ({
  children,
}) => {
  const [basket, setBasket] = useState<AztecProduct[]>([]);
  const [isBasketCollapsed, setIsBasketCollapsed] = useState(true);
  const [basketHasChanged, setBasketHasChanged] = useState(false);

  const addProductToBasket = (
    product: AztecProduct,
    specialRequest?: string,
  ) => {
    if (specialRequest || product.specialRequest) {
      product.specialRequest = specialRequest;
    }

    // Split each selected portion into separate basket line
    if (
      getProductPortions(product).length > 1 &&
      !portionsHaveChoices(product)
    ) {
      const splitProductByPortions: AztecProduct[] = [];
      Object.values(product.portions).forEach((p) => {
        if (p.quantity && p.quantity > 0) {
          const updatedProduct = { ...product };
          const newPortion: AztecPortions = {};
          newPortion[p.id] = p;
          updatedProduct.portions = newPortion;
          updatedProduct.basketId = uuidv4();
          splitProductByPortions.push(updatedProduct);
        }
      });
      setBasket((prev) => [...prev, ...splitProductByPortions]);
    } else if (product.basketId) {
      // Check for a basketId to update product or to add new line
      const updatedBasket = basket.map((basketItem) =>
        basketItem.basketId === product.basketId ? product : basketItem,
      );
      setBasket(updatedBasket);
    } else {
      product.basketId = uuidv4();
      setBasket((prev) => [...prev, product]);
    }
  };

  const updateBasketQuantity = (
    idx: number,
    quantity: number,
    portionId: number,
  ) => {
    const updatedBasket = [...basket];
    updatedBasket[idx].portions[portionId].quantity = quantity;
    setBasket(updatedBasket);
  };

  const removeBasketItem = (idx: number) => {
    setBasket((prev) => prev.filter((_, i) => i !== idx));
  };

  const clearBasket = () => {
    setBasket([]);
  };

  const getBasketQuantity = (
    productId: number,
    displayRecordId: number,
  ): number =>
    basket
      .filter(
        (line) =>
          line.id === productId && line.displayRecordId === displayRecordId,
      )
      .reduce((sum, c) => {
        const selectedPortion = getSelectedPortion(c);
        return selectedPortion?.quantity ? sum + selectedPortion.quantity : sum;
      }, 0);

  useEffect(() => {
    setBasketHasChanged(true);
    if (basket.length === 0) {
      setIsBasketCollapsed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basket]);

  return (
    <BasketContext.Provider
      value={{
        initialised: true,
        addProductToBasket,
        basket,
        basketHasChanged,
        clearBasket,
        getBasketQuantity,
        isBasketCollapsed,
        removeBasketItem,
        setBasketHasChanged,
        setIsBasketCollapsed,
        updateBasketQuantity,
      }}
    >
      {children}
    </BasketContext.Provider>
  );
};
