import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormatDateOptions, useIntl } from 'react-intl';
import { useConfig } from 'contexts/ConfigContext';
import { ConfirmBasketChangesOnTimeslotChangeModal } from './Modals';
import { TimeslotPhrase } from 'common/components/TimeslotPhrase';
import { useSalesAreas, useServices, useVenues } from 'contexts/VenueContext';
import { useBasket } from 'contexts/BasketContext';
import { useTimeslots } from 'contexts/TimeslotContext/TimeslotContext';
import { useVouchers } from 'contexts/VoucherContext';
import { useCheckout } from 'contexts/CheckoutContext';

export const TimeslotChanger: React.FC = () => {
  const intl = useIntl();
  const { timeslots24hFormat } = useConfig();

  const { selectedVenue } = useVenues();
  const { selectedService } = useServices();
  const { selectedSalesArea } = useSalesAreas();
  const { basket, clearBasket } = useBasket();
  const { clearCheckout } = useCheckout();
  const { redeemedVouchers } = useVouchers();
  const { handleFetchTimeslots, setShowTimeslotsModal, selectedTimeslot } =
    useTimeslots();

  const { voidAllVouchers } = useVouchers();

  const timezone = selectedVenue?.timezone;

  const formatOptions: FormatDateOptions = {
    hourCycle: timeslots24hFormat === true ? 'h23' : 'h12',
    timeZone: timezone,
    timeZoneName: 'short',
  };

  const isBasketEmpty = basket.length === 0;

  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const showModal = () => {
    if (isBasketEmpty) {
      confirmTimeslotChange();
    } else {
      setShowConfirmationModal(true);
    }
  };

  const confirmTimeslotChange = () => {
    if (redeemedVouchers.length > 0) {
      voidAllVouchers();
    }
    clearBasket();
    clearCheckout();
    handleFetchTimeslots(selectedVenue, selectedService, selectedSalesArea);
    setShowConfirmationModal(false);
    setShowTimeslotsModal(true);
  };

  const cancelTimeslotChange = () => {
    setShowConfirmationModal(false);
  };

  if (!selectedTimeslot) {
    return null;
  }

  const timeslotPhrase = TimeslotPhrase({
    selectedService,
  });

  return (
    <>
      {`${timeslotPhrase}: ${intl.formatTime(
        selectedTimeslot.time,
        formatOptions,
      )}`}
      <Button
        bsStyle="link"
        className="btn-change"
        onClick={() => showModal()}
        data-testid="change-timeslot-button"
      >
        CHANGE?
      </Button>
      <ConfirmBasketChangesOnTimeslotChangeModal
        show={showConfirmationModal}
        proceed={confirmTimeslotChange}
        dismiss={cancelTimeslotChange}
      />
    </>
  );
};
