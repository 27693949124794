import { AztecProduct, UpsellGroup } from 'types/models';
import { AndedUpsellItem } from 'menu/components/MenuModal/ModalBody';
import { updateAndedUpsellQuantity } from 'menu/utils';

interface AndedUpsellGroupProps {
  aztecProduct: AztecProduct;
  updateAztecProduct: (product: AztecProduct) => void;
  upsellGroup: UpsellGroup;
  andedUpsells: AztecProduct[];
}

export const AndedUpsellGroup: React.FC<AndedUpsellGroupProps> = ({
  aztecProduct,
  updateAztecProduct,
  upsellGroup,
  andedUpsells,
}) => {
  const handleUpsellItemClick = (upsellIndex: number) => {
    const updatedProduct = updateAndedUpsellQuantity(
      aztecProduct,
      upsellGroup,
      upsellIndex,
    );
    updateAztecProduct(updatedProduct);
  };
  return (
    <div className="choice-group-section" data-testid="anded-item-choice-group">
      <h2>{upsellGroup.label}</h2>
      {upsellGroup.description ? (
        <div className="choice-group-description text-muted">
          {upsellGroup.description}
        </div>
      ) : null}
      {andedUpsells.map((upsell, index) => (
        <AndedUpsellItem
          andedUpsell={upsell}
          upsellIndex={index}
          handleUpsellItemClick={handleUpsellItemClick}
          key={`${upsell.displayRecordId}-${upsell.id}`}
        />
      ))}
    </div>
  );
};
