/* eslint-disable no-use-before-define */
import * as actionTypes from './actionTypes';

export const setTipAmount = (payload) => ({
  type: actionTypes.SET_TIP_AMOUNT,
  payload,
});

export const setAdditionalInfoError999 = (message) => ({
  type: actionTypes.SET_ADDITIONAL_INFO_ERROR_DIALOG,
  message,
});

export const setSelectedTipPreset = (preset) => ({
  type: actionTypes.SET_SELECTED_TIP_PRESET,
  preset,
});
