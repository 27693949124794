import { MdCheckCircle } from 'react-icons/md';
import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { usePhrases } from 'contexts/ConfigContext';
import { VerifiedGiftCard } from 'types/models/GiftCard';
import { getMaskedCardNumber } from 'utils';

interface GiftCardItemProps {
  giftCard: VerifiedGiftCard;
}

export const GiftCardItem: React.FC<GiftCardItemProps> = ({ giftCard }) => {
  const {
    giftCard: { giftCardPhrase },
  } = usePhrases();
  return (
    <div className="gc-tile">
      <div>
        <div className="gc-tile-card-name">
          {`${giftCardPhrase} ${getMaskedCardNumber(giftCard.giftCardNumber)}`}
        </div>
        <div className="gc-tile-card-balance text-muted ">
          Balance: <FormattedCurrency value={giftCard.balance} />
        </div>
      </div>
      <MdCheckCircle className="gc-check-icon" />
    </div>
  );
};
