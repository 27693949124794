import { useBasket } from 'contexts/BasketContext';
import { FaShoppingBasket } from 'react-icons/fa';
import { MenuGroupProduct } from 'types/models';

interface ProductQuantityIndicatorProps {
  product: MenuGroupProduct;
}

export const ProductQuantityIndicator: React.FC<
  ProductQuantityIndicatorProps
> = ({ product }) => {
  const { getBasketQuantity } = useBasket();
  const totalSelected = getBasketQuantity(
    product.productId,
    product.displayRecordId,
  );

  return totalSelected > 0 ? (
    <div className="product-quantity">
      <div className="selected-quantity">
        <div>
          <FaShoppingBasket /> x {totalSelected}
        </div>
      </div>
    </div>
  ) : null;
};
