import { NavLink } from 'react-router-dom';
import { useConfig } from 'contexts/ConfigContext';

export const NavigationLinks: React.FC = () => {
  const { googleMapsApiKey } = useConfig();

  return (
    <>
      {googleMapsApiKey ? (
        <div className="row venue-view">
          <div className="text-center col-md-4 col-md-offset-4">
            <NavLink
              to="/venues/list"
              className="btn btn-default"
              activeClassName="venue-active"
              data-testid="panel-button-venue"
            >
              List
            </NavLink>
            <NavLink
              to="/venues/map"
              className="btn btn-default"
              activeClassName="venue-active"
              data-testid="panel-button-map"
            >
              Map
            </NavLink>
          </div>
        </div>
      ) : null}
    </>
  );
};
