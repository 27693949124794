import user from '../user';
import { LOG_OUT } from '../user/actionTypes';

export const tokenCheck = (store) => (next) => (action) => {
  if (action.type === LOG_OUT) {
    return next(action);
  }

  const userState = store.getState().user;

  if (userState.get('token')) {
    const tokenArray = userState.get('token').split('.');
    const tokenPayload = JSON.parse(atob(tokenArray[1]));
    const expiry = new Date(tokenPayload.exp).getTime();
 
    if (expiry > new Date().getTime()) {
      return store.dispatch(user.actions.logOut(true));
    }
  }

  return next(action);
};
