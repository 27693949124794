import { Loader } from 'components/Loader';
import { useMenuGroups } from 'contexts/MenuContext/MenuGroupContext';
import { MenuGroupAndProductDisplay } from 'menu/components/MenuGroupAndProductDisplay';
import { TopLevelMenuNav } from 'menu/components/MenuNav';
import { useParams } from 'react-router';
import { LoadingError } from 'menu/components/Alerts';

interface ParamsProps {
  venueId: string;
  serviceName: string;
  menuId: string;
}

export const MenuDisplay: React.FC = () => {
  const { loadingMenuGroups, menuGroups } = useMenuGroups();
  const params = useParams<ParamsProps>();

  return (
    <>
      {/* This will already be loaded at this point, 
      so we don't want to include it in the loading state so as to keep the nav shown consistently*/}
      <TopLevelMenuNav
        selectedMenuId={params.menuId}
        serviceName={params.serviceName}
        venueId={params.venueId}
      />
      {loadingMenuGroups ? (
        <Loader text="Loading Menu" />
      ) : (
        <>
          {menuGroups.length > 0 ? (
            <MenuGroupAndProductDisplay menuGroups={menuGroups} />
          ) : (
            <LoadingError />
          )}
        </>
      )}
    </>
  );
};
