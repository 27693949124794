import { useEffect } from 'react';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { Loader } from 'components/Loader';
import { usePayMyBill } from 'contexts/PayMyBillContext/PayMyBillContext';
import { useSalesAreas, useServices, useVenues } from 'contexts/VenueContext';
import { VenueInfoLayout } from 'layouts';
import { useHistory, useLocation, useParams } from 'react-router';
import { useEffectSkipFirst } from 'hooks/useEffectSkipFirst';
import { CombinedVenue, ServiceType } from 'types/models';
import { useTables } from 'contexts/TableContext';
import {
  ReactSelectGroup,
  ReactSelectOption,
} from 'types/react-select-virtualized';
import { BillPageBody } from 'PayMyBill/bill/BillPageBody';

interface ParamsProps {
  venueId: string;
  tableNumber?: string;
  salesAreaId?: string;
  accountId?: string;
  serviceName?: string;
}

export const BillPage: React.FC = () => {
  const { logoLinkToHomepage, homepage } = useConfig();
  const { table: tablePhrase } = usePhrases();
  const params = useParams<ParamsProps>();
  const { pathname } = useLocation();

  const history = useHistory();
  const {
    selectedVenue,
    fetchVenues,
    fetchVenueHome,
    fetchVenueSummary,
    venues,
    venueHomeData,
    isLoadingVenues,
    setSelectedVenue,
  } = useVenues();
  const { selectedService, setSelectedService } = useServices();
  const { selectedSalesArea, setSelectedSalesArea } = useSalesAreas();
  const {
    selectedTable,
    fetchTables,
    isFetchingTables,
    tables,
    hasGroups,
    setSelectedTable,
  } = useTables();

  const {
    fetchBill,
    isFetchingBill,
    openAccount,
    fetchOpenAccounts,
    bill,
    isFetchingAccounts,
  } = usePayMyBill();

  const linkedVenueId = parseInt(params.venueId, 10);
  const salesAreaFromRoute = parseInt(params.salesAreaId ?? '-1');
  const tableIdFromRoute = parseInt(params.tableNumber ?? '-1');
  const accountIdFromRoute = parseInt(params.accountId ?? '-1');

  const hasDeeplinkParams = Boolean(
    linkedVenueId &&
      linkedVenueId !== -1 &&
      salesAreaFromRoute &&
      salesAreaFromRoute !== -1,
  );

  const isLoading =
    isFetchingBill || isFetchingAccounts || isLoadingVenues || isFetchingTables;

  const findVenue = (linkedVenueId: number) => {
    return venues.find((x: { id: number }) => x.id === linkedVenueId);
  };

  const checkForServiceAndSalesArea = (foundVenue: CombinedVenue) => {
    // Check that there is a param for service and sales area
    // If both service and sales area are valid then set state else redirect to venue home page
    // This is only hit after venues have been fetched and a valid venue found
    if (salesAreaFromRoute !== -1) {
      const supportsService = foundVenue.services.includes(
        ServiceType.PayMyBill,
      );
      const foundSalesArea = foundVenue.salesArea.find(
        (sa) => sa.id === salesAreaFromRoute,
      );
      if (
        supportsService &&
        foundSalesArea?.services.includes(ServiceType.PayMyBill)
      ) {
        setSelectedService(ServiceType.PayMyBill);
        setSelectedSalesArea(foundSalesArea);
      } else {
        history.push(`/venue/${foundVenue.id}`);
      }
    }
  };

  const redirectToHomePage = () => {
    logoLinkToHomepage
      ? window.open(homepage, '_self')
      : history.push('/venues/');
  };

  // Deeplink step 1 - get venues
  useEffect(() => {
    // If the user deeplinks using the regular url structure or refreshes the page we want to put them back tot he venue page
    if (!selectedVenue && pathname.includes('pay-my-bill')) {
      history.push(
        `/venue/${linkedVenueId}/service/${ServiceType.PayMyBill}/sales-areas`,
      );
    }
    if (!selectedVenue && venues.length === 0) {
      fetchVenues();
      fetchVenueSummary(undefined, linkedVenueId);
      fetchVenueHome(linkedVenueId);
    } else if (selectedVenue && venueHomeData?.venueId !== selectedVenue.id) {
      fetchVenueHome(linkedVenueId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Deeplink step 2 - handle found venue and validate service/sales area
  useEffectSkipFirst(() => {
    if (!isLoadingVenues && venues.length > 0) {
      const foundVenue = findVenue(linkedVenueId);
      // Check for found venue and save if it is new
      if (!foundVenue) {
        redirectToHomePage();
        return;
      } else if (foundVenue && foundVenue.id != selectedVenue?.id) {
        setSelectedVenue(foundVenue);
      }

      checkForServiceAndSalesArea(foundVenue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingVenues, venues]);

  // Deeplink step 3 - fetch open accounts if table provided or fetch bill if account provided.
  // Run this after venue, service and sales area are all valid
  useEffect(() => {
    if (
      hasDeeplinkParams &&
      selectedVenue &&
      selectedSalesArea &&
      selectedService
    ) {
      if (tableIdFromRoute !== -1) {
        fetchOpenAccounts(
          selectedVenue?.id,
          selectedSalesArea.id,
          tableIdFromRoute,
        );
      } else if (accountIdFromRoute !== -1) {
        fetchBill(selectedVenue.id, selectedSalesArea.id, accountIdFromRoute);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSalesArea, selectedService, selectedVenue]);

  // Deeplink step 4 - if table was provided in step 3 this will fetch the bill if resolved successfully
  // This also covers the regular path to the page and will get the bill on first load
  useEffect(() => {
    if (selectedVenue && selectedSalesArea && openAccount) {
      fetchBill(
        selectedVenue?.id,
        selectedSalesArea?.id,
        openAccount.AccountId,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAccount]);

  // Deeplink step 5 - after the bill has been fetched get the tables
  useEffect(() => {
    if (hasDeeplinkParams && bill && !selectedTable) {
      fetchTables(linkedVenueId, salesAreaFromRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bill, selectedTable]);

  // Deeplink step 6 - after tables have been fetched find the table from the bill in the options and set as selected
  // We need the friendly name instead of showing the table number provided
  useEffect(() => {
    if (hasDeeplinkParams && bill && !selectedTable) {
      const tableOptions = hasGroups
        ? (tables as ReactSelectGroup[]).flatMap((t) => t.options)
        : (tables as ReactSelectOption[]);

      const foundTable = tableOptions.find(
        (to) => to.number === bill.tableNumber,
      );

      if (foundTable) {
        setSelectedTable(foundTable);
      } else {
        // This is the fallback if the table cannot be found
        // We use the bill as the source of truth for the table number
        const tableFromBill: ReactSelectOption = {
          label: `${tablePhrase} ${bill.tableNumber}`,
          number: bill.tableNumber,
          value: bill.tableNumber,
        };
        setSelectedTable(tableFromBill);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tables]);

  if (isLoading) {
    return (
      <div className="container">
        <div className="panel panel-default">
          <Loader />
        </div>
      </div>
    );
  }

  return selectedVenue ? (
    <VenueInfoLayout
      selectedVenue={selectedVenue}
      classNamePrefix="review-bill"
    >
      <BillPageBody
        isDeeplink={hasDeeplinkParams}
        selectedVenue={selectedVenue}
      />
    </VenueInfoLayout>
  ) : null;
};
