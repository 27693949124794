import { Dialog } from 'components/Dialog';
import { Voucher } from 'types/models/Vouchers';

interface VoucherModalProps {
  removeVoucher: (voucher: Voucher) => void;
  show: boolean;
  cancelVoucherRemoval: () => void;
  voucher: Voucher;
}

export const ConfirmVoucherRemovalModal: React.FC<VoucherModalProps> = ({
  removeVoucher,
  show,
  cancelVoucherRemoval,
  voucher,
}) => {
  const voucherName =
    voucher.metaData && voucher.metaData.friendlyName
      ? voucher.metaData.friendlyName
      : 'Voucher';

  return (
    <Dialog
      isOpen={show}
      title="Remove Voucher"
      description={`Would you like to remove the voucher ${voucherName}?`}
      accept={{
        action: () => removeVoucher(voucher),
        text: 'Remove Voucher',
        btnType: 'destructive',
      }}
      reject={{
        action: () => cancelVoucherRemoval(),
        text: 'Cancel',
      }}
    />
  );
};
