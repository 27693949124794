import * as actionTypes from './actionTypes';
import checkout from 'checkout';
import core from 'core';
import {
  getCookies,
  getLogoutCookies,
  isSsoSupported,
  sanitizeCookiePath,
  supportsJavaScriptCookies,
} from 'sso/model';
import { LOGIN_METHOD_ANONYMOUS, LOGIN_METHOD_USER } from 'common';
import { receiveOrders } from 'order-history/actions';
import { getUrlPart } from 'utils';

export const receiveUser =
  (headers, user, userToken) => (dispatch, getState) => {
    dispatch({
      type: actionTypes.RECEIVE_USER,
      user,
      // HACK: token should be renamed to userToken!
      token: userToken,
    });

    const state = getState();

    // #289547 ensure we persist http set-cookies for SSO
    if (isSsoSupported(state)) {
      const sso = window?.config?.sso; // state.core.getIn(['config', 'sso']);

      if (sso.source === 'cookies') {
        // https://github.com/axios/axios/issues/953 (maxRedirects?)
        // TODO: Is this the correct cookie name?
        const ssoToken = headers['set-cookie'];

        if (ssoToken) {
          getCookies().set(
            sso.name,
            ssoToken,
            sso.domain,
            // TODO: This *might* be overcomplicating the story.
            //       If issues come after serialization, this will be why.
            { path: sanitizeCookiePath(sso.path) },
          );
        }
      }
    }
  };

export const registerFromCheckout = (status) => ({
  type: actionTypes.REGISTERING_FROM_CHECKOUT,
  status,
});

export const initLogin = () => ({
  type: actionTypes.INIT_LOG_IN,
});

export const initGuestCheckout = () => ({
  type: actionTypes.INIT_GUEST_CHECKOUT,
});

export const resetGuestCheckout = () => ({
  type: actionTypes.RESET_GUEST_CHECKOUT,
});

export const storeGuestEmail = (email) => ({
  type: actionTypes.RECEIVE_GUEST_EMAIL,
  email,
});

export const setLoginMethod = (method) => ({
  type: actionTypes.RECEIVE_LOGIN_METHOD,
  method,
});

export const requestLogIn = (user) => ({
  type: actionTypes.REQUEST_LOG_IN,
  method: 'postLoginEmail',
  user,
});

export const receiveLogIn = () => ({
  type: actionTypes.RECEIVE_LOG_IN,
});

export const guestCheckout = (checkBasketCallback) => (dispatch) => {
  dispatch(initGuestCheckout());
  dispatch(setLoginMethod(LOGIN_METHOD_ANONYMOUS));
  dispatch(resetGuestCheckout());
  checkBasketCallback(true);
};

const processLogin =
  (response, prevRoute, history, isCheckingOutCallback, checkBasketCallback) =>
  (dispatch) => {
    if (response.data.user) {
      // To keep login button disabled while checkBasket is being called
      if (isCheckingOutCallback || prevRoute === '/checkout') {
        isCheckingOutCallback(true);
      }
      dispatch(
        receiveUser(
          response.headers,
          response.data.user,
          response.data['X-Auth-UserToken'],
        ),
      );

      dispatch(hideOTPModal());

      // choose where to go
      if (prevRoute === '/checkout') {
        // if we were on the way to checkout, then check basket and go there
        checkBasketCallback(true);
      } else if (prevRoute !== undefined) {
        // if we had a previous route, then redirect to previous route
        history.push(prevRoute);
      } else {
        // redirect to venues
        history.push('/');
      }
    } else {
      dispatch(core.actions.addNotification(response.data.detail, 'danger'));
    }
  };

export const logIn =
  (formData, prevRoute, history, isCheckingOutCallback, checkBasketCallback) =>
  (dispatch) => {
    dispatch(initLogin());
    dispatch(core.actions.removeNotification());
    dispatch(requestLogIn(formData))
      .then((response) => {
        dispatch(setLoginMethod(LOGIN_METHOD_USER));
        dispatch(receiveLogIn());

        if (response.data.challenge) {
          // challenge received, lets check thew OTP
          dispatch(
            displayChallenge(
              formData.email,
              response.data.challenge.channel,
              response.data.challenge.channel === 'email'
                ? response.data.challenge.email
                : response.data.challenge.mobilePhoneEndsWith,
              response.data.challenge.expirationTimeUtc,
              response.data.challenge.id,
            ),
          );
        } else {
          dispatch(
            processLogin(
              response,
              prevRoute,
              history,
              isCheckingOutCallback,
              checkBasketCallback,
            ),
          );
          dispatch(setOtpEnabled(false));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

const initOTP = (email, channel, account, expirationTimeUtc, id) => ({
  type: actionTypes.INIT_OTP,
  email,
  channel,
  account,
  expirationTimeUtc,
  id,
});

export const setOtpEnabled = (otpEnabled) => ({
  type: actionTypes.OTP_ENABLED,
  otpEnabled,
});

export const displayChallenge =
  (email, channel, account, expirationTimeUtc, id) => (dispatch) => {
    dispatch(initOTP(email, channel, account, expirationTimeUtc, id));
    dispatch(showOTPModal());
  };

export const requestLogInWithOTP = (challengeId, otp, email) => ({
  type: actionTypes.REQUEST_LOG_IN_WITH_OTP,
  method: 'loginWithOTP',
  challengeID: challengeId,
  user: { email: email },
  otp,
});

export const verifyChallenge =
  (otpCode, prevRoute, history, isCheckingOutCallback, checkBasketCallback) =>
  (dispatch, getState) => {
    const email = getState().user.get('otpEmail');
    const challengeId = getState().user.get('otpChallengeId');

    dispatch(requestLogInWithOTP(challengeId, otpCode, email)).then(
      (response) => {
        dispatch(setOtpEnabled(true));
        dispatch(
          processLogin(
            response,
            prevRoute,
            history,
            isCheckingOutCallback,
            checkBasketCallback,
          ),
        );
      },
    );
  };

export const resendOTP =
  (prevRoute, user, history, isCheckingOutCallback, checkBasketCallback) =>
  (dispatch) => {
    dispatch(
      logIn(
        user,
        prevRoute,
        history,
        isCheckingOutCallback,
        checkBasketCallback,
      ),
    );
    dispatch(core.actions.addNotification('One-time code re-sent', 'success'));
  };

export const hideOTPModal = () => ({
  type: actionTypes.HIDE_OTP_MODAL,
});

export const showOTPModal = () => ({
  type: actionTypes.SHOW_OTP_MODAL,
});

export const updateUserData = (type, value) => ({
  type: actionTypes.UPDATE_USER_DATA,
  data: type,
  value,
});

export const clearLogoutCookies = (logoutCookies) => {
  logoutCookies.forEach((cookie) => {
    const { name, domain } = cookie;

    // TODO: Should we try catch? How to check if visible?
    // TODO: Is this a Promise?
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${domain}`;
  });
};

export const logOut =
  (retainBasket, clearBasketCallback, clearCheckoutCallback) =>
  (dispatch, getState) => {
    dispatch({
      type: actionTypes.LOG_OUT,
    });

    dispatch(checkout.actions.setTipAmount(0));
    dispatch(checkout.actions.setSelectedTipPreset(0));
    dispatch(setOtpEnabled(false));

    if (!retainBasket) {
      clearBasketCallback();
      clearCheckoutCallback();
    }

    dispatch(receiveOrders([]));

    if (supportsJavaScriptCookies()) {
      // #289549 clear any references to the logout cookies defined by the api
      clearLogoutCookies(getLogoutCookies(getState()));
    }
  };

export const requestEdit = (user) => ({
  type: actionTypes.REQUEST_EDIT,
  method: 'putMe',
  user,
});

export const receiveEdit = () => ({
  type: actionTypes.RECEIVE_EDIT,
});

export const edit = (user) => (dispatch) => {
  dispatch(requestEdit(user))
    .then((response) => {
      if (response?.data?.user) {
        dispatch(
          receiveUser(
            response.headers,
            response.data.user,
            response.data['X-Auth-UserToken'],
          ),
        );
        dispatch(
          core.actions.addNotification(
            'Your details have been updated successfully.',
            'success',
          ),
        );
      } else {
        const errorMessage =
          response?.data?.detail ??
          'Something went wrong updating your details. Please try again.';
        dispatch(core.actions.addNotification(errorMessage, 'danger'));
      }
    })
    .catch((e) => {
      console.log(e);
      dispatch(
        core.actions.addNotification(
          'Something went wrong updating your details. Please try again.',
          'danger',
        ),
      );
    })
    .finally(() => {
      dispatch(receiveEdit());
    });
};

export const initRegister = () => ({
  type: actionTypes.INIT_REGISTER,
});

export const requestRegister = (user) => ({
  type: actionTypes.REQUEST_REGISTER,
  method: 'postRegisterUser',
  user,
});

export const receiveRegister = () => ({
  type: actionTypes.RECEIVE_REGISTER,
});

const makeMissingFieldsVerbose = (response) => {
  if (response.code === '-961') {
    const allErrors = response.missingRequiredFields.reduce(
      (acc, field) => `${acc + field.Key}: ${field.Description}. `,
      ' ',
    );

    return response.detail + allErrors;
  }
  return response.detail;
};

export const register =
  (
    user,
    history,
    selectedVenueId,
    selectedService,
    isRegisteringFromCheckout,
    checkBasketCallback,
  ) =>
  (dispatch) => {
    dispatch(initRegister());
    dispatch(requestRegister(user))
      .then((response) => {
        dispatch(setLoginMethod(LOGIN_METHOD_USER));
        dispatch(receiveRegister());
        if (response.data.user) {
          // Prevents redirect until checkBasket call is made
          if (isRegisteringFromCheckout) {
            // This is setting the isFetchingBasket state
            isRegisteringFromCheckout(true);
          }
          dispatch(
            receiveUser(
              response.headers,
              response.data.user,
              response.data['X-Auth-UserToken'],
            ),
          );

          const serviceUrlPart = getUrlPart(selectedService);
          const menuPath =
            selectedVenueId && selectedService !== -1
              ? `/venue/${selectedVenueId}/${serviceUrlPart}/menus/`
              : '/venues';

          if (isRegisteringFromCheckout) {
            checkBasketCallback(true);
            dispatch(registerFromCheckout(false));
          } else if (
            window.config
              .redirectNewUserFromOrderHistory /* getState().core.getIn(['config', 'redirectNewUserFromOrderHistory'])*/
          ) {
            if (selectedVenueId) {
              history.push({
                pathname: menuPath,
                state: { from: '/user/register' },
              });
            } else {
              history.push({
                pathname: '/',
                state: { from: '/user/register' },
              });
            }
          }
        } else if (typeof response.data.code !== 'undefined') {
          dispatch(
            core.actions.addNotification(
              makeMissingFieldsVerbose(response.data),
              'danger',
            ),
          );
        } else {
          dispatch(
            core.actions.addNotification(
              'Registration is not completed. Please check all fields.',
            ),
            'danger',
          );
        }
      })
      .catch(() => {
        // console.error(error);
      });
  };

export const requestChangePassword = (
  oldPassword,
  newPassword,
  newPasswordConfirm,
) => ({
  type: actionTypes.REQUEST_CHANGE_PASSWORD,
  method: 'changePassword',
  oldPassword,
  newPassword,
  newPasswordConfirm,
});

export const receiveChangePassword = () => ({
  type: actionTypes.RECEIVE_CHANGE_PASSWORD,
});

export const changePassword = (passwords, history) => (dispatch) => {
  const oldPassword = passwords.get('old-password');
  const newPassword = passwords.get('new-password');
  const newPasswordConfirm = passwords.get('confirm-password');

  dispatch(requestChangePassword(oldPassword, newPassword, newPasswordConfirm))
    .then((response) => {
      dispatch(receiveChangePassword());

      if (response.data.code && response.data.code !== 200) {
        dispatch(core.actions.addNotification(response.data.detail, 'danger'));
      } else {
        history.push('/user/order');
        dispatch(
          core.actions.addNotification(
            'Your password has been updated.',
            'success',
          ),
        );
      }
    })
    .catch(() => {
      // console.error(error);
    });
};

export const requestResetPasswordByToken = (newPassword, token) => ({
  type: actionTypes.REQUEST_RESET_PASSWORD_BY_TOKEN,
  method: 'resetPasswordByToken',
  token,
  newPassword,
});

export const receiveResetPasswordByToken = () => ({
  type: actionTypes.RECEIVE_RESET_PASSWORD_BY_TOKEN,
});

export const resetPasswordByToken =
  (formData, token, history) => (dispatch) => {
    const newPassword = formData.get('password');
    dispatch(requestResetPasswordByToken(newPassword, token))
      .then((response) => {
        dispatch(receiveResetPasswordByToken());

        if (response.data.response === 'OK') {
          history.push({ pathname: '/user/login', state: { from: '/venues' } });
          dispatch(
            core.actions.addNotification(
              'Password reset successfully! Please click here to login.',
              'success',
            ),
          );
        } else {
          dispatch(
            core.actions.addNotification(response.data.detail, 'danger'),
          );
        }
      })
      .catch(() => {
        // console.error(error);
      });
  };

export const requestResetPassword = (email) => ({
  type: actionTypes.REQUEST_RESET_PASSWORD,
  method: 'resetPassword',
  email,
});

export const receiveResetPassword = () => ({
  type: actionTypes.RECEIVE_RESET_PASSWORD,
});

export const resetPassword = (formData) => (dispatch) => {
  const email = formData.email;
  dispatch(requestResetPassword(email))
    .then((response) => {
      dispatch(receiveResetPassword());

      if (response.data.code && response.data.code !== 200) {
        dispatch(core.actions.addNotification(response.data.detail, 'danger'));
      } else {
        dispatch(
          core.actions.addNotification(
            `An email containing a new password has been sent to ${email}.`,
            'success',
          ),
        );
      }
    })
    .catch(() => {
      // console.error(error);
    });
};

export const changeDOB = (part, value) => ({
  type: actionTypes.CHANGE_DOB,
  part,
  value,
});
