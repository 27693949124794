import { ServiceType, ServiceUrlName } from 'types/models';

export const getUrlPart = (
  serviceId: ServiceType,
): ServiceUrlName | undefined => {
  switch (serviceId) {
    case ServiceType.OrderAndPay:
      return 'order-and-pay';
    case ServiceType.ClickAndCollect:
      return 'click-and-collect';
    case ServiceType.DeliveryToLocation:
      return 'delivery-to-location';
    case ServiceType.PayMyBill:
      return 'pay-my-bill';
    default:
      return;
  }
};

export const getServiceFromUrl = (serviceId: ServiceUrlName): ServiceType => {
  switch (serviceId) {
    case 'order-and-pay':
      return ServiceType.OrderAndPay;
    case 'click-and-collect':
      return ServiceType.ClickAndCollect;
    case 'delivery-to-location':
      return ServiceType.DeliveryToLocation;
    case 'pay-my-bill':
      return ServiceType.PayMyBill;
    default:
      return 0;
  }
};
