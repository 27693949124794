import { Dialog } from 'components/Dialog';
import { usePhrases } from 'contexts/ConfigContext';
import { useGiftCard } from 'contexts/GiftCardContext';

export const GiftCardOverchargeModal: React.FC = () => {
  const {
    giftCard: { giftCardPhrase },
  } = usePhrases();
  const { showGiftCardOverchargeModal, setShowGiftCardOverchargeModal } =
    useGiftCard();
  return (
    <Dialog
      isOpen={showGiftCardOverchargeModal}
      title={`${giftCardPhrase} in Basket`}
      description={`It looks like you have a ${giftCardPhrase} in your basket that is higher than the total cost of your items`}
      accept={{
        action: () => setShowGiftCardOverchargeModal(false),
        text: 'Review Basket',
        btnType: 'destructive',
      }}
    />
  );
};
