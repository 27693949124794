import { OrderBasketLine, OrderPromotionLine } from 'types/models/Orders';

export const isPromotion = (
  basketItem: OrderBasketLine | OrderPromotionLine,
): basketItem is OrderPromotionLine => {
  return (basketItem as OrderPromotionLine).promotionId !== undefined;
};

export const isBasketLine = (
  basketItem: OrderBasketLine | OrderPromotionLine,
): basketItem is OrderBasketLine => {
  return (basketItem as OrderBasketLine).ingredientId !== undefined;
};
