import { KeyboardEvent } from 'react';
import { Button } from 'react-bootstrap';

export const handleReturnKeyPress = (
  event: KeyboardEvent<Button> | KeyboardEvent<HTMLDivElement>,
  callback: () => void,
): void => {
  if (event.key === 'Enter') {
    callback();
  }
};