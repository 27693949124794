export const formatCurrencyInput = (inputValue: string, decimalLimit: number, fallbackValue: number): number => {
  // deafult fallback value if input is invalid and doesn't pass checks
  let fallback: number = fallbackValue;

  // if input value is invalid and has two trailing periods then remove the last period
  if (isNaN(Number(inputValue)) && inputValue.split(".").length === 3 && inputValue.endsWith('.') && inputValue !== '..') {
    fallback = Number(inputValue.substring(0, inputValue.length - 1));
  }

  // remove the extra decimal points if present
  const removeDecimals = (): number => {
    let returnValue: string = inputValue;

    if (!isNaN(Number(inputValue)) && inputValue.split(".").length === 2 && inputValue.split(".")[1].length === decimalLimit + 1) {
      returnValue = inputValue.substring(0, inputValue.length - 1);
    }

    return Number(returnValue);
  };

  return (isNaN(Number(inputValue)) ? fallback : removeDecimals());
}