import cs from 'classnames';
import { useConfig } from 'contexts/ConfigContext';
import { FaLocationArrow } from 'react-icons/fa';

interface FormattedDistanceProps {
  additionalClassName?: string;
  distance: number;
  showBrackets?: boolean;
  showIcon: boolean;
}

export const FormattedDistance: React.FC<FormattedDistanceProps> = ({
  additionalClassName,
  distance,
  showBrackets = false,
  showIcon,
}) => {
  const { distanceFormatMiles } = useConfig();

  const calcDistance = (distance: number) => {
    if (distanceFormatMiles === true) {
      return distance >= 1000
        ? `${Math.round(distance / 100 / 1.609) / 10} mi`
        : `${Math.round(distance / 1.094)} yd`;
    }

    return distance >= 1000
      ? `${Math.round(distance / 100) / 10} km`
      : `${distance} m`;
  };

  const distanceClasses = cs('venue-distance-indicator', additionalClassName);

  if (distance === -1) return null;

  return (
    <span className={distanceClasses}>
      {showIcon === true ? <FaLocationArrow /> : null}
      {showBrackets === true ? ` (` : null}
      {calcDistance(distance)}
      {showBrackets === true ? `)` : null}
    </span>
  );
};
