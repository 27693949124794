import { useEffect } from 'react';

import { Redirect } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import {
  GuestLoginButton,
  LoginDivider,
  LoginForm,
  RegistrationButton,
  OTPModal,
} from 'login/components';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { useDispatch, useSelector } from 'react-redux';
import { removeNotification } from 'core/actions';
import { hideOTPModal, logIn, resendOTP, verifyChallenge } from 'user/actions';
import { selectIsLoggedIn } from 'user/selectors';
import { WLWOLocationState } from 'types/models/WLWOLocationState';
import { LoginContextualLoader } from './components/ContextualLoader';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useCheckout } from 'contexts/CheckoutContext';
import { useBasket } from 'contexts/BasketContext';

interface FormProps {
  email: string;
  password: string;
}

export const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const { acceptsPayment, accountDeletionOnly } = useConfig();
  const methods = useForm<FormProps>({ mode: 'onBlur' && 'onSubmit' });

  const {
    login: { loginButtonText },
  } = usePhrases();

  const { isFetchingBasket } = useCheckout();

  useEffect(() => {
    dispatch(removeNotification());
    dispatch(hideOTPModal());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { guestCheckoutEnabled } = useConfig();

  const history = useHistory();

  const { checkBasket, setIsFetchingBasket } = useCheckout();
  const { basket } = useBasket();

  const location = useLocation<WLWOLocationState>();
  const {
    from: { pathname },
  } = location.state || { from: { pathname: '/user/order' } };

  const isCheckingOut = pathname === '/checkout';
  // This will be removed when the user is moved to context from redux
  const isCheckingOutCallback = isCheckingOut ? setIsFetchingBasket : undefined;
  const prevRoute = isCheckingOutCallback ? '/checkout' : pathname;

  const handleLogIn: SubmitHandler<FormProps> = (values) => {
    dispatch(
      logIn(values, prevRoute, history, isCheckingOutCallback, checkBasket),
    );
  };

  const handleOtpSubmit = (otp: string) => {
    dispatch(
      verifyChallenge(
        otp,
        pathname,
        history,
        isCheckingOutCallback,
        checkBasket,
      ),
    );
  };

  const handleOTPResend = () => {
    dispatch(
      resendOTP(
        pathname,
        methods.getValues(),
        history,
        isCheckingOutCallback,
        checkBasket,
      ),
    );
  };

  const showGuestCheckout =
    isCheckingOut &&
    Boolean(guestCheckoutEnabled) &&
    acceptsPayment &&
    basket.length > 0;

  if (isLoggedIn && !isFetchingBasket) {
    return <Redirect to={pathname} />;
  }
  return (
    <div className="container">
      <div className="login-wrapper">
        <div className="login-grid">
          <div className="login-panel">
            <div className="card">
              <LoginContextualLoader />
              <h2>{loginButtonText}</h2>
              <FormProvider {...methods}>
                <LoginForm handleSubmit={methods.handleSubmit(handleLogIn)} />
              </FormProvider>
              {!accountDeletionOnly ? (
                <>
                  <LoginDivider />
                  <RegistrationButton />
                </>
              ) : null}
              {showGuestCheckout && !accountDeletionOnly ? (
                <>
                  <LoginDivider />
                  <GuestLoginButton />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <OTPModal
        handleSubmit={handleOtpSubmit}
        handleOTPResend={handleOTPResend}
      />
    </div>
  );
};
