import { AztecProduct, BreadcrumbTrack } from 'types/models';
import { ChoiceGroupsModal } from 'menu/components/MenuModal/ModalBody';

interface ChoiceSelectorModalProps {
  aztecProduct: AztecProduct;
  updateAztecProduct: (product: AztecProduct) => void;
  updateBreadcrumbs: (breadcrumbs: BreadcrumbTrack[]) => void;
}

export const ChoiceSelectorModal: React.FC<ChoiceSelectorModalProps> = ({
  aztecProduct,
  updateAztecProduct,
  updateBreadcrumbs,
}) => {
  return (
    <div>
      <ChoiceGroupsModal
        aztecProduct={aztecProduct}
        updateAztecProduct={updateAztecProduct}
        updateBreadcrumbs={updateBreadcrumbs}
      />
    </div>
  );
};
