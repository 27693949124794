import { WLWOPhrases } from 'types/models';

export const defaultPhrases: WLWOPhrases = {
  additionalInformation: {
    headline: 'Where can we find you?',
    subtitle: 'Let us know where we need to deliver your order',
    supportingText: '',
    usagePhrase:
      'Your contact details may be used to contact you if there is an issue with your order.',
  },
  allergensTitleLabel: 'Read about allergens',
  allergenPrompts: [],
  basket: 'My Basket',
  branchTitle: 'Branch',
  cardNumber: 'XXXX XXXX XXXX XXXX',
  chargeToRoom: {
    buttonTitle: 'Charge to $roomPhrase$',
    folioSelector: {
      supportingText: '',
    },
    roomSelector: {
      button: {
        title: 'Find my $roomPhrase$',
      },
      guestNamePhrase: 'Guest Name',
      roomNumberPhrase: '$roomPhrase$ Number',
      subtitle:
        'Let us know which $roomPhrase$ in $siteName$ you are staying in.',
      supportingText: '',
      title: 'Which $roomPhrase$ are you staying in?',
    },
  },
  cvvNumber: 'XXX',
  dailyCalorieAllowancePhrase: 'Adults need around 2000 kcal a day',
  deliveryToLocation: {
    deliveryTimePhrase: 'Delivery Time',
    orderInformationPhrase: 'Order Information',
  },
  deleteAccount: {
    confirmation: {
      supportingText: '',
      title: 'Your account deletion request has been accepted',
    },
    prompt: {
      supportingText: '',
    },
  },
  folioPhrase: 'Folio',
  giftCard: {
    giftCardPhrase: 'Gift Card',
    callToActionTitle: 'Add $giftCardPhrase$',
    redeemButtonTitle: 'Redeem $giftCardPhrase$',
    redeemSupportingText: '',
  },
  guestCheckout: {
    guestCheckoutPhrase:
      'Your email address will be used to send you an order confirmation.',
    guestCheckoutTitle: 'Guest Checkout',
  },
  homeText: 'Home',
  hotelPhrase: 'Hotel',
  landingHeader: '',
  login: {
    continueAsGuestText: 'Guest Checkout',
    loginButtonText: 'Log In',
    createAccountText: 'Register',
    forgottenPasswordText: 'Forgotten Password?',
  },
  loyalty: {
    cardPhrase: 'Loyalty Card',
    linkButtonText: 'Verify',
    linkTitleText: 'Verify your $loyaltyCardPhrase$',
    redeemButtonText: 'Add $loyaltyRewardPhrase$',
    rewardBasketIndicator: 'bookmark',
    rewardPhrase: 'Reward',
    rewardTitleText: 'Add $loyaltyRewardPhrase$',
    siteAvailabilityPhrase:
      'The following $loyaltyRewardPhrase$s can be redeemed at $siteName$ in $siteTown$.',
  },
  menus: 'Menus',
  minAgePrompt: {
    phrase:
      'By adding this product, you are agreeing that you were born on or before $DATEOFBIRTH$. Proof of age may be required.',
  },
  missingAllergensMessage:
    'Allergens information is not available. Please see a member of staff.',
  modalMultipleProductVariationsTitle: 'Change Quantity of Products',
  otpSupportingText:
    'Enter the digits from the message and press the verify button',
  payMyBillBalanceText: 'Outstanding balance',
  payMyBillEnterTablePlaceholder: 'Select your {tablePhrase}',
  payMyBillRemoveServiceChargeText: '',
  payMyBillSelectorText: 'Enter {tablePhrase} number',
  payMyBillTotalText: 'Total',
  postalCode: 'XXXX XXX',
  reorderMenuTitle: 'Same again?',
  reorderMenuIntroduction:
    'Here you can reorder products you have previously ordered from this venue in the last 7 days',
  retrieveMyBillButtonText: 'Retrieve my bill',
  roomPhrase: 'Room',
  searchPlaceholder: 'Search',
  salesAreaAtTableHeadline: 'Where are you seated?',
  salesAreaAtTableSubtitle:
    'Let us know where in $siteName$ you would like to order.',
  salesAreaPhrase: 'Area',
  salesAreaClickAndCollectHeadline: 'Where are you collecting from?',
  salesAreaClickAndCollectSubtitle:
    'Let us know where in $siteName$ you would like to order.',
  salesAreaDeliveryToHeadline: 'Where would you like to order from?',
  salesAreaDeliveryToSubtitle:
    'Let us know where in $siteName$ you would like to order.',
  selectVenue: 'Select a venue',
  table: 'table',
  tipButtonText: 'Add a tip',
  tipSupportingText: '',
  venueTitle: 'venue',
  venueSalesAreaFormat: '$siteName$ ($salesAreaName$)',
  vouchering: {
    voucherCallToAction: 'Vouchers',
    voucherButtonText: 'Apply',
    voucherPlaceholder: 'Enter voucher code',
    voucherSupportingText: '',
  },
  waitTime: '',
};
