import { Button } from 'react-bootstrap';
import { Notification } from 'core/components/Notification';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/Modal';
import { ServiceModalHeader } from 'venue/components/SharedModalComponents';
import { useVenues } from 'contexts/VenueContext';
import { useTimeslots } from 'contexts/TimeslotContext/TimeslotContext';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { TimeslotSelector } from 'features/timeslot/components/TimeslotSelector';

interface TimeslotSelectorFormProps {
  timeslot: string;
}

export const TimeslotsModal: React.FC = () => {
  const methods = useForm<TimeslotSelectorFormProps>();
  const { isLoadingVenues } = useVenues();

  const { setSelectedTimeslot, showTimeslotsModal, setShowTimeslotsModal } =
    useTimeslots();

  const updateTimeslot: SubmitHandler<TimeslotSelectorFormProps> = (
    formValues,
  ) => {
    const formTimeslot = formValues.timeslot;
    if (formTimeslot && !isNaN(new Date(formTimeslot).getTime())) {
      setSelectedTimeslot({ time: formTimeslot, available: true });
      setShowTimeslotsModal(false);
    }
  };

  const hideModal = () => {
    setShowTimeslotsModal(false);
  };

  return (
    <div>
      <FormProvider {...methods}>
        <Modal
          isOpen={showTimeslotsModal}
          onRequestClose={hideModal}
          datatestid="time-selector-modal"
        >
          <ModalHeader closeButton onRequestClose={hideModal}>
            <ServiceModalHeader />
          </ModalHeader>
          <ModalBody>
            <Notification />
            <TimeslotSelector />
          </ModalBody>
          <ModalFooter>
            <Button
              className="view-menu-button"
              data-testid="timeslot-update-save-button"
              bsStyle="primary"
              type="submit"
              onClick={methods.handleSubmit(updateTimeslot)}
              disabled={isLoadingVenues}
            >
              Save
            </Button>
            <Button
              className="hide-modal-button  hide-on-responsive"
              data-testid="timeslot-update-close-button"
              onClick={hideModal}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </FormProvider>
    </div>
  );
};
