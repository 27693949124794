import { useConfig } from 'contexts/ConfigContext';
import { CustomFormattedNumber } from 'common/components/CustomFormattedNumber';
import { ServiceLabel } from 'core/components';
import { FormatDateOptions } from 'react-intl';
import { Order, OrderBasketLine } from 'types/models/Orders';
import { OrderDetails } from 'order-history/components/OrderHistoryTable';
import { FormattedDateTime } from 'common/components/FormattedDateTime';
import { simpleDateToIsoString } from 'utils';
import { isPromotion } from 'types/guards';

interface OrderHistoryItemProps {
  order: Order;
  orderLocationName: string;
  goToOrder: (accountNumber: number) => void;
}

export const OrderHistoryItem: React.FC<OrderHistoryItemProps> = ({
  order,
  orderLocationName,
  goToOrder,
}) => {
  const { currency, currencySymbolsDisabled, timeslots24hFormat } = useConfig();

  const dateFormatOptions: FormatDateOptions = {
    month: 'long',
    weekday: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: !timeslots24hFormat,
  };

  const formattedDate = FormattedDateTime({
    date: new Date(simpleDateToIsoString(order.orderTimestamp)),
    options: dateFormatOptions,
  });

  const serviceId = (
    order.basket.find((line) => !isPromotion(line)) as OrderBasketLine
  ).orderingModeId;

  if (!serviceId) {
    return null;
  }

  return (
    <tr
      key={order.accountNumber}
      onClick={() => goToOrder(order.accountNumber)}
    >
      <td data-testid="order-history-account-number">{order.accountNumber}</td>
      <OrderDetails
        order={order}
        orderService={serviceId}
        orderLocationName={orderLocationName}
      />
      <td className="desktop-table-item">{formattedDate}</td>
      <td className="desktop-table-item">{orderLocationName}</td>
      <td className="desktop-table-item">
        <CustomFormattedNumber
          style={currencySymbolsDisabled ? 'decimal' : 'currency'}
          value={Number(order.grandTotal.toFixed(2))}
          currency={currency}
          minimumFractionDigits={2}
        />
      </td>
      <td className="desktop-table-item">
        {serviceId ? <ServiceLabel id={serviceId} /> : null}
      </td>
    </tr>
  );
};
