import { useEffect } from 'react';

import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { useAdditionalInformation } from 'contexts/AdditionalInformationContext';
import {
  DeliveryToLocationModalFooter,
  DeliveryToLocationModalHeader,
} from 'venue/components/SharedModalComponents';
import { DeliveryToLocationInformation } from 'venue/components/DeliveryToLocationModal';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  AdditionalInfoKey,
  LocationInfoFormProps,
} from 'types/models/AdditionalInformation';
import { Field } from 'types/models/Forms';

interface EditLocationInfoModalProps {
  shown: boolean;
  setShown: (arg: boolean) => void;
}

export const EditLocationInfoModal: React.FC<EditLocationInfoModalProps> = ({
  shown,
  setShown,
}) => {
  const {
    additionalInformationError,
    savedLocationInformation,
    locationInformationFields,
    handleSaveLocationInformation,
  } = useAdditionalInformation();
  const methods = useForm<LocationInfoFormProps>();

  const handleClose = () => {
    setShown(false);
  };
  const saveLocationInfo: SubmitHandler<LocationInfoFormProps> = (
    formValues,
  ) => {
    handleSaveLocationInformation(formValues);
    setShown(false);
  };

  useEffect(() => {
    locationInformationFields.forEach((field: Field) => {
      const key = field.name as AdditionalInfoKey;
      if (savedLocationInformation[key]) {
        if (field.type === 'text') {
          methods.setValue(
            key,
            savedLocationInformation[key] === '-'
              ? ''
              : savedLocationInformation[key],
          );
        }
        if (field.type === 'select') {
          methods.setValue(key, {
            value: savedLocationInformation[key] || '',
            label: savedLocationInformation[key] || '',
          });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shown]);

  return (
    <FormProvider {...methods}>
      <Modal isOpen={shown} onRequestClose={handleClose}>
        <ModalHeader closeButton onRequestClose={handleClose}>
          <DeliveryToLocationModalHeader />
        </ModalHeader>
        <ModalBody>
          <DeliveryToLocationInformation />
        </ModalBody>
        <DeliveryToLocationModalFooter
          handleConfirm={methods.handleSubmit(saveLocationInfo)}
          handleClose={handleClose}
          disabled={!!additionalInformationError}
        />
      </Modal>
    </FormProvider>
  );
};
