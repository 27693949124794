import { AztecProduct } from 'types/models';

export const productHasAndedUpsells = (product: AztecProduct): boolean => {
  return product.andedUpsells ? product.andedUpsells.length > 0 : false;
};

export const getSelectedUpsells = (
  andedUpsells: AztecProduct[],
): AztecProduct[] => {
  return andedUpsells.filter((upsell) => {
    const upsellPortion = upsell.portions[upsell.defaultPortionId];
    return upsellPortion.quantity && upsellPortion.quantity > 0;
  });
};
