import { Choice } from 'types/models';
import { getSelectedPortion } from 'menu/utils';
import { BasketNestedChoiceLine } from 'checkout/components/CheckoutBasket/BasketLines';

interface BasketChoiceLineProps {
  choice: Choice;
  showNested?: boolean;
}

export const BasketChoiceLine: React.FC<BasketChoiceLineProps> = ({
  choice,
  showNested = true,
}) => {
  const nestedPortion =
    showNested &&
    choice.productToUse &&
    getSelectedPortion(choice.productToUse);

  const nestedPortionChoices = nestedPortion
    ? nestedPortion.choices.map((c) => (
        <BasketNestedChoiceLine
          choice={c}
          key={`${c.choiceId}-${c.displayRecordId}`}
        />
      ))
    : null;

  const nestedChoice = showNested && choice.choiceId && choice.choiceGroup;

  const nestedChoices = nestedChoice ? (
    <BasketNestedChoiceLine choice={choice} />
  ) : null;

  return (
    <span>
      {choice.displayRecordToUse?.name}
      {choice.quantity > 1 && ` x ${choice.quantity}`}
      {nestedPortionChoices}
      {nestedChoices}
    </span>
  );
};
