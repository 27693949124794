import { useEffect, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { Banner } from 'components/Banner';
import { useConfig } from 'contexts/ConfigContext';
import { Button } from 'react-bootstrap/lib';
import { generateCookie, isCookieValid } from 'utils';
import { useCookies } from 'react-cookie';

export const CookieConsentBanner: React.FC = () => {
  const [isShown, setIsShown] = useState(false);

  const { client, privacyPolicyUrl } = useConfig();
  const { pathname } = useLocation();

  const [cookies, setCookie] = useCookies([`${client}::wo`]);

  useEffect(() => {
    if (
      !navigator.cookieEnabled ||
      pathname === '/cookie-notice' ||
      pathname === '/ff'
    ) {
      setIsShown(false);
      return;
    }

    const cookie = cookies[`${client}::wo`];

    if (!cookie) {
      setIsShown(true);
      return;
    }

    isCookieValid(cookie) ? setIsShown(false) : setIsShown(true);
  }, [client, cookies, pathname]);

  const handleClose = () => {
    setCookie(
      `${client}::wo`,
      { release: generateCookie() },
      {
        // 90 days
        maxAge: 7776000,
      },
    );
  };

  return (
    <Banner isShown={isShown}>
      <div className="cookie-container" data-testid="cookie-consent-banner">
        <h2 className="cookie-header">Our use of cookies</h2>
        <div className="cookie-content">
          <div className="cookie-text">
            <p>
              We use necessary cookies to make our site work. You may disable
              these by changing your browser settings, but this may affect how
              the website functions. Third party cookies may track your usage
              and build a profile about you for targeted advertising purposes.
            </p>
            <p>
              For more detailed information about the cookies we use, see our{' '}
              <Link target="_blank" rel="noreferrer" to={'/cookie-notice'}>
                Cookies Policy
              </Link>
              .
            </p>
            {privacyPolicyUrl && privacyPolicyUrl.trim().length > 0 ? (
              <p>
                You may also wish to see our{' '}
                <a href={privacyPolicyUrl} target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
                .
              </p>
            ) : null}
          </div>
          <div className="cookie-button">
            <Button bsStyle="primary" onClick={handleClose}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </Banner>
  );
};
