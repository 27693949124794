import { useState } from 'react';
import { TablesModal } from 'core/components/Modals/TablesModal';
import { TimeslotsModal } from 'core/components/Modals/TimeslotsModal';
import { Button } from 'react-bootstrap';
import { ClearBasketButton } from 'basket/components/Basket/SharedComponents';
import { CustomFormattedNumber } from 'common/components/CustomFormattedNumber';
import { EmptyBasketConfirmationModal } from 'basket/components/EmptyBasketConfirmationModal';
import { LineList, QuantityIndicator } from 'basket/components';
import { LoginMethod } from 'types/models/User';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { ServiceChanger } from 'core/components';
import { selectLoginMethod } from 'user/selectors';
import { setLoginMethod } from 'user/actions';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { useDispatch, useSelector } from 'react-redux';
import { useMenus } from 'contexts/MenuContext';
import { useBasket } from 'contexts/BasketContext';
import { AztecProduct } from 'types/models';
import { getSelectedPortion, totalBasketPrice } from 'menu/utils';
import { useCheckout } from 'contexts/CheckoutContext';
import { useVouchers } from 'contexts/VoucherContext';

export const MenuBasket: React.FC = () => {
  const [basketCollapsed, setBasketCollapsed] = useState<boolean>(true);
  const dispatch = useDispatch();

  const {
    currency,
    currencySymbolsDisabled,
    disableUserSignIn,
    pickupTimeOnTop,
    pickupTimeUnderHeader,
    stickyBasketEnabled,
  } = useConfig();

  const { courses } = useMenus();

  const { basket, clearBasket } = useBasket();
  const { isFetchingBasket, checkBasket, clearCheckout } = useCheckout();
  const loginMethod = useSelector(selectLoginMethod);

  const onCheckoutClick = () => {
    if (disableUserSignIn) {
      dispatch(setLoginMethod(LoginMethod.Anonymous));
    } else if (loginMethod === LoginMethod.Anonymous) {
      dispatch(setLoginMethod(undefined));
    }
    checkBasket(disableUserSignIn);
  };

  const { basket: basketTitle } = usePhrases();

  const { redeemedVouchers, voidAllVouchers } = useVouchers();

  const [showClearBasketModal, setShowClearBasketModal] =
    useState<boolean>(false);

  const confirmClearBasket = () => {
    if (redeemedVouchers.length > 0) {
      voidAllVouchers();
    }
    clearBasket();
    clearCheckout();
    setShowClearBasketModal(false);
  };

  const dismissClearBasket = () => {
    setShowClearBasketModal(false);
  };

  const handleClearBasket = () => {
    setShowClearBasketModal(true);
  };

  const quantity = basket.reduce(
    (sum: number, line: AztecProduct) =>
      sum + (getSelectedPortion(line).quantity ?? 0),
    0,
  );

  return !stickyBasketEnabled ? (
    <div className={!basketCollapsed ? 'basket open' : 'basket'}>
      {pickupTimeOnTop && !pickupTimeUnderHeader ? <ServiceChanger /> : null}
      <div
        className="basket-header-button"
        role="button"
        tabIndex={0}
        onClick={() => {
          setBasketCollapsed(!basketCollapsed);
        }}
        onKeyPress={() => {
          setBasketCollapsed(!basketCollapsed);
        }}
      >
        <h2>{basketTitle}</h2>
        <QuantityIndicator quantity={quantity} />
        <div className="hidden-md hidden-lg basket-arrow">
          {basketCollapsed ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp />}
        </div>
      </div>
      <div className={basketCollapsed ? 'hidden-xs hidden-sm' : ''}>
        <LineList courses={courses} />
        <div className="basket-summary">
          Subtotal{' '}
          <CustomFormattedNumber
            style={currencySymbolsDisabled ? 'decimal' : 'currency'}
            value={totalBasketPrice(basket)}
            currency={currency}
            minimumFractionDigits={2}
          />
        </div>
        <div className="basket-action-toolbar">
          {isFetchingBasket ? (
            <Button className="checkout-button" bsStyle="primary" disabled>
              Loading
            </Button>
          ) : (
            <Button
              className="checkout-button"
              data-testid="basket-checkout-button"
              bsStyle="primary"
              onClick={() => onCheckoutClick()}
              disabled={basket.length === 0}
            >
              Checkout
            </Button>
          )}
          <ClearBasketButton handleClearBasket={handleClearBasket} />
        </div>
        {!pickupTimeOnTop && !pickupTimeUnderHeader ? <ServiceChanger /> : null}
      </div>
      <TimeslotsModal />
      <TablesModal />
      <EmptyBasketConfirmationModal
        show={showClearBasketModal}
        proceed={confirmClearBasket}
        dismiss={dismissClearBasket}
      />
    </div>
  ) : null;
};
