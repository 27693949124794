/* eslint-disable no-undef */
import { useEffect } from 'react';
import applePay from 'braintree-web/apple-pay';
import braintreeClient from 'braintree-web/client';
import dataCollector from 'braintree-web/data-collector';
import { useDispatch, useSelector } from 'react-redux';
import { selectTipAmount } from 'checkout/selectors';
import { addNotification } from 'core/actions';
import { useConfig } from 'contexts/ConfigContext';
import cs from 'classnames';
import { useAdditionalInformation } from 'contexts/AdditionalInformationContext';
import {
  getApplePayFieldsAsFormData,
  getApplePayPaymentContactFields,
  getApplePayShippingContactFields,
  normaliseFormData,
} from 'checkout/utils';
import { LOGIN_METHOD_ANONYMOUS } from 'common';
import { selectLoginMethod } from 'user/selectors';
import {
  useCheckout,
  useCheckoutDetails,
  usePayment,
} from 'contexts/CheckoutContext';
import { PaymentType } from 'types/models/Payment';
import { usePayMyBill } from 'contexts/PayMyBillContext/PayMyBillContext';

export const ApplePay = ({ isPmb = false }) => {
  const dispatch = useDispatch();
  const { applePayButtonColour } = useConfig();
  const loginMethod = useSelector(selectLoginMethod);
  const { submitPayment } = useCheckout();
  const { braintreeToken, basketTotal } = useCheckoutDetails();
  const { setPaymentEnabled, setPaymentReady, paymentDisplayName } =
    usePayment();
  const {
    braintreeToken: pmbBraintreeToken,
    paymentAmount,
    submitPayment: submitPmbPayment,
  } = usePayMyBill();

  const tipAmount = useSelector(selectTipAmount);
  const braintreeTokenToUse = isPmb ? pmbBraintreeToken : braintreeToken;
  const totalToUse = isPmb ? paymentAmount : basketTotal;

  const totalPlusTip = totalToUse + tipAmount;

  const {
    savedLocationInformation,
    locationInformationFields,
    userInformationFields,
  } = useAdditionalInformation();

  const showError = (error) => {
    dispatch(addNotification(error, 'danger'));
  };

  const handleSubmitPayment = (formData, deviceData, nonce) => {
    if (isPmb) {
      submitPmbPayment(formData, deviceData, nonce, PaymentType.ApplePay);
    } else {
      submitPayment(formData, deviceData, nonce, PaymentType.ApplePay);
    }
  };

  const { client: clientName, clientPaymentName } = useConfig();

  const applePayLabel = paymentDisplayName || clientPaymentName || clientName;

  const controller = new AbortController();

  const userFields = getApplePayShippingContactFields(userInformationFields);
  const loggedInUserFields = getApplePayPaymentContactFields(
    userInformationFields,
  );

  const paymentFields =
    loginMethod !== LOGIN_METHOD_ANONYMOUS && loggedInUserFields.length > 0
      ? Object.assign({}, ...loggedInUserFields)
      : undefined;

  if (loginMethod === LOGIN_METHOD_ANONYMOUS) {
    userFields.push('email');
  }

  const uniqueUserFields = [...new Set(userFields)];

  useEffect(() => {
    if (!braintreeTokenToUse) {
      showError('Basket is not authorised');
      setPaymentEnabled(PaymentType.ApplePay, false);
      return;
    }

    try {
      if (!window.ApplePaySession || !ApplePaySession.canMakePayments()) {
        setPaymentEnabled(PaymentType.ApplePay, false);
        return;
      }
    } catch (e) {
      showError('Error calling Apple Pay Session');
      setPaymentEnabled(PaymentType.ApplePay, false);
      return;
    }

    braintreeClient.create(
      { authorization: braintreeTokenToUse },
      (clientErr, client) => {
        if (clientErr) {
          showError('Error connecting to payment service');
          setPaymentEnabled(PaymentType.ApplePay, false);
          return;
        }

        dataCollector.create(
          { client, kount: true, paypal: true },
          (dataCollectorError, dataCollectorInstance) => {
            if (dataCollectorError) {
              showError(
                `Error collecting device data ${JSON.stringify(
                  dataCollectorError,
                )}`,
              );

              return;
            }

            const { deviceData } = dataCollectorInstance;

            applePay.create({ client }, (applePayErr, applePayInstance) => {
              if (applePayErr) {
                showError('Error initialising Apple Pay');
                setPaymentEnabled(PaymentType.ApplePay, false);
                return;
              }

              ApplePaySession.canMakePaymentsWithActiveCard(
                applePayInstance.merchantIdentifier,
              ).then((canMakePaymentsWithActiveCard) => {
                if (!canMakePaymentsWithActiveCard) {
                  showError(
                    'Unable to complete purchase. Please review your Apple Pay set-up',
                  );
                  setPaymentEnabled(PaymentType.ApplePay, false);

                  return;
                }

                const button = document.querySelector(
                  '#request-payment-button',
                );

                button.addEventListener(
                  'click',
                  () => {
                    const paymentRequestObj = {
                      total: {
                        label: applePayLabel,
                        amount: totalPlusTip,
                      },
                    };

                    if (uniqueUserFields.length > 0) {
                      paymentRequestObj.requiredShippingContactFields =
                        uniqueUserFields;
                    }

                    if (
                      paymentFields &&
                      Object.keys(paymentFields).length > 0
                    ) {
                      paymentRequestObj.shippingContact = paymentFields;
                    }

                    const paymentRequest =
                      applePayInstance.createPaymentRequest(paymentRequestObj);

                    const session = new ApplePaySession(3, paymentRequest);

                    session.onvalidatemerchant = (event) => {
                      applePayInstance.performValidation(
                        {
                          validationURL: event.validationURL,
                          displayName: 'My Store',
                        },
                        (validationErr, merchantSession) => {
                          if (validationErr) {
                            showError('Error validating merchant');
                            session.abort();
                            return;
                          }

                          session.completeMerchantValidation(merchantSession);
                        },
                      );
                    };

                    session.onpaymentauthorized = (event) => {
                      applePayInstance.tokenize(
                        {
                          token: event.payment.token,
                        },
                        (tokenizeErr, payload) => {
                          if (tokenizeErr) {
                            showError('Error tokenizing Apple Pay');
                            session.completePayment(
                              ApplePaySession.STATUS_FAILURE,
                            );
                            return;
                          }

                          const formData = getApplePayFieldsAsFormData(
                            event?.payment?.shippingContact,
                            userInformationFields,
                          );

                          const normalisedFormData = normaliseFormData(
                            locationInformationFields,
                            userInformationFields,
                            { ...savedLocationInformation, ...formData },
                          );
                          session.completePayment(
                            ApplePaySession.STATUS_SUCCESS,
                          );
                          handleSubmitPayment(
                            normalisedFormData,
                            deviceData,
                            payload.nonce,
                          );
                        },
                      );
                    };

                    session.begin();
                  },
                  { signal: controller.signal },
                );
                setPaymentReady(PaymentType.ApplePay, true);
              });
            });
          },
        );
      },
    );
    return () => {
      setPaymentReady(PaymentType.ApplePay, false);
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPlusTip, savedLocationInformation]);

  // We have to apply these styles inline as webpack tries to convert the safari vars into hex colours, which stops them from working
  // We also still have to apply the class for compatibility reasons
  const buttonStyle =
    applePayButtonColour === 'white'
      ? { '-apple-pay-button-style': 'white' }
      : applePayButtonColour === 'outline'
      ? { '-apple-pay-button-style': 'white-outline' }
      : { '-apple-pay-button-style': 'black' };

  const classNames = cs({
    'apple-pay-button-with-text': true,
    'apple-pay-button-white-with-text': applePayButtonColour === 'white',
    'apple-pay-button-white-with-line-with-text':
      applePayButtonColour === 'outline',
    'apple-pay-button-black-with-text': applePayButtonColour === 'black',
  });

  return (
    <div className="text-center">
      <div
        id="request-payment-button"
        className={classNames}
        style={buttonStyle}
      >
        <span className="text">Buy with</span>
        <span className="logo" />
      </div>
    </div>
  );
};
