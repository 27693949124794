import { ReactElement } from 'react';

import AmericanExpress from 'images/payment-icons/american-express.png';
import DinersClub from 'images/payment-icons/diners-club.png';
import Discover from 'images/payment-icons/discover.png';
import JCB from 'images/payment-icons/jcb.png';
import Maestro from 'images/payment-icons/maestro.png';
import MasterCard from 'images/payment-icons/master-card.png';
import UnionPay from 'images/payment-icons/unionpay.png';
import Visa from 'images/payment-icons/visa.png';

interface PaymentIconsProps {
  supportedCards: string[];
}

export const PaymentIcons: React.FC<PaymentIconsProps> = ({
  supportedCards,
}) => {
  const cardIcons: ReactElement[] = [];

  supportedCards.forEach((card) => {
    switch (card) {
      case 'american-express':
        cardIcons.push(
          <img
            key={`acceptance-mark-${card}`}
            className="payment-icon"
            src={AmericanExpress}
            alt="accepted card: American Express"
          />,
        );
        break;
      case 'diners-club':
        cardIcons.push(
          <img
            key={`acceptance-mark-${card}`}
            className="payment-icon"
            src={DinersClub}
            alt="accepted card: Diner's Club"
          />,
        );
        break;
      case 'discover':
        cardIcons.push(
          <img
            key={`acceptance-mark-${card}`}
            className="payment-icon"
            src={Discover}
            alt="accepted card: Discover"
          />,
        );
        break;
      case 'jcb':
        cardIcons.push(
          <img
            key={`acceptance-mark-${card}`}
            className="payment-icon"
            src={JCB}
            alt="accepted card: JCB"
          />,
        );
        break;
      case 'maestro':
        cardIcons.push(
          <img
            key={`acceptance-mark-${card}`}
            className="payment-icon"
            src={Maestro}
            alt="accepted card: Maestro"
          />,
        );
        break;
      case 'master-card':
        cardIcons.push(
          <img
            key={`acceptance-mark-${card}`}
            className="payment-icon"
            src={MasterCard}
            alt="accepted card: MasterCard"
          />,
        );
        break;
      case 'unionpay':
        cardIcons.push(
          <img
            key={`acceptance-mark-${card}`}
            className="payment-icon"
            src={UnionPay}
            alt="accepted card: Union Pay"
          />,
        );
        break;
      case 'visa':
        cardIcons.push(
          <img
            key={`acceptance-mark-${card}`}
            className="payment-icon"
            src={Visa}
            alt="accepted card: Visa"
          />,
        );
        break;
    }
  });

  return <div className="payment-icons">{cardIcons}</div>;
};
