import { Switch, useRouteMatch } from 'react-router-dom';
import { AuthenticatedRoute } from 'routers/PrivateRoute';

import { LinkLoyalty } from 'loyalty/LinkLoyalty';

export const LoyaltyRouter: React.FC = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <AuthenticatedRoute path={`${url}/link`} component={LinkLoyalty} />
    </Switch>
  );
};
