import { useConfig, useSetConfig } from 'contexts/ConfigContext';

import { ControlLabel, FormControl } from 'react-bootstrap';

export const MaxNumberOfVouchersText: React.FC = () => {
  const { maxNumberOfVouchers } = useConfig();
  const setMaxNumberOfVouchers = useSetConfig();

  const handleChange = (event: React.ChangeEvent<FormControl>) => {
    // This is a tad annoying having to do this, but react-bootstrap sucks
    const value: string = (event.target as unknown as HTMLInputElement).value;
    setMaxNumberOfVouchers('maxNumberOfVouchers', Number(value) || '');
  };

  return (
    <div>
      <ControlLabel className="ff-label">Max Number of Vouchers:</ControlLabel>
      <FormControl
        data-testid={`ff-maxNumberOfVouchers`}
        id={`ff-maxNumberOfVouchers`}
        type="text"
        value={maxNumberOfVouchers}
        placeholder="Enter number or empty for undefined"
        onChange={handleChange}
      />
    </div>
  );
};
