import { Dialog } from 'components/Dialog';
import { TimeslotPhrase } from 'common/components/TimeslotPhrase';
import { useServices } from 'contexts/VenueContext';

interface ConfirmBasketChangesOnTimeslotChangeModalProps {
  show: boolean;
  proceed: () => void;
  dismiss: () => void;
}
export const ConfirmBasketChangesOnTimeslotChangeModal: React.FC<
  ConfirmBasketChangesOnTimeslotChangeModalProps
> = ({ show, proceed, dismiss }) => {
  const {selectedService} = useServices();

  const timeslotPhrase = TimeslotPhrase({
    selectedService,
  });

  return (
    <Dialog
      isOpen={show}
      title={`Change ${timeslotPhrase}`}
      description={`If you change your ${timeslotPhrase} some items in the basket may no longer be
        available and the basket may be emptied as a result. Do you wish to
        continue?`}
      accept={{
        action: proceed,
        text: 'Continue',
        btnType: 'destructive',
        datatestid: 'pickuptime-change-warning-continue-button',
      }}
      reject={{
        action: dismiss,
        text: 'Cancel',
        datatestid: 'pickuptime-change-warning-cancel-button',
      }}
    />
  );
};
