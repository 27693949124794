import { FormattedNumber } from 'react-intl';
import cs from 'classnames';

interface CustomFormattedNumberProps {
  className?: string;
  currency?: string;
  currencyDisplay?: 'symbol' | 'code' | 'name' | 'narrowSymbol';
  hideZeroValue?: boolean;
  localeMatcher?: 'lookup' | 'best fit';
  maximumFractionDigits?: number;
  maximumSignificantDigits?: number;
  minimumFractionDigits?: number;
  minimumIntegerDigits?: number;
  minimumSignificantDigits?: number;
  precedingText?: string;
  style?: 'decimal' | 'percent' | 'currency' | 'unit';
  useGrouping?: boolean;
  value: number;
}

export const CustomFormattedNumber: React.FC<CustomFormattedNumberProps> = ({
  className,
  currency,
  currencyDisplay,
  hideZeroValue,
  localeMatcher,
  maximumFractionDigits,
  maximumSignificantDigits,
  minimumFractionDigits,
  minimumIntegerDigits,
  minimumSignificantDigits,
  precedingText,
  style,
  useGrouping,
  value,
}) => {
  const numberClasses = cs(
    hideZeroValue && (value === 0 || isNaN(value)) && 'hidden',
    className,
  );
  return (
    <span className={numberClasses}>
      {precedingText ? precedingText : null}
      <FormattedNumber
        value={value}
        localeMatcher={localeMatcher}
        style={style}
        currency={currency}
        currencyDisplay={currencyDisplay}
        useGrouping={useGrouping}
        minimumIntegerDigits={minimumIntegerDigits}
        minimumFractionDigits={minimumFractionDigits}
        maximumFractionDigits={maximumFractionDigits}
        minimumSignificantDigits={minimumSignificantDigits}
        maximumSignificantDigits={maximumSignificantDigits}
      />
    </span>
  );
};
