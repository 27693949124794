import { useConfig } from 'contexts/ConfigContext';
import { useSelector } from 'react-redux';
import { selectTipAmount } from 'checkout/selectors';
import { FormattedCurrency } from 'common/components/FormattedCurrency';

export const TipTotal: React.FC = () => {
  const { supportsTip, acceptsPayment } = useConfig();
  const value = useSelector(selectTipAmount);

  return supportsTip && acceptsPayment ? (
    <div className="total">
      <div>Tip</div>
      <div data-testid="tip-amount">
        <FormattedCurrency value={value} />
      </div>
    </div>
  ) : null;
};
