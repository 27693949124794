import { Dialog } from 'components/Dialog';
import { Voucher } from 'types/models/Vouchers';

interface VoucherModalProps {
  applyVoucher: (voucher: Voucher) => void;
  show: boolean;
  cancelVoucherApply: (voucher: Voucher) => void;
  voucher: Voucher;
}

export const ConfirmVoucherApplicationModal: React.FC<VoucherModalProps> = ({
  applyVoucher,
  show,
  cancelVoucherApply,
  voucher,
}) => {
  const voucherName =
    voucher.metaData && voucher.metaData.friendlyName
      ? voucher.metaData.friendlyName
      : 'Voucher';

  return (
    <Dialog
      isOpen={show}
      title="Apply Voucher"
      description={`Would you like to apply the voucher ${voucherName}?`}
      datatestid="confrimation-voucher-modal"
      accept={{
        action: () => applyVoucher(voucher),
        text: 'Apply Voucher',
        datatestid: 'confirm-apply-voucher',
      }}
      reject={{
        action: () => cancelVoucherApply(voucher),
        text: 'Cancel',
      }}
    />
  );
};
