import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { selectIsLoggedIn, selectLoginMethod } from 'user/selectors';
import { LoginMethod } from 'types/models/User';

export const AuthenticatedRoute: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const loginMethod = useSelector(selectLoginMethod);

  const ComponentElement = Component as React.ElementType;

  return (
    <Route
      {...rest}
      render={(props): React.ReactNode => {
        if (
          isLoggedIn &&
          isLoggedIn === true &&
          (loginMethod === LoginMethod.Sso || loginMethod === LoginMethod.User)
        ) {
          return <ComponentElement {...props} />;
        }

        return (
          <Redirect
            to={{
              pathname: '/user/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
