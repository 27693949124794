import { usePhrases } from 'contexts/ConfigContext';
import { useSalesAreas, useServices, useVenues } from 'contexts/VenueContext';
import { Menu } from 'types/models';
import { isTimeslotService } from 'venue/utils';

interface CanOrderBannerProps {
  menu?: Menu;
}
export const CanOrderBanner: React.FC<CanOrderBannerProps> = ({ menu }) => {
  const { salesAreaPhrase } = usePhrases();
  const { selectedVenue } = useVenues();
  const { selectedSalesArea } = useSalesAreas();
  const { selectedService } = useServices();

  const menuCanOrder = isTimeslotService(selectedService)
    ? true
    : menu?.canOrder;

  if (!selectedVenue?.canPlaceOrder) {
    return (
      <div className="can-order-banner">
        Ordering is currently unavailable at {selectedVenue?.name}.
      </div>
    );
  }

  if (!selectedSalesArea?.canPlaceOrder) {
    return (
      <div className="can-order-banner">
        Ordering is currently unavailable at {salesAreaPhrase}:{' '}
        {selectedSalesArea?.friendly || selectedSalesArea?.name}.
      </div>
    );
  }

  if (!menu) {
    return null;
  }

  if (!menuCanOrder) {
    return (
      <div className="can-order-banner">
        {/* If you change table/timeslot, and that changes availability, the menu name doesn't exist anymore - so make the message a bit cleaner */}
        {menu?.name
          ? `${menu?.name} menu is currently unavailable.`
          : 'Menu is currently unavailable.'}
      </div>
    );
  }

  return null;
};
