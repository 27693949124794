import { FormattedCurrency } from 'common/components/FormattedCurrency/';
import { PromotionLine as PromotionLineType } from 'types/models/Basket';

interface PromotionLineProps {
  promotion: PromotionLineType;
}

export const PromotionLine: React.FC<PromotionLineProps> = ({ promotion }) => {
  return (
    <div className="promotion" key={`promotion-${promotion.Id}`}>
      <div>Promotion applied:</div>
      <div data-testid="promotion-amount">
        <FormattedCurrency value={promotion.amount} />
      </div>
    </div>
  );
};
