import { createContext } from 'react';

interface ServiceModalContext {
  onClose: (redirectIfRequired: boolean) => void;
}

export const ServiceModalContext = createContext<ServiceModalContext>({
  onClose: () => {
    throw new Error('ServiceModalContext is not initialised');
  },
});
