import { Dialog } from 'components/Dialog';
import { usePhrases } from 'contexts/ConfigContext';
import { useGiftCard } from 'contexts/GiftCardContext';

interface ConfirmLeaveBasketModalProps {
  show: boolean;
  cancelAction: () => void;
  acceptAction: () => void;
}

export const ConfirmLeaveBasketModal: React.FC<
  ConfirmLeaveBasketModalProps
> = ({ show, cancelAction, acceptAction }) => {
  const {
    giftCard: { giftCardPhrase },
    loyalty: { rewardPhrase },
  } = usePhrases();

  const { redeemedGiftCards } = useGiftCard();

  const hasGiftCards = redeemedGiftCards.length > 0;
  const phraseToUse = hasGiftCards ? giftCardPhrase : rewardPhrase;

  return (
    <Dialog
      isOpen={show}
      title={`${phraseToUse} in Basket`}
      description={`It looks like you have a ${phraseToUse} in your basket. All ${phraseToUse}s will be removed from your basket and will need to be re-added.`}
      accept={{
        action: () => acceptAction(),
        text: 'Remove and return to menus',
        btnType: 'destructive',
      }}
      reject={{
        action: () => cancelAction(),
        text: 'Cancel',
      }}
    />
  );
};
