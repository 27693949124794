import { useAllergenPrompt } from 'contexts/AllergenContext';
import { useConfig } from 'contexts/ConfigContext';
import { useMenus } from 'contexts/MenuContext';
import { useVenues } from 'contexts/VenueContext';
import { ConfirmAllergenDialog } from 'menu/components/Allergens/ConfirmAllergenDialog';
import { RedirectAllergenDialog } from 'menu/components/Allergens/RedirectAllergenDialog';
import { useState } from 'react';
import { useHistory } from 'react-router';

export const AllergenPrompt: React.FC = () => {
  const { showAllergenPrompt } = useConfig();
  const history = useHistory();

  const { selectedVenue } = useVenues();
  const { menus } = useMenus();

  const { hasPromptBeenShown, setHasPromptBeenShown } = useAllergenPrompt();
  const [confirmHasAllergen, setConfirmHasAllergen] = useState(false);

  const showDialog =
    showAllergenPrompt && !hasPromptBeenShown && menus.length > 0;

  const handleRedirect = () => {
    history.push(`/venue/${selectedVenue?.id}`);
  };

  if (!showDialog) return null;
  //   When First prompt is confirmed it will open the second one
  return !confirmHasAllergen ? (
    <ConfirmAllergenDialog
      proceed={() => setConfirmHasAllergen(true)}
      dismiss={() => setHasPromptBeenShown(true)}
    />
  ) : (
    <RedirectAllergenDialog proceed={handleRedirect} />
  );
};
