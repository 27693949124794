import { useConfig, useSetConfig } from 'contexts/ConfigContext';

import { ControlLabel, FormControl } from 'react-bootstrap';

export const Currency: React.FC = () => {
  const { currency } = useConfig();
  const setConfig = useSetConfig();

  const handleChange = (event: React.ChangeEvent<FormControl>) => {
    const value: string = (event.target as unknown as HTMLInputElement).value;
    setConfig('currency', value);
  };

  return (
    <div>
      <ControlLabel className="ff-label">Currency Code:</ControlLabel>
      <FormControl
        data-testid={`ff-currency`}
        id={`ff-currency`}
        type="text"
        value={currency}
        placeholder="Enter currency code"
        onChange={handleChange}
      />
    </div>
  );
};
