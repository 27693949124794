export interface Service {
  id: number;
  label: string;
  name: string;
}

export type ServiceUrlName =
  | 'click-and-collect'
  | 'order-and-pay'
  | 'pay-my-bill'
  | 'delivery-to-location';

export enum ServiceType {
  OrderAndPay = 1,
  ClickAndCollect = 2,
  PayMyBill = 6,
  DeliveryToLocation = 9,
}
