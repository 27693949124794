import { usePhrases, useSetPhrases } from 'contexts/ConfigContext';

import { ControlLabel, FormControl } from 'react-bootstrap';

export const LoginContinueAsGuest: React.FC = () => {
  const {
    login: { continueAsGuestText },
  } = usePhrases();
  const setPhrase = useSetPhrases();

  const handleChange = (event: React.ChangeEvent<FormControl>) => {
    // This is a tad annoying having to do this, but react-bootstrap sucks
    const value: string = (event.target as unknown as HTMLInputElement).value;
    setPhrase('continueAsGuestText', value);
  };

  return (
    <div>
      <ControlLabel className="ff-label">Continue as guest text:</ControlLabel>
      <FormControl
        data-testid={`ff-continueAsGuestText`}
        id={`ff-continueAsGuestText`}
        type="text"
        value={continueAsGuestText}
        placeholder="Enter text"
        onChange={handleChange}
      />
    </div>
  );
};
