import cs from 'classnames';

interface MenuItemDescriptionProps {
  description: string;
  isOutOfStock?: boolean;
}

export const MenuItemDescription: React.FC<MenuItemDescriptionProps> = ({
  description,
  isOutOfStock,
}) => {
  const classes = cs(
    'item-description',
    'text-muted',
    isOutOfStock ? 'out-of-stock' : undefined,
  );
  return (
    <div data-testid="item-description" className={classes}>
      {description}
    </div>
  );
};
