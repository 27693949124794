import { usePhrases } from 'contexts/ConfigContext';
import { useAdditionalInformation } from 'contexts/AdditionalInformationContext';
import { useEffectSkipFirst } from 'hooks/useEffectSkipFirst';
import { useState } from 'react';

import { AdditionalInfoField } from 'types/models/AdditionalInformation';
import { EditLocationInfoModal } from '../Modals';
import { RestartOrderDialog } from './RestartOrderDialog';

export const AdditionalInfo: React.FC = () => {
  const {
    locationInformationFields,
    savedLocationInformation,
    getAdditionalInfo,
    isFetchingAdditionalInfo,
    additionalInformationError,
  } = useAdditionalInformation();
  const {
    deliveryToLocation: { orderInformationPhrase },
  } = usePhrases();

  const [modalShown, setModalShown] = useState<boolean>(false);
  const [dialogShown, setDialogShown] = useState<boolean>(false);
  const showAdditionalInfoPanel = locationInformationFields.length > 0;

  const handleModalToShow = () => {
    getAdditionalInfo();
  };

  useEffectSkipFirst(() => {
    if (!isFetchingAdditionalInfo) {
      if (
        additionalInformationError ||
        (locationInformationFields.length === 0 &&
          (savedLocationInformation.field_1 ||
            savedLocationInformation.field_2))
      ) {
        setDialogShown(true);
      } else {
        setModalShown(true);
      }
    }
  }, [isFetchingAdditionalInfo]);

  return (
    <>
      {showAdditionalInfoPanel && (
        <div className="card additional-info">
          <div className="header">
            <h3 className="title">Additional Information</h3>
            <button
              className="btn btn-link"
              onClick={handleModalToShow}
              disabled={isFetchingAdditionalInfo}
            >
              Edit
            </button>
          </div>
          <div className="body">
            {locationInformationFields.map((field) => (
              <div className="info-item" key={field.name}>
                <div>{field.label}</div>
                <div>
                  {savedLocationInformation[
                    field.name as keyof AdditionalInfoField
                  ] || '-'}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <EditLocationInfoModal shown={modalShown} setShown={setModalShown} />
      <RestartOrderDialog
        isOpen={dialogShown}
        title={`Unable to display ${orderInformationPhrase}`}
        description={`Unfortunately, ${orderInformationPhrase.toLowerCase()} cannot be displayed at this time.`}
      />
    </>
  );
};
