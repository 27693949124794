import { Map } from 'immutable';
import * as actionTypes from './actionTypes';
import { initialState } from './model';

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOG_OUT:
      return state.merge({
        isLoggedIn: false,
        loginMethod: null,
        token: null,
        user: Map(),
      });

    case actionTypes.RECEIVE_LOG_IN:
      return state.set('isFetchingLogIn', false);

    case actionTypes.INIT_LOG_IN:
      return state.set('isFetchingLogIn', true);

    case actionTypes.RESET_GUEST_CHECKOUT:
      return state.set('isFetchingGuestCheckout', false);

    case actionTypes.INIT_GUEST_CHECKOUT:
      return state.set('isFetchingGuestCheckout', true);

    case actionTypes.RECEIVE_GUEST_EMAIL:
      return state.set('guestEmail', action.email);

    case actionTypes.RECEIVE_LOGIN_METHOD:
      return state.set('loginMethod', action.method);

    case actionTypes.RECEIVE_USER:
      return state.merge({
        isFetchingLogIn: false,
        isLoggedIn: true,
        token: action.token,
        user: Map(action.user),
      });

    case actionTypes.UPDATE_USER_DATA:
      return state.setIn(['user', action.data], action.value);

    case actionTypes.REQUEST_EDIT:
      return state.set('isFetchingEdit', true);

    case actionTypes.RECEIVE_EDIT:
      return state.set('isFetchingEdit', false);

    case actionTypes.INIT_REGISTER:
      return state.set('isFetchingRegister', true);

    case actionTypes.RECEIVE_REGISTER:
      return state.set('isFetchingRegister', false);

    case actionTypes.REGISTERING_FROM_CHECKOUT:
      return state.merge({ isRegisteringFromCheckout: action.status });

    case actionTypes.SHOW_OTP_MODAL:
      return state.set('otpModalFlag', true);

    case actionTypes.HIDE_OTP_MODAL:
      return state.set('otpModalFlag', false);

    case actionTypes.INIT_OTP:
      return state.merge({
        otpEmail: action.email,
        otpMethod: action.channel,
        otpAccount: action.account,
        otpExpires: action.expirationTimeUtc,
        otpChallengeId: action.id,
      });

    case actionTypes.REQUEST_CHANGE_PASSWORD:
      return state.set('isFetchingChangePassword', true);

    case actionTypes.RECEIVE_CHANGE_PASSWORD:
      return state.set('isFetchingChangePassword', false);

    case actionTypes.REQUEST_RESET_PASSWORD:
      return state.set('isFetchingResetPassword', true);

    case actionTypes.RECEIVE_RESET_PASSWORD:
      return state.set('isFetchingResetPassword', false);

    case actionTypes.CHANGE_DOB:
      return state.setIn(['defaultBirthday', action.part], action.value);

    case actionTypes.OTP_ENABLED:
      return state.set('otpEnabled', action.otpEnabled);

    default:
      return state;
  }
}

export default reducer;

// Selectors that return Immutable objects

export const selectLoggedInUser = (state) => state.user.get('user');

export const selectDefaultBirthday = (state) =>
  state.user.get('defaultBirthday');

export const selectUser = (state) => state.user;
