import { useConfig, useSetConfig } from 'contexts/ConfigContext';

import { ControlLabel, FormControl } from 'react-bootstrap';

export const GooglePayMerchantId: React.FC = () => {
  const { googleMerchantId } = useConfig();
  const setConfig = useSetConfig();

  const handleChange = (event: React.ChangeEvent<FormControl>) => {
    // This is a tad annoying having to do this, but react-bootstrap sucks
    const value: string = (event.target as unknown as HTMLInputElement).value;

    setConfig('googleMerchantId', value);
  };

  return (
    <div>
      <ControlLabel className="ff-label">
        Merchant Id for Google Pay
      </ControlLabel>
      <FormControl
        data-testid={`ff-googleMerchantId`}
        id={`ff-googleMerchantId`}
        type="text"
        value={googleMerchantId}
        placeholder="Enter text"
        onChange={handleChange}
      />
    </div>
  );
};
