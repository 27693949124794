import { ActionMeta } from 'react-select';
import VirtualisedSelect from 'react-select-virtualized';
import { useVenues } from 'contexts/VenueContext';
import { getGoogleCoordinates } from 'venue/googlePredictions';
import { useVenueSearch } from 'hooks/useVenueSearch';


interface AutocompleteSearchProps {
  searchVenue: (arg: string, prediction: boolean) => void;
  predictions: google.maps.places.AutocompletePrediction[];
}

export const AutocompleteSearch: React.FC<AutocompleteSearchProps> = ({
  searchVenue,
  predictions,
}) => {
  const { fetchVenueSummary } = useVenues();

  const { dropPredictions, setSearchTerm } = useVenueSearch();

  const handleGetCoordinates = (placeId: string) => {
    getGoogleCoordinates(placeId).then((response) => {
      const coordinates = {
        lat: response?.lat(),
        lng: response?.lng(),
      };

      if (coordinates.lat && coordinates.lng) {
        fetchVenueSummary({
          latitude: coordinates.lat,
          longitude: coordinates.lng,
        });
      }
    });
  };

  const onAction = (
    option: google.maps.places.AutocompletePrediction | null,
    actionMeta: ActionMeta<google.maps.places.AutocompletePrediction>,
  ) => {
    if (option) {
      handleGetCoordinates(option.place_id);
      dropPredictions();
    }

    if (actionMeta.action === 'clear') {
      setSearchTerm('');
      dropPredictions();
    }
  };

  return (
    <VirtualisedSelect
      options={predictions}
      placeholder={`Search`}
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
      inputProps={{
        autoComplete: 'off',
        autoCorrect: 'off',
        spellCheck: 'off',
      }}
      className={'form-group'}
      isClearable={true}
      menuShouldScrollIntoView={true}
      onChange={onAction}
      onInputChange={(value: string) => searchVenue(value, true)}
      getOptionLabel={(option: google.maps.places.AutocompletePrediction) =>
        option.description
      }
      getOptionValue={(option: google.maps.places.AutocompletePrediction) =>
        option.description
      }
      formatOptionLabel={(option: google.maps.places.AutocompletePrediction) =>
        option.description
      }
      noOptionsMessage={() => 'No suggestions found'}
    />
  );
};
