import { Email, Password } from 'core/components/RHF/FieldTemplates';
import { Field } from 'types/models/Forms';
import { Button } from 'react-bootstrap';
import { ForgottenPassword } from './ForgottenPassword';
import { usePhrases } from 'contexts/ConfigContext';

interface LoginFormProps {
  handleSubmit: () => void;
}

export const LoginForm: React.FC<LoginFormProps> = ({ handleSubmit }) => {
  const {
    login: { loginButtonText },
  } = usePhrases();

  const emailField: Field = {
    label: 'Email Address',
    max: 64,
    min: 5,
    name: 'email',
    placeholder: 'Email Address',
    required: true,
    type: 'email',
  };

  const passwordField: Field = {
    label: 'Password',
    min: 8,
    name: 'password',
    placeholder: 'Password',
    required: true,
    type: 'password',
  };

  return (
    <div data-testid="login-panel">
      <div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <Email field={emailField} />
            <Password
              field={passwordField}
              autocomplete="current-password"
              shouldValidatePasswordStrength={false}
            />
          </div>
          <ForgottenPassword />
          <Button
            bsStyle="primary"
            type="submit"
            onClick={handleSubmit}
            data-testid="login-submit-button"
            block
          >
            {loginButtonText}
          </Button>
        </form>
      </div>
    </div>
  );
};
