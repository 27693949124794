import { Map } from 'immutable';
import Cookies from 'universal-cookie';

export const initialState = Map({});

export const isSsoSupported = () => {
  const sso = window?.config?.sso;

  if (sso) {
    const isValidEntity = (str) => str && str.length > 0;
    const { source, provider, name, path } = sso;

    // NOTE: At this stage, SSO only supports using cookies.
    if (
      source === 'cookies' &&
      supportsJavaScriptCookies() &&
      isValidEntity(provider) &&
      isValidEntity(name)
    ) {
      const sanitizedCookiePath = sanitizeCookiePath(path);

      // NOTE: Here we assert that to use SSO for cookies,
      //       the config must define a matching logoutCookie.
      return getLogoutCookies().reduce(
        (found, cookie) =>
          found ||
          (cookie.name === name &&
            sanitizeCookiePath(cookie.path) === sanitizedCookiePath),
        false,
      );
    }
  }
  return false;
};

export const supportsJavaScriptCookies = () => navigator.cookieEnabled;

export const getLogoutCookies = () => {
  return window?.config?.logoutCookies || [];
};

export const sanitizeCookiePath = (path) => path || '/';

export const getCookies = () => {
  // TODO: What is the cookie header? How to get this to work for Pizza Express?
  // TODO: Should this be config?
  const cookieHeader = undefined;

  return new Cookies(cookieHeader);
};
