import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { registerFromCheckout } from 'user/actions';
import { usePhrases } from 'contexts/ConfigContext';

export const RegistrationButton: React.FC = () => {
  const dispatch = useDispatch();
  const {
    login: { createAccountText },
  } = usePhrases();

  const handleRegisterFromCheckout = (status: boolean) => {
    dispatch(registerFromCheckout(status));
  };

  return (
    <Button
      bsStyle="primary"
      href="#/user/register"
      onClick={() => handleRegisterFromCheckout(true)}
      block
      data-testid="register-link-button"
    >
      {createAccountText}
    </Button>
  );
};
