import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useConfig } from 'contexts/ConfigContext';

export const OrderingRoute: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { accountDeletionOnly } = useConfig();
  const ComponentElement = Component as React.ElementType;

  return (
    <Route
      {...rest}
      render={(props): React.ReactNode => {
        if (!accountDeletionOnly) {
          return <ComponentElement {...props} />;
        }

        return (
          <Redirect
            to={{
              pathname: '/user/delete-account',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
