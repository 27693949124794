import { Button } from 'react-bootstrap';
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md';
import { QuantityIndicator } from 'basket/components/QuantityIndicator';
import { useBasket } from 'contexts/BasketContext';
import { AztecProduct } from 'types/models';
import { getSelectedPortion } from 'menu/utils';
import { useVouchers } from 'contexts/VoucherContext';

export const ViewBasketButton: React.FC = () => {
  const { basket, isBasketCollapsed } = useBasket();
  const { redeemedVouchers } = useVouchers();

  const quantity = basket.reduce(
    (sum: number, line: AztecProduct) =>
      sum + (getSelectedPortion(line).quantity ?? 0),
    0,
  );

  if (basket.length + redeemedVouchers.length === 0) {
    return null;
  }

  return isBasketCollapsed ? (
    <Button
      className="checkout-button sticky-view-basket-button btn-with-icon"
      bsStyle="default"
      data-testid="sticky-basket-view-basket-button"
    >
      <MdArrowUpward className="basket-view-icon" />
      <span className="basket-view-icon">View Basket</span>
      <QuantityIndicator quantity={quantity} />
    </Button>
  ) : (
    <Button
      className="checkout-button sticky-view-basket-button btn-with-icon"
      bsStyle="default"
      data-testid="sticky-basket-hide-basket-button"
    >
      <MdArrowDownward className="basket-view-icon" />
      <span className="basket-view-icon">Hide Basket</span>
      <QuantityIndicator quantity={quantity} />
    </Button>
  );
};
