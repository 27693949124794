import cs from 'classnames';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import {
  isInteger,
  isLoyaltyCard,
  isNotOnlyWhitespace,
  isRequired,
  maxLength,
  minLength,
} from 'core/components/RHF/validation';
import { Field } from 'types/models/Forms';
import { useEffect } from 'react';

interface CardNumberProps {
  field: Field;
  isNumeric?: boolean;
}

export const CardNumber: React.FC<CardNumberProps> = ({ field, isNumeric }) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { min, max, required, label, placeholder, name, disabled } = field;

  const labelToUse = label ? label : name;

  const requiredLabel = required ? <span className="required">*</span> : null;

  const validationRules: RegisterOptions = {
    ...isRequired(labelToUse, required),
    ...minLength(labelToUse, min),
    ...maxLength(labelToUse, max ?? 999),
    validate: {
      ...(isNumeric
        ? {
            numeric: (value) => isInteger(value, labelToUse),
          }
        : { isLoyaltyCard }),
      ...(required && {
        whiteSpace: (value) => isNotOnlyWhitespace(value, labelToUse),
      }),
    },
  };

  const formGroupClasses = cs(
    'form-group',
    errors && errors[name] && 'has-error',
  );

  useEffect(() => {
    if (field.content) {
      setValue(field.name, field.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={formGroupClasses}>
      <label className="control-label" htmlFor={name}>
        {label}
        {requiredLabel}
      </label>
      <input
        aria-invalid={errors && errors[name] ? 'true' : 'false'}
        className="form-control"
        placeholder={placeholder ? placeholder : labelToUse}
        {...register(name, validationRules)}
        type="text"
        inputMode={isNumeric ? 'numeric' : 'text'}
        disabled={disabled}
      />

      {errors && errors[name] && (
        <span className="help-block" role="alert">
          {errors[name].message}
        </span>
      )}
    </div>
  );
};
