import { Radio, Checkbox } from 'react-bootstrap';

import { ChoiceType } from 'types/models';
import cs from 'classnames';
import { useEffect } from 'react';

/**
 * Generates the "selector" component for the choice item (ie what the user clicks to select that
 * choice). Can either be a radio or checkbox, depending on the type of choice group.
 */
interface MenuChoiceSelectorProps {
  choiceGroupType: ChoiceType;
  isSelected: boolean;
  choiceGroupId: number;
  disabled: boolean;
  outOfStock: boolean;
  numberOfChoices: number;
  choiceIndex: number;
  choiceGroupIndex: number;
  hasNestedChoice: boolean;
  portionId: number;
  updateChoice: (
    choiceIndex: number,
    choiceGroupIndex: number,
    quantity?: number,
    hasNestedChoice?: boolean,
  ) => void;
}

export const MenuChoiceSelector: React.FC<MenuChoiceSelectorProps> = ({
  choiceGroupType,
  isSelected,
  choiceGroupId,
  disabled,
  outOfStock,
  numberOfChoices,
  choiceIndex,
  choiceGroupIndex,
  hasNestedChoice,
  updateChoice,
}) => {
  const onChoiceSelectorClick = (
    choiceIndex: number,
    choiceGroupIndex: number,
  ) => {
    updateChoice(choiceIndex, choiceGroupIndex, undefined, hasNestedChoice);
  };

  const isPreselected =
    choiceGroupType === 'SingleRequired' && numberOfChoices === 1;

  useEffect(() => {
    if (isPreselected && !isSelected) {
      updateChoice(choiceIndex, choiceGroupIndex, undefined, hasNestedChoice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Defaults are set a quantity=1 when the product is first clicked
  // Here we check if it is disabled or out of stock to set the quantity=0
  if (isSelected && (disabled || outOfStock)) {
    updateChoice(choiceIndex, choiceGroupIndex, 0);
  }

  const inputClass = cs(
    isSelected && 'on',
    outOfStock && 'out-of-stock',
    disabled && 'disabled',
    'no-flex-shrink',
  );

  switch (choiceGroupType) {
    case 'SingleRequired': {
      /* display radio */
      return (
        <Radio
          inline
          checked={isSelected}
          className={inputClass}
          data-testid="menu-choice-option-radio"
          disabled={disabled || outOfStock}
          name={`choiceGroup:${choiceGroupId}`}
          onChange={() => onChoiceSelectorClick(choiceIndex, choiceGroupIndex)}
        />
      );
    }
    case 'SingleOptional':
    case 'Multiple': {
      /* display checkbox */
      /*
       onChoiceGroupProductClick will behave differently based on the choice group type, hence
       it's ok to merge the two cases
       */
      return (
        <Checkbox
          inline
          checked={isSelected}
          className={inputClass}
          data-testid="menu-choice-option-checkbox"
          disabled={disabled || outOfStock}
          name={`choiceGroup:${choiceGroupId}`}
          onChange={() => onChoiceSelectorClick(choiceIndex, choiceGroupIndex)}
        />
      );
    }

    default:
      return null;
  }
};
