import { usePhrases } from 'contexts/ConfigContext';
import { replaceWithDynamicText } from 'utils';
import { ServiceType } from 'types/models';
import { useServices, useVenues } from 'contexts/VenueContext';

export const SalesAreaSubtitle: React.FC = () => {
  const { selectedService, services } = useServices();
  const service = services.find((serv) => serv.id === selectedService);

  const { selectedVenue } = useVenues();

  const {
    salesAreaAtTableSubtitle,
    salesAreaClickAndCollectSubtitle,
    salesAreaDeliveryToSubtitle,
  } = usePhrases();

  return selectedVenue && service ? (
    <p className="text-muted">
      {selectedService === ServiceType.OrderAndPay ||
      selectedService === ServiceType.PayMyBill
        ? replaceWithDynamicText(
            salesAreaAtTableSubtitle,
            selectedVenue,
            service,
          )
        : null}
      {selectedService === ServiceType.ClickAndCollect
        ? replaceWithDynamicText(
            salesAreaClickAndCollectSubtitle,
            selectedVenue,
            service,
          )
        : null}
      {selectedService === ServiceType.DeliveryToLocation
        ? replaceWithDynamicText(
            salesAreaDeliveryToSubtitle,
            selectedVenue,
            service,
          )
        : null}
    </p>
  ) : null;
};
