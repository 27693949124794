import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { BasketAddition } from 'types/models/Basket';

interface BasketAdditionLineProps {
  basketAddition: BasketAddition;
}

export const BasketAdditionLine: React.FC<BasketAdditionLineProps> = ({
  basketAddition,
}) => {
  const price = basketAddition.quantity * basketAddition.tariffPrice;
  return (
    <div className="basket-line" data-testid="cardBasket-item">
      <div
        className="qty text-right"
        data-testid="cardBasket-item-quantity"
      >{`${basketAddition.quantity}x`}</div>
      <div className="description" data-testid="cardBasket-item-description">
        <div>{basketAddition.displayName}</div>
      </div>
      <div className="price" data-testid="cardBasket-item-price">
        <FormattedCurrency value={price} />
      </div>
    </div>
  );
};
