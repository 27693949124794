import cs from 'classnames';

interface BadgeProps {
  className?: string;
  children: React.ReactNode;
}
export const Badge: React.FC<BadgeProps> = ({ className, children }) => {
  const badgeClasses = cs('badge', className);
  return <span className={badgeClasses}>{children}</span>;
};
