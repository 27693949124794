import { useConfig } from 'contexts/ConfigContext';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { removeNotification } from 'core/actions';

export const Logo: React.FC = () => {
  const dispatch = useDispatch();
  const { logoLinkToHomepage, homepage } = useConfig();

  const clearNotification = () => {
    dispatch(removeNotification());
  };

  return (
    <div className="logo">
      {logoLinkToHomepage ? (
        <div>
          <a href={`${homepage}`} onClick={clearNotification} target="blank">
            <div
              className="logo-img"
              data-testid="header-logo"
              role="img"
              aria-label="Company logo"
            />
          </a>
        </div>
      ) : (
        <Link onClick={clearNotification} to="/">
          <div
            className="logo-img"
            data-testid="header-logo"
            role="img"
            aria-label="Company logo"
          />
        </Link>
      )}
    </div>
  );
};
