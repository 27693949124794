import { ModalHeader } from 'components/Modal';
import { useConfig } from 'contexts/ConfigContext';
import { CustomFormattedNumber } from 'common/components/CustomFormattedNumber';
import { AztecProduct } from 'types/models';
import { calculateLinePrice, getProductPortions } from 'menu/utils';
import { CalorieAmountDisplay } from 'menu/components/Calories';

interface MenuModalHeaderProps {
  aztecProduct: AztecProduct;
  handleClose: () => void;
}

export const MenuModalHeader: React.FC<MenuModalHeaderProps> = ({
  aztecProduct,
  handleClose,
}) => {
  const { currency, currencySymbolsDisabled } = useConfig();

  const portions = getProductPortions(aztecProduct);
  return (
    <ModalHeader closeButton onRequestClose={handleClose}>
      <div className="title menu-modal-title">
        <h2 className="menu-modal-title-name">
          {aztecProduct?.displayRecords[aztecProduct.displayRecordId].name ||
            aztecProduct?.displayName}
        </h2>
        <h2 className="menu-modal-title-price">
          <CustomFormattedNumber
            style={currencySymbolsDisabled ? 'decimal' : 'currency'}
            value={calculateLinePrice(aztecProduct)}
            currency={currency}
            hideZeroValue
            minimumFractionDigits={2}
          />
        </h2>
      </div>
      <CalorieAmountDisplay
        displayRecord={
          aztecProduct?.displayRecords[aztecProduct.displayRecordId]
        }
        portions={portions}
      />
    </ModalHeader>
  );
};
