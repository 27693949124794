import { useEffect } from 'react';

import { createPortal } from 'react-dom';

interface BannerProps {
  isShown: boolean;
  children?: React.ReactNode;
}

export const Banner: React.FC<BannerProps> = ({ isShown, children }) => {
  useEffect(() => {
    isShown
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto');
  }, [isShown]);

  return (
    <>
      {isShown
        ? createPortal(
            <div className="banner-overlay" data-testid="banner-overlay">
              <div className="banner" data-testid="banner">
                {children}
              </div>
            </div>,
            document.getElementById('root') as Element,
          )
        : null}
    </>
  );
};
