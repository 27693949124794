import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { OrderBasketAndedUpsellLine } from 'types/models/Orders';

interface OrderAndedLineProps {
  andedLine: OrderBasketAndedUpsellLine;
}

export const OrderAndedLine: React.FC<OrderAndedLineProps> = ({
  andedLine,
}) => {
  return (
    <div className="order-history-anded-line text-muted">
      <div>+ {andedLine.name}</div>
      {andedLine.tariffPrice ? (
        <FormattedCurrency value={andedLine.tariffPrice} />
      ) : null}
    </div>
  );
};
