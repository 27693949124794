import * as userActionTypes from 'user/actionTypes';
import * as ssoActionTypes from 'sso/actionTypes';
import * as orderHistoryActionTypes from 'order-history/actionTypes';

export const actionTypes = [
  orderHistoryActionTypes.REQUEST_ORDERS,
  ssoActionTypes.REQUEST_AUTH_SSO,
  userActionTypes.REQUEST_CHANGE_PASSWORD,
  userActionTypes.REQUEST_EDIT,
  userActionTypes.REQUEST_LOG_IN,
  userActionTypes.REQUEST_LOG_IN_WITH_OTP,
  userActionTypes.REQUEST_REGISTER,
  userActionTypes.REQUEST_RESET_PASSWORD,
  userActionTypes.REQUEST_RESET_PASSWORD_BY_TOKEN,
];
