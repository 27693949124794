import { useConfig, useSetConfig } from 'contexts/ConfigContext';

import { ControlLabel } from 'react-bootstrap';
import { ToggleSwitch } from 'core/components/ToggleSwitch';

export const OpeningTimesToggle: React.FC = () => {
  const { openingTimes24hFormat } = useConfig();
  const setConfig = useSetConfig();

  const handleClick = (toggledValue: boolean) => {
    setConfig('openingTimes24hFormat', toggledValue);
  };

  return (
    <div>
      <ControlLabel className="ff-label">Opening times:</ControlLabel>
      <ToggleSwitch
        id="opening-times-format"
        onChange={handleClick}
        checked={openingTimes24hFormat}
        disabled={false}
        name="opening-times-format"
        onLabel="24h"
        offLabel="12h"
        small={false}
      />
    </div>
  );
};
