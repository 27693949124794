import { useLocation } from 'react-router';
import { useConfig } from 'contexts/ConfigContext';
import dompurify from 'dompurify';
import { useBasket } from 'contexts/BasketContext';
import { useMenuGroups } from 'contexts/MenuContext';

export const Footer: React.FC = () => {
  const { pathname } = useLocation();
  const { basket, isBasketCollapsed } = useBasket();

  const { customFooter, stickyBasketEnabled } = useConfig();
  const isOnMenuScreen = pathname.indexOf('menu') > 0;
  const basketIsEmpty = basket.length === 0;
  const { products } = useMenuGroups();

  let basketPlaceholder = null;

  const isMobile = window.innerWidth < 500;

  if (isOnMenuScreen && stickyBasketEnabled && products) {
    if (isBasketCollapsed) {
      basketPlaceholder = (
        <div className="sticky-basket-placeholder-collapsed" />
      );
    } else {
      basketPlaceholder = <div className="sticky-basket-placeholder-opened" />;
    }
  }

  const html_unescape = (s: string) => {
    const div = document.createElement('div');
    div.innerHTML = s;
    return div.textContent ?? div.innerText; // IE is different
  };

  const sanitizer = dompurify.sanitize;

  dompurify.addHook('afterSanitizeAttributes', function (node: Element) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener');
    }
  });

  return (
    <>
      <footer data-testid="footer" role="contentinfo">
        {customFooter && (
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizer(html_unescape(customFooter)),
            }}
          />
        )}
      </footer>
      {(!basketIsEmpty || isMobile) && basketPlaceholder}
    </>
  );
};
