import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

export const FieldGroup = ({ input, label, meta, type, ...props }) => {
  let validationState = null;
  let message = null;

  if (meta) {
    const { error, warning, touched } = meta;

    validationState =
      (touched && ((error && 'error') || (warning && 'warning'))) || null;
    message = touched && (error || warning) && (
      <span className="help-block">{error || warning}</span>
    );
  }

  return (
    <FormGroup validationState={validationState}>
      {label && <ControlLabel>{label}</ControlLabel>}
      <FormControl {...input} type={type} {...props} />
      {message}
    </FormGroup>
  );
};
