import { CustomFormattedNumber } from 'common/components/CustomFormattedNumber';
import { useConfig } from 'contexts/ConfigContext';

interface ChoicePriceLabelProps {
  priceToDisplay: number;
}

export const ChoicePriceLabel: React.FC<ChoicePriceLabelProps> = ({
  priceToDisplay,
}) => {
  const { currencySymbolsDisabled, currency } = useConfig();

  return (
    <div>
      <span className="item-price">
        <CustomFormattedNumber
          style={currencySymbolsDisabled ? 'decimal' : 'currency'}
          value={priceToDisplay}
          currency={currency}
          hideZeroValue
          minimumFractionDigits={2}
          precedingText='+'
        />
      </span>
    </div>
  );
};
