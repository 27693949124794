import { Button } from 'react-bootstrap';
import { addNotification } from 'core/actions';
import { useDispatch } from 'react-redux';
import { FaLocationArrow } from 'react-icons/fa';
import { useVenueSearch } from 'hooks/useVenueSearch';
import { useVenues } from 'contexts/VenueContext';

export const LocationSearchButton: React.FC = () => {
  const dispatch = useDispatch();
  const { setSearchTerm } = useVenueSearch();
  const { fetchVenueSummary } = useVenues();

  const fetchVenuesByLocation = () => {
    if (!navigator.geolocation) {
      // eslint-disable-next-line no-alert
      dispatch(
        addNotification(
          'Unfortunately, we were unable to determine your location as it is not supported by your web browser.',
        ),
      );

      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setSearchTerm('');
        fetchVenueSummary({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (err) => {
        //let's have some specific, friendlier errors
        // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError/code
        switch (err.code) {
          case 1:
            dispatch(
              addNotification(
                'Unfortunately, we were unable to determine your location as you have chosen to decline the permission.',
                'danger',
              ),
            );
            break;
          case 2:
            dispatch(
              addNotification(
                'Unfortunately, we were unable to determine your location.',
                'danger',
              ),
            );
            break;
          case 3:
            dispatch(
              addNotification(
                'Unfortunately, the request to determine your location has timed out. Please try again.',
                'danger',
              ),
            );
            break;
        }
      },
    );
  };

  return 'geolocation' in navigator ? (
    <>
      <Button
        bsStyle="link"
        bsSize="xsmall"
        className="pull-right"
        onClick={() => fetchVenuesByLocation()}
      >
        <span className="h4 pull-right btn-use-location btn-with-icon">
          <FaLocationArrow /> {'Use your current location'}
        </span>
      </Button>
    </>
  ) : null;
};
