import * as actionTypes from './actionTypes';

export const changeCurrency = (currency) => ({
  type: actionTypes.CHANGE_CURRENCY,
  currency,
});

export const changeLocale = (locale) => ({
  type: actionTypes.CHANGE_LOCALE,
  locale,
});

export const addNotification = (message, level, code) => ({
  type: actionTypes.ADD_NOTIFICATION,
  message,
  level,
  code,
});

export const removeNotification = () => ({
  type: actionTypes.REMOVE_NOTIFICATION,
});

export const getFriendlyVenueTitle = () => (dispatch, getState) => {
  const { core } = getState();
  const dictionary = core.get('dictionary') || {};

  return dictionary?.venueTitle || 'venue';
};

export const networkError = () => ({
  type: actionTypes.NETWORK_ERROR_TIMEOUT,
});

export const setDefaultThemeColour = (defaultThemeColour) => ({
  type: actionTypes.RECEIVE_DEFAULT_THEME_COLOUR,
  defaultThemeColour,
});

export const setCookiesDisabledAcknowledgement = (
  cookiesDisabledAcknowledgement,
) => ({
  type: actionTypes.COOKIES_DISABLED_ACKNOWLEDGEMENT,
  cookiesDisabledAcknowledgement,
});
