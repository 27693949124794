import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addNotification } from 'core/actions';
import { fetchBodyFormatter } from 'utils';
import { selectLoggedInUser } from 'user/reducer';
import { receiveUser } from 'user/actions';
import { useAPI } from 'api/useAPI';
import { useHistory } from 'react-router';

export const useLoyalty = () => {
  const [isVerifying, setIsVerifying] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectLoggedInUser);

  const history = useHistory();

  const {
    url,
    userAddLoyaltyNumber,
    verifyLoyalty: userVerifyLoyaltyNumber,
  } = useAPI();
  async function verifyLoyalty(values) {
    const userVerifyLoyaltyNumberConfig = userVerifyLoyaltyNumber();
    const userAddLoyaltyNumberConfig = userAddLoyaltyNumber();

    setIsVerifying(true);

    const loyaltyCard = user.get('loyaltyCard');

    const bodyParams = loyaltyCard
      ? userVerifyLoyaltyNumberConfig.body
      : userAddLoyaltyNumberConfig.body;

    const response = await fetch(`${url}`, {
      method: 'POST',
      headers: loyaltyCard
        ? userVerifyLoyaltyNumberConfig.headers
        : userAddLoyaltyNumberConfig.headers,
      body: fetchBodyFormatter({
        method: loyaltyCard
          ? userVerifyLoyaltyNumberConfig.method
          : userAddLoyaltyNumberConfig.method,
        ...values,
        ...bodyParams,
      }),
    });

    const payload = await response.json();

    if (payload.response.toLowerCase() === 'ok') {
      dispatch(
        receiveUser(
          response.headers,
          payload.user,
          payload['X-Auth-UserToken'],
        ),
      );

      history.push('/');
      dispatch(addNotification(`Successfully linked loyalty`, 'success'));
    } else if (payload.response.toLowerCase() === 'error') {
      dispatch(
        addNotification(`Error verifying loyalty: ${payload.detail}`, 'danger'),
      );
    } else {
      dispatch(addNotification(`Something went wrong!`, 'danger'));
    }

    setIsVerifying(false);
  }

  return { isVerifying, verifyLoyalty };
};
