import { Button } from 'react-bootstrap';
import { MdDelete } from 'react-icons/md';
import { Voucher, VoucherBasketLine } from 'types/models/Vouchers';
import { useCheckout } from 'contexts/CheckoutContext';
import { useVouchers } from 'contexts/VoucherContext';

interface VoucherLineProps {
  line: VoucherBasketLine;
}

export const VoucherLine: React.FC<VoucherLineProps> = ({ line }) => {
  const { isFetchingBasket } = useCheckout();

  const { voidVoucher, voidVoucherLoading } = useVouchers();

  const handleVoidVoucher = (voucher: Voucher) => {
    voidVoucher(voucher.voucherCode);
  };

  return (
    <div className="basket-voucher-item">
      <div>
        <h4 className="basket-line">{'Voucher to be applied:'}</h4>
        <i>{line.voucher?.voucherCode}</i>
      </div>
      <div className="sticky-basket-delete">
        <Button
          title="Remove"
          className="button-remove-line pull-right remove-voucher-button"
          bsSize="xsmall"
          bsStyle="danger"
          onClick={() => handleVoidVoucher(line.voucher)}
          disabled={isFetchingBasket || voidVoucherLoading}
        >
          <span>
            <MdDelete />
          </span>
        </Button>
      </div>
    </div>
  );
};
