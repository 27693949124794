import { useConfig } from 'contexts/ConfigContext';
import { CustomFormattedNumber } from 'common/components/CustomFormattedNumber';
import {
  QuantityField,
  SpecialRequestField,
} from 'menu/components/MenuModal/ModalComponents';
import { AztecProduct } from 'types/models';
import { useEffect } from 'react';
import { AllergenLink } from 'menu/components/Allergens';
import { getProductPortions } from 'menu/utils';

interface OnlyPortionModalProps {
  aztecProduct: AztecProduct;
  updateAztecProduct: (product: AztecProduct) => void;
}

export const OnlyPortionModal: React.FC<OnlyPortionModalProps> = ({
  aztecProduct,
  updateAztecProduct,
}) => {
  const { currency, currencySymbolsDisabled, specialRequestsDisabled } =
    useConfig();

  const portion = getProductPortions(aztecProduct)[0];
  const displayRecord =
    aztecProduct.displayRecords[aztecProduct.displayRecordId];

  const onLineQuantityChange = (quantity: number) => {
    const updatedProduct = { ...aztecProduct };
    updatedProduct.portions[portion.id].quantity = quantity;
    updateAztecProduct(aztecProduct);
  };

  useEffect(() => {
    if (!portion?.quantity) {
      onLineQuantityChange(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div key={`portion:${portion.id}`}>
      {displayRecord?.description ? (
        <div className="display-record-description">
          {displayRecord.description} <AllergenLink />
        </div>
      ) : (
        <div className="display-record-description">
          <AllergenLink />
        </div>
      )}
      {/* TODO PortionItem here as well? */}
      <div className="menu-item choice-row">
        <span className="item-name">
          {portion.name || portion.portion_name}
        </span>
        <div className="item-price-selector choice-section">
          <CustomFormattedNumber
            style={currencySymbolsDisabled ? 'decimal' : 'currency'}
            value={portion.price}
            currency={currency}
            hideZeroValue
            minimumFractionDigits={2}
            className="item-price"
          />
          <span className="item-quantity">
            <QuantityField
              quantity={portion.quantity || 1}
              onChange={onLineQuantityChange}
              min={1}
              max={99}
            />
          </span>
        </div>
      </div>
      {!specialRequestsDisabled ? (
        <SpecialRequestField aztecProduct={aztecProduct} />
      ) : null}
    </div>
  );
};
