import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { VenueModalFooter } from '../SharedModalComponents/VenueModalFooter';
import { ServiceModalHeader } from 'venue/components/SharedModalComponents';
import { hideServiceChoiceModal } from 'venue/actions';
import { Notification } from 'core/components/Notification';
import { ModalHeader, ModalBody } from 'components/Modal';
import { ServiceModalContext } from 'contexts/ServiceModalContext';
import { TableSelector } from 'features/table/components/TableSelector';
import { useSalesAreas, useVenues } from 'contexts/VenueContext';
import { useTables } from 'contexts/TableContext/TableContext';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ReactSelectOption } from 'types/react-select-virtualized';
import { useTimeslots } from 'contexts/TimeslotContext/TimeslotContext';
import { usePhrases } from 'contexts/ConfigContext';
import { usePayMyBill } from 'contexts/PayMyBillContext/PayMyBillContext';
import { Loader } from 'components/Loader';
import { useEffectSkipFirst } from 'hooks/useEffectSkipFirst';

interface TableSelectorFormProps {
  tableSelector: ReactSelectOption;
}

export const SelectedServicePayMyBill: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { onClose } = useContext(ServiceModalContext);
  const { retrieveMyBillButtonText } = usePhrases();

  const {
    fetchOpenAccounts,
    isFetchingAccounts,
    openAccount,
    resetOpenAccounts,
  } = usePayMyBill();
  const { selectedVenue } = useVenues();
  const { selectedSalesArea } = useSalesAreas();
  const { setSelectedTable } = useTables();
  const { setSelectedTimeslot } = useTimeslots();

  const methods = useForm<TableSelectorFormProps>();

  const handleConfirm: SubmitHandler<TableSelectorFormProps> = (formValues) => {
    if (selectedVenue?.id && selectedSalesArea?.id) {
      setSelectedTable(formValues.tableSelector);
      setSelectedTimeslot(undefined);

      fetchOpenAccounts(
        selectedVenue?.id,
        selectedSalesArea?.id,
        formValues.tableSelector.number,
      );
    }
  };

  useEffect(() => {
    resetOpenAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffectSkipFirst(() => {
    if (openAccount) {
      dispatch(hideServiceChoiceModal());
      history.push(`/venue/${selectedVenue?.id}/pay-my-bill/account`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAccount]);

  return (
    <FormProvider {...methods}>
      <ModalHeader closeButton onRequestClose={() => onClose(true)}>
        <ServiceModalHeader useDistanceIfAvailable />
      </ModalHeader>
      <ModalBody>
        <Notification />
        {isFetchingAccounts ? <Loader /> : <TableSelector />}
      </ModalBody>
      <VenueModalFooter
        handleConfirm={methods.handleSubmit(handleConfirm)}
        disabled={isFetchingAccounts}
        confirmButtonText={retrieveMyBillButtonText}
      />
    </FormProvider>
  );
};
