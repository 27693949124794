import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { isNil } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useSalesAreas, useVenues } from 'contexts/VenueContext';
import { getOrderLocationPhrase } from 'utils';

export const VenueTitle: React.FC = () => {
  const { showRestaurantTitleOnTop } = useConfig();
  const { venueSalesAreaFormat } = usePhrases();
  const { selectedVenue } = useVenues();
  const { selectedSalesArea } = useSalesAreas();

  const orderLocationPhrase = getOrderLocationPhrase(
    venueSalesAreaFormat,
    selectedVenue,
    selectedSalesArea,
  );

  const location = useLocation();

  const showTitle =
    location.pathname.indexOf('menu') > 0 ||
    location.pathname.indexOf('checkout') > 0;

  return showRestaurantTitleOnTop && !isNil(selectedVenue?.name) ? (
    <h5 className="venue-title text-center" data-testid="label-venue-title">
      {showTitle ? orderLocationPhrase : null}
    </h5>
  ) : null;
};
