import { getCookies, isSsoSupported } from 'sso/model';
import * as actionTypes from './actionTypes';
import { logOut, receiveUser, setLoginMethod } from 'user/actions';
import { LOGIN_METHOD_SSO } from 'common';

// eslint-disable-next-line camelcase
export const requestAuthSso = (sso_provider, sso_token) => (dispatch) =>
  Promise.resolve().then(() =>
    dispatch({
      type: actionTypes.REQUEST_AUTH_SSO,
      method: 'authSSO',
      sso_provider,
      sso_token,
    }),
  );

export const checkForSso = () => (dispatch, getState) => {
  const state = getState();
  const loggedIn = state.user.get('isLoggedIn');

  if (!loggedIn && isSsoSupported(state)) {
    const sso = window.config?.sso; // state.core.getIn(['config', 'sso']);

    if (sso.source === 'cookies') {
      const { provider, name } = sso;

      // TODO: Is this a valid mechanism? How to clarify?
      const token = getCookies().get(name);

      // #289552 if a supported cookie is detected, the user should be logged in
      if (token) {
        // TODO the assumption is fetchAuthSso will log us in
        dispatch(requestAuthSso(provider, token))
          .then(({ data, headers }) => {
            if (data.user && data['X-Auth-UserToken']) {
              dispatch(
                receiveUser(headers, data.user, data['X-Auth-UserToken']),
              );
              dispatch(setLoginMethod(LOGIN_METHOD_SSO));
            }
          })
          .catch((err) => {
            // if this fails, then delete the cookie
            // eslint-disable-next-line no-console
            console.error(err);
            dispatch(logOut(true));
          });
      }
    }
  }
};
