import { Fragment } from 'react';
import { AccountCourse, FormattedBill } from 'types/models/PayMyBill';
import { CourseHeader } from 'checkout/components/CheckoutBasket/BasketLines';
import { BillProductLine } from 'PayMyBill/bill/components/Products/BillProductLine';
import { BillTableRow } from 'PayMyBill/bill/components/Products/BillTableRow';

interface BasicBillProps {
  bill: FormattedBill;
}

export const BasicBill: React.FC<BasicBillProps> = ({ bill }) => {
  const courseIds = bill.accountLines.map((line) => line.courseId);

  const existingCourses = bill.courses
    .filter((course) => courseIds.includes(course.id))
    .sort((a, b) => a.id - b.id);

  const courseCount = (currentCourse: AccountCourse) => {
    return courseIds.filter((course) => course == currentCourse.id).length;
  };

  return (
    <>
      {existingCourses.map((course) => (
        <Fragment key={course.id}>
          <CourseHeader course={course.name} count={courseCount(course)} />
          {bill.accountLines
            .filter((line) => line.courseId === course.id)
            .map((filteredLine, i) => (
              <BillProductLine
                item={filteredLine}
                key={`${i}-${filteredLine.productId}`}
              />
            ))}
        </Fragment>
      ))}
      {bill.promotionLines.length > 0 ? (
        <>
          <CourseHeader
            course="Promotions"
            count={bill.promotionLines.length}
          />
          {bill.promotionLines.map((promoLine, i) => (
            <BillTableRow
              name={promoLine.name}
              amount={promoLine.amount}
              key={`${i}-${promoLine.promotionId}`}
              isReduction={true}
            />
          ))}
        </>
      ) : null}
      {bill.discountLines.length > 0 ? (
        <>
          <CourseHeader course="Discounts" count={bill.discountLines.length} />
          {bill.discountLines.map((discount) => (
            <BillTableRow
              name={discount.name}
              amount={discount.amount}
              key={discount.discountId}
              isReduction={true}
            />
          ))}
        </>
      ) : null}
    </>
  );
};
