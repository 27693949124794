export const getLoyaltyPhrase = (
  baseText: string,
  loyaltyPhrase: string,
): string => baseText.replace('$loyaltyRewardPhrase$', loyaltyPhrase);

export const getLoyaltyLinkTitle = (
  baseText: string,
  loyaltyCardPhrase: string,
): string => baseText.replace('$loyaltyCardPhrase$', loyaltyCardPhrase);

export const getLoyaltyAvailabiltyPhrase = (
  baseText: string,
  loyaltyPhrase: string,
  venueName: string,
  venueTown: string,
): string =>
  baseText
    .replace('$loyaltyRewardPhrase$', loyaltyPhrase)
    .replace('$siteName$', venueName)
    .replace('$siteTown$', venueTown);
