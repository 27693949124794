import { Link, useLocation } from 'react-router-dom';

interface NavItemLinkProps {
  children: React.ReactNode;
  to: string;
  from?: string;
  className?: string;
  datatestid: string;
  onClick?: () => void;
}

export const NavItemLink: React.FC<NavItemLinkProps> = ({
  to,
  from,
  className,
  children,
  datatestid,
  onClick,
}) => {
  const location = useLocation();

  const isMatch = location.pathname === to;

  return (
    <li role="presentation" className={isMatch ? 'active' : ''}>
      <Link
        to={{ pathname: to, state: { from: { pathname: from } } }}
        className={className}
        data-testid={datatestid}
        onClick={onClick}
      >
        {children}
      </Link>
    </li>
  );
};
