import { Loader } from 'components/Loader';
import { useAllergenPrompt } from 'contexts/AllergenContext';
import { useBasket } from 'contexts/BasketContext';
import { useCheckout } from 'contexts/CheckoutContext';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { useGiftCard } from 'contexts/GiftCardContext';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { useSalesAreas, useServices, useVenues } from 'contexts/VenueContext';
import { VenueInfoLayout } from 'layouts';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { CombinedVenue } from 'types/models';
import { getUrlPart } from 'utils';
import { showServiceChoiceModal } from 'venue/actions';
import { ConfirmVenueModal } from 'venue/components';
import { SelectedServiceChoiceModal } from 'venue/components/SelectedServiceChoiceModal';
import {
  VenueErrorPanel,
  ServiceList,
  BannerContainer,
} from 'venue/components/SingleVenue';
interface ParamsProps {
  venueId: string;
}

export const VenueHomePage: React.FC = () => {
  const params = useParams<ParamsProps>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { venueTitle } = usePhrases();
  const { showVenueHomeBanners } = useConfig();

  const {
    selectedVenue,
    setSelectedVenue,
    isLoadingVenues,
    venueHomeData,
    fetchVenueSummary,
    fetchVenues,
    fetchVenueHome,
  } = useVenues();

  const { selectedService, setSelectedService } = useServices();
  const { setSelectedSalesArea } = useSalesAreas();
  const { basket, clearBasket } = useBasket();
  const { clearCheckout } = useCheckout();
  const { removeAllGiftCards, redeemedGiftCards } = useGiftCard();
  const { removeAllRewards, redeemedLoyaltyRewards } = useLoyaltyRewards();
  const { setHasPromptBeenShown } = useAllergenPrompt();

  const hasGiftCards = redeemedGiftCards.length > 0;
  const hasLoyaltyRewards = redeemedLoyaltyRewards.length > 0;

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const basketIsEmpty = basket.length === 0;
  const linkedVenueId = parseInt(params.venueId, 10);

  const goToSalesArea = (service: number) => {
    const availableServiceAreas = selectedVenue?.salesArea.filter(
      (sa) => sa.canOrder && sa.services.includes(service),
    );
    if (availableServiceAreas?.length === 1) {
      setSelectedSalesArea(availableServiceAreas[0]);
      dispatch(showServiceChoiceModal());
    } else {
      history.push(
        `/venue/${selectedVenue?.id}/${getUrlPart(service)}/sales-areas`,
      );
    }
  };

  const handleServiceClick = (venue: CombinedVenue, service: number) => {
    setSelectedService(service);
    setHasPromptBeenShown(false);
    if (
      !basketIsEmpty &&
      (venue.id !== selectedVenue?.id || hasGiftCards || hasLoyaltyRewards)
    ) {
      setShowConfirmModal(true);
    } else {
      goToSalesArea(service);
    }
  };

  const handleAccept = () => {
    if (hasGiftCards) {
      removeAllGiftCards();
    }

    if (hasLoyaltyRewards) {
      removeAllRewards();
    }

    clearBasket();
    clearCheckout();
    goToSalesArea(selectedService);
    setShowConfirmModal(false);
  };

  const handleDismiss = () => {
    setSelectedService(-1);
    setShowConfirmModal(false);
  };

  useEffect(() => {
    if (linkedVenueId) {
      // If a different venue or no venue has been selected then make the venues call
      if (selectedVenue?.id !== linkedVenueId) {
        setSelectedVenue(undefined);
        fetchVenueSummary(undefined, linkedVenueId);
        fetchVenues(linkedVenueId);
        fetchVenueHome(linkedVenueId);
      } else if (!venueHomeData || venueHomeData?.venueId !== linkedVenueId) {
        // There is a selected venue but the home call needs to be made
        fetchVenueHome(linkedVenueId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoadingVenues && selectedVenue) {
    return (
      <>
        <VenueInfoLayout
          selectedVenue={selectedVenue}
          classNamePrefix="single-venue"
        >
          {showVenueHomeBanners ? (
            <BannerContainer
              venue={selectedVenue}
              handleClick={handleServiceClick}
            />
          ) : (
            <ServiceList
              venue={selectedVenue}
              handleClick={handleServiceClick}
            />
          )}
        </VenueInfoLayout>
        <ConfirmVenueModal
          show={showConfirmModal}
          proceed={handleAccept}
          venue={selectedVenue}
          dismiss={handleDismiss}
        />
        <SelectedServiceChoiceModal />
      </>
    );
  }

  if (isLoadingVenues || !venueHomeData) {
    return <Loader text={`Fetching ${venueTitle}`} />;
  }

  return (
    <VenueErrorPanel
      title={`Sorry, this ${venueTitle} could not be found`}
      classNames="container container-sm"
    />
  );
};
