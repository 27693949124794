import { ModalFooter } from 'components/Modal';

interface DeliveryToLocationModalFooterProps {
  handleConfirm: () => void;
  handleClose: () => void;
  disabled: boolean;
}

export const DeliveryToLocationModalFooter: React.FC<
  DeliveryToLocationModalFooterProps
> = ({ handleConfirm, handleClose, disabled }) => {
  return (
    <ModalFooter>
      <button
        className="btn btn-primary"
        onClick={handleConfirm}
        disabled={disabled}
        data-testid="btn-delivery-to-location-confirm"
      >
        Confirm
      </button>
      <button
        className="btn btn-default hide-modal-button hide-on-responsive"
        onClick={handleClose}
      >
        Cancel
      </button>
    </ModalFooter>
  );
};
