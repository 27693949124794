import { useServices } from 'contexts/VenueContext';
import { createContext, useContext, useEffect, useState } from 'react';

interface AllergenContext {
  initialised: boolean;
  hasPromptBeenShown: boolean;
  setHasPromptBeenShown: (status: boolean) => void;
}

// set up context
export const AllergenContext = createContext<AllergenContext>({
  initialised: false,
  hasPromptBeenShown: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setHasPromptBeenShown: () => {},
});

// set up hook
export const useAllergenPrompt = (): AllergenContext => {
  const consumer = useContext(AllergenContext);

  // Condition used to determine if Context Provider has been declared
  if (!consumer.initialised) {
    throw new Error('AllergenContextProvider not initialised');
  }

  return consumer;
};

interface AllergenContextProviderProps {
  children: React.ReactNode;
}

// set up provider
export const AllergenContextProvider: React.FC<
  AllergenContextProviderProps
> = ({ children }) => {
  const { selectedService } = useServices();
  const [hasPromptBeenShown, setHasPromptBeenShown] = useState(false);

  useEffect(() => {
    setHasPromptBeenShown(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService]);

  return (
    <AllergenContext.Provider
      value={{
        initialised: true,
        hasPromptBeenShown,
        setHasPromptBeenShown,
      }}
    >
      {children}
    </AllergenContext.Provider>
  );
};
