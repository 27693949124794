import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { useConfig } from 'contexts/ConfigContext';
import { useGiftCard } from 'contexts/GiftCardContext';
import { useVouchers } from 'contexts/VoucherContext';

export const SavingsTotal: React.FC = () => {
  const {
    vouchersEnabled,
    supportsRewardsRedemption,
    supportsGiftCardRedemption,
  } = useConfig();
  const { redeemedGiftCards } = useGiftCard();
  const { redeemedVouchers } = useVouchers();

  const voucherTotal = redeemedVouchers.reduce((a, b) => a + b.Amount, 0);
  const giftCardTotal = redeemedGiftCards?.reduce((a, b) => a + b.amount, 0);

  const savingsTotal = Number(voucherTotal) - Number(giftCardTotal);

  return !vouchersEnabled &&
    !supportsRewardsRedemption &&
    !supportsGiftCardRedemption ? null : (
    <div className="total savings">
      <div>Savings</div>
      <div data-testid="savings">
        <FormattedCurrency value={savingsTotal ?? 0} />
      </div>
    </div>
  );
};
