import {
  GetTableResponseTable,
  GetTablesResponse,
} from 'types/models/Responses';
import {
  ReactSelectGroup,
  ReactSelectOption,
} from 'types/react-select-virtualized';

export const isFriendlyTableName = (
  tableName: string | number | unknown,
): boolean => {
  return typeof tableName === 'string' || tableName instanceof String;
};

export const processTablesToReactSelectOptions = (
  payload: GetTablesResponse,
  tablePhrase: string,
): (ReactSelectGroup | ReactSelectOption)[] => {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });

  const formatTable = (table: GetTableResponseTable): ReactSelectOption => ({
    value: table.id,
    label: isFriendlyTableName(table.tableName)
      ? `${table.tableName}`
      : `${tablePhrase} ${table.tableName}`,
    number: table.number,
  });

  const formattedData: (ReactSelectGroup | ReactSelectOption)[] = [];

  if (payload.groups?.length) {
    payload.groups.forEach((group) => {
      const tables = payload.tables
        .filter((table) => table.groups?.includes(group.id))
        .map(formatTable)
        .sort((a, b) => collator.compare(a.label, b.label));
      formattedData.push({ label: group.name, options: tables });
    });
    formattedData.sort((a, b) => collator.compare(a.label, b.label));
  } else {
    const tables = payload.tables
      .map(formatTable)
      .sort((a, b) => collator.compare(a.label, b.label));
    formattedData.push(...tables);
  }
  return formattedData;
};
