// lots of mutually recursive functions in this file:
/* eslint-disable no-use-before-define */

import { Map } from 'immutable';

export const initialState = Map({
  additionalInfoErrorDialog: '',
  selectedTipPreset: 0,
  tipAmount: 0,
});
