import { Button } from 'react-bootstrap';
import { Menu } from 'types/models/Menu';

interface MenuImageCardProps {
  menu: Menu;
  onClick: (id: number) => void;
  menuLayoutType: string;
  index: number;
}

export const MenuImageCard: React.FC<MenuImageCardProps> = ({
  menu,
  onClick,
  menuLayoutType,
  index,
}) => {
  const backgroundUrl =
    menuLayoutType === 'banner' ||
    (menuLayoutType === 'heroGrid' && index === 0)
      ? menu.image
      : menu.squareImageURL;

  return (
    <Button
      className="menu-image-card"
      onClick={() => onClick(menu.id)}
      style={{ backgroundImage: `url(${backgroundUrl})` }}
      data-testid={`menu-select-card-${menu.id}`}
      aria-label={backgroundUrl ? menu.name : undefined}
    >
      {!backgroundUrl && <h4 className="menu-name">{menu.name}</h4>}
    </Button>
  );
};
