import { cloneDeep } from 'lodash';
import { createContext, useContext, useState } from 'react';
import {
  AztecPortions,
  AztecProduct,
  AztecProducts,
  BreadcrumbTrack,
  ChoiceGroups,
  MenuGroupProduct,
  UpsellGroup,
  UpsellGroups,
} from 'types/models';
import {
  applyDefaults,
  getProductChoices,
  portionHasChoices,
  portionsHaveChoices,
} from 'menu/utils';

interface MenuModalContext {
  initialised: boolean;
  breadcrumbs: BreadcrumbTrack[];
  handleResetMenuModal: () => void;
  handleSelectedMenuItem: (
    menuItem: MenuGroupProduct,
    productList: AztecProducts,
    choiceGroup: ChoiceGroups,
    upsellGroupId: number | undefined,
    upsellGroups: UpsellGroups,
    menuId: number,
  ) => void;
  selectedAztecProduct: AztecProduct | undefined;
  selectUpsellProduct: () => void;
  setBreadcrumbs: (breadcrumbs: BreadcrumbTrack[]) => void;
  setSelectedAztecProduct: (product: AztecProduct | undefined) => void;
  updateSelectedAztecProduct: (product: AztecProduct) => void;
  upsellGroup: UpsellGroup | undefined;
  upsellProduct: AztecProduct | undefined;
}

export const MenuModalContext = createContext<MenuModalContext>({
  initialised: false,
  breadcrumbs: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleSelectedMenuItem: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleResetMenuModal: () => {},
  selectedAztecProduct: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  selectUpsellProduct: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setBreadcrumbs: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedAztecProduct: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateSelectedAztecProduct: () => {},
  upsellGroup: undefined,
  upsellProduct: undefined,
});

export const useMenuModal = (): MenuModalContext => {
  const consumer = useContext(MenuModalContext);
  if (!consumer.initialised) {
    throw new Error('Menu Modal Context Provider not initialised');
  }
  return consumer;
};

interface MenuModalContextProviderProps {
  children: React.ReactNode;
}

export const MenuModalContextProvider: React.FC<
  MenuModalContextProviderProps
> = ({ children }) => {
  const [selectedAztecProduct, setSelectedAztecProduct] =
    useState<AztecProduct>();

  const [upsellProduct, setUpsellProduct] = useState<AztecProduct>();
  const [upsellGroup, setUpsellGroup] = useState<UpsellGroup>();

  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbTrack[]>([]);

  const handleResetMenuModal = () => {
    setSelectedAztecProduct(undefined);
    setBreadcrumbs([]);
    setUpsellGroup(undefined);
    setUpsellProduct(undefined);
  };

  const handleSelectedMenuItem = (
    menuItem: MenuGroupProduct,
    productList: AztecProducts,
    choiceGroups: ChoiceGroups,
    upsellGroupId: number | undefined,
    upsellGroups: UpsellGroups,
    menuId: number,
  ) => {
    // // Create deep copy of menu product
    const menuProduct: AztecProduct = cloneDeep(
      productList[menuItem.productId],
    );

    menuProduct.defaultPortionId = menuItem.defaultPortionId;
    menuProduct.displayRecordId = menuItem.displayRecordId;
    menuProduct.displayName = menuItem.displayName;
    menuProduct.menuId = menuId;

    // If enabled it filters out the portions to be shown
    if (menuItem.controlPortions) {
      const filteredPortions: AztecPortions = Object.fromEntries(
        Object.entries(menuProduct.portions).filter(([key]) =>
          menuItem.showPortions.includes(Number(key)),
        ),
      );
      menuProduct.portions = filteredPortions;
    }

    const productHasChoices = portionsHaveChoices(menuProduct);
    if (productHasChoices) {
      getProductChoices(menuProduct, choiceGroups, productList);
      applyDefaults(menuProduct);
    }

    if (upsellGroupId && upsellGroups) {
      handleUpsell(
        menuProduct,
        upsellGroupId,
        upsellGroups,
        productList,
        choiceGroups,
      );
    }

    setSelectedAztecProduct(menuProduct);
  };

  const updateSelectedAztecProduct = (product: AztecProduct) => {
    setSelectedAztecProduct((prev) => {
      if (prev) {
        return {
          ...prev,
          product,
        };
      }
      return product;
    });
  };

  const handleUpsell = (
    product: AztecProduct,
    upsellGroupId: number,
    upsellGroups: UpsellGroups,
    productList: AztecProducts,
    choiceGroups: ChoiceGroups,
  ): void => {
    const upsellGroup = upsellGroups[upsellGroupId];
    if (!upsellGroup) return;

    if (upsellGroup.items.length === 1 && !upsellGroup.isAnded) {
      createRegularUpsell(upsellGroup, productList, choiceGroups);
    } else if (upsellGroup.items.length > 0 && upsellGroup.isAnded) {
      createAndedUpsells(product, upsellGroup, productList);
    }
  };

  const createRegularUpsell = (
    upsellGroup: UpsellGroup,
    productList: AztecProducts,
    choiceGroups: ChoiceGroups,
  ): void => {
    const upsellItem = upsellGroup.items[0];
    const upsellProduct = cloneDeep(productList[upsellItem.productId]);
    if (
      upsellProduct &&
      !upsellProduct.isOutOfStock &&
      upsellProduct.portions[upsellItem.portionId]
    ) {
      const selectedPortion = upsellProduct.portions[upsellItem.portionId];
      if (
        selectedPortion.price === undefined ||
        selectedPortion.price === null ||
        selectedPortion.price < 0
      )
        return;

      upsellProduct.displayRecordId = upsellItem.displayRecordId;
      upsellProduct.displayName = upsellItem.product.displayName;
      selectedPortion.quantity = 1;
      // Remove other portions to stop the user going back to the portion selector
      upsellProduct.portions = { [upsellItem.portionId]: selectedPortion };

      const productHasChoices = portionsHaveChoices(upsellProduct);
      if (productHasChoices) {
        getProductChoices(upsellProduct, choiceGroups, productList);
        applyDefaults(upsellProduct);
      }
      setUpsellGroup(upsellGroup);
      setUpsellProduct(upsellProduct);
    }
  };

  const createAndedUpsells = (
    product: AztecProduct,
    upsellGroup: UpsellGroup,
    productList: AztecProducts,
  ): void => {
    const andedUpsellProducts: AztecProduct[] = [];
    upsellGroup.items.forEach((upsellItem) => {
      // get the product for each upsell
      const upsellProduct = cloneDeep(productList[upsellItem.productId]);

      if (!upsellProduct) return;
      // find the portion using the item.portionId
      const selectedPortion = upsellProduct.portions[upsellItem.portionId];
      if (selectedPortion) {
        // remove other portions
        upsellProduct.portions = { [upsellItem.portionId]: selectedPortion };
        // Instructions don't have any price
        // setting the price as 0 here means we don't have to check this is lots of other components
        if (upsellProduct.isInstruction) {
          selectedPortion.price = 0;
        }
        // add isInvalid flag if there is no price or if the portion has choices
        if (
          selectedPortion.price === undefined ||
          selectedPortion.price === null ||
          selectedPortion.price < 0 ||
          portionHasChoices(selectedPortion) ||
          upsellProduct.isOutOfStock
        ) {
          upsellProduct.isInvalid = true;
        }

        upsellProduct.displayRecordId = upsellItem.displayRecordId;
        upsellProduct.displayName = upsellItem.product.displayName;
        upsellProduct.defaultPortionId = upsellItem.portionId;

        andedUpsellProducts.push(upsellProduct);
      }
    });

    // if andedUpsellProducts have >= 1 valid prducts then setAndedUpsell state
    if (
      andedUpsellProducts.length > 0 &&
      andedUpsellProducts.some((upsell) => !upsell.isInvalid)
    ) {
      product.upsellGroup = upsellGroup;
      product.andedUpsells = andedUpsellProducts;
    }
  };

  const selectUpsellProduct = () => {
    setSelectedAztecProduct(upsellProduct);
    setUpsellProduct(undefined);
    setUpsellGroup(undefined);
  };

  return (
    <MenuModalContext.Provider
      value={{
        initialised: true,
        breadcrumbs,
        handleResetMenuModal,
        handleSelectedMenuItem,
        selectedAztecProduct,
        selectUpsellProduct,
        setBreadcrumbs,
        setSelectedAztecProduct,
        updateSelectedAztecProduct,
        upsellGroup,
        upsellProduct,
      }}
    >
      {children}
    </MenuModalContext.Provider>
  );
};
