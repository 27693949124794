import { cloneDeep } from 'lodash';
import {
  AztecChoice,
  AztecProduct,
  AztecProducts,
  Choice,
  ChoiceGroups,
} from 'types/models';
import { getProductPortions } from 'menu/utils';

// ------- PRODUCT SETUP --------------

export const getProductChoices = (
  product: AztecProduct,
  choiceGroups: ChoiceGroups,
  productList: AztecProducts,
): void => {
  getProductPortions(product).forEach((portion) => {
    if (portion.choices && portion.choices.length > 0) {
      portion.choices.forEach(
        (choice, index) =>
          (portion.choices[index] = formatProductChoices(
            choice,
            choiceGroups,
            productList,
          )),
      );
    }
  });
};

export const formatProductChoices = (
  choice: AztecChoice,
  choiceGroups: ChoiceGroups,
  productList: AztecProducts,
): AztecChoice => {
  const choiceGroup = cloneDeep(choiceGroups[choice.choiceId]);

  if (choiceGroup?.choices && choiceGroup?.choices.length > 0) {
    getChoiceGroupChoices(
      choiceGroup?.choices,
      choice.displayRecordId,
      productList,
      choiceGroups,
    );
  }
  return { ...choice, choiceGroup: choiceGroup };
};

export const getChoiceGroupChoices = (
  choices: Choice[],
  choiceGroupDisplayRecordId: number,
  productList: AztecProducts,
  choiceGroups: ChoiceGroups,
): void => {
  choices.forEach((c) =>
    getDisplayRecord(c, choiceGroupDisplayRecordId, productList, choiceGroups),
  );
};

export const getDisplayRecord = (
  choice: Choice,
  choiceGroupDisplayRecordId: number,
  productList: AztecProducts,
  choiceGroups: ChoiceGroups,
): void => {
  // Get this ITEM's display record to use based on the ID of the CHOICE GROUP's display record ID
  const choiceDisplayRecordToUse = choice.displayRecords.find(
    (dispRec) => dispRec.choiceDisplayRecordId === choiceGroupDisplayRecordId,
  );

  if (choiceDisplayRecordToUse) {
    const productDispRecIdToUse =
      choiceDisplayRecordToUse.productDisplayRecordId;
    const choiceTypeIsProduct = choice.hasOwnProperty('productId');
    if (choiceTypeIsProduct) {
      const product = cloneDeep(productList[choice.productId]);
      getProductChoices(product, choiceGroups, productList);
      // Product Display Record
      choice.productToUse = product;
      choice.displayRecordToUse = product.displayRecords[productDispRecIdToUse];
    } else if (choice.choiceId) {
      const choiceGroup = cloneDeep(choiceGroups[choice.choiceId]);
      // Choice Display Record
      choice.displayRecordToUse =
        choiceGroup.displayRecords[productDispRecIdToUse];

      if (choice.displayRecordToUse && choiceGroup?.choices?.length > 0) {
        getChoiceGroupChoices(
          choiceGroup?.choices,
          choice.displayRecordToUse.id,
          productList,
          choiceGroups,
        );
        choice.choiceGroup = choiceGroup;
      }
    }
  }
};

export const applyDefaults = (product: AztecProduct): void => {
  getProductPortions(product).forEach((portion) => {
    portion.choices.forEach((c) => {
      if (c.choiceGroup && c?.choiceGroup?.defaults.length > 0) {
        c.choiceGroup.defaults.forEach((d) => {
          c.choiceGroup?.choices.forEach((cgc) => {
            if (cgc.productId === d) {
              cgc.quantity = 1;
            }
          });
        });
      }
    });
  });
};
