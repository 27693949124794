import { useServices } from 'contexts/VenueContext';
import { getServiceLabel } from 'venue/utils';

interface ServiceLabelProps {
  id: number;
}

export const ServiceLabel: React.FC<ServiceLabelProps> = ({ id }) => {
  const { services } = useServices();
  const serviceLabel = getServiceLabel(services, id);

  return <>{serviceLabel}</>;
};
