import { MdErrorOutline } from 'react-icons/md';
import { FormattedCurrency } from 'common/components/FormattedCurrency';

interface TipConflictTextProps {
  totalToUse: number;
}

export const TipConflictText: React.FC<TipConflictTextProps> = ({
  totalToUse,
}) => {
  return totalToUse === 0 ? (
    <div className="reward-conflict text-muted" data-testid="tip-conflict-text">
      <MdErrorOutline size={25} />
      Tips cannot be applied because the total is{' '}
      <FormattedCurrency value={0} />.
    </div>
  ) : null;
};
