import { Popover, Button } from 'react-bootstrap';
import { usePhrases } from 'contexts/ConfigContext';
import { CombinedVenue } from 'types/models';
import { useHistory } from 'react-router';

interface MapPopoverProps {
  venue: CombinedVenue;
}

export const MapPopover: React.FC<MapPopoverProps> = (props) => {
  const { venueTitle } = usePhrases();
  const history = useHistory();
  const { venue, ...otherProps } = props;

  const goToVenue = () => {
    history.push(`/venue/${venue.id}`);
  };

  return (
    <Popover
      placement="top"
      id={`popover-positioned-scrolling-top-venue-${venue.id}`}
      title={venue.name}
      positionTop={0}
      {...otherProps}
    >
      <Button bsStyle="primary" bsSize="sm" onClick={() => goToVenue()}>
        View {venueTitle}
      </Button>
    </Popover>
  );
};
