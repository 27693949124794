import { useEffect, useState } from 'react';

import cs from 'classnames';
import ReactModal from 'react-modal';
import { MdCancel } from 'react-icons/md';
import { Helmet } from 'react-helmet-async';
import { selectDefaultThemeColour } from 'core/selectors';
import { useSelector } from 'react-redux';
import { useBreakpoint } from 'contexts/BreakpointContext';

interface ModalProps {
  children: React.ReactNode;
  className?: string;
  datatestid?: string;
  isOpen: boolean;
  onRequestClose?: () => void;
  contentOverride?: string;
}

interface ModalHeaderProps {
  children: React.ReactNode;
  classOverride?: string;
  closeButton?: boolean;
  showCloseOnDesktop?: boolean;
  onRequestClose?: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  className,
  datatestid,
  isOpen,
  onRequestClose,
  contentOverride,
}) => {
  const [refVal, setRefVal] = useState<HTMLDivElement>();
  const [themeColour, setThemeColour] = useState<string>();
  const defaultThemeColour = useSelector(selectDefaultThemeColour);

  const { breakpoints } = useBreakpoint();

  useEffect(() => {
    if (refVal && isOpen === true) {
      const theme = getComputedStyle(refVal);

      //don't do this if it's bigger than md screen size
      (breakpoints.xs === true ||
        breakpoints.tiny === true ||
        breakpoints.sm === true) &&
      breakpoints.md === false
        ? setThemeColour(theme.backgroundColor)
        : setThemeColour(defaultThemeColour);
    }
  }, [defaultThemeColour, isOpen, refVal, themeColour, breakpoints]);

  return (
    <ReactModal
      aria-modal="true"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closeTimeoutMS={200}
      portalClassName={className}
      className={contentOverride}
      contentRef={(modalRef) => {
        setRefVal(modalRef);
      }}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
        },
      }}
      overlayClassName="modal-overlay"
      testId={datatestid}
    >
      {themeColour && (
        <Helmet>
          <meta name="theme-color" content={themeColour} />
        </Helmet>
      )}
      {children}
    </ReactModal>
  );
};

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  children,
  closeButton,
  showCloseOnDesktop,
  onRequestClose,
  classOverride,
}) => {
  const headerClasses = cs(
    'modal-header',
    closeButton && ' modal-header--icon-displayed',
    classOverride && ` ${classOverride}`,
  );

  const closeButtonClasses = cs(
    'modal-header-cancel-icon',
    closeButton && !showCloseOnDesktop && 'show-on-responsive',
    showCloseOnDesktop && 'show-on-desktop',
  );

  return (
    <div className={headerClasses}>
      <div className="modal-header-content-container">{children}</div>
      {closeButton && (
        <MdCancel
          onClick={onRequestClose}
          className={closeButtonClasses}
          role="button"
        />
      )}
    </div>
  );
};

interface ModalBodyProps {
  children: React.ReactNode;
  datatestid?: string;
}

export const ModalBody: React.FC<ModalBodyProps> = ({
  children,
  datatestid,
}) => {
  return (
    <div className="modal-body" data-testid={datatestid}>
      {children}
    </div>
  );
};

interface ModalFooterProps {
  children: React.ReactNode;
  className?: string;
}

export const ModalFooter: React.FC<ModalFooterProps> = ({
  children,
  className,
}) => {
  const footerClasses = cs('modal-footer', className);
  return <div className={footerClasses}>{children}</div>;
};

//bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement('#root');
