import { Modal } from 'components/Modal';
import { MdCancel } from 'react-icons/md';
import { CombinedVenue } from 'types/models';
import { VenueImage } from 'venue/components/SingleVenue';
import { VenueInformation } from 'venue/components/SingleVenue/VenueInfo';
import cs from 'classnames';

interface VenueInfoModalProps {
  venue: CombinedVenue;
  isOpen: boolean;
  handleClose: () => void;
}

export const VenueInfoModal: React.FC<VenueInfoModalProps> = ({
  venue,
  isOpen,
  handleClose,
}) => {
  const image = venue?.displayImages[0];
  const imageUrl = image?.url;

  const classes = cs(
    imageUrl !== null && imageUrl !== '' && imageUrl !== undefined
      ? 'venue-info-close pointer svg-outline'
      : 'venue-info-close brand-primary pointer',
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="venue-info-modal"
    >
      <VenueImage image={image} />
      <VenueInformation venue={venue} isModal={true} />
      <MdCancel
        className={classes}
        size={42}
        onClick={handleClose}
        role="button"
        tabIndex={0}
      />
    </Modal>
  );
};
