import { useEffect, useState } from 'react';

export const useExternalScript = (
  url: string,
  scriptType: string,
  scriptAsync: boolean = false,
): string => {
  const [scriptState, setScriptState] = useState<string>(
    url ? 'loading' : 'idle',
  );

  useEffect(() => {
    if (!url) {
      setScriptState('idle');
      return;
    }
    let script = document.querySelector(
      `script[src="${url}"]`,
    ) as HTMLScriptElement;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleScript = (e: Event) => {
      setScriptState(e.type === 'load' ? 'ready' : 'error');
    };

    if (!script) {
      script = document.createElement('script');
      script.type = scriptType;
      script.src = url;
      script.async = scriptAsync;
      document.body.appendChild(script);
      script.addEventListener('load', handleScript);
      script.addEventListener('error', handleScript);
    }

    return () => {
      if (script) {
        script.removeEventListener('load', handleScript);
        script.removeEventListener('error', handleScript);
        script.remove();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return scriptState;
};
