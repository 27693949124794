import { Dispatch, SetStateAction, useEffect, useRef } from 'react';

import ReactDOM from 'react-dom';
import { selectDefaultThemeColour } from 'core/selectors';
import { useSelector } from 'react-redux';
import {
  ViewBasketButton,
  CheckoutButton,
} from 'basket/components/Basket/StickyBasketComponents';
import { useBasket } from 'contexts/BasketContext';

interface StickyBasketFirstRowProps {
  getClass: () => string;
  setHeaderColour: Dispatch<SetStateAction<string>>;
}

export const StickyBasketFirstRow: React.FC<StickyBasketFirstRowProps> = ({
  getClass,
  setHeaderColour,
}) => {
  const defaultThemeColour = useSelector(selectDefaultThemeColour);
  const { isBasketCollapsed, setIsBasketCollapsed, basket } = useBasket();

  const handleBasketCollapse = () => {
    if (basket.length > 0 || !isBasketCollapsed) {
      setIsBasketCollapsed(!isBasketCollapsed);
    }
  };

  const firstRowRef = useRef<HTMLDivElement>(null);

  const calcClass = getClass();

  useEffect(() => {
    if (calcClass === 'sticky-basket-list-opened') {
      if (firstRowRef?.current) {
        // eslint-disable-next-line react/no-find-dom-node
        const element = ReactDOM.findDOMNode(firstRowRef?.current);
        const theme = getComputedStyle(element as Element);
        setHeaderColour(theme.backgroundColor);
      }
    } else {
      setHeaderColour(defaultThemeColour);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcClass, defaultThemeColour]);

  return (
    <div className="row">
      <div
        className="col-ms-12 col-md-12 sticky-basket-row basket-first-row"
        onClick={() => handleBasketCollapse()}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            handleBasketCollapse();
          }
        }}
        ref={firstRowRef}
        role="button"
        tabIndex={0}
      >
        <ViewBasketButton />
        <CheckoutButton />
      </div>
    </div>
  );
};
