import { useEffect, useState } from 'react';

import {
  ApplePay,
  ChargeToRoom,
  GooglePay,
  HostedFields,
  PayPal,
} from 'checkout/components/Payment';
import { useConfig } from 'contexts/ConfigContext';
import { useSelector } from 'react-redux';
import { selectAdditionalInfoErrorDialog } from 'checkout/selectors';
import { useHotels } from 'contexts/HotelContext';
import { selectLoginMethod, selectIsLoggedIn } from 'user/selectors';
import { PaymentContextualLoader } from 'checkout/components/Payment/ContextualLoader';
import { useExternalScript } from 'hooks/useExternalScript';
import { LoginMethod } from 'types/models/User';
import { RestartOrderDialog } from 'checkout/components/DeliveryToLocation';
import { useSalesAreas } from 'contexts/VenueContext';
import { useCheckoutDetails, usePayment } from 'contexts/CheckoutContext';
import { useGiftCard } from 'contexts/GiftCardContext';

interface PaymentFormProps {
  isPmb?: boolean;
}

export const PaymentForm: React.FC<PaymentFormProps> = ({ isPmb = false }) => {
  const { acceptsPayment } = useConfig();

  const { selectedSalesArea } = useSalesAreas();
  const {
    checkHotels,
    hotels: { hotelName },
  } = useHotels();
  const { basketTotal } = useCheckoutDetails();
  const { isApplePayEnabled, isGooglePayEnabled, isPayPalEnabled } =
    usePayment();
  const { redeemedGiftCards } = useGiftCard();

  const additionalInfoError999 = useSelector(selectAdditionalInfoErrorDialog);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const loginMethod = useSelector(selectLoginMethod);

  const [chargeToRoomEnabled, setChargeToRoomEnabled] = useState(false);

  const hasGiftCards = redeemedGiftCards.length > 0;
  const guestCheckout: boolean =
    loginMethod === LoginMethod.Anonymous ? true : false;

  const googlePayScriptState = useExternalScript(
    'https://pay.google.com/gp/p/js/pay.js',
    'text/javascript',
  );

  useEffect(() => {
    if (selectedSalesArea?.canChargeToRoom && !isPmb) {
      checkHotels();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isLoggedIn &&
      !guestCheckout &&
      typeof hotelName === 'string' &&
      !isPmb
    ) {
      setChargeToRoomEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestCheckout, hotelName, isLoggedIn]);

  if (!acceptsPayment) {
    return null;
  }

  return (
    <>
      <PaymentContextualLoader datatestid="payment-loader" isPmb={isPmb}/>
      {(basketTotal > 0 || isPmb) && <h3 className="title">Payment</h3>}
      <div className="body">
        <div className="payment-buttons">
          {isApplePayEnabled && <ApplePay isPmb={isPmb} />}
          {isGooglePayEnabled &&
            googlePayScriptState === 'ready' &&
            navigator.cookieEnabled && <GooglePay isPmb={isPmb} />}
          {isPayPalEnabled && <PayPal isPmb={isPmb} />}
          {chargeToRoomEnabled && !hasGiftCards && !isPmb && <ChargeToRoom />}
        </div>
        <HostedFields isPmb={isPmb} />
        <RestartOrderDialog
          isOpen={!!additionalInfoError999}
          title="Payment Error"
          description={additionalInfoError999}
        />
      </div>
    </>
  );
};
