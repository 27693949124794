import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import { useAdditionalInformation } from 'contexts/AdditionalInformationContext';
import { FieldGenerator } from 'core/components/RHF';
import { usePhrases } from 'contexts/ConfigContext';
import { Field } from 'types/models/Forms';

interface PayPalAdditionalFieldsModalProps {
  shown: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
  handleFormFill: (field: Field) => Field;
}

export const PayPalAdditionalFieldsModal: React.FC<
  PayPalAdditionalFieldsModalProps
> = ({ shown, handleConfirm, handleClose, handleFormFill }) => {
  const { userInformationFields } = useAdditionalInformation();
  const {
    additionalInformation: { usagePhrase },
  } = usePhrases();

  return (
    <Modal isOpen={shown}>
      <ModalHeader>
        <h2 className="title">We just need to confirm your details...</h2>
        <div>{usagePhrase}</div>
      </ModalHeader>
      <ModalBody>
        <>
          {userInformationFields
            .filter((userField) => userField.showInUI)
            .map((field) => (
              <FieldGenerator field={handleFormFill(field)} key={field.name} />
            ))}
        </>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-primary" onClick={handleConfirm}>
          Confirm and Pay
        </button>
        <button
          className="btn btn-default hide-modal-button"
          onClick={handleClose}
        >
          Cancel
        </button>
      </ModalFooter>
    </Modal>
  );
};
