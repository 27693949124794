import { useEffect, useState } from 'react';
import { ModalFooter, ModalBody, ModalHeader } from 'components/Modal';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { getLoyaltyLinkTitle } from 'utils';
import { ConfigField, WLAConfigField } from 'types/models/Forms';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { Button } from 'react-bootstrap';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { useSelector } from 'react-redux';
import { selectUserLoyaltyCardNumber } from 'user/selectors';
import { ContextualLoader } from 'components/ContextualLoader';
import { isWebOrderingConfig } from 'types/guards';
import { FormGenerator, WLASchemaFormGenerator } from 'core/components/RHF';

interface VerifyLoyaltyProps {
  closeModal: () => void;
}

export const VerifyLoyaltyModalBody: React.FC<VerifyLoyaltyProps> = ({
  closeModal,
}) => {
  const { verifyUserLoyalty, isVerifying, resendPin } = useLoyaltyRewards();
  const {
    loyalty: { cardPhrase, linkButtonText, linkTitleText },
  } = usePhrases();
  const methods = useForm();
  const userLoyaltyNumber = useSelector(selectUserLoyaltyCardNumber);
  const { forms } = useConfig();
  const { showResetLoyaltyPin } = useConfig();
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  const hasLoyaltyForm =
    forms && forms.linkLoyaltyAccount && forms.linkLoyaltyAccount[0];

  const setCardNumberDisabledWO = (
    formFields: ConfigField[],
  ): ConfigField[] => {
    const sanitisedFormData = formFields;
    formFields.forEach((row) =>
      row.fields.forEach((field) => {
        if (field.name === 'loyalty_card') {
          field.disabled = true;
        }
      }),
    );
    return sanitisedFormData;
  };

  const setCardNumberDisabledWLA = (
    formFields: WLAConfigField[],
  ): WLAConfigField[] => {
    const sanitisedFormData = formFields;
    formFields.forEach((field) => {
      if (field.name === 'loyalty_card') {
        field.disabled = true;
      }
    });
    return sanitisedFormData;
  };

  const handleVerify: SubmitHandler<FieldValues> = (formData) => {
    verifyUserLoyalty(formData);
  };

  const handleResendPin = () => {
    setIsResendDisabled(true);
    resendPin(userLoyaltyNumber);
    setTimeout(() => {
      setIsResendDisabled(false);
    }, 20000);
  };

  useEffect(() => {
    methods.setValue('loyalty_card', userLoyaltyNumber.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalHeader closeButton onRequestClose={closeModal} showCloseOnDesktop>
        <h2 className="title">
          {getLoyaltyLinkTitle(linkTitleText, cardPhrase)}
        </h2>
      </ModalHeader>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(handleVerify)}
          className="loyalty-verify-form"
          data-testid="verify-loyalty-form"
        >
          <ModalBody>
            {/* How to disable the card number field? */}
            {hasLoyaltyForm &&
            isWebOrderingConfig(forms.linkLoyaltyAccount[0]) ? (
              <div className="col-sm-12">
                <FormGenerator
                  definition={setCardNumberDisabledWO(
                    forms.linkLoyaltyAccount as ConfigField[],
                  )}
                />
              </div>
            ) : (
              <div className="col-sm-12">
                <WLASchemaFormGenerator
                  definition={setCardNumberDisabledWLA(
                    forms.linkLoyaltyAccount as WLAConfigField[],
                  )}
                />
              </div>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              className="btn btn-primary"
              data-testid="verify-loyalty-btn"
              bsStyle="primary"
              type="submit"
              onClick={methods.handleSubmit(handleVerify)}
            >
              {linkButtonText}
            </Button>
            {showResetLoyaltyPin && (
              <Button
                className="btn btn-default resend-pin-btn"
                onClick={handleResendPin}
                disabled={isResendDisabled}
              >
                Resend PIN
              </Button>
            )}
          </ModalFooter>
        </form>
      </FormProvider>
      <ContextualLoader isVisible={isVerifying} />
    </>
  );
};
