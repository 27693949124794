import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { FormattedNumber } from 'react-intl';
import { UpsellModalFooter } from './UpsellModalFooter';
import { useConfig } from 'contexts/ConfigContext';
import { useMenuModal } from 'contexts/MenuModalContext';
import cs from 'classnames';
import { getSelectedPortion } from 'menu/utils';
import { CalorieAmountDisplay } from 'menu/components/Calories';

export const UpsellModal: React.FC = () => {
  const { currency, currencySymbolsDisabled } = useConfig();
  const { upsellGroup, upsellProduct, selectedAztecProduct } = useMenuModal();

  const showModal =
    upsellGroup !== undefined &&
    upsellProduct !== undefined &&
    selectedAztecProduct === undefined;

  if (!showModal) {
    return null;
  }

  const upsellDisplayRecord =
    upsellProduct.displayRecords[upsellProduct.displayRecordId];
  const selectedPortion = getSelectedPortion(upsellProduct);

  const upsellPriceClass = cs(
    'col-xs-12',
    upsellDisplayRecord.image
      ? 'upsell-image-buffer upsell-price'
      : 'upsell-price',
  );

  return (
    <Modal isOpen={showModal}>
      <ModalHeader>
        <span className="h2">
          <div className="row">
            <div className="col-xs-12">{upsellDisplayRecord.description}</div>
          </div>
        </span>
        <CalorieAmountDisplay
          displayRecord={upsellDisplayRecord}
          portions={[selectedPortion]}
        />
      </ModalHeader>
      <ModalBody>
        {upsellDisplayRecord.image ? (
          <div className="row">
            <div className="col-xs-12">
              <img
                src={upsellDisplayRecord.image}
                height={200}
                width={200}
                className="center-image"
                alt={upsellDisplayRecord.description}
              />
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className={upsellPriceClass}>
            Add for{' '}
            <FormattedNumber
              style={currencySymbolsDisabled ? 'decimal' : 'currency'}
              value={selectedPortion.price}
              currency={currency}
              minimumFractionDigits={2}
            />
          </div>
        </div>
      </ModalBody>
      <UpsellModalFooter />
    </Modal>
  );
};
