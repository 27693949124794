import { useEffect, useState } from 'react';

import { ModalBody, ModalFooter } from 'components/Modal';
import { Folio } from 'types/models/Hotels';
import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { useSelector } from 'react-redux';
import { selectTipAmount } from 'checkout/selectors';
import { FolioChoice } from './FolioChoice';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { useHotels } from 'contexts/HotelContext';
import { PaymentContextualLoader } from 'checkout/components/Payment/ContextualLoader';
import { useCheckoutDetails } from 'contexts/CheckoutContext';

interface FolioSelectorProps {
  handleClose: () => void;
  roomFolios: Folio[];
  errorMessage: string;
  resetError: () => void;
}

export const FolioSelectorModal: React.FC<FolioSelectorProps> = ({
  handleClose,
  roomFolios,
  errorMessage,
  resetError,
}) => {
  const {
    chargeToRoom: { folioSelector },
    roomPhrase,
  } = usePhrases();
  const { shouldPrefixRoomPhrase } = useConfig();
  const {
    chargeToRoom: { isSubmittingChargeToRoom },
    folios: { roomNumber },
    makeChargeToRoom,
  } = useHotels();
  const [selectedFolio, setSelectedFolio] = useState<Folio | null>(null);
  const tipAmount = useSelector(selectTipAmount);
  const { basketTotal } = useCheckoutDetails();
  const totalAmount = Number(basketTotal) + Number(tipAmount);

  useEffect(() => {
    if (roomFolios.length === 1) {
      setSelectedFolio(roomFolios[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFolioSelect = (folio: Folio) => {
    setSelectedFolio(folio);
  };

  const handleChargeFolio = () => {
    resetError();
    makeChargeToRoom(selectedFolio);
  };

  return (
    <>
      <ModalBody>
        <PaymentContextualLoader />
        <div className="redemption-modal-header">
          <h2>
            <FormattedCurrency value={totalAmount} />
          </h2>
          <span className="text-muted">Amount to charge</span>
        </div>
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
        <fieldset className="folio-list">
          {roomFolios.map((folio: Folio, index) => {
            return (
              <FolioChoice
                folio={folio}
                isSelected={selectedFolio?.folioId === folio.folioId}
                handleFolioSelect={handleFolioSelect}
                key={index}
              />
            );
          })}
        </fieldset>
        {folioSelector.supportingText && (
          <div>{folioSelector.supportingText}</div>
        )}
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-primary"
          data-testid="btn-charge-to-room-submit"
          onClick={handleChargeFolio}
          disabled={(selectedFolio ? false : true) || isSubmittingChargeToRoom}
        >
          Charge {shouldPrefixRoomPhrase && <span>{roomPhrase}</span>}{' '}
          {roomNumber}
        </button>
        <button
          className="btn btn-default hide-modal-button"
          data-testid="btn-charge-to-room-cancel"
          onClick={handleClose}
          disabled={isSubmittingChargeToRoom}
        >
          Cancel
        </button>
      </ModalFooter>
    </>
  );
};
