import { AztecPortion, DisplayRecord } from 'types/models';

export const getCalories = (
  displayRecord: DisplayRecord,
  portions: AztecPortion[],
): string => {
  if (hasCalories(displayRecord?.calories)) {
    return `${displayRecord.calories}`;
  }

  if (portions.length === 1 && hasCalories(portions[0]?.calories)) {
    return `${portions[0].calories}`;
  }

  if (portions.length > 1) {
    const portionCaloriesArray = portions
      .filter((p) => hasCalories(p.calories))
      .map((portion) => portion.calories as number);

    if (portionCaloriesArray.length > 0) {
      return `From ${Math.min(...portionCaloriesArray)}`;
    }
  }

  return '';
};

export const hasCalories = (calories: number | null | undefined): boolean => {
  return calories !== null && calories !== undefined && calories >= 0;
};
