import { useBasket } from 'contexts/BasketContext';
import { QuantityField } from 'menu/components/MenuModal/ModalComponents';
import { AztecProduct } from 'types/models';
import { getSelectedPortion } from 'menu/utils';

interface BasketLinePortionProps {
  line: AztecProduct;
  idx: number;
}

export const BasketLinePortion: React.FC<BasketLinePortionProps> = ({
  line,
  idx,
}) => {
  const selectedPortion = getSelectedPortion(line);
  const { updateBasketQuantity } = useBasket();
  const onUpdateBasketQuantity = (idx: number, quantity: number) => {
    updateBasketQuantity(idx, quantity, selectedPortion.id);
  };

  return (
    <div className="basket-portion line-portion-copy user-select-none">
      <QuantityField
        callbackArgs={[idx]}
        onChange={onUpdateBasketQuantity}
        quantity={selectedPortion.quantity}
        min={1}
        max={99}
      />{' '}
      <span className="times">x {selectedPortion.name}</span>
    </div>
  );
};
