import { useConfig } from 'contexts/ConfigContext';

interface FormattedCurrencyProps {
  value: number | bigint;
}

export const FormattedCurrency: React.FC<FormattedCurrencyProps> = ({
  value,
}) => {
  const { currency, currencySymbolsDisabled } = useConfig();

  return (
    <>
      {new Intl.NumberFormat(navigator.language, {
        style: currencySymbolsDisabled ? 'decimal' : 'currency',
        currency: currency,
        minimumFractionDigits: 2,
      }).format(value)}
    </>
  );
};
