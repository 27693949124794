import { Button } from 'react-bootstrap';
import {
  CustomButtonText,
  TipPresetButtonText,
} from 'checkout/components/Tip/components';

interface TipPresetButtonProps {
  calculateTipAmount: (preset: number) => string;
  classNames: (preset: number) => string;
  handlePresetClick: (preset: number) => void;
  preset: number;
  totalToUse: number;
}

export const TipPresetButton: React.FC<TipPresetButtonProps> = ({
  calculateTipAmount,
  classNames,
  handlePresetClick,
  preset,
  totalToUse
}) => {

  return (
    <Button
      id={`tip-preset-button-id${preset}`}
      onClick={() => {
        handlePresetClick(preset);
      }}
      data-testid={`tip-preset-button-${preset}`}
      className={classNames(preset)}
      disabled={totalToUse === 0}
    >
      {preset >= 0 ? (
        <TipPresetButtonText
          calculateTipAmount={calculateTipAmount}
          preset={preset}
        />
      ) : (
        <CustomButtonText />
      )}
    </Button>
  );
};
