import {
  AztecProduct,
  AztecPortion,
  AztecPortions,
  PortionRatio,
} from 'types/models';

export const getProductPortions = (product: AztecProduct): AztecPortion[] =>
  product?.portions ? Object.values(product.portions) : [];

export const productPortionsCount = (product: AztecProduct): number =>
  getProductPortions(product).length;

export const getSelectedPortionId = (portions: AztecPortions): number => {
  const selectedPortion = Object.values(portions).find(
    (p) => p.quantity && p.quantity > 0,
  );
  return selectedPortion ? selectedPortion.id : -1;
};

export const getSelectedPortion = (product: AztecProduct): AztecPortion =>
  product.portions[getSelectedPortionId(product.portions)];

export const portionsHaveChoices = (product: AztecProduct): boolean => {
  return Object.values(product.portions).some((portion) =>
    portionHasChoices(portion),
  );
};

export const portionHasChoices = (portion: AztecPortion): boolean =>
  portion.choices && portion.choices.length > 0;

export const sortPortionsByPriceThenId = (
  portions: AztecPortion[],
): AztecPortion[] => portions.toSorted((a, b) => a.price - b.price || a.id - b.id);

export const doesPortionHaveValidPrice = (portion: AztecPortion): boolean =>
  'price' in portion && portion.price >= 0;

export const updatePortionQuantity = (
  product: AztecProduct,
  portionId: number,
  quantity: number,
): AztecProduct => {
  const updatedProduct = { ...product };
  updatedProduct.portions[portionId].quantity = quantity;
  return updatedProduct;
};

export const getChildPortionId = (
  portionRatios: PortionRatio[],
  parentProductId: number,
  parentPortionId: number,
): number => {
  const matchedPortionRatio = portionRatios.find(
    (pr) => pr.productId === parentProductId && pr.parent === parentPortionId,
  );

  return matchedPortionRatio ? matchedPortionRatio.child : -1;
};
