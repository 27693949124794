import { useAPI } from 'api/useAPI';
import { requestBodyFormatter } from 'api/utils';
import axios, { AxiosRequestConfig } from 'axios';
import { useServices, useVenues } from 'contexts/VenueContext';
import { createContext, useContext, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import {
  ErrorResponse,
  GetAdditionalOrderInformationResponse,
  ServiceType,
} from 'types/models';
import {
  AdditionalInfoField,
  LocationInfoFormProps,
} from 'types/models/AdditionalInformation';
import { Field } from 'types/models/Forms';
import { selectIsLoggedIn } from 'user/selectors';

interface AdditionalInformationContext {
  initialised: boolean;
  isFetchingAdditionalInfo: boolean;
  locationInformationFields: Field[];
  userInformationFields: Field[];
  additionalInformationError: ErrorResponse | null;
  savedLocationInformation: AdditionalInfoField;
  handleSaveLocationInformation: (formValues: LocationInfoFormProps) => void;
  getAdditionalInfo: () => void;
  isUserFieldRequired: (fieldName: string) => boolean;
  resetState: () => void;
}

// set up context
export const AdditionalInformationContext =
  createContext<AdditionalInformationContext>({
    initialised: false,
    isFetchingAdditionalInfo: false,
    userInformationFields: [],
    locationInformationFields: [],
    additionalInformationError: null,
    savedLocationInformation: {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    getAdditionalInfo: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleSaveLocationInformation: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    isUserFieldRequired: () => false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    resetState: () => {},
  });

// set up hook
export const useAdditionalInformation = (): AdditionalInformationContext => {
  const consumer = useContext(AdditionalInformationContext);

  // Condition used to determine if Context Provider has been declared
  if (!consumer.initialised) {
    throw new Error('AdditionalInformationProvider not initialised');
  }

  return consumer;
};

interface AdditionalInformationProviderProps {
  children: React.ReactNode;
}

// set up provider
export const AdditionalInformationProvider: React.FC<
  AdditionalInformationProviderProps
> = ({ children }) => {
  const { selectedVenue } = useVenues();
  const { selectedService } = useServices();
  const { url, getAdditionalInformation } = useAPI();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  //
  // **************************************************************************//
  const [isFetchingAdditionalInfo, setIsFetchingAdditionalInfo] =
    useState<boolean>(false);
  const [additionalInfoLoading, setAdditionalInfoLoading] =
    useState<boolean>(false);
  const [userInformationFields, setUserInformationFields] = useState<Field[]>(
    [],
  );
  const [locationInformationFields, setLocationInformationFields] = useState<
    Field[]
  >([]);
  const [savedLocationInformation, setSavedLocationInformation] =
    useState<AdditionalInfoField>({});
  const [additionalInformationError, setAdditionalInformationError] =
    useState<ErrorResponse | null>(null);
  // **************************************************************************//

  const getAdditionalInfo = () => {
    setIsFetchingAdditionalInfo(true);
  };

  const normaliseAdditionalInfoData = (
    locationFields: Field[],
    userFields: Field[],
  ) => {
    const userFieldsToKeep: Field[] = [];
    const userFieldsToLocationFields: Field[] = [];
    if (userFields.length > 0) {
      userFields.forEach((field) => {
        field?.userFieldType === ''
          ? userFieldsToLocationFields.push(field)
          : userFieldsToKeep.push(field);
      });
    }

    setUserInformationFields(userFieldsToKeep);
    setLocationInformationFields([
      ...locationFields,
      ...userFieldsToLocationFields,
    ]);
  };

  useEffect(() => {
    if (isLoggedIn && selectedService !== -1) {
      setIsFetchingAdditionalInfo(true);
    }
  }, [isLoggedIn, selectedService]);

  useEffect(() => {
    if (isFetchingAdditionalInfo) {
      fetchAdditionalInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingAdditionalInfo]);

  const fetchAdditionalInfo = () => {
    setAdditionalInformationError(null);

    if (!additionalInfoLoading) {
      setAdditionalInfoLoading(true);
      const getAdditionalInformationConfig = getAdditionalInformation();

      const additionalInfoOptions: AxiosRequestConfig = {
        url,
        method: 'POST',
        headers: getAdditionalInformationConfig.headers,
        data: requestBodyFormatter({
          ...getAdditionalInformationConfig.body,
          version: '20',
          method: getAdditionalInformationConfig.method,
          siteId: selectedVenue?.id,
          orderModeId: selectedService,
        }),
      };

      axios(additionalInfoOptions)
        .then((response) => {
          const data = response.data as GetAdditionalOrderInformationResponse;
          if (data.response === 'OK') {
            if (
              data.additionalInformation.length > 0 ||
              data.locationInformation.length > 0
            ) {
              normaliseAdditionalInfoData(
                data.locationInformation,
                data.additionalInformation,
              );
            } else if (selectedService === ServiceType.DeliveryToLocation) {
              setAdditionalInformationError({
                response: 'error',
                code: 0,
                detail:
                  'Unfortunately Delivery to Location is not currently available',
              });
            }
          } else {
            const err = response.data as ErrorResponse;
            setAdditionalInformationError(err);
          }
        })
        .catch(() => {
          setAdditionalInformationError({
            response: 'error',
            code: 401,
            detail: 'An unexpected error occurred, please try again.',
          });
        })
        .finally(() => {
          setIsFetchingAdditionalInfo(false);
          setAdditionalInfoLoading(false);
        });
    }
  };

  const handleSaveLocationInformation = (formValues: LocationInfoFormProps) => {
    let field_1Val = undefined;
    let field_2Val = undefined;

    if (formValues?.field_1 !== undefined) {
      if (typeof formValues?.field_1 === 'string') {
        field_1Val =
          formValues.field_1.trim() === '' ? '-' : formValues.field_1;
      } else {
        field_1Val = formValues.field_1?.value;
      }
    }

    if (formValues?.field_2 !== undefined) {
      if (typeof formValues.field_2 === 'string') {
        field_2Val =
          formValues.field_2.trim() === '' ? '-' : formValues.field_2;
      } else {
        field_2Val = formValues.field_2?.value;
      }
    }

    const formattedLocationInfo: AdditionalInfoField = {
      field_1: field_1Val,
      field_2: field_2Val,
    };

    setSavedLocationInformation(formattedLocationInfo);
  };

  const resetState = () => {
    setIsFetchingAdditionalInfo(false);
    setAdditionalInfoLoading(false);
    setAdditionalInformationError(null);
    setSavedLocationInformation({});
    setLocationInformationFields([]);
    setUserInformationFields([]);
  };

  const isUserFieldRequired = (fieldName: string) => {
    return userInformationFields.some(
      (field) => field.userFieldType === fieldName && field.showInUI,
    );
  };

  useEffect(() => {
    resetState();
  }, [selectedService]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  return (
    <AdditionalInformationContext.Provider
      value={{
        initialised: true,
        isFetchingAdditionalInfo,
        locationInformationFields,
        userInformationFields,
        additionalInformationError,
        savedLocationInformation,
        handleSaveLocationInformation,
        getAdditionalInfo,
        isUserFieldRequired,
        resetState,
      }}
    >
      {children}
    </AdditionalInformationContext.Provider>
  );
};
