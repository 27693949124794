import { VenueSalesArea, CombinedVenue } from 'types/models';

export const getRoomPhrase = (phrase: string, room: string): string => {
  return phrase.replace('$roomPhrase$', room);
};

export const getRoomSubtitlePhrase = (
  phrase: string,
  roomPhrase: string,
  venue: CombinedVenue,
  salesArea: VenueSalesArea,
  hotelPhrase: string,
  hotelName: string | undefined,
  serviceLabel: string,
): string => {
  return phrase
    .replace('$roomPhrase$', roomPhrase)
    .replace('$siteName$', boldPhrase(venue.name))
    .replace(
      '$salesAreaName$',
      boldPhrase(salesArea.friendly || salesArea.name),
    )
    .replace('$hotelPhrase$', boldPhrase(hotelPhrase))
    .replace('$hotelName$', boldPhrase(hotelName || ''))
    .replace('$siteTown$', boldPhrase(venue.address.town))
    .replace('$siteCounty$', boldPhrase(venue.address.county))
    .replace('$orderMode$', boldPhrase(serviceLabel));
};

const boldPhrase = (phrase: string) => {
  return `<b>${phrase}</b>`;
};
