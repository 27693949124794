import cs from 'classnames';
import { AztecProduct } from 'types/models';
import { Checkbox } from 'react-bootstrap';
import { ChoicePriceLabel } from 'menu/components/MenuModal/ModalComponents';
import { Keywords } from 'menu/components/Keywords';
import { MinimumAgeBadge } from 'menu/components/MinimumAge';
import { MenuItemDescription } from 'menu/components/MenuItemDescription';
import { CalorieAmountDisplay } from 'menu/components/Calories';

interface AndedUpsellItemProps {
  andedUpsell: AztecProduct;
  upsellIndex: number;
  handleUpsellItemClick: (upsellIndex: number) => void;
}

export const AndedUpsellItem: React.FC<AndedUpsellItemProps> = ({
  andedUpsell,
  upsellIndex,
  handleUpsellItemClick,
}) => {
  const portionToUse = andedUpsell.portions[andedUpsell.defaultPortionId];
  const displayRecord = andedUpsell.displayRecords[andedUpsell.displayRecordId];
  const isSelected = Boolean(
    portionToUse.quantity && portionToUse.quantity > 0,
  );
  const isInvalid = andedUpsell.isInvalid;
  const minimumAge = andedUpsell.minimumCustomerAge;

  const inputClass = cs(
    isSelected && 'on',
    isInvalid && 'out-of-stock',
    'no-flex-shrink',
  );

  const detailsClass = cs(
    'item-details',
    isInvalid ? 'out-of-stock' : undefined,
  );

  return (
    <div className="menu-item choice-row">
      <div className="choice-section flex-grow">
        <Checkbox
          inline
          checked={isSelected}
          className={inputClass}
          disabled={isInvalid}
          onChange={() => handleUpsellItemClick(upsellIndex)}
          data-testid="anded-item-choice-checkbox"
        />
        <div className={detailsClass}>
          <div className="anded-item-header">
            <span className="item-name" data-testid="anded-choice-item-name">
              {andedUpsell.displayName}
            </span>
            <span className="product-keywords">
              {minimumAge ? <MinimumAgeBadge minimumAge={minimumAge} /> : null}
              {displayRecord && displayRecord?.keywords?.length > 0 ? (
                <Keywords list={displayRecord.keywords} />
              ) : null}
            </span>
          </div>
          <CalorieAmountDisplay
            displayRecord={displayRecord}
            portions={[portionToUse]}
          />
          {displayRecord?.description ? (
            <MenuItemDescription description={displayRecord.description} />
          ) : null}
        </div>
      </div>
      <div className="choice-section item-price-selector">
        {portionToUse.price && portionToUse.price !== 0 && !isInvalid ? (
          <ChoicePriceLabel priceToDisplay={portionToUse.price} />
        ) : null}
      </div>
    </div>
  );
};
