import { useConfig, useSetConfig } from 'contexts/ConfigContext';

import { ControlLabel, FormControl } from 'react-bootstrap';

export const GoogleMapsApiKey: React.FC = () => {
  const { googleMapsApiKey } = useConfig();
  const setConfig = useSetConfig();

  const handleChange = (event: React.ChangeEvent<FormControl>) => {
    const value: string = (event.target as unknown as HTMLInputElement).value;
    setConfig('googleMapsApiKey', value);
  };

  return (
    <div>
      <ControlLabel className="ff-label">Google Maps API Key:</ControlLabel>
      <FormControl
        data-testid={`ff-googleMapsApiKey`}
        id={`ff-googleMapsApiKey`}
        type="text"
        value={googleMapsApiKey}
        placeholder="Enter text"
        onChange={handleChange}
      />
    </div>
  );
};
