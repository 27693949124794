import {
  BasketAddition,
  PromotionLine as PromotionLineType,
} from 'types/models/Basket';
import { useMenus } from 'contexts/MenuContext';
import { Course } from 'types/models';
import {
  BasketAdditionLine,
  BasketLine,
  CourseHeader,
  PromotionLine,
} from 'checkout/components/CheckoutBasket/BasketLines';
import { Fragment } from 'react';
import { useBasket } from 'contexts/BasketContext';
import { useCheckoutAdditions } from 'contexts/CheckoutContext';

export const BasketLines: React.FC = () => {
  const { courses } = useMenus();
  const { basket } = useBasket();
  const { basketAdditions, promotions } = useCheckoutAdditions();

  const courseIds = basket
    .map((line) => line.defaultCourseId)
    .concat(basketAdditions.map((addition) => addition.courseId));

  const existingCourses = courses
    .filter((course) => courseIds.includes(course.id))
    .sort((foundCourse) => foundCourse.sortOrder);

  const courseCount = (currentCourse: Course) => {
    return courseIds.filter((course) => course == currentCourse.id).length;
  };

  const additionalPromotionLines = promotions.filter(
    (promotion: PromotionLineType) => !courseIds.includes(promotion.courseId),
  );

  return (
    <>
      {existingCourses.map((existingCourse) => {
        return (
          <Fragment key={existingCourse.id}>
            <CourseHeader
              course={existingCourse.displayName}
              count={courseCount(existingCourse)}
            />
            {basket
              .filter((line) => line.defaultCourseId == existingCourse.id)
              .map((basketLine) => (
                <BasketLine key={basketLine.basketId} line={basketLine} />
              ))}
            {basketAdditions
              .filter((addition) => addition.courseId == existingCourse.id)
              .map((line: BasketAddition, index: number) => (
                <BasketAdditionLine key={index} basketAddition={line} />
              ))}

            {promotions
              .filter(
                (promotion: PromotionLineType) =>
                  promotion.courseId == existingCourse.id,
              )
              .map((promotionLine: PromotionLineType) => (
                <PromotionLine
                  promotion={promotionLine}
                  key={promotionLine.promotionId}
                />
              ))}
          </Fragment>
        );
      })}
      {additionalPromotionLines && additionalPromotionLines.length > 0 && (
        <div className="additional-promotions">
          <hr />
          {additionalPromotionLines.map((promotionLine) => (
            <PromotionLine
              promotion={promotionLine}
              key={promotionLine.promotionId}
            />
          ))}
        </div>
      )}
    </>
  );
};
