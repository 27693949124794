import { usePayMyBill } from 'contexts/PayMyBillContext/PayMyBillContext';
import { useEffect, useState } from 'react';
import { VenueErrorPanel } from 'venue/components/SingleVenue';
import { ReviewBillBody } from 'PayMyBill/bill/ReviewBillBody';
import { BillMode } from 'types/models/PayMyBill';
import { PartPaymentBody } from 'PayMyBill/bill/components/Payment/PartPaymentBody';
import { useHistory } from 'react-router';
import { CombinedVenue } from 'types/models';

interface BillPageBodyProps {
  isDeeplink: boolean;
  selectedVenue: CombinedVenue;
}

export const BillPageBody: React.FC<BillPageBodyProps> = ({
  isDeeplink,
  selectedVenue,
}) => {
  const history = useHistory();
  const { bill, accountError, handleGoToCheckout } = usePayMyBill();
  const [mode, setMode] = useState<BillMode>(BillMode.showBill);

  const changeDisplayMode = (newMode: BillMode) => {
    setMode(newMode);
  };

  const handlePaymentAmount = (amount: number) => {
    handleGoToCheckout(amount);
  };

  useEffect(() => {
    if (accountError) {
      history.push(`/venue/${selectedVenue.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountError]);

  if (accountError || !bill) {
    return (
      <VenueErrorPanel title="Unfortunately, we couldn't retrieve the bill for this table. Please contact a member of staff to pay your bill" />
    );
  }

  return (
    <div>
      {mode === BillMode.showBill ? (
        <ReviewBillBody
          bill={bill}
          handleChangeDisplay={changeDisplayMode}
          handlePaymentAmount={handlePaymentAmount}
          isDeeplink={isDeeplink}
        />
      ) : (
        <PartPaymentBody
          bill={bill}
          billMode={mode}
          handleChangeDisplay={changeDisplayMode}
          handlePaymentAmount={handlePaymentAmount}
        />
      )}
    </div>
  );
};
