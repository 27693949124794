import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { useCheckoutDetails, useTaxes } from 'contexts/CheckoutContext';
import { useGiftCard } from 'contexts/GiftCardContext';
import { useVouchers } from 'contexts/VoucherContext';

export const SubTotal: React.FC = () => {
  const { basketTotal } = useCheckoutDetails();
  const { taxLines } = useTaxes();
  const { redeemedGiftCards } = useGiftCard();
  const { redeemedVouchers } = useVouchers();

  const voucherTotal = redeemedVouchers.reduce((a, b) => a + b.Amount, 0);
  const giftCardTotal = redeemedGiftCards?.reduce((a, b) => a + b.amount, 0);
  const taxTotal = taxLines.reduce((a, b) => a + b.amount, 0);

  const voucherCheckedTotal = isNaN(voucherTotal) ? 0 : Number(voucherTotal);
  const giftCardCheckedTotal = isNaN(giftCardTotal) ? 0 : Number(giftCardTotal);

  const subTotal =
    Number(basketTotal) -
    Number(taxTotal) -
    voucherCheckedTotal +
    giftCardCheckedTotal;

  return (
    <div className="total subtotal">
      <div>Subtotal</div>
      <div data-testid="Subtotal">
        <FormattedCurrency value={subTotal < 0 ? 0 : subTotal} />
      </div>
    </div>
  );
};
