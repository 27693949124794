import { isAccountItemChoice } from 'PayMyBill/utils/sharedUtils';
import { AccountItem, AccountItemChoice } from 'types/models/PayMyBill';

interface BillProductSubtextProps {
  item: AccountItem;
}

export const BillProductSubtext: React.FC<BillProductSubtextProps> = ({
  item,
}) => {
  const hasPortion = Boolean(item.portion);
  const hasChoiceLines = Boolean(item.choiceLines);
  const list = [];

  hasPortion && item.portion.name && list.push(item.portion.name);

  const printChoiceLines = (item: AccountItem | AccountItemChoice) => {
    if (item.choiceLines) {
      item.choiceLines.forEach((line) => {
        if (isAccountItemChoice(line)) {
          list.push(line.display.name);
          if (line.choiceLines) {
            printChoiceLines(line);
          }
        }
      });
    }
  };

  hasChoiceLines && printChoiceLines(item);

  return <>{list.join(', ')}</>;
};
