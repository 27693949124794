import { useState, useEffect } from 'react';
import { usePhrases } from 'contexts/ConfigContext';
import { CombinedVenue, ServiceType, VenueSalesArea } from 'types/models';
import { getOrderLocationPhrase } from 'utils';
import { getServiceLabel, isTimeslotService } from 'venue/utils';
import { Order } from 'types/models/Orders';
import { OrderServiceInformation } from 'order-history/components/Order';
import { useServices, useVenues } from 'contexts/VenueContext';
import { VenueAddress } from 'venue/components/SingleVenue/VenueInfo';

interface OrderVenueInformationProps {
  orderService: number;
  order: Order;
}

export const OrderVenueInformation: React.FC<OrderVenueInformationProps> = ({
  orderService,
  order,
}) => {
  const { venueSalesAreaFormat } = usePhrases();

  const [orderLocationName, setOrderLocationName] = useState<string>();

  const { venues } = useVenues();
  const { services } = useServices();
  const foundVenue = venues.find(
    (venue: CombinedVenue) => venue.id === order.venueId,
  );

  useEffect(() => {
    if (order && foundVenue) {
      if (foundVenue.salesArea.length > 1) {
        const salesAreaOrderedFrom = foundVenue.salesArea.find(
          (sa: VenueSalesArea) => sa.id === order.salesAreaId,
        );

        setOrderLocationName(
          salesAreaOrderedFrom
            ? getOrderLocationPhrase(
                venueSalesAreaFormat,
                foundVenue,
                salesAreaOrderedFrom,
              )
            : '',
        );
      } else {
        setOrderLocationName(order.venue.name ? order.venue.name : 'unknown');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!order) {
    return null;
  }

  return (
    <div className="panel panel-default">
      {isTimeslotService(orderService) && (
        <span>Order for {getServiceLabel(services, orderService)} at</span>
      )}
      {orderService === ServiceType.OrderAndPay && <span>Ordered at </span>}
      <h4>{orderLocationName}</h4>
      <VenueAddress
        address={order.venue.address}
        separator="<br/>"
        className="text-muted"
      />
      <OrderServiceInformation order={order} orderService={orderService} />
    </div>
  );
};
