import { BillProductSubtext } from 'PayMyBill/bill/components/Products/BillProductSubtext';
import { calculateItemCost } from 'PayMyBill/utils/sharedUtils';
import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { AccountItem } from 'types/models/PayMyBill';

interface BillProductLineProps {
  item: AccountItem;
  isReduction?: boolean;
  additionalClasses?: string;
}

export const BillProductLine: React.FC<BillProductLineProps> = ({
  item,
  isReduction,
  additionalClasses = '',
}) => {
  if (!item.portion) {
    return null;
  }

  const value = calculateItemCost(item);
  const hasQuantity = Boolean(item.quantity);

  return (
    <div className={`bill-line ${additionalClasses}`}>
      <div className="bill-description">
        {hasQuantity ? <div className="bill-qty">{item.quantity}x</div> : null}
        <div>
          <div>{item.display.name}</div>
          <BillProductSubtext item={item} />
        </div>
      </div>
      <div className="text-right">
        <FormattedCurrency value={isReduction ? -value : value} />
      </div>
    </div>
  );
};
