import { useEffect, useState } from 'react';
import cs from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import {
  MenuGroup,
  MenuGroupItemProduct,
  HyperlinkUiSpecificData,
} from 'types/models';
import { useDispatch } from 'react-redux';
import { addNotification, removeNotification } from 'core/actions';
import { MenuDisplayItem } from 'menu/components/MenuDisplayItems';
import { useConfig } from 'contexts/ConfigContext';
import { MenuBasket } from 'basket/components/Basket';
import {
  MenuSearchBar,
  MenuNavButton,
  MenuBackButton,
} from 'menu/components/MenuNav';
import { useMenuGroups, useMenus } from 'contexts/MenuContext';
import { Element, scroller } from 'react-scroll';
import { CanOrderBanner } from 'menu/components/Alerts';
import { sortWithSortOrder } from 'utils';
import { getAztecProduct, getProductDisplayRecord } from 'menu/utils';
import { Loader } from 'components/Loader';
import { useServices } from 'contexts/VenueContext';
import { isTimeslotService } from 'venue/utils';
import { CalorieAllowancePhrase } from 'menu/components/Calories';

interface MenuGroupAndProductDisplayProps {
  menuGroups: MenuGroup[];
}

interface MenuDisplayState {
  menuId: number;
  sortOrder: number;
  active: boolean;
}

interface ParamsProps {
  venueId: string;
  serviceName: string;
  menuId: string;
}

export const MenuGroupAndProductDisplay: React.FC<
  MenuGroupAndProductDisplayProps
> = ({ menuGroups }) => {
  const params = useParams<ParamsProps>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { stickyBasketEnabled, galleryViewEnabled, multipleMenusEnabled } =
    useConfig();
  const { menus, hyperlink, setHyperlink } = useMenus();

  const { products } = useMenuGroups();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredMenuGroups, setFilteredMenuGroups] =
    useState<MenuGroup[]>(menuGroups);
  const isMobile = window.innerWidth < 500;

  const menu = menus.find((x) => x.id === Number(params.menuId));

  const { selectedService } = useServices();

  const { getMenuPages } = useMenuGroups();

  const [activeMenuGroups, setActiveMenuGroups] = useState<MenuDisplayState[]>(
    menuGroups.map((mg) => {
      return {
        menuId: mg.groupId,
        sortOrder: mg.sortOrder,
        active: false,
      } as MenuDisplayState;
    }),
  );

  useEffect(() => {
    setFilteredMenuGroups(menuGroups);
    setSearchTerm('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuGroups]);

  useEffect(() => {
    if (searchTerm.length) {
      //deep copy the menu groups
      const tempMenuGroup: MenuGroup[] = JSON.parse(JSON.stringify(menuGroups));

      //filter products
      tempMenuGroup.forEach((mg) => {
        mg.items = mg.items.filter((item) => {
          //filter out everything that isn't a product
          if (!item || item.itemType !== 'product') return false;

          const productItem = item as MenuGroupItemProduct;
          const aztecProduct = getAztecProduct(
            productItem.product.productId,
            products,
          );
          const displayRecord = getProductDisplayRecord(
            aztecProduct,
            productItem.product.displayRecordId,
          );
          //check if search term is contained in title or description
          return (
            productItem.product.displayName
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            displayRecord?.description
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          );
        });
      });

      //only display mgs with items
      const updatedFilteredMenuGroups: MenuGroup[] = [];
      tempMenuGroup.forEach((mg) => {
        if (mg.items.length) {
          updatedFilteredMenuGroups.push(mg);
        }
      });

      setFilteredMenuGroups(updatedFilteredMenuGroups);
    } else if (filteredMenuGroups !== menuGroups) {
      setFilteredMenuGroups(menuGroups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    setActiveMenuGroups(
      filteredMenuGroups.map((mg) => {
        return {
          menuId: mg.groupId,
          sortOrder: mg.sortOrder,
          active: false,
        } as MenuDisplayState;
      }),
    );
  }, [filteredMenuGroups]);

  const backButtonClick = () => {
    history.push(`/venue/${params.venueId}/${params.serviceName}/menus/`);
    window.scrollTo(0, 0); // Navigate to top of page after customer hits button
  };

  useEffect(() => {
    handleHyperlinkItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  useEffect(() => {
    handleHyperlinkGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuGroups]);

  useEffect(() => {
    if (hyperlink.isSameMenu) {
      handleHyperlinkGroup();
      handleHyperlinkItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hyperlink]);

  const handleHyperlinkGroup = () => {
    const type = hyperlink.hyperlinkUiSpecificData.hyperlinkType;
    if (hyperlink.comingFromHyperlink === true && type === 'group') {
      const menuGroupId = hyperlink.hyperlinkUiSpecificData.hyperlinkGroupValue;
      if (menuGroups.some((mg) => mg.groupId === menuGroupId)) {
        jumpToElement(`WP-${menuGroupId}`);
      } else {
        dispatch(
          addNotification(
            `Unfortunately, the selected menu group is unavailable.`,
            'danger',
          ),
        );
      }
      setHyperlink({
        ...hyperlink,
        comingFromHyperlink: false,
        isSameMenu: false,
      });
    }
  };

  const handleHyperlinkItem = () => {
    const type = hyperlink.hyperlinkUiSpecificData.hyperlinkType;

    if (
      Object.keys(products).length > 0 &&
      hyperlink.comingFromHyperlink === true &&
      type === 'item'
    ) {
      const productId = hyperlink.hyperlinkUiSpecificData.hyperlinkItemValue;

      if (products.hasOwnProperty(productId)) {
        jumpToElement(`product-item-${productId}`);

        setHyperlink({
          ...hyperlink,
          highlightProduct: true,
          comingFromHyperlink: false,
          isSameMenu: false,
        });
      } else {
        dispatch(
          addNotification(
            `Unfortunately, the selected product is unavailable.`,
            'danger',
          ),
        );
        setHyperlink({
          ...hyperlink,
          isSameMenu: false,
        });
      }
    }
  };

  const handleHyperlinkClick = (
    hyperlinkUiSpecificData: HyperlinkUiSpecificData,
  ) => {
    dispatch(removeNotification());

    if (
      !menus.some((e) => e.id === hyperlinkUiSpecificData.hyperlinkMenuValue)
    ) {
      dispatch(
        addNotification(
          `Unfortunately, the selected menu is unavailable.`,
          'danger',
        ),
      );
    } else {
      if (
        !menus.some(
          (e) =>
            e.id === hyperlinkUiSpecificData.hyperlinkMenuValue &&
            (isTimeslotService(selectedService) ? true : e.canOrder),
        )
      ) {
        dispatch(
          addNotification(
            `Unfortunately, the selected menu is unavailable.`,
            'danger',
          ),
        );
      } else {
        if (
          hyperlinkUiSpecificData.hyperlinkMenuValue.toString() !==
          params.menuId
        ) {
          getMenuPages(hyperlinkUiSpecificData.hyperlinkMenuValue);
          history.push(
            `/venue/${params.venueId}/${params.serviceName}/menus/${hyperlinkUiSpecificData.hyperlinkMenuValue}`,
          );
          setHyperlink({
            comingFromHyperlink: true,
            isSameMenu: false,
            highlightProduct: false,
            hyperlinkUiSpecificData: hyperlinkUiSpecificData,
          });
        } else {
          setHyperlink({
            comingFromHyperlink: true,
            isSameMenu: true,
            highlightProduct: false,
            hyperlinkUiSpecificData: hyperlinkUiSpecificData,
          });
        }
      }
    }
  };

  const handleMenuGroupStateChange = (menuId: number, activeState: boolean) => {
    const selectedMG = {
      ...activeMenuGroups.find((mg) => mg.menuId === menuId),
      active: activeState,
    } as MenuDisplayState;
    const otherMGs = activeMenuGroups.filter((mg) => mg.menuId !== menuId);
    setActiveMenuGroups([selectedMG, ...otherMGs]);
  };

  const jumpToElement = (elementId: string) => {
    scroller.scrollTo(elementId, {
      offset: isMobile ? (multipleMenusEnabled ? -120 : -100) : -80,
      delay: 200,
      smooth: 'easeOutQuint',
      ignoreCancelEvents: true,
    });
  };

  // select the first Menu Group that it active, or the first one
  const activeMG =
    activeMenuGroups
      .sort((mga, mgb) => sortWithSortOrder(mga, mgb))
      .filter((mg) => mg.active === true)[0] || activeMenuGroups[0];

  // handle scrolling of the horizontal menu (has no effect in vertical mode)
  useEffect(() => {
    const inset = getComputedStyle(document.documentElement).getPropertyValue(
      '--sal',
    );
    const insetAsNumber = parseInt(inset, 10);

    if (activeMG) {
      const handleScroll = () => {
        const menuElement = document.querySelector(
          `#mg-item-${activeMG.menuId}`,
        ) as HTMLElement;

        const leftValue = menuElement?.offsetLeft - insetAsNumber;

        const horizMenu = document.querySelector(
          `#horizontal-menu`,
        ) as HTMLElement;
        horizMenu &&
          horizMenu.scrollTo({
            top: 0,
            left: leftValue,
            behavior: 'smooth',
          });
      };

      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0,
      );

      // firing it after 500ms so it doesn't conflict with scrollIntoView above
      // sorry for the cancer of hard coded breakpoints
      vw <= 990 && setTimeout(() => handleScroll(), 500);
    }
  }, [activeMG]);

  const gridClasses = cs(
    'multi-menu-grid',
    stickyBasketEnabled === true ? 'two-col' : 'three-col',
  );

  //This only happens for a split second when the table/timeslot is changed within the menu grid
  if (menu === undefined) {
    return <Loader text="Loading Menu" />;
  }

  return (
    <div className="multi-menu-container">
      <div className={gridClasses}>
        <div className="menu-nav">
          <MenuBackButton onClick={() => backButtonClick()} />
          <div
            id="horizontal-menu"
            className="display-group-list menu-groups list-group"
            data-testid="menu-group-list"
          >
            {filteredMenuGroups.map((mg) => {
              const navItemClasses = cs(
                activeMG && mg.groupId === activeMG.menuId && 'active',
                'list-group-item',
              );
              return (
                <MenuNavButton
                  key={`mg-item-${mg.groupId}`}
                  buttonId={`mg-item-${mg.groupId}`}
                  buttonClass={navItemClasses}
                  handleClick={() => {
                    jumpToElement(`WP-${mg.groupId}`);
                  }}
                  testId={`menu-group-item-${mg.groupId}`}
                  buttonText={mg.groupName}
                />
              );
            })}
          </div>
        </div>
        <div className="menu-content">
          <CanOrderBanner menu={menu} />
          <MenuSearchBar
            searchTerm={searchTerm}
            changeSearchTerm={setSearchTerm}
            clearSearchTerm={() => setSearchTerm('')}
            placeHolder="Search"
          />
          {filteredMenuGroups.length === 0 && searchTerm.length > 0 ? (
            <h4 className="menu-no-search-results-message">
              There are no products that match your search
            </h4>
          ) : null}
          {filteredMenuGroups.map((mg) => {
            return (
              <div
                key={`mm-group-title-${mg.groupId}`}
                className="display-group-container menu-group-content"
                data-testid="display-group-menu-content"
              >
                {/* offset needed for horiz menu */}
                <Waypoint
                  onEnter={() => {
                    handleMenuGroupStateChange(mg.groupId, true);
                  }}
                  onLeave={() => {
                    handleMenuGroupStateChange(mg.groupId, false);
                  }}
                  topOffset={200}
                  bottomOffset={200}
                >
                  <div>
                    <Element name={`WP-${mg.groupId}`}>
                      <h2 className="user-select-none">{mg.groupName}</h2>
                      <CalorieAllowancePhrase />
                      <div
                        className={
                          galleryViewEnabled && stickyBasketEnabled
                            ? 'gallery-grid'
                            : galleryViewEnabled
                            ? 'gallery-grid side-basket'
                            : 'list-group'
                        }
                      >
                        {mg.items &&
                          mg.items
                            .filter((i) => i) // filter undefined
                            .map((item) => {
                              return (
                                <MenuDisplayItem
                                  key={`product-item-${item.itemId}`}
                                  handleHyperlinkClick={handleHyperlinkClick}
                                  item={item}
                                />
                              );
                            })}
                      </div>
                    </Element>
                  </div>
                </Waypoint>
              </div>
            );
          })}
        </div>
        {stickyBasketEnabled === false ? (
          <div className="menu-basket">
            <MenuBasket />
          </div>
        ) : null}
      </div>
    </div>
  );
};
