import { Button } from 'react-bootstrap';
import { ModalFooter } from 'components/Modal';
import { useMenuModal } from 'contexts/MenuModalContext';

export const UpsellModalFooter: React.FC = () => {
  const { handleResetMenuModal, selectUpsellProduct } = useMenuModal();

  const onClearUpsell = () => {
    handleResetMenuModal();
  };

  const onAcceptUpsellOffer = () => {
    selectUpsellProduct();
  };

  return (
    <ModalFooter>
      <div className="row">
        <div className="col-xs-12">
          <Button
            onClick={onAcceptUpsellOffer}
            className="pull-right"
            bsStyle="primary"
          >
            Yes
          </Button>
        </div>
        <div className="col-xs-12">
          <Button className="cancel-button" onClick={onClearUpsell}>
            No
          </Button>
        </div>
      </div>
    </ModalFooter>
  );
};
