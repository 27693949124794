import { Button } from 'react-bootstrap';
import { Notification } from 'core/components/Notification';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/Modal';
import { ServiceModalHeader } from 'venue/components/SharedModalComponents/ServiceModalHeader';
import { TableSelector } from 'features/table/components/TableSelector';
import { useTables } from 'contexts/TableContext/TableContext';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ReactSelectOption } from 'types/react-select-virtualized';

interface TableSelectorFormProps {
  tableSelector: ReactSelectOption;
}

export const TablesModal: React.FC = () => {
  const methods = useForm<TableSelectorFormProps>();

  const {
    setSelectedTable,
    isFetchingTables,
    tables,
    showTablesModal,
    setShowTablesModal,
  } = useTables();

  const hasTablesAvailable = tables.length > 0;

  const handleClose = () => {
    setShowTablesModal(false);
  };

  const updateTable: SubmitHandler<TableSelectorFormProps> = (formValues) => {
    setSelectedTable(formValues.tableSelector);
    setShowTablesModal(false);
  };

  return (
    <div>
      <FormProvider {...methods}>
        <Modal
          isOpen={showTablesModal}
          onRequestClose={handleClose}
          datatestid="table-selector-modal"
        >
          <ModalHeader closeButton onRequestClose={handleClose}>
            <ServiceModalHeader />
          </ModalHeader>
          <ModalBody>
            <Notification />
            <TableSelector />
          </ModalBody>
          <ModalFooter>
            <Button
              className="view-menu-button"
              data-testid="table-update-save-button"
              bsStyle="primary"
              type="submit"
              onClick={methods.handleSubmit(updateTable)}
              disabled={isFetchingTables || !hasTablesAvailable}
            >
              Save
            </Button>
            <Button
              className="hide-modal-button hide-on-responsive"
              data-testid="table-update-cancel-button"
              onClick={handleClose}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </FormProvider>
    </div>
  );
};
