import { usePhrases } from 'contexts/ConfigContext';
import { MdErrorOutline } from 'react-icons/md';
import { useGiftCard } from 'contexts/GiftCardContext';
import { useVouchers } from 'contexts/VoucherContext';

export const LoyaltyRewardsPanelPhrase: React.FC = () => {
  const { redeemedGiftCards } = useGiftCard();
  const { redeemedVouchers } = useVouchers();

  const hasGiftCards = redeemedGiftCards.length > 0;
  const hasVouchersApplied = redeemedVouchers.length > 0;
  const {
    giftCard: { giftCardPhrase },
    loyalty: { rewardPhrase },
  } = usePhrases();

  return (
    <>
      {hasVouchersApplied || hasGiftCards ? (
        <div className="reward-conflict text-muted">
          <MdErrorOutline size={25} />
          {`${rewardPhrase}s cannot be applied because the basket contains a ${
            hasVouchersApplied ? 'voucher' : giftCardPhrase
          }.`}
        </div>
      ) : null}
    </>
  );
};
