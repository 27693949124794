import { isBasketAddition, isPromotionLine } from 'types/guards/Checkout';
import { BasketAddition, PromotionLine } from 'types/models/Basket';
import { Field } from 'types/models/Forms';
import { RedeemedGiftCard } from 'types/models/GiftCard';
import { RedeemedRewardItem } from 'types/models/Loyalty';
import {
  AdditionalInformationFormData,
  AdditionalInformationRequest,
  ApplePayPaymentContactFields,
  ApplePayShippingInformation,
} from 'types/models/Payment';
import {
  UnfilteredBasketLine,
  CheckBasketResponseProductLine,
  CheckBasketData,
  CheckBasketResponse,
  CheckBasketResponseLoyaltyLine,
  GiftCardLine,
  CheckBasketResponseGiftCardLine,
} from 'types/models/Responses/CheckBasketResponse';
import { Voucher, VoucherBasketLine } from 'types/models/Vouchers';

export const normaliseFormData = (
  locationInfo: Field[],
  userInfo: Field[],
  formData: AdditionalInformationFormData,
): AdditionalInformationFormData => {
  const additionalInfo = [...locationInfo, ...userInfo];

  if (
    formData.field_1 === undefined ||
    formData.field_1.trim() === '' ||
    formData.field_1 === null ||
    formData.field_1 === '-'
  ) {
    formData.field_1 = setFieldValue('field_1', additionalInfo);
  }

  if (
    formData.field_2 === undefined ||
    formData.field_2.trim() === '' ||
    formData.field_2 === null ||
    formData.field_2 === '-'
  ) {
    formData.field_2 = setFieldValue('field_2', additionalInfo);
  }

  return formData;
};

const setFieldValue = (
  fieldName: string,
  additionalInfo: Field[],
): string | undefined => {
  const fieldConfig = additionalInfo.find((x) => x.name === fieldName);

  return fieldConfig?.type !== 'select' ? '-' : undefined;
};

/**
 * Returns the additional information in format makeBraintreePayment understands
 */
export const getAdditionalInformation = (
  formData: AdditionalInformationFormData,
): AdditionalInformationRequest[] => {
  const valueArray = [];
  const field1 = formData.field_1
    ? { value: formData.field_1, name: 'field_1' }
    : undefined;
  const field2 = formData.field_2
    ? { value: formData.field_2, name: 'field_2' }
    : undefined;

  if (field1) {
    valueArray.push(field1);
  }

  if (field2) {
    valueArray.push(field2);
  }

  return valueArray as AdditionalInformationRequest[];
};

export const getApplePayShippingContactFields = (
  userInfo: Field[],
): string[] => {
  const fieldsForApplePayRequest: string[] = [];

  const userFields: Record<string, string> = {
    first_name: 'name',
    firstName: 'name',
    last_name: 'name',
    lastName: 'name',
    email: 'email',
    mobile_number: 'phone',
    mobile_phone: 'phone',
    home_phone: 'phone',
    address1: 'postalAddress',
    address2: 'postalAddress',
    address3: 'postalAddress',
    postcode: 'postalAddress',
  };

  userInfo.forEach((x) => {
    if (x.userFieldType) {
      const fieldName = userFields[x.userFieldType];

      if (fieldName) {
        fieldsForApplePayRequest.push(fieldName);
      }
    }
  });

  return fieldsForApplePayRequest;
};

export const getApplePayPaymentContactFields = (
  userInfo: Field[],
): ApplePayPaymentContactFields[] => {
  const fieldsForApplePayRequest: ApplePayPaymentContactFields[] = [];

  const userFields: Record<string, string> = {
    first_name: 'givenName',
    firstName: 'givenName',
    last_name: 'familyName',
    lastName: 'familyName',
    email: 'emailAddress',
    mobile_number: 'phoneNumber',
    mobile_phone: 'phoneNumber',
    home_phone: 'phoneNumber',
    address1: 'addressLines',
    address2: 'locality',
    address3: 'administrativeArea',
    postcode: 'postalCode',
  };

  userInfo.forEach((x) => {
    if (x.userFieldType) {
      const fieldName = userFields[x.userFieldType];

      if (fieldName && x.content && x.content !== '') {
        if (fieldName === 'addressLines') {
          const addressLines = { addressLines: [x.content] };

          fieldsForApplePayRequest.push(addressLines);
        } else {
          fieldsForApplePayRequest.push({ [fieldName]: x.content });
        }
      }
    }
  });

  return fieldsForApplePayRequest;
};

export const getApplePayFieldsAsFormData = (
  shippingContact: ApplePayShippingInformation,
  userInfo: Field[],
): AdditionalInformationFormData => {
  if (!shippingContact || Object.keys(shippingContact).length === 0) {
    return {};
  }
  const userFields: Record<string, string | undefined> = {
    first_name: shippingContact.givenName,
    firstName: shippingContact.givenName,
    last_name: shippingContact.familyName,
    lastName: shippingContact.familyName,
    email: shippingContact.emailAddress,
    mobile_number: shippingContact.phoneNumber,
    mobile_phone: shippingContact.phoneNumber,
    home_phone: shippingContact.phoneNumber,
    address1: shippingContact?.addressLines?.at(0),
    address2: shippingContact?.addressLines?.at(1) ?? shippingContact?.locality,
    address3: shippingContact?.administrativeArea,
    postcode: shippingContact?.postalCode,
  };

  const returnObj: AdditionalInformationFormData = {};

  if (userFields['email']) {
    returnObj.email = userFields['email'];
  }

  userInfo.forEach((x) => {
    if (x.userFieldType) {
      const fieldValue = userFields[x.userFieldType];

      if (fieldValue) {
        returnObj[x.name as keyof AdditionalInformationFormData] = fieldValue;
      }
    }
  });

  return returnObj;
};

export const formatCheckBasketResponse = (
  response: CheckBasketResponse,
): CheckBasketData => {
  const { promotions, basketLines, basketAdditions } = filterBasketLines(
    response.lines,
  );

  const data: CheckBasketData = {
    basketAdditions: basketAdditions,
    basketId: response.basketId,
    basketLines: basketLines,
    basketTotal: response.basketTotal >= 0 ? response.basketTotal : 0,
    braintreeToken: response.braintreeToken,
    estateId: response.estateId,
    promotions: promotions,
    salesAreaId: response.salesAreaId,
    showWaitTime: response.showWaitTime,
    siteId: response.siteId,
    taxes: response.taxes,
    waitTime: response.waitTime,
  };

  return data;
};

export const filterBasketLines = (
  lines: UnfilteredBasketLine[],
): {
  basketAdditions: BasketAddition[];
  promotions: PromotionLine[];
  basketLines: CheckBasketResponseProductLine[];
} => {
  const basketAdditions: BasketAddition[] = [];
  const promotions: PromotionLine[] = [];
  const basketLines: CheckBasketResponseProductLine[] = [];

  lines.forEach((line) => {
    if (isBasketAddition(line)) {
      basketAdditions.push(line);
    } else if (isPromotionLine(line)) {
      promotions.push(line);
    } else {
      basketLines.push(line);
    }
  });
  return { basketAdditions, promotions, basketLines };
};

export const formatLoyaltyLinesResponse = (
  loyaltyLines: CheckBasketResponseLoyaltyLine[] | undefined,
): RedeemedRewardItem[] => {
  if (loyaltyLines) {
    return loyaltyLines.map((line) => ({
      transactionId: line.transactionId,
      displayName: line.metaData.name,
      rewardId: line.rewardId,
      rewardType: line.rewardType,
      quantity: line.quantity,
    }));
  } else {
    return [];
  }
};

export const formatLoyaltyLinesRequest = (
  redeemedRewards: RedeemedRewardItem[],
  loyaltyReward?: RedeemedRewardItem,
): { transactionId: string }[] => {
  const loyaltyLines =
    redeemedRewards?.length > 0
      ? redeemedRewards.map((lr) => ({
          transactionId: lr.transactionId,
        }))
      : [];

  if (loyaltyReward) {
    loyaltyLines.push({ transactionId: loyaltyReward.transactionId });
  }

  return loyaltyLines;
};

export const formatGiftCardLinesRequest = (
  redeemedGiftCards: GiftCardLine[],
  giftCard?: RedeemedGiftCard,
): { transactionId: number; giftCardId: string }[] => {
  const giftCardLines =
    redeemedGiftCards.length > 0
      ? redeemedGiftCards.map((gc) => ({
          transactionId: gc.transactionId,
          giftCardId: gc.giftCardId,
        }))
      : [];

  if (giftCard) {
    giftCardLines.push({
      transactionId: giftCard.transactionId,
      giftCardId: giftCard.giftCardId,
    });
  }

  return giftCardLines;
};

export const formatGiftCardLinesResponse = (
  redeemedGiftCards: CheckBasketResponseGiftCardLine[] | undefined,
): GiftCardLine[] => {
  if (redeemedGiftCards) {
    return redeemedGiftCards.map((line) => ({
      amount: line.amount,
      giftCard: line.giftCard,
      giftCardId: line.giftCardId,
      giftCardNumber: line.giftCardNumber,
      paymentMethodId: line.paymentMethodId,
      transactionId: line.transactionNumber,
    }));
  } else {
    return [];
  }
};

export const formatVoucherLinesRequest = (
  redeemedVouchers: VoucherBasketLine[],
  voucher?: Voucher,
): { voucherCode: string }[] => {
  const voucherLines =
    redeemedVouchers.length > 0
      ? redeemedVouchers.map((v) => ({
          voucherCode: v.VoucherCode,
        }))
      : [];

  if (voucher) {
    voucherLines.push({
      voucherCode: voucher.voucherCode,
    });
  }

  return voucherLines;
};

export const getGiftCardsFromError = (errorDetail: string): number[] => {
  // match the first set of parentheses followed by a period
  // expected format = "Gift card in request has expired (1234, 5678). (Z853)"
  const regex = /\(([^)]+)\)\./;
  const giftCardsFromErrorResponse = regex.exec(errorDetail);

  // Split the content by comma, trim whitespace, and parse as integers
  const timedOutGiftCards = giftCardsFromErrorResponse
    ? giftCardsFromErrorResponse[1]
        .split(',')
        .map((num) => parseInt(num.trim(), 10))
        .filter(Number.isFinite)
    : [];

  // Remove duplicates
  return [...new Set(timedOutGiftCards)];
};

export const getVouchersFromError = (errorDetail: string): string[] => {
  // match the first set of parentheses followed by a period
  // expected format = "The following vouchers have timed out (ZONL02881). (Z714)"
  const regex = /\(([^)]+)\)\./;
  const vouchersFromErrorResponse = regex.exec(errorDetail);

  // Split the content by comma, trim whitespace, and parse as integers
  const timedOutVouchers = vouchersFromErrorResponse
    ? vouchersFromErrorResponse[1].split(',').map((v) => v.trim())
    : [];

  // Remove duplicates
  return [...new Set(timedOutVouchers)];
};
