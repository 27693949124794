import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios, { AxiosRequestConfig } from 'axios';
import { fetchBodyFormatter } from 'utils';
import { addNotification } from 'core/actions';
import {
  Menu,
  HyperlinkUiSpecificData,
  ServiceType,
  Course,
} from 'types/models';
import { ErrorResponse, GetMenuResponse } from 'types/models/Responses';
import { useSalesAreas, useServices, useVenues } from 'contexts/VenueContext';
import { useAPI } from 'api/useAPI';
import { isTimeslotService } from 'venue/utils';
import { useTimeslots } from 'contexts/TimeslotContext/TimeslotContext';

interface HyperlinkProps {
  comingFromHyperlink: boolean;
  isSameMenu: boolean;
  highlightProduct: boolean;
  hyperlinkUiSpecificData: HyperlinkUiSpecificData;
}

interface MenuContext {
  initialised: boolean;
  layoutType: string;
  loadingMenus: boolean;
  menus: Menu[];
  courses: Course[];
  hyperlink: HyperlinkProps;
  setHyperlink: React.Dispatch<HyperlinkProps>;
  getMenus: () => void;
}

interface MenusObject {
  menus: Menu[];
  isProcessingData: boolean;
  layoutType: string;
}

interface CourseObject {
  courses: Course[];
}

// set up context
export const MenuContext = createContext<MenuContext>({
  initialised: false,
  layoutType: 'banner',
  loadingMenus: false,
  menus: [],
  courses: [],
  hyperlink: {
    comingFromHyperlink: false,
    isSameMenu: false,
    highlightProduct: false,
    hyperlinkUiSpecificData: {
      hyperlinkGroupValue: 0,
      hyperlinkItemValue: 0,
      hyperlinkMenuValue: 0,
      hyperlinkType: '',
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setHyperlink: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getMenus: () => {},
});

// set up hook
export const useMenus = (): MenuContext => {
  const consumer = useContext(MenuContext);

  // Condition used to determine if Context Provider has been declared
  if (!consumer.initialised) {
    throw new Error('MenuContextProvider not initialised');
  }

  return consumer;
};

interface MenuContextProviderProps {
  children: React.ReactNode;
}

// set up provider
export const MenuContextProvider: React.FC<MenuContextProviderProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const { selectedVenue } = useVenues();
  const { selectedService } = useServices();
  const { selectedSalesArea } = useSalesAreas();
  const { selectedTimeslot } = useTimeslots();
  const { url, getMenus: getMenuAPI } = useAPI();
  const getMenuAPIConfig = getMenuAPI();

  const [menusState, setMenusState] = useState<MenusObject>({
    menus: [],
    isProcessingData: true,
    layoutType: 'banner',
  });

  const [courseState, setCourseState] = useState<CourseObject>({ courses: [] });

  const [hyperlink, setHyperlink] = useState<HyperlinkProps>({
    comingFromHyperlink: false,
    isSameMenu: false,
    highlightProduct: false,
    hyperlinkUiSpecificData: {
      hyperlinkGroupValue: 0,
      hyperlinkItemValue: 0,
      hyperlinkMenuValue: 0,
      hyperlinkType: '',
    },
  });

  // set optional params (could do this better)
  let optionalParams = {};

  if (selectedService === ServiceType.OrderAndPay) {
    optionalParams = { serviceId: selectedService };
  } else if (isTimeslotService(selectedService)) {
    optionalParams = {
      serviceId: selectedService,
      timeslot: selectedTimeslot?.time,
    };
  }

  // set axios hook options
  const menuOptions: AxiosRequestConfig = {
    url,
    method: 'POST',
    headers: getMenuAPIConfig.headers,
    data: fetchBodyFormatter({
      method: getMenuAPIConfig.method,
      salesAreaId: selectedSalesArea?.id,
      siteId: selectedVenue?.id,
      version: '1.0.0',
      ...getMenuAPIConfig.body,
      ...optionalParams,
    }),
  };

  const getMenus = () => {
    setMenusState({
      menus: [],
      isProcessingData: true,
      layoutType: 'banner',
    });
    axios(menuOptions)
      .then((response) => {
        const data = response.data as GetMenuResponse;
        if (data.response === 'OK') {
          setMenusState({
            menus: data.menus,
            isProcessingData: false,
            layoutType: data.layoutType,
          });
          setCourseState({ courses: data.courses });
        } else {
          setMenusState({
            menus: [],
            isProcessingData: false,
            layoutType: 'banner',
          });

          const errResponse = response.data as ErrorResponse;
          dispatch(
            addNotification(
              `Error retrieving menus: ${errResponse.detail} (${errResponse.code})`,
              'danger',
            ),
          );
        }
      })
      .catch((error) => {
        setMenusState({
          menus: [],
          isProcessingData: false,
          layoutType: 'banner',
        });
        setCourseState({ courses: [] });
        // deal with API error

        dispatch(
          addNotification(
            `Error retrieving menus: ${error.toString()}`,
            'danger',
          ),
        );
      });
  };

  useEffect(() => {
    if (hyperlink.highlightProduct) {
      setTimeout(() => {
        setHyperlink({ ...hyperlink, highlightProduct: false });
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hyperlink.highlightProduct]);

  return (
    <MenuContext.Provider
      value={{
        initialised: true,
        layoutType: menusState.layoutType,
        loadingMenus: menusState.isProcessingData,
        menus: menusState.menus,
        courses: courseState.courses,
        hyperlink,
        setHyperlink,
        getMenus,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
