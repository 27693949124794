import { useConfig, useSetConfig } from 'contexts/ConfigContext';

import { ControlLabel } from 'react-bootstrap';
import { ToggleSwitch } from 'core/components/ToggleSwitch';

export const ShowCalorieAllowancePhrase: React.FC = () => {
  const { shouldDisplayDailyCalorieAllowancePhraseOnMenu } = useConfig();
  const setConfig = useSetConfig();

  const handleClick = (toggledValue: boolean) => {
    setConfig('shouldDisplayDailyCalorieAllowancePhraseOnMenu', toggledValue);
  };

  return (
    <div>
      <ControlLabel className="ff-label">
        Show Calorie Allowance Phrase
      </ControlLabel>
      <ToggleSwitch
        id="show-calorie-phrase"
        onChange={handleClick}
        checked={shouldDisplayDailyCalorieAllowancePhraseOnMenu}
        disabled={false}
        name="show-calorie-phrase"
        onLabel="on"
        offLabel="off"
        small={false}
      />
    </div>
  );
};
