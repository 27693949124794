import { Map } from 'immutable';
import { initialState } from './model';
import * as actionTypes from './actionTypes';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_CURRENCY:
      return state.set('currency', action.currency);

    case actionTypes.CHANGE_LOCALE:
      return state.set('locale', action.locale);

    case actionTypes.ADD_NOTIFICATION:
      return state.merge({
        notification: state.get('notification').merge(
          Map({
            message: action.message,
            level: action.level,
            code: action.code,
          }),
        ),
      });

    case actionTypes.REMOVE_NOTIFICATION:
      return state.merge({
        notification: state.get('notification').merge(
          Map({
            message: null,
            level: null,
            code: null,
          }),
        ),
      });

    case actionTypes.RECEIVE_DEFAULT_THEME_COLOUR:
      return state.set('defaultThemeColour', action.defaultThemeColour);

    case actionTypes.COOKIES_DISABLED_ACKNOWLEDGEMENT:
      return state.set(
        'cookiesDisabledAcknowledgement',
        action.cookiesDisabledAcknowledgement,
      );

    default:
      return state;
  }
};

export default reducer;

// Selectors that are Immutable objects

export const getNotification = (state) => state.core.get('notification');
