import { Dialog } from 'components/Dialog';
import { useAdditionalInformation } from 'contexts/AdditionalInformationContext';
import { useSalesAreas, useServices, useVenues } from 'contexts/VenueContext';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { setAdditionalInfoError999 } from 'checkout/actions';

interface RestartOrderDialogProps {
  isOpen: boolean;
  title: string;
  description: string;
}

export const RestartOrderDialog: React.FC<RestartOrderDialogProps> = ({
  isOpen,
  title,
  description,
}) => {
  const dispatch = useDispatch();
  const { resetState } = useAdditionalInformation();
  const history = useHistory();
  const { selectedVenue } = useVenues();
  const { selectedService } = useServices();
  const { selectedSalesArea } = useSalesAreas();

  const handleRestart = () => {
    dispatch(setAdditionalInfoError999(''));
    resetState();
    history.push(
      `/venue/${selectedVenue?.id}/sales-area/${selectedSalesArea?.id}/service/${selectedService}/`,
    );
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      description={description}
      accept={{
        action: handleRestart,
        text: 'Restart Order',
        btnType: 'destructive',
      }}
    />
  );
};
