import { Modal } from 'components/Modal';
import { useMenuModal } from 'contexts/MenuModalContext';
import { MenuModalHeader } from 'menu/components/MenuModal';
import { AztecProduct, BreadcrumbTrack } from 'types/models';
import { MenuModalBody } from 'menu/components/MenuModal/ModalBody';
import { MenuModalFooter } from 'menu/components/MenuModal/MenuModalFooter';
import { useBasket } from 'contexts/BasketContext';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  getNestedChoice,
  getProductPortions,
  getSelectedPortion,
  portionsHaveChoices,
  removeNestedChoices,
  removeSelectedChoices,
} from 'menu/utils';

interface FormProps {
  specialRequest: string;
}

export const MenuModal: React.FC = () => {
  const {
    selectedAztecProduct,
    handleResetMenuModal,
    updateSelectedAztecProduct,
    setSelectedAztecProduct,
  } = useMenuModal();
  const { addProductToBasket } = useBasket();
  const { breadcrumbs, setBreadcrumbs, upsellGroup, upsellProduct } =
    useMenuModal();

  const methods = useForm<FormProps>({
    mode: 'all',
  });

  const handleConfirm: SubmitHandler<FormProps> = ({ specialRequest }) => {
    if (selectedAztecProduct) {
      addProductToBasket(selectedAztecProduct, specialRequest);
      if (
        upsellGroup &&
        upsellProduct &&
        getSelectedPortion(selectedAztecProduct)?.quantity === 1
      ) {
        setSelectedAztecProduct(undefined);
      } else {
        handleResetMenuModal();
      }
      methods.reset();
    }
  };

  const handleCancel = () => {
    if (selectedAztecProduct) {
      const productPortions = getProductPortions(selectedAztecProduct);
      const updatedProduct = { ...selectedAztecProduct };
      const selectedPortion = getSelectedPortion(updatedProduct);
      if (!selectedPortion) {
        handleResetMenuModal();
        methods.reset();
        return;
      }

      if (breadcrumbs.length === 0) {
        // Go back to portion selector
        if (
          portionsHaveChoices(selectedAztecProduct) &&
          productPortions.length > 1
        ) {
          // remove all selected choices - nested included
          removeSelectedChoices(selectedPortion);
          selectedPortion.quantity = 0;
          updateSelectedAztecProduct(updatedProduct);
        } else {
          handleResetMenuModal();
          methods.reset();
        }
      } else {
        // go through top level breadcrumb, remove any selected quantities and then remove the breadcrumb
        const nestedChoice = getNestedChoice(selectedPortion, breadcrumbs);
        removeNestedChoices(nestedChoice);
        const breadcrumbCopy = [...breadcrumbs];
        breadcrumbCopy.splice(-1);
        updateSelectedAztecProduct(updatedProduct);
        setBreadcrumbs(breadcrumbCopy);
      }
    }
  };

  const updateAztecProduct = (product: AztecProduct) => {
    updateSelectedAztecProduct(product);
  };

  const updateBreadcrumbs = (breadcrumbs: BreadcrumbTrack[]) => {
    setBreadcrumbs(breadcrumbs);
  };

  if (!selectedAztecProduct) {
    return null;
  }

  return (
    <Modal
      isOpen={!!selectedAztecProduct}
      onRequestClose={handleCancel}
      className="menu-modal"
    >
      <FormProvider {...methods}>
        <MenuModalHeader
          aztecProduct={selectedAztecProduct}
          handleClose={handleCancel}
        />
        <MenuModalBody
          aztecProduct={selectedAztecProduct}
          updateAztecProduct={updateAztecProduct}
          updateBreadcrumbs={updateBreadcrumbs}
        />
        <MenuModalFooter
          aztecProduct={selectedAztecProduct}
          updateAztecProduct={updateAztecProduct}
          handleCancel={handleCancel}
          handleConfirm={methods.handleSubmit(handleConfirm)}
        />
      </FormProvider>
    </Modal>
  );
};
