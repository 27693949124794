import { Popover, OverlayTrigger } from 'react-bootstrap';

interface PopoverButtonProps {
  name: string;
  text: string;
  position: string;
  inline: boolean;
}

/**
 * @deprecated
 */
export const PopoverButton: React.FC<PopoverButtonProps> = ({
  name,
  text,
  position,
  inline,
}) => (
  <OverlayTrigger
    trigger="click"
    placement={position}
    overlay={<Popover id={name}>{text}</Popover>}
  >
    <div className={inline ? 'tooltip-icon-inline' : 'tooltip-icon'} />
  </OverlayTrigger>
);
