import { useState } from 'react';

import { GiftCardBasketLine } from 'checkout/components/GiftCard/components';
import { useGiftCard } from 'contexts/GiftCardContext';
import { ConfirmGiftCardRemovalModal } from 'checkout/components/Modals';
import { GiftCardLine } from 'types/models/Responses/CheckBasketResponse';
import { useCheckout } from 'contexts/CheckoutContext';

export const GiftCardBasketLines: React.FC = () => {
  const { checkBasket } = useCheckout();
  const { creditGiftCard, redeemedGiftCards } = useGiftCard();

  const [showModal, setShowModal] = useState(false);
  const [giftCardForPrompt, setGiftCardForPrompt] = useState<GiftCardLine>();

  const hasGiftCards = redeemedGiftCards.length > 0;

  const cancelAction = () => {
    setShowModal(false);
  };

  const handleRemoveGiftCard = (card: GiftCardLine) => {
    setShowModal(false);
    creditGiftCard(
      card.giftCardId,
      card.transactionId,
      card.amount,
      checkBasket,
    );
  };

  const handleShowModal = (card: GiftCardLine) => {
    setGiftCardForPrompt(card);
    setShowModal(true);
  };

  return (
    <>
      <div className="panel-line-container" data-testid="panel-giftcard">
        {hasGiftCards &&
          redeemedGiftCards.map((card) => (
            <GiftCardBasketLine
              giftCard={card}
              key={card.transactionId}
              handleShowModal={handleShowModal}
            />
          ))}
      </div>
      <ConfirmGiftCardRemovalModal
        show={showModal}
        cardToRemove={giftCardForPrompt}
        cancelAction={cancelAction}
        acceptAction={handleRemoveGiftCard}
      />
    </>
  );
};
