import { selectSelectedTipPreset, selectTipAmount } from 'checkout/selectors';
import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { useSelector } from 'react-redux';

export const CustomButtonText: React.FC = () => {
  const selectedTipPreset = useSelector(selectSelectedTipPreset);
  const tipAmount = useSelector(selectTipAmount);

  return selectedTipPreset === -1 && tipAmount > 0 ? (
    <>
      Custom
      <br />
      <FormattedCurrency value={tipAmount} />
    </>
  ) : (
    <>
      Set
      <br />
      Custom
    </>
  );
};
