import {
  OrderVoucherLine,
  OrderPromotionLine,
  OrderPayment,
  OrderBasketLine,
} from 'order-history/components/OrderBasket';
import {
  Order,
  OrderBasketLine as OrderBasketLineType,
  OrderPromotionLine as OrderPromotionLineType,
} from 'types/models/Orders';
import { isBasketLine, isPromotion } from 'types/guards';

interface OrderBasketProps {
  order: Order;
}

export const OrderBasket: React.FC<OrderBasketProps> = ({ order }) => {
  return (
    <>
      {order.basket.map(
        (basketItem: OrderBasketLineType | OrderPromotionLineType, index) => {
          if (isPromotion(basketItem)) {
            return (
              <OrderPromotionLine
                amount={basketItem.amount}
                key={`${basketItem.promotionId}-${index}`}
              />
            );
          }

          if (isBasketLine(basketItem) && basketItem.name) {
            return (
              <OrderBasketLine
                basketItem={basketItem}
                key={`${basketItem.displayRecordId}-${index}`}
              />
            );
          }
        },
      )}
      {order.voucherLines.length > 0 && <h4>Vouchers</h4>}
      {order.voucherLines.map((voucherLine) => {
        return (
          <OrderVoucherLine
            key={voucherLine.VoucherRedemptionId}
            voucherLine={voucherLine}
          />
        );
      })}
      <OrderPayment order={order} />
    </>
  );
};
