import { FormattedCurrency } from 'common/components/FormattedCurrency';

interface OrderPromotionLineProps {
  amount: number;
}
export const OrderPromotionLine: React.FC<OrderPromotionLineProps> = ({
  amount,
}) => {
  return (
    <>
      <div className="order-history-promotion-line">
        <div>Promotion</div>
        <FormattedCurrency value={amount} />
      </div>
      <hr />
    </>
  );
};
