import { usePhrases } from 'contexts/ConfigContext';
import { Button } from 'react-bootstrap';
import { useCheckout, useCheckoutDetails } from 'contexts/CheckoutContext';

interface VoucherButtonProps {
  handleSubmit: () => void;
  isLoading: boolean;
  isDisabled: boolean;
}

export const VoucherButton: React.FC<VoucherButtonProps> = ({
  handleSubmit,
  isLoading,
  isDisabled,
}) => {
  const {
    vouchering: { voucherButtonText },
  } = usePhrases();

  const { basketTotal } = useCheckoutDetails();
  const { isFetchingBasket } = useCheckout();

  if (isLoading) {
    return (
      <Button
        className="redeem"
        bsStyle="primary checkout-fw-button"
        disabled
        type="submit"
      >
        Loading
      </Button>
    );
  }

  return (
    <Button
      className="redeem checkout-fw-button"
      bsStyle="primary"
      onClick={handleSubmit}
      disabled={isDisabled || isLoading || basketTotal <= 0 || isFetchingBasket}
      type="submit"
    >
      {voucherButtonText}
    </Button>
  );
};
