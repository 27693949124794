import { usePhrases } from 'contexts/ConfigContext';
import dompurify from 'dompurify';

interface MinimumAgeDisclaimerProps {
  minAge: number;
}

export const MinimumAgeDisclaimer: React.FC<MinimumAgeDisclaimerProps> = ({
  minAge,
}) => {
  const { minAgePrompt } = usePhrases();

  const getFormattedPhrase = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - minAge);
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();

    const minDob = '<b>' + dd + '/' + mm + '/' + yyyy + '</b>';

    return minAgePrompt.phrase.replace('$DATEOFBIRTH$', minDob) || '';
  };

  const sanitizer = dompurify.sanitize;
  return (
    <div
      className="min-age-disclaimer"
      dangerouslySetInnerHTML={{ __html: sanitizer(getFormattedPhrase()) }}
    ></div>
  );
};
