import './polyfills';
import { createRoot } from 'react-dom/client';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { StartUp } from 'StartUp';

import { reportWebVitals } from './reportWebVitals';
import { ConfigContextProvider } from 'contexts/ConfigContext';
import version from './version.json';

// if (process.env.NODE_ENV !== 'production') {
// require(`@wlwo/client-styles/clients/staffordtest/scss/theme.scss`);
// require('./common.scss');
// require('./style.scss');
// }

window.env.REACT_APP_CODE_VERSION = version.version;

const container = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); //recommended by React docs

root.render(
  <HelmetProvider>
    <Helmet>
      {process.env.NODE_ENV === 'production' ? (
        <link href="./styles.css" type="text/css" rel="stylesheet" />
      ) : null}
      <script type="text/javascript">wlwo_version();</script>
    </Helmet>
    <ConfigContextProvider env={window.env.REACT_APP_ENV}>
      <StartUp />
    </ConfigContextProvider>
  </HelmetProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
