import { forwardRef } from 'react';
import cs from 'classnames';
import { usePhrases } from 'contexts/ConfigContext';
import {
  DeepMap,
  FieldError,
  FieldValues,
  RegisterOptions,
  UseFormRegisterReturn,
} from 'react-hook-form';
import { isVoucherCode } from 'core/components/RHF/validation';
import { useCheckout } from 'contexts/CheckoutContext';
import { useVouchers } from 'contexts/VoucherContext';

interface VoucherFieldProps {
  register: (
    name: string,
    registerOptions: RegisterOptions,
  ) => UseFormRegisterReturn;
  errors: DeepMap<FieldValues, FieldError>;
}

export const VoucherField = forwardRef<HTMLInputElement, VoucherFieldProps>(
  ({ register, errors }, forwardedRef) => {
    const {
      vouchering: { voucherPlaceholder },
    } = usePhrases();

    const { isFetchingBasket } = useCheckout();
    const { checkVoucherLoading, voidVoucherLoading, voidAllVouchersLoading } =
      useVouchers();

    const registerOptions: RegisterOptions = {
      required: true,
      validate: {
        isVoucherCode,
      },
    };

    const formGroupClasses = cs(
      'form-group voucher-form-field',
      errors && errors['voucher'] && 'has-error',
    );

    return (
      <div className={formGroupClasses}>
        {forwardedRef && (
          <div ref={forwardedRef}>
            <input
              className="form-control"
              type="text"
              disabled={
                checkVoucherLoading ||
                voidVoucherLoading ||
                voidAllVouchersLoading ||
                isFetchingBasket
              }
              placeholder={voucherPlaceholder}
              aria-invalid={errors && errors['voucher'] ? 'true' : 'false'}
              {...register('voucher', registerOptions)}
            />
          </div>
        )}
      </div>
    );
  },
);
