import { useVenues, useSalesAreas, useServices } from 'contexts/VenueContext';
import { usePhrases } from 'contexts/ConfigContext';
import { MdPlace } from 'react-icons/md';
import { FormattedDistance } from 'common/components/FormattedDistance';
import { filterActiveSalesAreas } from 'venue/utils';
import { getOrderLocationPhrase } from 'utils';

interface ServiceModalHeaderProps {
  useDistanceIfAvailable?: boolean;
}

export const ServiceModalHeader: React.FC<ServiceModalHeaderProps> = ({
  useDistanceIfAvailable,
}) => {
  const { branchTitle, venueSalesAreaFormat } = usePhrases();
  const { selectedVenue } = useVenues();
  const { selectedService } = useServices();
  const { selectedSalesArea, salesAreas } = useSalesAreas();

  const activeSalesAreas = filterActiveSalesAreas(salesAreas, selectedService);
  const orderLocationPhrase = getOrderLocationPhrase(
    venueSalesAreaFormat,
    selectedVenue,
    selectedSalesArea,
  );

  const locationToUse =
    activeSalesAreas.length > 1 &&
    selectedSalesArea &&
    selectedSalesArea.location.distance > 0
      ? selectedSalesArea.location.distance
      : activeSalesAreas.length === 1 &&
        selectedVenue &&
        selectedVenue?.distance > 0
      ? selectedVenue.distance
      : 0;

  return (
    <div className="modal-title-container">
      <div className="modal-title-icon">
        <MdPlace size={80} className="primary-colour " />
      </div>
      <div className="modal-title-text" data-testid="label-modal-title">
        <div>
          <span className="text-muted">{branchTitle}</span>
        </div>
        <div>
          <span className="h2">{orderLocationPhrase}</span>
        </div>
        {useDistanceIfAvailable && locationToUse ? (
          <div>
            <FormattedDistance
              distance={locationToUse}
              showIcon={false}
              additionalClassName={'text-muted'}
              showBrackets={false}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
