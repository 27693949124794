import { useEffect, useState } from 'react';

import { Modal } from 'components/Modal';
import { RoomSelectorModal } from '../ChargeToRoom';
import { useHotels } from 'contexts/HotelContext';
import {
  isChargeToRoomError,
  isRoomFolios,
  isRoomFoliosError,
} from 'types/guards';
import { useDispatch } from 'react-redux';
import { addNotification } from 'core/actions';
import { Folio } from 'types/models/Hotels';
import { FolioSelectorModal } from '../ChargeToRoom/FolioSelectorModal';

interface ChargeToRoomModalProps {
  shown: boolean;
  setShown: (arg: boolean) => void;
}

export const ChargeToRoomModal: React.FC<ChargeToRoomModalProps> = ({
  shown,
  setShown,
}) => {
  const dispatch = useDispatch();
  const {
    folios: { foliosResponse, foliosLoading },
    checkFolios,
    resetState,
    chargeToRoom: { chargeToRoomResponse },
  } = useHotels();
  const [roomFolios, setRoomFolios] = useState<Folio[]>([]);
  const [chargeToRoomError, setChargeToRoomError] = useState<string>('');

  const resetError = () => {
    setChargeToRoomError('');
  };

  const handleClose = () => {
    setShown(false);
    setRoomFolios([]);
    resetError();
    resetState();
  };

  const handleFindRoom = (name: string, roomNumber: string) => {
    resetError();
    checkFolios(name, roomNumber);
  };

  const errorCodesToCloseModal = [
    -204, // Expired timeslot
    -206, // Menu not available
    -216, // Product not available
    -714, // Expired voucher
    -805, // Charge to room disabled
    -820, // Product out of stock
    -999, // Missing additional info
    -2571, // Expired rewards
  ];

  const handleErrors = (code: number, message: string) => {
    if (errorCodesToCloseModal.includes(code)) {
      dispatch(addNotification(message, 'danger'));
      handleClose();
    } else {
      setChargeToRoomError(message);
    }
  };

  useEffect(() => {
    if (foliosResponse) {
      if (isRoomFolios(foliosResponse)) {
        setRoomFolios(foliosResponse.folios);
      } else if (isRoomFoliosError(foliosResponse)) {
        handleErrors(foliosResponse.code, foliosResponse.detail);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foliosResponse]);

  useEffect(() => {
    if (chargeToRoomResponse && isChargeToRoomError(chargeToRoomResponse)) {
      handleErrors(chargeToRoomResponse.code, chargeToRoomResponse.detail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargeToRoomResponse]);

  return (
    <Modal
      isOpen={shown}
      onRequestClose={handleClose}
      data-testid="charge-to-room-modal"
      className="charge-to-room-modal"
    >
      {roomFolios.length === 0 ? (
        <RoomSelectorModal
          handleClose={handleClose}
          errorMessage={chargeToRoomError}
          handleFindRoom={handleFindRoom}
          foliosLoading={foliosLoading}
        />
      ) : (
        <FolioSelectorModal
          handleClose={handleClose}
          roomFolios={roomFolios}
          errorMessage={chargeToRoomError}
          resetError={resetError}
        />
      )}
    </Modal>
  );
};
