/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useRef } from 'react';

import { FormattedCurrency } from 'common/components/FormattedCurrency/FormattedCurrency';
import { TaxLine } from 'types/models/Basket';
import { FaInfoCircle } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { handleReturnKeyPress } from 'utils/onKeyEventHelper';

interface TaxLineItemProps {
  taxLine: TaxLine;
  handleRemovalClick: (taxLine: TaxLine) => void;
}

export const TaxLineItem: React.FC<TaxLineItemProps> = ({
  taxLine,
  handleRemovalClick,
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const infoIconRef = useRef<HTMLDivElement>(null);
  const showPopover = () => {
    setShowTooltip((prevVal) => !prevVal);
  };

  return (
    <div className="tax" key={`tax-${taxLine.id}`}>
      <div
        className="tax-section"
        onClick={taxLine.phrase ? showPopover : undefined}
      >
        <div className="tax-name">{taxLine.friendlyName || taxLine.name}</div>
        {taxLine.phrase && (
          <>
            <div
              ref={infoIconRef}
              className="tax-info-icon"
              onKeyDown={(e) => handleReturnKeyPress(e, showPopover)}
              role="button"
              tabIndex={0}
              aria-label="Service charge description"
            >
              <FaInfoCircle className="tax-icon" />
            </div>
            <Tippy
              content={taxLine.phrase}
              reference={infoIconRef}
              visible={showTooltip}
              onClickOutside={() => setShowTooltip(false)}
              theme="tax-info"
            />
          </>
        )}
      </div>

      <div className="tax-section">
        {taxLine.hasOwnProperty('isCompulsory') && !taxLine.isCompulsory && (
          <div
            className="tax-remove"
            role="button"
            onClick={() => handleRemovalClick(taxLine)}
            onKeyDown={(e) =>
              handleReturnKeyPress(e, () => handleRemovalClick(taxLine))
            }
            tabIndex={0}
          >
            <MdDelete className="tax-icon tax-remove-icon" />
            Remove
          </div>
        )}
        <div className="tax-amount">
          <FormattedCurrency value={Number(taxLine.amount)} />
        </div>
      </div>
    </div>
  );
};
