// the service id for Order & Pay
export const SERVICE_ID_ORDER_AND_PAY: number = 1;

// the service id for Click & Collect
export const SERVICE_ID_CLICK_AND_COLLECT: number = 2;

// the service id for Pay My Bill
export const SERVICE_ID_PAY_MY_BILL: number = 6;

export const SERVICE_ID_DELIVERY_TO_LOCATION: number = 9;

//flag for standard login
export const LOGIN_METHOD_USER: number = 1;

//flag for guest checkout
export const LOGIN_METHOD_ANONYMOUS: number = 2;

//flag for SSO
export const LOGIN_METHOD_SSO: number = 3;
