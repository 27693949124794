import { useEffect } from 'react';

import * as Sentry from '@sentry/browser';

export const SentryLog: React.FC = () => {
  useEffect(() => {
    Sentry.captureEvent({
      message: `This is a test for Sentry Errors in WLWO`,
    });
  }, []);

  return <div>Sentry Testing</div>;
};
