import { VenueSalesArea } from 'types/models';
import { hexToRgb } from 'utils';
import { useConfig } from 'contexts/ConfigContext';
import { FormattedDistance } from 'common/components/FormattedDistance';
import { Button } from 'react-bootstrap';

interface SalesAreaCardProps {
  salesArea: VenueSalesArea;
  handleClick: (salesArea: VenueSalesArea) => void;
}

export const SalesAreaCard: React.FC<SalesAreaCardProps> = ({
  salesArea,
  handleClick,
}) => {
  const { gradientTintColour } = useConfig();

  const salesAreaImage = salesArea.images[0];
  const salesAreaName = salesArea.friendly || salesArea.name;

  return (
    <Button
      className="sales-area-card"
      onClick={() => handleClick(salesArea)}
      data-testid={`sales-area-card-${salesArea.id}`}
      style={{
        background: `linear-gradient(0, rgba(${hexToRgb(
          gradientTintColour,
        )}, 0.6),
        rgba(0,0,0,0)), url(${salesAreaImage?.url})`,
      }}
    >
      <div className="sales-area-descriptor" data-testid="sales-area-card-name">
        <h4>{salesAreaName}</h4>
        {salesArea.location.distance > 0 ? (
          <p>
            <FormattedDistance
              distance={salesArea.location.distance}
              showIcon={true}
              showBrackets={false}
            />
          </p>
        ) : null}
      </div>
    </Button>
  );
};
