import cs from 'classnames';
import { useVenues } from 'contexts/VenueContext';
import { VenueDisplayImage } from 'types/models';

interface VenueImageProps {
  image: VenueDisplayImage | undefined;
}

export const VenueImage: React.FC<VenueImageProps> = ({ image }) => {
  const { selectedVenue } = useVenues();
  const imageUrl = image?.url;

  const imageClasses = cs('venue-image-container', {
    'venue-image-none': !imageUrl,
  });

  return (
    <div className={imageClasses} data-testid="image-venue-container">
      {imageUrl && (
        <img
          src={imageUrl}
          className="venue-image"
          alt={image.altText || selectedVenue?.name}
        />
      )}
    </div>
  );
};
