import { Badge } from 'common/components/Badge';
import cs from 'classnames';

interface CourseHeaderProps {
  course: string;
  count?: number;
  className?: string;
}

export const CourseHeader: React.FC<CourseHeaderProps> = ({
  course,
  count,
  className,
}) => {
  const courseHeaderClasses = cs('course-header', className);
  return (
    <div className={courseHeaderClasses}>
      <h4>{course}</h4>
      {count && <Badge>{count}</Badge>}
    </div>
  );
};
