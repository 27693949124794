import { Order } from 'types/models/Orders';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import {
  OrderTaxLine,
  OrderGiftCardLine,
} from 'order-history/components/OrderBasket';
import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { getGiftCardPhrase } from 'utils';

interface OrderPaymentProps {
  order: Order;
}
export const OrderPayment: React.FC<OrderPaymentProps> = ({ order }) => {
  const { shouldPrefixRoomPhrase } = useConfig();
  const {
    roomPhrase,
    giftCard: { giftCardPhrase },
  } = usePhrases();
  const hasTip = order.tip && order.tip > 0 ? order.tip : false;
  const hasTaxes = order.taxes && order.taxes.length > 0;
  const subtotal = hasTaxes
    ? order.taxes.reduce((total, tax) => {
        return total - tax.amount;
      }, order.basketTotal)
    : order.basketTotal;
  const hasGiftCard = order?.giftCardLines?.length > 0;

  return (
    <>
      {hasTip || hasTaxes || hasGiftCard ? (
        <>
          {hasGiftCard ? (
            <>
              <h4>
                {getGiftCardPhrase('$giftCardPhrase$s applied', giftCardPhrase)}
              </h4>
              {order.giftCardLines.map((giftCardLine, i) => (
                <OrderGiftCardLine key={i} giftCardLine={giftCardLine} />
              ))}
              <hr />
            </>
          ) : null}
          <div className="order-history-total-line">
            <div>Subtotal</div>
            <div data-testid="order-history-basket-total">
              <FormattedCurrency value={subtotal} />
            </div>
          </div>
          {hasTaxes &&
            order.taxes.map((taxLine, index) => (
              <OrderTaxLine taxLine={taxLine} key={index} />
            ))}
          {hasTip && (
            <div
              className="order-history-total-line"
              data-testid="order-history-tip-amount"
            >
              <div>Tip</div>
              <FormattedCurrency value={order.tip} />
            </div>
          )}
          <hr />
        </>
      ) : null}
      <div className="order-history-total-line">
        <h2>Total</h2>
        <div>
          <h2 data-testid="order-history-grand-total">
            <FormattedCurrency value={order.grandTotal} />
          </h2>
        </div>
      </div>
      {order.paidBy && order.paidBy !== 'Charge To Room' ? (
        <div
          className="text-right text-muted"
          data-testid="order-history-payment-method"
        >
          {`Paid by ${order.paidBy}`}
        </div>
      ) : null}
      {order.roomNumber && order.paidBy === 'Charge To Room' ? (
        <div
          className="text-right text-muted"
          data-testid="order-history-payment-method"
        >
          Charged to {shouldPrefixRoomPhrase && <span>{roomPhrase} </span>}
          {order.roomNumber}
        </div>
      ) : null}
    </>
  );
};
