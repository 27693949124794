import { ServiceType } from 'types/models';

export const TimeslotPhrase = ({
  selectedService,
}: {
  selectedService: ServiceType;
}): string => {
  return selectedService === ServiceType.DeliveryToLocation
    ? 'Delivery Time'
    : 'Collection Time';
};
