import { usePhrases, useSetPhrases } from 'contexts/ConfigContext';

import { ControlLabel, FormControl } from 'react-bootstrap';

export const VoucherCallToActionText: React.FC = () => {
  const { vouchering } = usePhrases();
  const { voucherCallToAction } = vouchering;

  const setPhrase = useSetPhrases();

  const handleChange = (event: React.ChangeEvent<FormControl>) => {
    // This is a tad annoying having to do this, but react-bootstrap sucks
    const value: string = (event.target as unknown as HTMLInputElement).value;

    const vouchering = { voucherCallToAction: value };

    setPhrase('vouchering', vouchering);
  };

  return (
    <div>
      <ControlLabel className="ff-label">
        Replacement phrase for &quot;voucher call to action&quot;, which is just
        the panel title:
      </ControlLabel>
      <FormControl
        data-testid={`ff-voucherCallToAction`}
        id={`ff-voucherCallToAction`}
        type="text"
        value={voucherCallToAction}
        placeholder="Enter text"
        onChange={handleChange}
      />
    </div>
  );
};
