import { Button } from 'react-bootstrap';
import { ModalFooter, ModalBody } from 'components/Modal';
import { Notification } from 'core/components/Notification';
import { ContextualLoader } from 'components/ContextualLoader';
import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { GiftCardItem } from './components/GiftCardItem';
import { useGiftCard } from 'contexts/GiftCardContext';
import { usePhrases } from 'contexts/ConfigContext';
import { getMaskedCardNumber } from 'utils';
import { useCheckout, useCheckoutDetails } from 'contexts/CheckoutContext';

interface RedeemProps {
  goBackToVerification: () => void;
  closeModal: () => void;
}
export const RedeemGiftCardModalBody: React.FC<RedeemProps> = ({
  goBackToVerification,
  closeModal,
}) => {
  const {
    giftCard: { giftCardPhrase },
  } = usePhrases();
  const { verifiedGiftCard, debitGiftCard, isDebiting } = useGiftCard();
  const { checkBasket } = useCheckout();
  const { basketTotal } = useCheckoutDetails();

  if (!verifiedGiftCard) {
    return null;
  }

  const valueToRedeem =
    basketTotal >= verifiedGiftCard.balance
      ? verifiedGiftCard.balance
      : basketTotal;

  const handleRedeem = () => {
    debitGiftCard(
      verifiedGiftCard.giftCardId,
      valueToRedeem,
      closeModal,
      checkBasket,
    );
  };

  return (
    <>
      <ModalBody>
        <div
          className="redemption-modal-header"
          data-testid="redeem-gift-card-modal"
        >
          <h2>
            <FormattedCurrency value={valueToRedeem} />
          </h2>
          <span className="text-muted">Amount to charge</span>
        </div>
        <GiftCardItem giftCard={verifiedGiftCard} />
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-primary"
          bsStyle="primary"
          data-testid="redeem-gift-card"
          onClick={handleRedeem}
        >
          Redeem (<FormattedCurrency value={valueToRedeem} />)
        </Button>
        <Button
          className="btn btn-default hide-modal-button"
          onClick={goBackToVerification}
        >
          Cancel
        </Button>
      </ModalFooter>
      <ContextualLoader
        isVisible={isDebiting}
        loaderText={`Redeeming ${giftCardPhrase} ${getMaskedCardNumber(
          verifiedGiftCard.giftCardNumber,
        )}`}
      />
      <Notification />
    </>
  );
};
