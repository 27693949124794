import { BillCustomAmount } from 'PayMyBill/bill/components/Payment/BillCustomAmount';
import { BillEvenSplit } from 'PayMyBill/bill/components/Payment/BillEvenSplit';
import { BillTotals } from 'PayMyBill/bill/components/Totals';
import { BackToBillBtn } from 'PayMyBill/checkout/components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { BillMode, FormattedBill } from 'types/models/PayMyBill';

interface PartPaymentBodyProps {
  bill: FormattedBill;
  billMode: BillMode;
  handleChangeDisplay: (mode: BillMode) => void;
  handlePaymentAmount: (amount: number) => void;
}

interface CustomFieldProps {
  customAmount: string;
}

export const PartPaymentBody: React.FC<PartPaymentBodyProps> = ({
  bill,
  billMode,
  handleChangeDisplay,
  handlePaymentAmount,
}) => {
  const methods = useForm<CustomFieldProps>({ mode: 'all' });

  const handleCustom: SubmitHandler<CustomFieldProps> = ({ customAmount }) => {
    // parsing form input as a number as a string is returned on form submission
    // this is likely happening because the CurrencyInput component handles text/string values
    handlePaymentAmount(Number(customAmount));
  };

  const handleBack = () => {
    handleChangeDisplay(BillMode.showBill);
  };

  return (
    <>
      <div className="panel panel-default">
        <BillTotals bill={bill} showTable />
      </div>
      {billMode === BillMode.showSplit ? (
        <BillEvenSplit handlePaymentAmount={handlePaymentAmount} bill={bill} />
      ) : null}
      {billMode === BillMode.showCustom ? (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleCustom)}>
            <BillCustomAmount bill={bill} />
          </form>
        </FormProvider>
      ) : null}
      <BackToBillBtn handleClick={handleBack} />
    </>
  );
};
