export const googlePredictions = (
  googleTerm: string,
): Promise<google.maps.places.AutocompletePrediction[] | null> =>
  new Promise((resolve, reject) => {
    const Google = global.google;

    if (!Google.maps) {
      reject();
    }

    const autocompleteService = new Google.maps.places.AutocompleteService();

    autocompleteService.getPlacePredictions(
      {
        input: googleTerm,

        componentRestrictions: { country: 'uk' },
      },
      (predictions: google.maps.places.AutocompletePrediction[] | null) => {
        resolve(predictions);
      },
    );
  });

export const getGoogleCoordinates = (
  placeId: string,
): Promise<google.maps.LatLng | null> =>
  new Promise((resolve, reject) => {
    const Google = global.google;

    if (!Google) {
      reject();
    }

    const geocoder = new Google.maps.Geocoder();

    geocoder.geocode(
      {
        placeId,
      },
      (placeDetails: google.maps.GeocoderResult[] | null) => {
        if (placeDetails !== null) {
          resolve(placeDetails[0].geometry.location);
        } else {
          resolve(null);
        }
      },
    );
  });
