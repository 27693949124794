import './ToggleSwitch.scss';

interface ToggleSwitchProps {
  checked: boolean;
  disabled: boolean;
  id: string;
  name: string;
  offLabel: string;
  onChange: (toggledValue: boolean) => void;
  onLabel: string;
  small: boolean;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  checked,
  disabled,
  id,
  name,
  offLabel,
  onChange,
  onLabel,
  small,
}) => {
  //   const handleKeyPress = (e: React.KeyboardEventHandler<HTMLLabelElement>) => {
  //     if (e.keyCode !== 32) return;

  //     e.preventDefault();
  //     onChange(!checked);
  //   };

  return (
    <div
      className={'toggle-switch' + (small ? ' small-switch' : '')}
      data-testid={`toggle-${id}`}
    >
      <input
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox"
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
        role="switch"
      />
      {id ? (
        <label
          className="toggle-switch-label"
          htmlFor={id}
          data-testid={`toggle-label-${id}`}
          tabIndex={disabled ? -1 : 1}
        >
          <span
            className={
              disabled
                ? 'toggle-switch-inner toggle-switch-disabled'
                : 'toggle-switch-inner'
            }
            data-yes={onLabel}
            data-no={offLabel}
            tabIndex={-1}
          />
          <span
            className={
              disabled
                ? 'toggle-switch-switch toggle-switch-disabled'
                : 'toggle-switch-switch'
            }
            tabIndex={-1}
          />
        </label>
      ) : null}
    </div>
  );
};
