import { Keyword } from './Keyword';

interface KeywordsProps {
  list: number[];
}

export const Keywords: React.FC<KeywordsProps> = ({ list }) => {
  const uniqueKeywords = Array.from(new Set(list));

  return (
    <>
      {uniqueKeywords?.map((keywordId) => (
        <Keyword key={`keyword-${keywordId}`} keywordId={keywordId} />
      ))}
    </>
  );
};
