import { useConfig } from 'contexts/ConfigContext';
import { CustomFormattedNumber } from 'common/components/CustomFormattedNumber';
import { MinimumAgeBadge } from 'menu/components/MinimumAge';
import {
  BasketLineDeleteButton,
  BasketLineEditButton,
  BasketLinePortion,
} from 'basket/components/Line/BasketLineContent';
import { AztecProduct } from 'types/models';
import { calculateLinePrice, getSelectedPortion } from 'menu/utils';
import {
  AndedUpsellLines,
  BasketChoices,
} from 'checkout/components/CheckoutBasket/BasketLines';

interface LineProps {
  idx: number;
  line: AztecProduct;
}

export const Line: React.FC<LineProps> = ({ idx, line }) => {
  const { currencySymbolsDisabled, currency } = useConfig();
  const minimumAge = line?.minimumCustomerAge;
  const selectedPortion = getSelectedPortion(line);

  return (
    <div className="basket-item">
      <div className="basket-title">
        {line?.displayRecords[line.displayRecordId].name || line?.displayName}
        {minimumAge ? <MinimumAgeBadge minimumAge={minimumAge} /> : null}
      </div>
      <div className="basket-body">
        <BasketLinePortion line={line} idx={idx} />
        <div>
          <BasketChoices choices={selectedPortion.choices} showNested={false} />
          {line.andedUpsells && (
            <AndedUpsellLines andedUpsells={line.andedUpsells} />
          )}
        </div>
        <CustomFormattedNumber
          style={currencySymbolsDisabled ? 'decimal' : 'currency'}
          value={calculateLinePrice(line) * selectedPortion.quantity}
          currency={currency}
          minimumFractionDigits={2}
          className="basket-price"
        />
        {line.specialRequest && (
          <p className="basket-special-request">{line.specialRequest}</p>
        )}
      </div>
      {/* {line?.item?.removable === false ? null : ( */}
      <div className="basket-button-toolbar">
        <BasketLineEditButton line={line} />
        <BasketLineDeleteButton idx={idx} />
      </div>
      {/* )} */}
    </div>
  );
};
