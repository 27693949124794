import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { useVenues } from 'contexts/VenueContext';
import { addNotification } from 'core/actions';
import { useDispatch } from 'react-redux';

export const AllergenLink: React.FC = () => {
  const { showAllergensButton } = useConfig();
  const { allergensTitleLabel, missingAllergensMessage } = usePhrases();
  const dispatch = useDispatch();

  const { selectedVenue } = useVenues();

  const allergenUrl = selectedVenue?.externalLinks.find(
    (x) => x.type.toLowerCase() === 'allergens',
  )?.url;

  const handleNoUrlClick = () => {
    dispatch(addNotification(missingAllergensMessage, 'danger'));
  };

  if (!showAllergensButton) {
    return null;
  }

  if (showAllergensButton && !allergenUrl) {
    return (
      <button
        className="btn btn-link"
        style={{ textTransform: 'none', padding: 0 }}
        onClick={handleNoUrlClick}
      >
        {allergensTitleLabel}
      </button>
    );
  }

  return (
    <a href={allergenUrl} target="_blank" rel="noreferrer">
      {allergensTitleLabel}
    </a>
  );
};
