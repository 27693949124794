import { RegisterOptions } from 'react-hook-form';

type PatternCheck = boolean | string;

// These fields have their own prop in RegisterOptions

export const isRequired = (
  fieldName: string,
  required: boolean,
): RegisterOptions => {
  return {
    required: {
      value: required,
      message: `${fieldName} is required.`,
    },
  };
};
export const maxLength = (fieldName: string, max: number): RegisterOptions => {
  return {
    maxLength: {
      value: max,
      message: `${fieldName} must be no more than ${max} characters.`,
    },
  };
};

export const maxValue = (
  fieldName: string,
  max: number,
  currencyField?: string,
): RegisterOptions => {
  return {
    max: {
      value: max,
      message: `${fieldName} must be no more than ${currencyField}${max}.`,
    },
  };
};

export const higherThanValue = (
  fieldName: string,
  max: number,
  comparableFieldName?: string,
  currencyField?: string,
): RegisterOptions => {
  const message = `${fieldName} is higher than the ${
    comparableFieldName ? comparableFieldName + ' ' : ''
  }(${currencyField ? currencyField : ''}${max})`;
  return {
    max: {
      value: max,
      message: message,
    },
  };
};

export const minValue = (
  fieldName: string,
  min: number,
  currencyField?: string,
): RegisterOptions => {
  return {
    min: {
      value: min,
      message: `${fieldName} must be greater than than ${
        currencyField ? currencyField : ''
      }${min}.`,
    },
  };
};

export const zeroValue = (fieldName: string, zero: number): RegisterOptions => {
  return {
    min: {
      value: zero,
      message: `${fieldName} cannot be zero`,
    },
  };
};

export const minLength = (fieldName: string, min: number): RegisterOptions => {
  return {
    minLength: {
      value: min,
      message: `${fieldName} be at least ${min} characters.`,
    },
  };
};

// The rest of these need to be passed in as validation options to allow multiple pattern checks
// The format of how you pass the validation option depends on its requirements

// ↓↓↓↓↓↓ Basic validation
//   isEmail,

// ↓↓↓↓↓↓ Show dynamic error message
// whiteSpace: (value) => isNotOnlyWhitespace(value, labelToUse),

// ↓↓↓↓↓↓ Conditionally apply validation
// ...(shouldValidate && { isEmail })

// ↓↓↓↓↓↓ Conditionally apply validation AND show dynamic error message
// ...(shouldValidate && {
//   whiteSpace: (value) => isNotOnlyWhitespace(value, labelToUse),
// })

export const isPhoneNumber = (value: string): PatternCheck => {
  if(value === '') return true
  return (
    /^([+][0-9]{1,3}([ .-])?)?([(]{1}[0-9]{3}[)])?([0-9A-Z .-]{1,32})((x|ext|extension)?[0-9]{1,4}?)$/g.test(
      value,
    ) || 'Phone number is invalid.'
  );
};

export const isNotOnlyWhitespace = (
  value: string,
  fieldName: string,
): PatternCheck => {
  return /\S/g.test(value) || `${fieldName} is required.`;
};

export const isLoyaltyCard = (value: string): PatternCheck => {
  if (value === '') return true;
  return /^[a-zA-Z0-9]+/g.test(value) || 'Invalid card number.';
};

export const isAlphanumeric = (value: string): PatternCheck => {
  if (value === '') return true;
  return (
    /^[a-zA-Z0-9]*$/.test(value) || 'Please only enter alphanumeric characters'
  );
};

export const isAlphanumericWithSpaces = (value: string): PatternCheck => {
  if (value === '') return true;
  return (
    /^[a-zA-Z0-9 ]*$/.test(value) || 'Please only enter alphanumeric characters'
  );
};

export const isEmail = (value: string): PatternCheck => {
  if (value === '') return true;
  return (
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
      value,
    ) || 'Email address is invalid.'
  );
};

export const isPin = (value: string): PatternCheck => {
  if (value === '') return true;
  return /^[0-9]*$/.test(value) || 'Invalid PIN.';
};

export const isVoucherCode = (value: string): PatternCheck => {
  if (value === '') return true;
  return /^[a-zA-Z0-9\-_]+$/.test(value) || 'Invalid voucher code.';
};

export const isNumber = (value: string, fieldName: string): PatternCheck => {
  if (value === '') return true;
  return (
    /^[0-9]{1,10}(\.\d{1,2})?$/.test(value) || `${fieldName} must be a number.`
  );
};

// This validation covers whole numbers as well as decimals where
// A leading point is present, in cases such: .1 .12 .123
// JS converts these values to 0.1, 0.12 and 0.123 respectively as they are non-NaN

export const isParsableFloatingPoint = (
  value: string,
  fieldName: string,
): PatternCheck => {
  if (value === '') return true;
  return (
    /^(?:\d{1,10}(?:\.\d{0,3})?|\.\d{1,3})$/.test(value) ||
    `${fieldName} must be a number.`
  );
};

export const isInteger = (value: string, fieldName: string): PatternCheck => {
  if (value === '') return true;
  return /^[0-9]*$/.test(value) || `${fieldName} must be a number.`;
};

export const isPassword = (value: string, min: number): PatternCheck => {
  if (value === '') return true;
  return (
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z`¬!"£$%^&*()-_=+'@#~<>,.{}[\]]{1,}$/g.test(
      value,
    ) ||
    `Password must contain at least ${min} characters, one uppercase character, one lowercase character, and one digit`
  );
};

export const isMatch = (
  value: string,
  matchedValue: string,
  fieldName: string,
): PatternCheck => {
  return value === matchedValue || `${fieldName} must match.`;
};
