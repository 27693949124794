import { useConfig, useSetConfig } from 'contexts/ConfigContext';

import { ControlLabel, FormControl } from 'react-bootstrap';

export const ApplePayButtonColourSelect: React.FC = () => {
  const { applePayButtonColour } = useConfig();
  const setConfig = useSetConfig();

  const handleChange = (event: React.ChangeEvent<FormControl>) => {
    // This is a tad annoying having to do this, but react-bootstrap sucks
    const value: string = (event.target as unknown as HTMLSelectElement).value;
    setConfig('applePayButtonColour', value);
  };

  return (
    <div>
      <ControlLabel className="ff-label">Apple Pay Button Colour:</ControlLabel>
      <FormControl
        componentClass="select"
        data-testid={`ff-applePayButtonColour`}
        id={`ff-applePayButtonColour`}
        type="select"
        value={applePayButtonColour}
        onChange={handleChange}
      >
        <option>black</option>
        <option>white</option>
        <option>outline</option>
      </FormControl>
    </div>
  );
};
