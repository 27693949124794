import { useConfig } from 'contexts/ConfigContext';
import { Link, useLocation } from 'react-router-dom';
import cs from 'classnames';
import { useVenues } from 'contexts/VenueContext';

interface ErrorPanelProps {
  title: string;
  classNames?: string;
}

export const VenueErrorPanel: React.FC<ErrorPanelProps> = ({
  title,
  classNames,
}) => {
  const location = useLocation();
  const { logoLinkToHomepage, homepage } = useConfig();
  const { selectedVenue } = useVenues();
  const containerClasses = cs('error-panel-contianer text-center', classNames);

  const getHomeLink = (): string => {
    if (!selectedVenue || location.pathname === `/venue/${selectedVenue.id}`) {
      return '/venues';
    } else {
      return `/venue/${selectedVenue.id}`;
    }
  };

  return (
    <div className={containerClasses}>
      <div className="panel panel-default">
        <h3>{title}</h3>
        <br />
        {logoLinkToHomepage ? (
          <a href={`${homepage}`} className="btn btn-primary">
            Back to homepage
          </a>
        ) : (
          <Link to={getHomeLink} className="btn btn-primary">
            Back to homepage
          </Link>
        )}
      </div>
    </div>
  );
};
