import { Grid } from 'react-bootstrap';

export const NotFound: React.FC = () => {
  return (
    <Grid>
      <div className="panel" data-testid="panel-not-found">
        <h3>Not Found</h3>
        <p>The page that you&apos;re looking for doesn&apos;t exist.</p>
        <p>
          <a href="/">Return to the homepage</a>
        </p>
      </div>
    </Grid>
  );
};
