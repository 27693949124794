import { ModalBody } from 'components/Modal';
import { AztecProduct, BreadcrumbTrack } from 'types/models';
import {
  ChoiceSelectorModal,
  PortionSelectorModal,
} from 'menu/components/MenuModal/ModalBody';
import { portionsHaveChoices } from 'menu/utils';

interface MenuModalBodyProps {
  aztecProduct: AztecProduct;
  updateAztecProduct: (product: AztecProduct) => void;
  updateBreadcrumbs: (breadcrumbs: BreadcrumbTrack[]) => void;
}

export const MenuModalBody: React.FC<MenuModalBodyProps> = ({
  aztecProduct,
  updateAztecProduct,
  updateBreadcrumbs,
}) => {
  const somePortionsHaveChoices = portionsHaveChoices(aztecProduct);
  const andedUpsells = aztecProduct.andedUpsells;
  console.log({ aztecProduct });

  const workOutModalToShow = () => {
    // Will have to add more logic to handle the no choice modal - when the portion selected has no choices but we could do that withing the ChoiceSelectorModal
    return (somePortionsHaveChoices ||
      (andedUpsells && andedUpsells.length > 0)) &&
      Object.values(aztecProduct.portions).some(
        (portion) => portion.quantity && portion.quantity >= 1,
      ) ? (
      <ChoiceSelectorModal
        aztecProduct={aztecProduct}
        updateAztecProduct={updateAztecProduct}
        updateBreadcrumbs={updateBreadcrumbs}
      />
    ) : (
      <PortionSelectorModal
        aztecProduct={aztecProduct}
        updateAztecProduct={updateAztecProduct}
      />
    );
  };

  const modalToShow = workOutModalToShow();

  return <ModalBody>{modalToShow}</ModalBody>;
};
