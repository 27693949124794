import { usePhrases } from 'contexts/ConfigContext';
import dompurify from 'dompurify';

export const DeliveryToLocationModalHeader: React.FC = () => {
  const sanitizer = dompurify.sanitize;
  const { additionalInformation } = usePhrases();

  return (
    <>
      <h2 className="title">{additionalInformation.headline}</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizer(additionalInformation.subtitle),
        }}
      />
    </>
  );
};
