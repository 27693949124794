import { useConfig, useSetConfig } from 'contexts/ConfigContext';

import { ControlLabel } from 'react-bootstrap';
import { ToggleSwitch } from 'core/components/ToggleSwitch';

export const SupportsGiftCardRedemptionToggle: React.FC = () => {
  const { supportsGiftCardRedemption } = useConfig();
  const setConfig = useSetConfig();

  const handleClick = (toggledValue: boolean) => {
    setConfig('supportsGiftCardRedemption', toggledValue);
  };

  return (
    <div>
      <ControlLabel className="ff-label">Gift Card Redemption:</ControlLabel>
      <ToggleSwitch
        id="gift-card-redemption"
        onChange={handleClick}
        checked={supportsGiftCardRedemption}
        disabled={false}
        name="gift-card-redemption"
        onLabel="on"
        offLabel="off"
        small={false}
      />
    </div>
  );
};
