import BarLoader from 'react-spinners/BarLoader';

interface Loader {
  text?: string;
}

export const Loader: React.FC<Loader> = ({ text }) => {
  return (
    <div className="data-loader" data-testid="loader">
      <h4 className="text" data-testid="loader-text">
        {text ? `${text}...` : 'Loading...'}
      </h4>
      <BarLoader width={104} />
    </div>
  );
};
