import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { selectIsLoggedIn, selectLoginMethod } from 'user/selectors';
import { LoginMethod } from 'types/models/User';
import { useConfig } from 'contexts/ConfigContext';

export const AuthenticatedOrderingRoute: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const loginMethod = useSelector(selectLoginMethod);

  const { accountDeletionOnly } = useConfig();

  const ComponentElement = Component as React.ElementType;

  const loggedIn =
    isLoggedIn &&
    isLoggedIn === true &&
    (loginMethod === LoginMethod.Sso || loginMethod === LoginMethod.User);

  return (
    <Route
      {...rest}
      render={(props): React.ReactNode => {
        if (loggedIn && !accountDeletionOnly) {
          return <ComponentElement {...props} />;
        }

        if (loggedIn && accountDeletionOnly) {
          return (
            <Redirect
              to={{
                pathname: '/user/delete-account',
                state: { from: props.location },
              }}
            />
          );
        }

        return (
          <Redirect
            to={{
              pathname: '/user/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
