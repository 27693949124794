import { MdDelete } from 'react-icons/md';
import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { VoucherBasketLine } from 'types/models/Vouchers';

interface VoucherPanelLineProps {
  handleRemovalClick: (voucherLine: VoucherBasketLine) => void;
  voucherLine: VoucherBasketLine;
  isVoiding: boolean;
}

export const VoucherPanelLine: React.FC<VoucherPanelLineProps> = ({
  handleRemovalClick,
  voucherLine,
  isVoiding,
}) => {
  return (
    <div className={isVoiding ? 'panel-line loading' : 'panel-line'}>
      <div className="remove-button">
        {!isVoiding ? (
          <MdDelete
            className="remove"
            size={25}
            role="button"
            onClick={() => handleRemovalClick(voucherLine)}
          />
        ) : (
          <MdDelete
            className="remove"
            size={25}
            aria-disabled={isVoiding}
            role="button"
          />
        )}
      </div>
      <div className="details">
        <h5>
          {voucherLine.voucher.metaData.friendlyName ||
            voucherLine.voucher.metaData.description}
        </h5>
        <div>{voucherLine.voucher.voucherCode}</div>
      </div>
      <div className="discount">
        {voucherLine.Amount ? (
          <FormattedCurrency value={voucherLine.Amount} />
        ) : null}
      </div>
    </div>
  );
};
