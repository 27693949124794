import cs from 'classnames';
import { Button } from 'react-bootstrap';
import { CombinedVenue } from 'types/models';

interface VenueInfoHeaderProps {
  venue: CombinedVenue;
  handleOpen: () => void;
}

export const VenueInfoHeader: React.FC<VenueInfoHeaderProps> = ({
  venue,
  handleOpen,
}) => {
  
  const image = venue?.displayImages[0]
  const imageUrl = image?.url;
  const imageStyles = imageUrl ? { background: `url(${imageUrl})` } : undefined;
  const imageClasses = cs('venue-info-header', {
    'venue-image-none': !imageUrl,
  });

  return (
    <div className={imageClasses} style={imageStyles}>
      {imageUrl ? <div className="overlay"></div> : null}
      <div className="venue-image-title">
        <h3 className="no-margin">{venue.name}</h3>
        <Button bsSize="small" bsStyle={'default'} onClick={handleOpen}>
          More Info
        </Button>
      </div>
    </div>
  );
};
