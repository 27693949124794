import { usePhrases } from 'contexts/ConfigContext';

export const LandingHeader: React.FC = () => {
  const { landingHeader, venueTitle } = usePhrases();

  return (
    <h1 className="text-center select-your-venue">
      {landingHeader || `Select your ${venueTitle}`}
    </h1>
  );
};
