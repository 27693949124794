import { AztecProduct } from 'types/models';
import {
  OnlyPortionModal,
  QuantifyPortionsModal,
  SelectPortionModal,
} from 'menu/components/MenuModal/ModalBody';
import { getProductPortions, portionsHaveChoices } from 'menu/utils';

interface PortionSelectorModalProps {
  aztecProduct: AztecProduct;
  updateAztecProduct: (product: AztecProduct) => void;
}

export const PortionSelectorModal: React.FC<PortionSelectorModalProps> = ({
  aztecProduct,
  updateAztecProduct,
}) => {
  const productPortions = getProductPortions(aztecProduct);
  const numberOfPortions = productPortions.length;
  const somePortionsHaveChoices = portionsHaveChoices(aztecProduct);
  const andedUpsells = aztecProduct.andedUpsells;

  if (numberOfPortions === 1) {
    return (
      <OnlyPortionModal
        aztecProduct={aztecProduct}
        updateAztecProduct={updateAztecProduct}
      />
    );
  } else if (
    numberOfPortions > 1 &&
    !somePortionsHaveChoices &&
    !andedUpsells
  ) {
    return (
      <QuantifyPortionsModal
        aztecProduct={aztecProduct}
        updateAztecProduct={updateAztecProduct}
      />
    );
  } else if (
    numberOfPortions > 1 &&
    (somePortionsHaveChoices || andedUpsells)
  ) {
    return (
      <SelectPortionModal
        aztecProduct={aztecProduct}
        updateAztecProduct={updateAztecProduct}
      />
    );
  }

  return null;
};
