import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { useConfig } from 'contexts/ConfigContext';

import { Folio } from 'types/models/Hotels';

interface FolioChoiceProps {
  folio: Folio;
  isSelected: boolean;
  handleFolioSelect: (folio: Folio) => void;
}

export const FolioChoice: React.FC<FolioChoiceProps> = ({
  folio,
  isSelected,
  handleFolioSelect,
}) => {
  const { showFolioBalance } = useConfig();
  return (
    <div className="folio-choice">
      <label
        htmlFor={folio.folioId}
        className={`radio-inline ${isSelected ? 'on' : ''}`}
      >
        <input
          name="room-folio"
          id={folio.folioId}
          onChange={() => handleFolioSelect(folio)}
          type="radio"
          checked={isSelected}
        />
      </label>
      <div>
        <h3>{folio.guestName}</h3>
        {showFolioBalance && (
          <div className="text-muted">
            Remaining credit:{' '}
            <FormattedCurrency value={folio.remainingCredit} />
          </div>
        )}
      </div>
    </div>
  );
};
