import { MdErrorOutline } from 'react-icons/md';

interface PaymentErrorDisplayProps {
  errorMessage: string;
  isVisible: boolean;
}

export const ContextualLoaderError: React.FC<PaymentErrorDisplayProps> = ({
  errorMessage,
  isVisible,
}) => {
  return isVisible ? (
    <div className="contextual-loader opaque error">
      <MdErrorOutline size={40} />
      <h4 className="text-center">{errorMessage}</h4>
    </div>
  ) : null;
};
