import { CustomFormattedNumber } from 'common/components/CustomFormattedNumber';
import { MinimumAgeBadge } from 'menu/components/MinimumAge';
import { useConfig } from 'contexts/ConfigContext';
import {
  BasketLineEditButton,
  BasketLineDeleteButton,
  BasketLinePortion,
} from 'basket/components/Line/BasketLineContent';
import { AztecProduct } from 'types/models';
import { calculateLinePrice, getSelectedPortion } from 'menu/utils';
import {
  AndedUpsellLines,
  BasketChoices,
} from 'checkout/components/CheckoutBasket/BasketLines';

interface StickyBasketLineProps {
  idx: number;
  line: AztecProduct;
}

export const StickyBasketLine: React.FC<StickyBasketLineProps> = ({
  idx,
  line,
}) => {
  const { currencySymbolsDisabled, currency } = useConfig();
  const minimumAge = line?.minimumCustomerAge;
  const selectedPortion = getSelectedPortion(line);

  return (
    <div data-testid="basket-item" className="list-group-item">
      <div className="basket-section">
        <h4 className="basket-line" data-testid="basket-item-name">
          {line?.displayRecords[line.displayRecordId].name || line?.displayName}
        </h4>
        <BasketLinePortion line={line} idx={idx} />
        <div className="sticky-basket-choices" data-testid="basket-item-choice">
          <BasketChoices choices={selectedPortion.choices} showNested={false} />
          {line.andedUpsells && (
            <AndedUpsellLines andedUpsells={line.andedUpsells} />
          )}
        </div>
      </div>
      <div className="basket-section">
        <div className="basket-item-bottom-bar">
          <span className="basket-price" data-testid="basket-item-price">
            <CustomFormattedNumber
              style={currencySymbolsDisabled ? 'decimal' : 'currency'}
              value={
                calculateLinePrice(line) * (selectedPortion?.quantity ?? 0)
              }
              currency={currency}
              minimumFractionDigits={2}
            />
          </span>

          {minimumAge ? (
            <div className="basket-minimum-age-container">
              <MinimumAgeBadge minimumAge={minimumAge} />
            </div>
          ) : null}
        </div>
        <p className="basket-special-request">{line?.specialRequest}</p>
        {/* {line?.item?.removable === false ? null : ( */}
        <div className="btn-toolbar">
          <div className="row">
            <div className="col-xs-5">
              <BasketLineEditButton line={line} />
            </div>
            <div className="col-xs-7 sticky-basket-delete">
              <BasketLineDeleteButton idx={idx} />
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    </div>
  );
};
