import { Loader } from 'components/Loader';

interface ContextualLoaderProps {
  isVisible: boolean;
  loaderText?: string;
  isOpaque?: boolean;
  datatestid?: string;
}

export const ContextualLoader: React.FC<ContextualLoaderProps> = ({
  isVisible,
  loaderText,
  isOpaque,
  datatestid
}) => {
  const classToUse =
    isOpaque !== undefined && isOpaque === true
      ? 'contextual-loader opaque'
      : 'contextual-loader';

  return (
    <div
      data-testid={datatestid || "loader-wrapper"}
      className={isVisible ? classToUse : `${classToUse} hidden`}
    >
      <Loader text={loaderText}></Loader>
    </div>
  );
};
