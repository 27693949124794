import { useSelector } from 'react-redux';
import { selectAdditionalInfoErrorDialog } from 'checkout/selectors';
import { Loader } from 'components/Loader';
import { Button } from 'react-bootstrap';
import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { GuestCheckoutForm } from 'checkout/components/Payment/GuestCheckout';
import { AdditionalFieldsForm } from 'checkout/components/Payment/AdditionalFields';
import { useAdditionalInformation } from 'contexts/AdditionalInformationContext';
import { normaliseFormData } from 'checkout/utils';
import { RestartOrderDialog } from 'checkout/components/DeliveryToLocation';
import { PaymentType } from 'types/models/Payment';
import { useCheckout } from 'contexts/CheckoutContext';

interface FormProps {
  email?: string;
  field_1?: string;
  field_2?: string;
}

export const ZeroPriceBasketPaymentPanel: React.FC = () => {
  const methods = useForm<FormProps>({ mode: 'all' });

  const {
    savedLocationInformation,
    locationInformationFields,
    userInformationFields,
  } = useAdditionalInformation();

  const { submitPayment, isSubmittingPayment } = useCheckout();

  const additionalInfoError999 = useSelector(selectAdditionalInfoErrorDialog);

  const placeOrder: SubmitHandler<FormProps> = (formData) => {
    const normalisedFormData = normaliseFormData(
      locationInformationFields,
      userInformationFields,
      { ...savedLocationInformation, ...formData },
    );
    submitPayment(normalisedFormData, null, null, PaymentType.CreditCard);
  };

  return (
    <>
      {isSubmittingPayment ? (
        <Loader text="Placing Order" />
      ) : (
        <>
          <h3 className="title">Place Order</h3>
          <FormProvider {...methods}>
            <div className="body">
              <form>
                <GuestCheckoutForm />
                <AdditionalFieldsForm />
                <Button
                  className="btn btn-primary center-block checkout-fw-button"
                  bsStyle="primary"
                  onClick={methods.handleSubmit(placeOrder)}
                  data-testid="btn-place-order"
                >
                  Place Order (<FormattedCurrency value={0} />)
                </Button>
              </form>
            </div>
          </FormProvider>
        </>
      )}
      <RestartOrderDialog
        isOpen={!!additionalInfoError999}
        title="Payment Error"
        description={additionalInfoError999}
      />
    </>
  );
};
