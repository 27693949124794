import { PaymentContextualLoader } from 'checkout/components/Payment/ContextualLoader';
import { selectSelectedTipPreset, selectTipAmount } from 'checkout/selectors';
import { setTipAmount, setSelectedTipPreset } from 'checkout/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { usePhrases } from 'contexts/ConfigContext';
import {
  CustomTipForm,
  TipPresetButtonList,
  TipConflictText,
} from 'checkout/components/Tip/components';
import { useCheckoutDetails } from 'contexts/CheckoutContext';
import { usePayMyBill } from 'contexts/PayMyBillContext/PayMyBillContext';

interface TipProps {
  isPmb?: boolean;
}

export const Tip: React.FC<TipProps> = ({ isPmb = false }) => {
  const dispatch = useDispatch();
  const { tipButtonText, tipSupportingText } = usePhrases();

  const { basketTotal } = useCheckoutDetails();
  const { paymentAmount } = usePayMyBill();

  const totalToUse = isPmb ? paymentAmount : basketTotal;

  const tipPercentage = useSelector(selectSelectedTipPreset);
  const tipAmount = useSelector(selectTipAmount);

  const [currencyInputShown, setCurrencyInputShown] = useState<boolean>(false);

  const calculateTipAmount = (preset: number) => {
    return Number(totalToUse * Number(preset / 100)).toFixed(2);
  };

  const calculatePercentageTip = (value: number) => {
    if (value < 0) {
      setCurrencyInputShown(!currencyInputShown);
    } else {
      dispatch(setTipAmount(calculateTipAmount(value)));
      dispatch(setSelectedTipPreset(value));
      setCurrencyInputShown(false);
    }
  };

  useMemo(() => {
    if (totalToUse === 0 || (tipPercentage < 0 && tipAmount === 0)) {
      calculatePercentageTip(0);
      return;
    }

    if (tipPercentage > 0) {
      calculatePercentageTip(tipPercentage);
    }

    if (tipPercentage < 0 && tipAmount > 0) {
      setCurrencyInputShown(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalToUse]);

  return (
    <div className="card tip">
      <PaymentContextualLoader loadingText="Loading" isOpaque={false} isPmb={isPmb}/>
      <h3 className="title">{tipButtonText}</h3>
      <div className="body">
        <TipConflictText totalToUse={totalToUse} />
      </div>
      <TipPresetButtonList
        handlePresetClick={calculatePercentageTip}
        calculateTipAmount={calculateTipAmount}
        totalToUse={totalToUse}
      />
      {currencyInputShown ? <CustomTipForm /> : null}
      {tipSupportingText ? (
        <div className="text-muted tip-text">{tipSupportingText}</div>
      ) : null}
    </div>
  );
};
