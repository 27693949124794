import { useConfig, useSetConfig } from 'contexts/ConfigContext';

import { ControlLabel } from 'react-bootstrap';
import { ToggleSwitch } from 'core/components/ToggleSwitch';

export const VenueHomeBanners: React.FC = () => {
  const { showVenueHomeBanners } = useConfig();
  const setConfig = useSetConfig();

  const handleClick = (toggledValue: boolean) => {
    setConfig('showVenueHomeBanners', toggledValue);
  };

  return (
    <div>
      <ControlLabel className="ff-label">Show Venue Home Page Banners:</ControlLabel>
      <ToggleSwitch
        id="venue-home-banners"
        onChange={handleClick}
        checked={showVenueHomeBanners}
        disabled={false}
        name="venue-home-banners"
        onLabel="Yes"
        offLabel="No"
        small={false}
      />
    </div>
  );
};
