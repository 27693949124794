import { usePhrases } from 'contexts/ConfigContext';
import { ServiceType } from 'types/models';
import { useServices } from 'contexts/VenueContext';

export const SalesAreaHeadline: React.FC = () => {
  const { selectedService } = useServices();
  const {
    salesAreaAtTableHeadline,
    salesAreaClickAndCollectHeadline,
    salesAreaDeliveryToHeadline,
  } = usePhrases();

  return (
    <h3>
      {selectedService === ServiceType.OrderAndPay ||
      selectedService === ServiceType.PayMyBill
        ? salesAreaAtTableHeadline
        : null}
      {selectedService === ServiceType.ClickAndCollect
        ? salesAreaClickAndCollectHeadline
        : null}
      {selectedService === ServiceType.DeliveryToLocation
        ? salesAreaDeliveryToHeadline
        : null}{' '}
    </h3>
  );
};
