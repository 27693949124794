import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { OrderTaxItem } from 'types/models/Orders';

interface OrderTaxLineProps {
  taxLine: OrderTaxItem;
}
export const OrderTaxLine: React.FC<OrderTaxLineProps> = ({ taxLine }) => {
  return (
    <div className="order-history-total-line">
      <div className="text-break">{taxLine.friendlyName}</div>
      <FormattedCurrency value={taxLine.amount} />
    </div>
  );
};
