// Typescripting stuff slowly from the model

import {
  Service,
  ServiceType,
  VenueAddress,
  CombinedVenue,
  VenueSalesArea,
  VenueBanner,
  acceptedVenueBannerUrls,
  VenueHomeImage,
  venueBannerCustomUrlPrefix,
} from 'types/models';
import { sortWithSortOrder } from 'utils';

export const isVenueTemporarilyUnavailable = (
  venue: CombinedVenue,
): boolean => {
  return !!venue.isTemporarilyClosed;
};

export const isSalesAreaSupportingService = (
  venue: CombinedVenue,
  service: number,
): boolean => {
  const salesArea = venue.salesArea;

  return salesArea?.some((area) => area.services.includes(service)) || false;
};

export const isVenueAvailable = (
  supportsOrderDestinations: boolean,
  venue: CombinedVenue | undefined,
): boolean => {
  // use this to check whether the services we support are set up
  if (!venue) return false;

  const venueCanOrder = !!venue.canOrder;

  if (supportsOrderDestinations && venueCanOrder) {
    return (
      (venue.services || []).indexOf(ServiceType.ClickAndCollect) >= 0 ||
      (venue.services || []).indexOf(ServiceType.OrderAndPay) >= 0 ||
      (venue.services || []).indexOf(ServiceType.PayMyBill) >= 0 ||
      (venue.services || []).indexOf(ServiceType.DeliveryToLocation) >= 0
    );
  }

  return venueCanOrder;
};

export const getServiceLabel = (
  serviceList: Service[],
  service: number,
): string => {
  const serviceArray = serviceList;
  const serviceItem = serviceArray.find((x) => x.id == service);

  if (serviceItem) {
    return serviceItem.label;
  }

  return '';
};

export const getSupportedServices = (
  services: number[],
  enableOrderAndPay: boolean,
  enableClickAndCollect: boolean,
  enableDeliveryLocation: boolean,
): number[] => {
  if (!services) return [];

  const venueServices = services.filter(
    (x) =>
      x === ServiceType.OrderAndPay ||
      x === ServiceType.ClickAndCollect ||
      x === ServiceType.DeliveryToLocation,
    // || x === ServiceType.PayMyBill,
  );

  const servicesToReturn = [];

  if (
    venueServices.includes(ServiceType.ClickAndCollect) &&
    !!enableClickAndCollect
  ) {
    servicesToReturn.push(ServiceType.ClickAndCollect);
  }

  if (venueServices.includes(ServiceType.OrderAndPay) && !!enableOrderAndPay) {
    servicesToReturn.push(ServiceType.OrderAndPay);
  }

  if (
    venueServices.includes(ServiceType.DeliveryToLocation) &&
    !!enableDeliveryLocation
  ) {
    servicesToReturn.push(ServiceType.DeliveryToLocation);
  }

  // if (venueServices.includes(ServiceType.PayMyBill)) {
  //   servicesToReturn.push(ServiceType.PayMyBill);
  // }

  // works b/c enum
  return servicesToReturn.sort((x, y) => y - x);
};

export const isTimeslotService = (serviceId: number): boolean => {
  return (
    serviceId === ServiceType.ClickAndCollect ||
    serviceId === ServiceType.DeliveryToLocation
  );
};

export const isSalesAreaSupportingServices = (
  venue: CombinedVenue,
  services: number[],
): boolean => {
  const salesArea = venue.salesArea;

  return (
    salesArea?.some((area) =>
      area.services.some((service) => services.includes(service)),
    ) || false
  );
};

export const filterActiveSalesAreas = (
  salesAreas: VenueSalesArea[] | undefined,
  serviceId: number,
): VenueSalesArea[] => {
  return salesAreas && serviceId
    ? salesAreas.filter(
        (sa: VenueSalesArea) => sa.canOrder && sa.services.includes(serviceId),
      )
    : [];
};

export const filterSalesAreasCanPlaceOrder = (
  salesAreas: VenueSalesArea[] | undefined,
): VenueSalesArea[] => {
  return salesAreas
    ? salesAreas.filter((sa: VenueSalesArea) => sa.canPlaceOrder)
    : [];
};

export const addressToString = (
  address: VenueAddress,
  separator: string,
  omit?: string[],
): string => {
  const addressFieldOrder = [
    'line1',
    'line2',
    'line3',
    'town',
    'county',
    'postcode',
    'country',
  ];

  const include = addressFieldOrder.reduce((acc: string[], addressField) => {
    // if part of address is not to be omitted, push it to final array

    if (address.hasOwnProperty(addressField) && !omit?.includes(addressField)) {
      acc.push(address[addressField]);
    }

    return acc;
  }, []);

  return include.filter((add) => add).join(`${separator} `);
};

export const formatVenueBanners = (banners: VenueBanner[]): VenueBanner[] => {
  const filteredBanners = filterAcceptedVenueBanners(banners);
  addServiceIdToBanner(filteredBanners);
  return filteredBanners.sort((a, b) => sortWithSortOrder(a, b));
};

const filterAcceptedVenueBanners = (banners: VenueBanner[]): VenueBanner[] => {
  return banners.filter(
    (banner) =>
      acceptedVenueBannerUrls.includes(banner.link.toLocaleLowerCase()) ||
      banner.link.startsWith(venueBannerCustomUrlPrefix),
  );
};

const addServiceIdToBanner = (banners: VenueBanner[]) => {
  banners.forEach((banner) => {
    const bannerLink = banner.link.toLocaleLowerCase();
    if (bannerLink === 'iorder://menu/orderfood') {
      banner.serviceId = ServiceType.OrderAndPay;
    }
    if (bannerLink === 'iorder://menu/clickandcollect') {
      banner.serviceId = ServiceType.ClickAndCollect;
    }
    if (bannerLink === 'iorder://menu/deliverytolocation') {
      banner.serviceId = ServiceType.DeliveryToLocation;
    }
    if (bannerLink === 'iorder://menu/paymybill') {
      banner.serviceId = ServiceType.PayMyBill;
    }
  });
};

export const allBannersAreOrderingModes = (banners: VenueBanner[]): boolean => {
  return banners.every((banner) => banner.serviceId);
};

export const getVenueImage = (
  images: VenueHomeImage[] | undefined,
): VenueHomeImage | undefined => {
  return images ? images.find((image) => image.device === 'normal') : undefined;
};
