import { usePhrases } from 'contexts/ConfigContext';
import { useAdditionalInformation } from 'contexts/AdditionalInformationContext';
import { FieldGenerator } from 'core/components/RHF';
import { isTimeslotService } from 'venue/utils';
import { useServices } from 'contexts/VenueContext';

export const AdditionalFieldsForm: React.FC = () => {
  const { selectedService } = useServices();
  const shouldShowAdditionalInfo = isTimeslotService(selectedService);

  const { userInformationFields } = useAdditionalInformation();
  const {
    additionalInformation: { usagePhrase },
  } = usePhrases();

  return (
    <>
      {shouldShowAdditionalInfo ? (
        <>
          {userInformationFields
            .filter((userField) => userField.showInUI)
            .map((field, i, { length }) => (
              <>
                <div
                  className="row"
                  data-testid={`additional-field-${
                    field.userFieldType || field.name
                  }`}
                >
                  <div className="col-xs-12 col-md-12">
                    <FieldGenerator field={field} key={field.name} />
                    {/* remove this dob type check once we get a DOB component written */}
                    {length - 1 === i && field.userFieldType !== 'dob' ? (
                      <p className="additional-info-supporting-text text-muted">
                        {usagePhrase}
                      </p>
                    ) : null}
                  </div>
                </div>
              </>
            ))}
        </>
      ) : null}
    </>
  );
};
