/* eslint-disable react/no-array-index-key */

import { Row } from 'react-bootstrap';
import isNil from 'lodash/isNil';
import { FieldGenerator } from 'core/components/RHF';
import { PopoverButton } from 'core/components/FormFields';
import { ConfigField } from 'types/models/Forms';

interface FormGeneratorProps {
  definition: ConfigField[];
}

export const FormGenerator: React.FC<FormGeneratorProps> = ({ definition }) => (
  <div className={definition.length < 3 ? '' : 'form-container'}>
    {definition.map((row) => (
      <Row className="form-row" key={`form-row-${row.row}`}>
        {row.fields.map((field) => (
          <FieldGenerator key={field.name} field={field} />
        ))}
        {row.fields.map(
          (field) =>
            isNil(!field.popoverText) && (
              <PopoverButton
                key={`${field.name}-popover`}
                name={field.name}
                text={field.popoverText || ''}
                position={field.popoverPosition || ''}
                inline
              />
            ),
        )}
      </Row>
    ))}
  </div>
);
