import { Button } from 'react-bootstrap';
import { FaChevronCircleLeft } from 'react-icons/fa';

interface BackToBillBtnProps {
  handleClick: () => void;
}

export const BackToBillBtn: React.FC<BackToBillBtnProps> = ({
  handleClick,
}) => {
  return (
    <Button
      onClick={handleClick}
      bsClass="btn btn-default checkout-fw-button back-to-bill-btn"
      data-testid="back-to-bill-button"
    >
      <FaChevronCircleLeft />
      Back to Bill
    </Button>
  );
};
