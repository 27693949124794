import { VoucherBasketLine } from 'types/models/Vouchers';

interface OrderVoucherLineProps {
  key: string;
  voucherLine: VoucherBasketLine;
}

export const OrderVoucherLine: React.FC<OrderVoucherLineProps> = ({
  key,
  voucherLine,
}) => {
  return (
    <>
      <div key={key}>
        <div>{voucherLine.voucher.metaData.friendlyName}</div>
        <div>
          <span className="text-muted">{voucherLine.voucherCode}</span>
        </div>
      </div>
      <hr />
    </>
  );
};
