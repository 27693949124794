import { initialState } from './model';
import * as actionTypes from './actionTypes';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_SERVICE_CHOICE_MODAL:
      return state.set('serviceChoiceModalFlag', true);

    case actionTypes.HIDE_SERVICE_CHOICE_MODAL:
      return state.set('serviceChoiceModalFlag', false);

    case actionTypes.MARK_AS_SHOWN:
      return state.set('modalShown', true);

    default:
      return state;
  }
};

export default reducer;
