export const sortByDateAscending = (a: string, b: string) :number => {
    // check if there either date is null or undefined
    if (!a && !b) return 0;
    if (a && !b) return -1;
    if (!a && b) return 1;

    // create date obj to compare
    const dateA = new Date(a).getTime();
    const dateB = new Date(b).getTime();
    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;
    return 0;
  };