import { Button } from 'react-bootstrap/lib';
import { MdEdit } from 'react-icons/md';
import { AztecProduct } from 'types/models';
import { useMenuModal } from 'contexts/MenuModalContext';
import { cloneDeep } from 'lodash';

interface BasketLineEditButtonProps {
  line: AztecProduct;
}

export const BasketLineEditButton: React.FC<BasketLineEditButtonProps> = ({
  line,
}) => {
  const { setSelectedAztecProduct } = useMenuModal();

  const onEditLineClick = (line: AztecProduct) => {
    const lineCopy = cloneDeep(line);
    setSelectedAztecProduct(lineCopy);
  };

  return (
    <Button
      title="Edit"
      className="button-edit-line btn-with-icon"
      bsSize="xsmall"
      onClick={() => onEditLineClick(line)}
      data-testid="edit-item-button"
    >
      <MdEdit />
    </Button>
  );
};
