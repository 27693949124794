import { useConfig } from 'contexts/ConfigContext';
import {
  ProductGalleryItem,
  ProductListItem,
} from 'menu/components/MenuDisplayItems';
import { Element } from 'react-scroll';
import { MenuGroupItemProduct } from 'types/models';

interface ProductItemProps {
  productItem: MenuGroupItemProduct;
}

export const ProductItem: React.FC<ProductItemProps> = ({ productItem }) => {
  const { galleryViewEnabled } = useConfig();

  return galleryViewEnabled ? (
    <ProductGalleryItem
      product={productItem.product}
      upsellGroupId={productItem.upsellGroupId}
    />
  ) : (
    <Element name={`product-item-${productItem.product.productId}`}>
      <ProductListItem
        product={productItem.product}
        upsellGroupId={productItem.upsellGroupId}
      />
    </Element>
  );
};
