import { useConfig, useSetConfig } from 'contexts/ConfigContext';
import { ControlLabel } from 'react-bootstrap';
import { ToggleSwitch } from 'core/components/ToggleSwitch';

export const CaptchaDisabledToggle: React.FC = () => {
  const { captchaDisabled } = useConfig();
  const setConfig = useSetConfig();

  const handleClick = (toggledValue: boolean) => {
    setConfig('captchaDisabled', toggledValue);
  };

  return (
    <div>
      <ControlLabel className="ff-label">Captcha Disabled</ControlLabel>
      <ToggleSwitch
        id="captcha-disabled"
        onChange={handleClick}
        checked={captchaDisabled}
        disabled={false}
        name="captcha-disabled"
        onLabel="on"
        offLabel="off"
        small={false}
      />
    </div>
  );
};
