interface SortOrderWise {
  sortOrder: number;
}

export const sortWithSortOrder = <T extends SortOrderWise>(
  a: T,
  b: T,
): number => {
  if (a.sortOrder < b.sortOrder) {
    return -1;
  }

  if (a.sortOrder > b.sortOrder) {
    return 1;
  }

  return 0;
};
