export const hasOwnPropertyCaseInsensitive = <T extends object>(
  obj: T,
  prop: string,
): boolean => {
  return (
    Object.keys(obj).filter(
      (x) => x.toLocaleLowerCase() === prop.toLocaleLowerCase(),
    ).length > 0
  );
};

export const getPropertyCaseInsensitive = <T extends object>(
  obj: T,
  key: string,
): unknown | undefined => {
  const asLowercase = key.toLowerCase();
  const objKey = Object.keys(obj).find((k) => k.toLowerCase() === asLowercase);
  return objKey ? obj[objKey as keyof T] : undefined;
};
