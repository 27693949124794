import { Dialog } from 'components/Dialog';
import { usePhrases } from 'contexts/ConfigContext';
import { useServices } from 'contexts/VenueContext';

interface RedirectAllergenDialogProps {
  proceed: () => void;
}

export const RedirectAllergenDialog: React.FC<RedirectAllergenDialogProps> = ({
  proceed,
}) => {
  const { allergenPrompts } = usePhrases();
  const { selectedService } = useServices();

  const selectedPrompt = allergenPrompts.find(
    (prompt) => prompt.orderModeId === selectedService,
  );

  const description =
    selectedPrompt?.message ||
    'Please contact a member of staff to proceed with your order';

  return (
    <Dialog
      isOpen={true}
      title="What to do next?"
      description={description}
      datatestid="allergen-action-modal"
      accept={{
        action: proceed,
        text: 'OK',
        btnType: 'primary',
        datatestid: 'button-allergen-ok-action',
      }}
    />
  );
};
