import axios from 'axios';

export const apiWrapper = (
  request,
  brandToken = null,
  userToken = null,
  url,
  networkErrorTimeout,
  loyalty,
) =>
  axios({
    method: 'post',
    url,
    data: `request=${encodeURIComponent(
      JSON.stringify({
        request: {
          // bundleIdentifier: 'com.zonal.mt.staffordtest.stg', // 'com.zonal.forthstreet-uat',
          userDeviceIdentifier: 'web',
          version: '20',
          platform: 'web',
          ...request,
          ...loyalty,
        },
      }),
    )}`,
    headers:
      userToken !== null
        ? {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
            'X-Auth-BrandToken': brandToken,
            'X-Auth-UserToken': userToken,
          }
        : {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
            'X-Auth-BrandToken': brandToken,
          },
    timeout: request.method === 'checkBasket' ? networkErrorTimeout : null,
  }).catch((error) => error.toString());
