import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { useTables } from 'contexts/TableContext/TableContext';
import { useCheckoutDetails } from 'contexts/CheckoutContext';

export const DisplaySelectedTable: React.FC = () => {
  const { waitTimeEnabled } = useConfig();
  const { waitTime: waitTimePhrase } = usePhrases();
  const { selectedTable } = useTables();
  const { waitTime } = useCheckoutDetails();

  let phraseToDisplay;

  if (waitTimePhrase && waitTime) {
    phraseToDisplay = waitTimePhrase.replace(/\$.*\$/g, waitTime.toString());
  }

  return (
    <div className="card collection" data-testid="card-collection">
      {`Your order will be sent to ${selectedTable?.label}. `}
      {waitTimeEnabled === true && phraseToDisplay && `${phraseToDisplay}`}
    </div>
  );
};
