import { useMenuModal } from 'contexts/MenuModalContext';
import { AztecProduct } from 'types/models';
import {
  getNestedChoice,
  getNestedProduct,
  getSelectedPortion,
  isChoiceInvalid,
  isProductInvalid,
} from 'menu/utils';

interface ConfirmButtonProps {
  aztecProduct: AztecProduct;
  handleConfirm: () => void;
}
export const ConfirmButton: React.FC<ConfirmButtonProps> = ({
  aztecProduct,
  handleConfirm,
}) => {
  const { breadcrumbs, setBreadcrumbs } = useMenuModal();
  const selectedPortion = getSelectedPortion(aztecProduct);
  const productIsInvalid = isProductInvalid(aztecProduct, []);

  const nestedProduct =
    breadcrumbs.length > 0 && getNestedProduct(aztecProduct, breadcrumbs);
  const nestedChoice =
    !nestedProduct && selectedPortion && breadcrumbs.length > 0
      ? getNestedChoice(getSelectedPortion(aztecProduct), breadcrumbs)
      : undefined;

  const isNestedOptionInvalid =
    (nestedProduct && isProductInvalid(nestedProduct, breadcrumbs)) ||
    (nestedChoice && isChoiceInvalid(nestedChoice)) ||
    (!nestedProduct && !nestedChoice);

  const handleNestedChoiceConfirm = () => {
    const breadcrumbCopy = [...breadcrumbs];
    breadcrumbCopy.splice(-1);
    setBreadcrumbs(breadcrumbCopy);
  };

  return breadcrumbs.length > 0 ? (
    <button
      className="btn btn-primary"
      disabled={isNestedOptionInvalid}
      onClick={handleNestedChoiceConfirm}
    >
      Confirm Selection
    </button>
  ) : (
    <button
      className="btn btn-primary"
      data-testid="menu-modal-footer-add-button"
      onClick={handleConfirm}
      disabled={productIsInvalid}
    >
      Add
    </button>
  );
};
