import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { CombinedVenue, ServiceType } from 'types/models';
import { ServiceButton } from 'venue/components/SingleVenue/ServiceButton';
import { VenueErrorPanel } from 'venue/components/SingleVenue/VenueErrorPanel';
import {
  getSupportedServices,
  isSalesAreaSupportingServices,
  isVenueAvailable,
  isVenueTemporarilyUnavailable,
} from 'venue/utils';

interface ServiceListProps {
  venue: CombinedVenue;
  handleClick: (venue: CombinedVenue, serviceId: number) => void;
}

export const ServiceList: React.FC<ServiceListProps> = ({
  venue,
  handleClick,
}) => {
  const {
    enableClickAndCollect,
    enableDeliveryLocation,
    enableOrderAndPay,
    supportsOrderDestinations,
  } = useConfig();

  const { venueTitle } = usePhrases();

  const supportedServices = getSupportedServices(
    venue.services,
    enableOrderAndPay,
    enableClickAndCollect,
    enableDeliveryLocation,
  );

  const salesAreaSupportsServices = isSalesAreaSupportingServices(
    venue,
    supportedServices,
  );

  if (
    !isVenueAvailable(supportsOrderDestinations, venue) ||
    supportedServices.length === 0 ||
    !salesAreaSupportsServices
  ) {
    return (
      <VenueErrorPanel
        title={`Ordering at this ${venueTitle} is currently unavailable`}
      />
    );
  }

  if (isVenueTemporarilyUnavailable(venue)) {
    return (
      <VenueErrorPanel
        title={`Ordering at this ${venueTitle} is temporarily unavailable`}
      />
    );
  }

  const sortedServices = [...supportedServices].sort((a, b) => a - b);

  const hasPmbEnabled = sortedServices.includes(ServiceType.PayMyBill);
  const hasOtherOrderModes =
    sortedServices.length - (hasPmbEnabled ? 1 : 0) > 0;

  return (
    <div className="service-list panel panel-default">
      {hasOtherOrderModes ? (
        <>
          <h3>How would you like to order?</h3>
          <hr />
          <div className="service-list-btns">
            {sortedServices
              .filter((service) => service !== ServiceType.PayMyBill)
              .map((service) => (
                <ServiceButton
                  venue={venue}
                  serviceId={service}
                  key={service}
                  handleClick={handleClick}
                />
              ))}
          </div>
        </>
      ) : null}
     {/* Currently don't want to show this button */}
      {/* {hasPmbEnabled ? (
        <>
          <h3>Ready to pay?</h3>
          <hr />
          <ServiceButton
            venue={venue}
            serviceId={ServiceType.PayMyBill}
            key={ServiceType.PayMyBill}
            handleClick={handleClick}
          />
        </>
      ) : null} */}
    </div>
  );
};
