import { Button } from 'react-bootstrap';
import { ChoiceType, PortionRatio } from 'types/models';
import { QuantityField } from 'menu/components/MenuModal/ModalComponents';
import { useMenuModal } from 'contexts/MenuModalContext';

interface MenuChoiceCustomiserProps {
  choiceGroupType: ChoiceType;
  isSelected: boolean;
  choiceGroupIndex: number;
  choiceIndex: number;
  disabled: boolean;
  quantity: number;
  portionRatios: PortionRatio[];
  maxForSelection: number | undefined;
  hasChoices: boolean;
  portionId: number;
  updateChoice: (
    choiceIndex: number,
    choiceGroupIndex: number,
    quantity?: number,
  ) => void;
}

export const MenuChoiceCustomiser: React.FC<MenuChoiceCustomiserProps> = ({
  choiceGroupType,
  isSelected,
  choiceGroupIndex,
  choiceIndex,
  disabled,
  quantity,
  portionRatios,
  maxForSelection,
  hasChoices,
  portionId,
  updateChoice,
}) => {
  const { breadcrumbs, setBreadcrumbs } = useMenuModal();

  const onChoiceCustomiserClick = (
    choiceIndex: number,
    choiceGroupIndex: number,
    portionId: number,
    portionRatios: PortionRatio[],
  ) => {
    const breadcrumbTrack = {
      choiceIndex: choiceIndex,
      choiceGroupIndex: choiceGroupIndex,
      portionId: portionId,
      portionRatios: portionRatios,
    };
    setBreadcrumbs([...breadcrumbs, breadcrumbTrack]);
  };

  const onUpdateChoiceQuantity = (
    choiceIndex: number,
    choiceGroupIndex: number,
    quantity: number,
  ) => {
    updateChoice(choiceIndex, choiceGroupIndex, quantity);
  };

  if (hasChoices) {
    return (
      <Button
        bsStyle="primary"
        bsSize="xsmall"
        disabled={disabled}
        onClick={() =>
          onChoiceCustomiserClick(
            choiceIndex,
            choiceGroupIndex,
            portionId,
            portionRatios,
          )
        }
      >
        Change
      </Button>
    );
  } else if (choiceGroupType === 'Multiple' && isSelected) {
    const min = 1;

    if (maxForSelection !== min) {
      return (
        <QuantityField
          callbackArgs={[choiceIndex, choiceGroupIndex]}
          onChange={onUpdateChoiceQuantity}
          quantity={quantity}
          min={min}
          max={maxForSelection}
        />
      );
    }
  }

  return null;
};
