import { useEffect, useState } from 'react';
import { Dialog } from 'components/Dialog';
import { selectCookiesDisabledAcknowledgement } from 'core/selectors';
import { setCookiesDisabledAcknowledgement } from 'core/actions';
import { useConfig } from 'contexts/ConfigContext';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

export const DisabledCookiesDialog: React.FC = () => {
  const { privacyPolicyUrl } = useConfig();
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const dialogClosed = useSelector(selectCookiesDisabledAcknowledgement);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!navigator.cookieEnabled && !dialogClosed) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, dialogClosed]);

  useEffect(() => {
    if (pathname.includes('/ff') || pathname.includes('/cookie-notice')) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  //   This is a href and not a link so it won't rerender this every time the route changes
  const cookieLink = (
    <a target="_blank" rel="noreferrer" href={'/#/cookie-notice'}>
      Cookies Policy
    </a>
  );

  const privacyPolicyLink = (
    <a href={privacyPolicyUrl} target="_blank" rel="noreferrer">
      Privacy Policy
    </a>
  );

  return (
    <Dialog
      datatestid="cookies-disabled-dialog"
      isOpen={isOpen}
      title="Cookies Disabled"
      description={
        <>
          <p>
            You have cookies disabled in your browser. While you can continue to
            use our site, some features that require necessary cookies may not
            be available.
          </p>
          {privacyPolicyUrl && privacyPolicyUrl.trim().length > 0 ? (
            <p>
              For more information, please view our {cookieLink} or{' '}
              {privacyPolicyLink}.
            </p>
          ) : (
            <p>For more information, please view our {cookieLink}.</p>
          )}
        </>
      }
      reject={{
        action: () => dispatch(setCookiesDisabledAcknowledgement(true)),
        text: 'Close',
      }}
    />
  );
};
